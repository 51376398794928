import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import Loader from "./Loader.web";

interface RoomBlock {
  [date: string]: RoomAvailability[] | string
}

interface RoomAvailability {
  name: string;
  total: number;
  avaialble: number;
  total_spaces: number;
  available:number


}

interface HotelAttributes {
  cover_photo: string | undefined;
  name: string;
  dog_rooms: RoomBlock[];
  cat_rooms: RoomBlock[];
  spaces_of_hotel: RoomBlock[]
}

interface Hotel {
  id: string;
  type: string;
  attributes: HotelAttributes;
}

interface Room {
  name: string;
  total: number;
  avaialble: number;
  available:number

}
interface DayOfWeek {
  dayOfWeek: string;
  dayOfMonth: number;
}

interface myProps {
  daysOfWeek: DayOfWeek[];
  currentDate: Date;
  isWeek: boolean;
  calendarData: Hotel[];
  startOfWeek: Date;
  pagLoader: boolean;
  calendarDayData: Hotel[]
}

export default function CalendarView(props: myProps) {
  const { currentDate, daysOfWeek, isWeek, pagLoader, calendarData, startOfWeek, calendarDayData } = props

  const getFormattedDate = (date: Date) => {
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const adjustedDay = (date.getDay() + 6) % 7; // Adjusting the day to start from Monday
    return `${daysOfWeek[adjustedDay]}, ${date.getDate()}`;
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const getFormattedDay = (currentDate: Date, daysToAdd: number): string => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + daysToAdd);
    return formatDate(newDate);
  };
  const weekDays = Array.from({ length: 7 }, (_, i) => getFormattedDay(startOfWeek, i));
  const formatedDate: any = formatDate(currentDate);
  const formattedDay = getFormattedDate(currentDate);
  const [dayOfWeek, dayOfMonth] = formattedDay.split(", ");
  const formatAvailability = (available: number): string => {
    if (available === -1) return "00";
    return available < 10 ? `0${available}` : `${available}`;
  };

  return (
    <>
      {isWeek ?
        <Box
          display="grid"
          gridTemplateColumns="102px repeat(7, 1fr)"
          style={{ gap: "3px" }}
        >
          <Box display="contents">
            <Box
              style={{
                fontWeight: "bold",
                padding: "10px",
                backgroundColor: "white",
                fontSize: "12px",
                borderRadius: "5px",
                color: "#999999",
                textAlign: "center",
              }}
            >
              Hotel Property
            </Box>
            {daysOfWeek.map((day, idx) => (
              <Box
                key={idx}
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  backgroundColor: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  textAlign: "center",
                  color: "#999999",
                }}
              >
                <div>{day.dayOfWeek}</div>
                <div>{day.dayOfMonth}</div>
              </Box>
            ))}
          </Box>{
            calendarData?.map((hotel, idx) => {
              return (
                <>
                  <Box key={idx} display="contents">
                    <Box
                      style={{
                        backgroundColor: "white",
                        textAlign: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      <img
                        src={hotel?.attributes?.cover_photo}
                        alt="img"
                        style={{
                          width: "75px",
                          height: "auto",
                          borderRadius: "9%",
                          marginTop: "3rem",
                          marginBottom: "1rem"
                        }}
                      />
                      <Box style={{
                        fontWeight: 600,
                        fontSize: "11px",
                        marginTop: "8px",
                        marginBottom: "1rem"
                      }}>
                        {hotel?.attributes?.name}
                      </Box>
                    </Box>
                    {weekDays?.map(day => {
                      const dogRoomData = hotel.attributes.dog_rooms.find(room => room[day]);
                      const catRoomData = hotel.attributes.cat_rooms.find(room => room[day]);
                      const dayCareSpacesData = hotel.attributes.spaces_of_hotel?.find(room => room[day]);

                      const dayCareSpacesStatus = dayCareSpacesData ? dayCareSpacesData[day] === 'blocked_days'
                        ? 'blocked_days'
                        : (dayCareSpacesData[day] as Room[]).map(room => ({
                          
                          available: room.available,
                        })) :
                        "No spaces available"
                      const dogRoomStatus = dogRoomData
                        ? dogRoomData[day] === 'blocked_days'
                          ? 'blocked_days'
                          : (dogRoomData[day] as Room[]).map(room => ({
                            name: room.name,
                            available: room.avaialble,
                          }))
                        : 'no available days';



                      const catRoomStatus = catRoomData
                        ? catRoomData[day] === 'blocked_days'
                          ? 'blocked_days'
                          : (catRoomData[day] as Room[]).map(room => ({
                            name: room.name,
                            available: room.avaialble,
                          }))
                        : 'no available room';

                      const isBlocked = (dogRoomStatus === 'blocked_days' && catRoomStatus === 'blocked_days' && dayCareSpacesStatus === 'blocked_days');
                      const isNoAvailability = dogRoomStatus === 'no available days' && catRoomStatus === 'no available room' && dayCareSpacesStatus === 'No spaces available';

                      return (
                        <Box
                          key={day}
                          style={{
                            backgroundColor: (dogRoomStatus === 'blocked_days' || catRoomStatus === 'blocked_days' || dayCareSpacesStatus === 'blocked_days') ? "#FDE5E5" : "rgb(237, 250, 255)",
                            border: "7px solid white",
                            paddingBottom: (dogRoomStatus === 'blocked_days' || catRoomStatus === 'blocked_days' || dayCareSpacesStatus === 'blocked_days') ? "0rem" : "5rem"
                          }}
                        >
                          {(dogRoomStatus === 'blocked_days' || catRoomStatus === 'blocked_days' || dayCareSpacesStatus === 'blocked_days') && (
                            <Box
                              style={{
                                padding: "10px",
                                fontSize: "12px",
                                color: "red",
                                fontWeight: 600,
                              }}
                            >
                              Blocked Days
                            </Box>
                          )}


                          {!isNoAvailability && dayCareSpacesStatus !== 'No spaces available' && (
                            dayCareSpacesStatus !== 'blocked_days' ? (dayCareSpacesStatus as { available: number, total_spaces: number }[]).map((room, index) => (
                              <Box
                                key={index}
                                style={{
                                  display: "flex",
                                  padding: "6px",
                                  justifyContent: "center"
                                }}>
                                <Box
                                  style={{
                                    marginTop: "1px",
                                    alignContent: "center",
                                  }}
                                >
                                  <Box
                                    style={{
                                      padding: "5px 18px",
                                      marginBottom: "5px",
                                      backgroundColor: "transparent",
                                      borderRadius: "5px",
                                      border: "2px solid rgb(228 36 139)",
                                      textAlign: "center",
                                      fontSize: "10px",
                                      color: "#EA0C78",
                                      fontWeight: 700,
                                      marginTop: "2px"
                                    }}
                                  >
                                    {formatAvailability(room.available)} Available
                                  </Box>
                                </Box>
                              </Box>
                            )) :
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "center"
                                }}>

                              </Box>
                          )}
                          {!isNoAvailability && dogRoomStatus !== 'no available days' && (
                            dogRoomStatus !== 'blocked_days' ? (dogRoomStatus as { name: string, available: number }[]).map((room, index) => (
                              <Box
                                key={index}
                                style={{
                                  display: "flex",
                                  padding: "6px",
                                  justifyContent: "center"
                                }}>
                                <Box
                                  style={{
                                    padding: "3px",
                                    backgroundColor: "#65C9F1",
                                    color: "white",
                                    borderRadius: "5px",
                                    margin: "3px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      // textTransform: "uppercase",
                                      fontWeight: 700,
                                      fontSize: "12px",
                                      marginTop: "2px",
                                    }}>
                                    {room?.name}
                                  </Typography>
                                </Box>
                                <Box
                                  style={{
                                    marginTop: "1px",
                                    alignContent: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: 700,
                                      margin: "3px"
                                    }}
                                  >
                                    Dog Rooms
                                  </Typography>
                                  <Box
                                    style={{
                                      padding: "5px 18px",
                                      marginBottom: "5px",
                                      backgroundColor: "transparent",
                                      borderRadius: "5px",
                                      border: "2px solid rgb(228 36 139)",
                                      textAlign: "center",
                                      fontSize: "10px",
                                      color: "#EA0C78",
                                      fontWeight: 700,
                                      marginTop: "2px"
                                    }}
                                  >
                                    {formatAvailability(room.available)} Available
                                  </Box>
                                </Box>
                              </Box>
                            )) :
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "center"
                                }}>

                              </Box>
                          )}
                          {!isNoAvailability && catRoomStatus !== 'no available room' && (
                            catRoomStatus !== 'blocked_days' ? (catRoomStatus as { name: string, available: number }[]).map((room, index) => (
                              <Box
                                key={index}
                                style={{
                                  display: "flex",
                                  padding: "6px",
                                  justifyContent: "center"
                                }}>
                                <Box
                                  style={{
                                    padding: "3px",
                                    backgroundColor: "#65C9F1",
                                    color: "white",
                                    borderRadius: "5px",
                                    margin: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      // textTransform: "uppercase",
                                      fontWeight: 700,
                                      width: "50%",
                                      fontSize: "12px",
                                      marginTop: "2px",
                                    }}>
                                    {room?.name}
                                  </Typography>
                                </Box>
                                <Box
                                  style={{
                                    marginTop: "1px",
                                    alignContent: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: 700,
                                      margin: "3px"
                                    }}
                                  >
                                    Cat Rooms
                                  </Typography>
                                  <Box
                                    style={{
                                      padding: "5px 18px",
                                      marginBottom: "5px",
                                      backgroundColor: "transparent",
                                      borderRadius: "5px",
                                      border: "2px solid rgb(228 36 139)",
                                      textAlign: "center",
                                      fontSize: "10px",
                                      color: "#EA0C78",
                                      fontWeight: 700,
                                      marginTop: "2px",
                                    }}
                                  >
                                    {formatAvailability(room.available)} Available
                                  </Box>
                                </Box>
                              </Box>
                            )) :
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "center"
                                }}>
                              </Box>
                          )}
                          {!isBlocked && isNoAvailability && (
                            <Typography style={{ fontSize: "12px", color: "grey", textAlign: "center", marginTop: "20px" }}>
                            </Typography>
                          )}
                        </Box>
                      );
                    })}

                  </Box>
                </>
              )
            })
          }
        </Box> : <Box >
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "102px 1fr",
              gap: "3px",
            }}
          >
            <Box
              style={{
                fontWeight: "bold",
                padding: "10px",
                backgroundColor: "white",
                fontSize: "12px",
                borderRadius: "5px",
                color: "#999999",
                textAlign: "center",
              }}
            >
              Hotel Property
            </Box>
            <Box
              style={{
                fontWeight: "bold",
                padding: "10px",
                backgroundColor: "white",
                fontSize: "12px",
                borderRadius: "5px",
                textAlign: "center",
                alignContent: "center",
                color: "#999999",
              }}
            >
              <Typography style={{ fontWeight: 700, fontSize: "12px", }}>
                {dayOfWeek}
              </Typography>
              <Typography style={{ fontWeight: 700, fontSize: "12px", }}>
                {dayOfMonth}
              </Typography>
            </Box>
          </Box>
          {
            calendarDayData?.map((hotel, index) => {
              const dogRoomData = hotel.attributes.dog_rooms[formatedDate];
              const dogRoomStatus = dogRoomData
                ? Array.isArray(dogRoomData)
                  ? dogRoomData.map(room => ({
                    name: room.name,
                    total: room.total,
                    available: room.avaialble,
                  }))
                  : 'blocked_days'
                : 'no available days';

              const catRoomData = hotel.attributes.cat_rooms[formatedDate];

              const catRoomStatus = catRoomData
                ? Array.isArray(catRoomData)
                  ? catRoomData.map(room => ({
                    name: room.name,
                    total: room.total,
                    available: room.avaialble,
                  }))
                  : 'blocked_days'
                : 'no available days';
              const dayCareSpacesData = hotel?.attributes?.spaces_of_hotel?.[formatedDate];

              const dayCareSpaceStatus = dayCareSpacesData
                ? Array.isArray(dayCareSpacesData)
                  ? dayCareSpacesData.map(room => ({
                    available: room.available,
                  }))
                  : 'blocked_days'
                : 'No spaces available';

              const isBlocked = (dogRoomStatus === 'blocked_days' && catRoomStatus === 'blocked_days' && dayCareSpaceStatus === "blocked_days");
              const isNoAvailability = (dogRoomStatus === 'no available days' && catRoomStatus === 'no available days' && dayCareSpaceStatus === "No spaces available");

              return (
                <React.Fragment key={index}>
                  <Box
                    style={{
                      display: "grid",
                      gridTemplateColumns: "102px 1fr",
                      gap: "3px",
                      marginTop: "3px",
                    }}
                  >
                    <Box
                      style={{
                        textAlign: "center",
                        backgroundColor: "white",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <img
                        src={hotel?.attributes?.cover_photo}
                        alt="img"
                        style={{
                          width: "75px",
                          height: "auto",
                          borderRadius: "9%",
                          marginTop: "3rem",
                        }}
                      />
                      <Typography variant="subtitle2" style={{ fontWeight: 600, fontSize: "11px", marginTop: "8px" }}>
                        {hotel.attributes.name}
                      </Typography>
                    </Box>
                    {(dogRoomStatus === 'blocked_days' && catRoomStatus === 'blocked_days' && dayCareSpaceStatus === 'blocked_days') && (
                      <Box
                        style={{
                          backgroundColor: "#FDE5E5",
                          padding: "20px",
                          borderRadius: "5px",
                          color: "red",
                        }}
                      >
                        <Typography style={{ marginTop: "9rem" }}>
                          Blocked days
                        </Typography>
                      </Box>
                    )}
                    {!isBlocked && isNoAvailability && (
                      <Box
                        style={{
                          backgroundColor: "rgb(237, 250, 255)",
                          padding: "20px",
                          borderRadius: "5px",
                          color: "gray",
                          border: "9px solid white",
                        }}
                      >
                      </Box>
                    )}

                    <Box style={webStyle.layout as React.CSSProperties}>
                      {dayCareSpaceStatus !== 'No spaces available' && dayCareSpaceStatus !== 'blocked_days' && (
                        dayCareSpaceStatus.map((room, index) => (
                          <Box
                            key={index}
                            style={webStyle.innerLayout as React.CSSProperties}>
                            <Box
                              style={webStyle.dayBox as React.CSSProperties}
                            >
                              {formatAvailability(room.available)} Available
                            </Box>
                          </Box>

                        ))
                      )}

                      {dogRoomStatus !== 'no available days' && dogRoomStatus !== 'blocked_days' && (
                        dogRoomStatus.map((room, index) => (
                          <Box
                            key={index}
                            style={{
                              padding: "10px",
                              borderRadius: "10px",
                              display: "flex",
                              gap: "30px",
                              backgroundColor: "rgb(237, 250, 255)",
                              border: "9px solid white",
                            }}
                          >
                            <Box
                              style={{
                                placeContent: "center",
                                textAlign: "center",
                                height: "132px",
                                width: "153px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                backgroundColor: "#65C9F1",
                                color: "white",
                                borderRadius: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              {room.name}
                            </Box>
                            <Box
                              style={{
                                marginTop: "10px",
                                alignContent: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  lineHeight: "22px",
                                  fontSize: "12px",
                                  fontWeight: 700,
                                }}
                              >
                                Dog Rooms
                              </Typography>
                              <Box style={{ display: "flex", gap: "8px" }}>
                                <Typography
                                  style={{
                                    lineHeight: "22px",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    alignSelf: "center",
                                  }}
                                >
                                  Total{" "}
                                </Typography>
                                <Typography
                                  style={{
                                    fontWeight: 700,
                                    fontSize: "12px",
                                  }}
                                >
                                  {formatAvailability(room.total)}
                                </Typography>
                              </Box>
                              <Box
                                style={{
                                  padding: "7px 28px",
                                  marginBottom: "5px",
                                  backgroundColor: "transparent",
                                  borderRadius: "5px",
                                  border: "2px solid rgb(228 36 139)",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  color: "#EA0C78",
                                  fontWeight: 700,
                                  marginTop: "7px",
                                }}
                              >
                                {formatAvailability(room.available)} Available
                              </Box>
                            </Box>
                          </Box>
                        ))
                      )}
                      {catRoomStatus !== 'no available days' && catRoomStatus !== 'blocked_days' && (
                        catRoomStatus.map((room, index) => (
                          <Box
                            key={index}
                            style={{
                              padding: "10px",
                              borderRadius: "10px",
                              display: "flex",
                              gap: "30px",
                              backgroundColor: "rgb(237, 250, 255)",
                              border: "9px solid white",
                            }}
                          >
                            <Box
                              style={{
                                placeContent: "center",
                                textAlign: "center",
                                height: "132px",
                                width: "153px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                backgroundColor: "#65C9F1",
                                color: "white",
                                borderRadius: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              {room.name}
                            </Box>
                            <Box
                              style={{
                                marginTop: "10px",
                                alignContent: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  lineHeight: "22px",
                                  fontSize: "12px",
                                  fontWeight: 700,
                                }}
                              >
                                Cat Rooms
                              </Typography>
                              <Box style={{ display: "flex", gap: "8px" }}>
                                <Typography
                                  style={{
                                    lineHeight: "22px",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    alignSelf: "center",
                                  }}
                                >
                                  Total{" "}
                                </Typography>
                                <Typography
                                  style={{
                                    fontWeight: 700,
                                    fontSize: "12px",
                                  }}
                                >
                                  {formatAvailability(room.total)}
                                </Typography>
                              </Box>
                              <Box
                                style={{
                                  padding: "7px 28px",
                                  marginBottom: "5px",
                                  backgroundColor: "transparent",
                                  borderRadius: "5px",
                                  border: "2px solid rgb(228 36 139)",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  color: "#EA0C78",
                                  fontWeight: 700,
                                  marginTop: "7px",
                                }}
                              >
                                {formatAvailability(room.available)} Available
                              </Box>
                            </Box>
                          </Box>
                        ))
                      )}
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })
          }

        </Box>}
      <Loader loading={pagLoader} />
    </>
  )
}

const webStyle = {
  layout: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "2px",
  },
  innerLayout: {
    padding: "10px",
    borderRadius: "10px",
    display: "flex",
    gap: "30px",
    backgroundColor: "rgb(237, 250, 255)",
    border: "9px solid white",
  },
  dayBox: {
    padding: "7px 28px",
    marginBottom: "5px",
    backgroundColor: "transparent",
    borderRadius: "5px",
    border: "2px solid rgb(228 36 139)",
    textAlign: "center",
    fontSize: "12px",
    color: "#EA0C78",
    fontWeight: 700,
    marginTop: "7px",
  }
}