import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { Avatar, Button, Grid, withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import Footer from "../../../components/src/Footer.web";
import { profileIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { theme } from "../../../components/src/theme";
import BussinessOwnerSideBar from "../../../components/src/SideBar";
// Customizable Area End
import BOwnerDetailsController, { Props,configJSON } from "./BOwnerDetailsController.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";


// Customizable Area Start

// Customizable Area End

export default class OwnerDetails extends BOwnerDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenu active={0} navigation={this.props.navigation} id="1" />
        <div style={styles.reservationContent as React.CSSProperties} className="mobile-margin">

          <div style={{ maxWidth: "900px", margin: 'auto' }}>
            <Grid
              container
              style={{ backgroundColor: "#fff" }}
            >
              <Grid
                style={styles.layout}
              >
                <Grid item>
                  <Avatar src={this.state.imageUrl} style={styles.imageAvatar} />
                </Grid>

                <Grid item style={{ marginLeft: "7%" }}>

                  <Typography
                    style={{
                      fontSize: "30px",
                      fontWeight: 700,
                      color: "white",
                    } as React.CSSProperties}
                    data-test-id='welcomeText'
                  >
                   {configJSON.welcomeText}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      color: "white",
                    } as React.CSSProperties}
                  >
                   {configJSON.welcomeSubtext}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                padding: "8% 10%",
                backgroundColor: "white",
                borderBottomRightRadius: "35px",
              }}
            >
              <Grid style={{ marginBottom: "6px" }}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: 400,
                    color: "#334155",
                  }}
                >
                  Start a New Listing
                </Typography>
              </Grid>

              <CssLinearProgress
                style={{ width: "100%", color: "#54A7C9" }}
                variant="determinate"
                value={0}
              />

              <Grid>
                <h2 style={{ color: "#334155", fontSize: "30px", fontWeight: 700, fontFamily: "inter" }}>
                  Getting started with &nbsp;
                  <Typography display="inline" variant="h3" color="secondary"
                    style={{ fontWeight: 700 }}
                  >PETHOTEL &nbsp;
                  </Typography>

                  is simple
                </h2>
              </Grid>
              {this.state.stepdata.map((value: {
                attributes: any; "": boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
              }, index: any) => {
                return (
                  <>
                    <Grid container style={styles.innerBox as React.CSSProperties}>
                      <div style={{ padding: "0px 7%", textAlign: "center", minWidth: "50px" }}>
                        <Typography style={styles.textNumberFont}>{value?.attributes?.order_number}.</Typography>
                      </div>
                      <div style={{ padding: "10px 20px 0px" }}>
                        <Typography style={styles.textHeadFont}>
                          {value?.attributes?.title}
                        </Typography>
                        <Typography style={styles.textFont as React.CSSProperties}>
                          {value?.attributes?.description}
                        </Typography>
                      </div>
                    </Grid>
                  </>
                )

              })}


              <Grid style={styles.buttonGrid}>
                <Grid style={{ display: "flex", alignItems: "center" }}>
                  <Link to="/ContentManagementBo">
                    <Button
                      size="small"
                      onClick={this.handleBack}
                      style={styles.backButton as React.CSSProperties}
                      data-test-id="handleBack"
                    >
                      <KeyboardArrowLeftIcon /> Back
                    </Button>
                  </Link>
                </Grid>

                <Grid>
                  <Button
                    size="small"
                    onClick={this.handleNext}
                    disabled={this.state.activeStep === 3}
                    style={styles.nextBtn as React.CSSProperties}
                    data-test-id="handleNext"
                  >
                    {" "}
                    Register Your Business <KeyboardArrowRightIcon />{" "}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid item xs={12} style={styles.footerStyle as React.CSSProperties}>
            <Footer color="#E0F4FC" footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />

          </Grid>
        </div>
        <Loader loading={this.state.firstLoader} />

      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const CssLinearProgress = withStyles({
  root: {
    backgroundColor: "#B2E4F8",
  }
})(LinearProgress);


const styles = {
  avtartStayle: {
    width: "90px",
    height: "90px",
    fontSize: "35px",
    fontWeight: 600,
    color: "#B2E4F8",
    backgroundColor: "#65C9F1",
  },
  innerBox: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#E0F4FC",
    padding: "10px",
    borderRadius: "8px",
    marginTop: "20px",
    width: "100%",
    flexWrap: "nowrap",
    minHeight: "110px",
  },
  textNumberFont: { fontSize: "45px", color: "#326478", fontWeight: 400 },
  textHeadFont: { fontSize: "16px", color: "#334155", fontWeight: 700, paddingBottom: "5px" },
  textFont: {
    fontSize: "16px",
    color: "#334155",
    fontWeight: 400,
    lineHeight: "24px",
    wordWrap: 'anywhere'
  },
  buttonGrid: {
    marginTop: "12%",
    display: "flex",
    justifyContent: "space-between",
  },
  nextBtn: {
    fontSize: "16px",
    backgroundColor: "#EA0C78",
    color: "white",
    padding: "5px 10px",
    minWidth: "210px",
    textTransform: "none",
    fontFamily: "inter",
    fontWeight: 700,
    paddingLeft: "17px"
  },
  backButton: {
    fontSize: "16px",

    color: "#0F172A",

    textTransform: "none",
    fontFamily: "inter",
    fontWeight: 700,
  },
  reservationContent: {
    marginLeft: "140px", // Adjust based on the width of your sidebar
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    overflowY: "auto", // Allow main reservationContent area to scroll
  },
  footerStyle: {
    marginTop: "20%"
  },
  imageAvatar: {
    width: '80px',
    height: "80px"
  },
  layout: {
    padding: "3% 10%",
    backgroundColor: "#54A7C9",
    height: "auto",
    borderBottomRightRadius: "35px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  }
};
// Customizable Area End
