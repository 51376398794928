import React from "react";
// Customizable Area Start
import {
    Box, Button, Grid, Typography
} from "@material-ui/core";
import { colors, theme } from "../../../components/src/theme";
export const configsJSON = require("./config");
import SubscriptionDailogs from "./SubscriptionDailogs.web";
import SubscriptionController, { Props } from "./SubscriptionController.web";
import moment from "moment";



// Customizable Area End

// Customizable Area Start

// Customizable Area End


export default class SubscriptionSettings extends SubscriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderBoxContent = (name: string, duration_type: string, status: string, price: number, transaction_fee?: number) => {
        return (
            <>
                <Box style={webStyle.detailsBox}>
                    <Grid container style={webStyle.boxContent as React.CSSProperties}>
                        <Grid item xs={12}>
                            <Typography variant="body1" style={webStyle.subTitleText}>{name}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" alignItems="center" spacing={4}>
                                <Grid item >
                                    <Typography
                                        variant="h6"
                                        style={webStyle.title}
                                        data-test-id="price_transaction_fee"
                                    >
                                        {name}
                                        {duration_type !== "commission_base" ? (
                                            ` | £${price.toFixed(2)}`
                                        ) : (
                                            transaction_fee
                                                ? ` | ${transaction_fee.toFixed(2)}%`
                                                : ""
                                        )}
                                    </Typography>

                                </Grid>
                                {status === 'active' &&

                                    <Grid item>
                                        <Box style={webStyle.activeBox}>
                                            <Typography variant="subtitle2" style={webStyle.activeText}>
                                                <div style={webStyle.activeDot} />&nbsp;
                                                Active</Typography>
                                        </Box>

                                    </Grid>}
                                {status === 'cancelled' &&

                                    <Grid item>
                                        <Box style={webStyle.cancelledBox}>
                                            <Typography variant="subtitle2" style={webStyle.cancelledText}>
                                                <div style={webStyle.cancelledDot} />&nbsp;Cancelled
                                            </Typography>
                                        </Box>

                                    </Grid>}
                                {status === 'done' &&

                                    <Grid item>
                                        <Box style={webStyle.activeBox}>
                                            <Typography variant="subtitle2" style={webStyle.activeText}>
                                                <div style={webStyle.activeDot} />&nbsp;Upcoming
                                            </Typography>
                                        </Box>

                                    </Grid>}


                            </Grid>

                        </Grid>

                    </Grid>
                </Box>
            </>
        )
    }
    renderChangedSubscription = () => {
        const { changeSubscription } = this.state;
        const { name, start_date, status, duration_type, price, transaction_fee } = changeSubscription
        return (
            <>
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={6} >
                            {this.renderBoxContent(name, duration_type, status, price, transaction_fee)}
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <Box style={webStyle.detailsBox}>
                                <Grid style={webStyle.boxContent as React.CSSProperties} container>
                                    <Grid item xs={12}>
                                        <Typography style={webStyle.subTitleText} variant="body1" >
                                            Activates on
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" style={webStyle.title}>

                                            {moment(start_date).format('Do MMM, YYYY')}

                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { userSubscription, userSubscriptionDetails, subscriptionList } = this.state
        const { status, end_date, change_subscription } = userSubscription;
        const { name, price, id, duration_type, transaction_fee } = userSubscriptionDetails
        const remainingPlans = subscriptionList.filter(plan => plan.id !== id)

        return (
            <>

                <Grid item xs={12} >
                    <Grid container >
                        {Object.keys(this.state.userSubscription).length === 0 ?
                            <>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h4" style={webStyle.title}>Current Plan</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} container justifyContent="flex-end">
                                    <Button variant="contained" color={'secondary'}
                                        data-test-id='create_plan'
                                        style={webStyle.containedButton as React.CSSProperties}
                                        onClick={this.createPlan}
                                    >
                                        Create Plan
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justifyContent="center" alignItems="center" style={webStyle.notFoundText} >
                                        <Grid item >
                                            <Typography variant="h5" align="center" style={webStyle.title}>No subscription found for hotel</Typography>
                                        </Grid>

                                    </Grid>

                                </Grid>
                            </> :
                            <>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography variant="h4" style={webStyle.title}>Current Plan</Typography>
                                        </Grid>
                                        {!change_subscription && <Grid item>
                                            <Button variant="contained" color={'secondary'}
                                                style={webStyle.containedButton as React.CSSProperties}
                                                onClick={() => this.handleSubscriptions('changePlan')}
                                                data-test-id='change_plan_button'
                                            >
                                                Change Plan
                                            </Button>
                                        </Grid>

                                        }
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container direction="row" alignItems="center" spacing={2}>
                                        <Grid item xs={12} sm={6} >
                                            {this.renderBoxContent(name, duration_type, status, price, transaction_fee)}


                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Box style={webStyle.detailsBox}>
                                                <Grid container style={webStyle.boxContent as React.CSSProperties}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" style={webStyle.subTitleText}>
                                                            {status === 'cancelled' ? "Ends on" : "Renews on"}
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item xs={12}>

                                                        <Typography variant="h6" style={webStyle.title}>
                                                            {duration_type === 'commission_base' ? "Until Plan Change" :
                                                                moment(end_date).format('Do MMM, YYYY')
                                                            }

                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Box>
                                        </Grid>


                                    </Grid>

                                </Grid>
                            </>}
                    </Grid>
                    {change_subscription &&
                        <>
                            {this.renderChangedSubscription()}
                        </>

                    }
                    {!change_subscription &&
                        <Box margin={'20px 0px 50px 0px'}>
                            <Grid item xs={12}>
                                <Grid container direction="row" justifyContent="flex-end" alignItems="center" >
                                    {status === 'active' && <Grid item>
                                        <Button variant="outlined" color={'secondary'}
                                            style={webStyle.outlinedButton as React.CSSProperties}
                                            onClick={() => this.handleSubscriptions('cancel')}
                                            data-test-id="cancel_button"
                                        >
                                            Cancel Subscription
                                        </Button>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Box>}
                </Grid>
                <SubscriptionDailogs
                    dailogOpen={this.state.dailogStatus}
                    dailogType={this.state.subscriptionPopUpType}
                    closeDailog={this.handleCloseSubscriptionDailog}
                    handleChangePopupPlan={this.handleChangePopupPlan}
                    cancelSubscription={this.cancelSubscription}
                    subscriptionList={remainingPlans}
                    subscriptionType={this.state.subscriptionType}
                    selectSubscription={this.selectSubscription}
                    changePlanPyment={this.changePlanPyment}
                    opnePlanDescription={this.opnePlanDescription}
                    descriptionDialog={this.state.descriptionDialog}
                    handleClose={this.closePlanDescription}
                    description={this.state.description} errorMessage={this.state.errorMessage} />
            </>
        );
        // Customizable Area End

    }
}

// Customizable Area Start
const webStyle = {
    mainContentLayout: {
        marginLeft: "140px",
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        overflowY: "auto",
    },
    contentLayout: {
        backgroundColor: theme.palette.common.white,
        height: 'auto',
        borderRadius: '0px 0px 50px 0px',
        padding: '60px'

    },
    title: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold
    },
    headingDivider: {
        backgroundColor: colors.primaryBlue,
        height: '3px',
        marginTop: 20
    },
    subTitleText: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightRegular,
        marginTop: '30px'
    },
    detailsBox: {
        border: "1px solid #B2E4F8",
        Padding: "20px, 16px, 20px, 16px",
        borderRadius: 8,
        height: "114px",
        width: '100%',
        margin: '10px 0px'
    },
    activeBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '32px',
        padding: '4px 12px',
        backgroundColor: '#D1FAE5',
        borderRadius: '200px'
    },
    activeText: {
        color: "#059669",
        fontWeight: theme.typography.fontWeightBold,
        display: 'flex',
        alignItems: 'center'
    },
    activeDot: {
        height: "8px",
        width: "8px",
        backgroundColor: '#059669',
        borderRadius: "50%",
        display: "inline-block"
    },
    cancelledText: {
        color: theme.palette.error.main,
        fontWeight: theme.typography.fontWeightBold,
        display: 'flex',
        alignItems: 'center'
    },
    cancelledBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '32px',
        padding: '4px 12px',
        backgroundColor: '#FEE2E2',
        borderRadius: '200px'
    },
    cancelledDot: {
        height: "8px",
        width: "8px",
        backgroundColor: theme.palette.error.main,
        borderRadius: "50%",
        display: "inline-block"
    },
    boxContent: {
        padding: '0 28px'
    },
    iconCursor: {
        cursor: 'pointer',
        color: "#94A3B8"
    },
    containedButton: {
        width: '180px',
        textTransform: 'capitalize',
        padding: '10px 16px',
        height: 56,
        borderRadius: 8,
        fontSize: 16,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        },
    }, outlinedButton: {
        border: `2px solid ${theme.palette.secondary.main}`,
        textTransform: 'capitalize',
        height: 56,
        borderRadius: 8,
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            border: `2px solid ${theme.palette.secondary.main}`,

        },
        width: '100%'
    },
    notFoundText: {
        height: '200px'
    }
}
// Customizable Area End