import React from "react";

// Customizable Area Start
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Typography, Checkbox, Grid, InputAdornment, withStyles, styled, Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { Link } from "react-router-dom";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const CssTextField = withStyles({
  root: {

    '& .MuiInput-underline:after': {
      borderBottomColor: '#54A7C9',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius: "8px"
      },

      '&.Mui-focused fieldset': {
        borderColor: '#54A7C9',
        borderWidth: "2px",
        borderRadius: "8px"
      },
    },
  },
})(TextField);
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";
import PetOwnerNavigationMenu from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";

export default class EmailAccountRegistrationWeb extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      confirmPassword,
      checkboxTrue,
      showPassword,
      showYourPassword,
      password,
      flashEmailSlack,
    } = this.state;

    const passwordValidationPo = this.validatePasswordPo();
    
    return (
      <>
        <PetOwnerNavigationMenu navType="auth" navigation={this.props.navigation} id="" />

        <CustomizedSnackbars duration={20000} openToaster={flashEmailSlack} message={" Please check your email to activate your account and get started"} toasterType={"success"} />

        <Grid
          container
          spacing={2}
          lg={12}
          xs={12}
          style={{ height: "auto", backgroundColor: "#F8FAFC", marginTop: "150px" }}
        >
          <Grid item style={styles.headMaingrid as React.CSSProperties}>
            <form onSubmit={this.SignUpApiForPo}>
              <Grid style={styles.headSecondgrid as React.CSSProperties}>
                <Typography
                  variant="h5"
                  style={styles.head as React.CSSProperties}
                >
                  Welcome to <span style={{ ...styles.head, color: "#EA0B77" } as React.CSSProperties}>PETHOTEL</span>
                </Typography>

                <Typography
                  variant="h6"
                  style={{ ...styles.head, fontSize: "20px", marginBottom: "10px" } as React.CSSProperties}
                >
                  Pet Owner Registration
                </Typography>

                <label style={styles.labelsEmailPassword as React.CSSProperties}>
                  Email
                </label>
                <br />
                <CssTextField
                  style={styles.inputEmailPassword as React.CSSProperties}
                  type="email"
                  placeholder="Your email"
                  variant="outlined"
                  value={this.state.email}
                  onChange={this.handleChangeEmail}
                  data-test-id="email-change"
                />
                <label style={styles.labelsEmailPassword as React.CSSProperties}>
                  Password
                </label>
                <br />

                <Typography variant="h1" color="initial" />

                <CssTextField
                  style={styles.inputEmailPassword as React.CSSProperties}
                  type={showPassword ? "text" : "password"}
                  placeholder="Your password"
                  variant="outlined"
                  value={password}
                  onChange={this.handleChangePassword}
                  data-test-id="password_change"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={this.handleTogglePassword}
                        data-test-id="togglePassword"
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon
                            style={{ color: "#94A3B8", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{ color: "#94A3B8", cursor: "pointer" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <br />
                <label style={styles.labelsEmailPassword as React.CSSProperties}>
                  Confirm Password
                </label>
                <br />
                <CssTextField
                  placeholder="Your password"
                  style={styles.inputEmailPassword as React.CSSProperties}
                  data-test-id="confirm_password"
                  variant="outlined"
                  type={showYourPassword ? "text" : "password"}
                  onChange={this.handleConfirmPassword}
                  name="confirmPassword"
                  value={confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        data-test-id="handle_pas_tgl"
                        position="end"
                        onClick={this.handleYourTogglePassword}
                      >
                        {showYourPassword ? (
                          <VisibilityOutlinedIcon
                            style={{ color: "#94A3B8", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{ color: "#94A3B8", cursor: "pointer" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                <div>
                  {[
                    {
                      isValid: passwordValidationPo.isLowerCaseLetter,
                      message: "At least one lowercase letter",
                    },
                    {
                      isValid: passwordValidationPo.isCapitalLetter,
                      message: "At least one capital letter",
                    },
                    {
                      isValid: passwordValidationPo.isMinLength,
                      message: "Minimum character length is 8 characters",
                    },
                    {
                      isValid: passwordValidationPo.isNumber,
                      message: "At least one number",
                    },
                    {
                      isValid: passwordValidationPo.isSpecialChracter,
                      message: "At least one special character"
                    }

                  ].map((validItem, idx) => (
                    <Grid
                      style={{
                        marginTop: "7px",
                        alignItems: "center",
                        display: "flex",
                        height: '25px'
                      }}
                      key={idx}
                    >
                      {validItem.isValid ? (
                        <CheckCircleIcon
                          style={{ color: "#34D399", marginRight: "5px" }}
                        />
                      ) : (
                        <>
                          {(password) && (
                            <RemoveCircleIcon
                              data-test-id='removeIcon'
                              style={{ color: "#DC2626", marginRight: "5px" }}
                            />)}
                        </>
                      )}
                      <span style={styles.errorMsgText}>{validItem.message}</span>
                    </Grid>
                  ))}
                </div>

                <br />
                <div style={{ marginTop: "10px", marginBottom: "15px", marginLeft: "-10px" }}>
                  <Checkbox
                    data-test-id="checkBoxCheck"
                    onChange={this.handleChangeCheckbox}
                    checked={checkboxTrue}
                    inputProps={{ "aria-label": "checkbox with default color" }}
                    color="default"
                    icon={<CustomIcon />}
                    checkedIcon={<CustomCheckedIcon />}
                  />

                  <label
                    style={{
                      color: "#64748B",
                      font: "inter",
                      textTransform: "none",
                      fontFamily: "inter",
                    }}
                  >
                    {" "}
                    I agree with{" "}

                    <span style={styles.privacyPolicy as React.CSSProperties} onClick={() => this.navigates("PrivacyPolicy")} data-test-id="navigatesf">
                      Privacy Policy{" "}
                    </span>
                    and{" "}

                    <span
                      style={styles.termsAndConditions as React.CSSProperties}
                      onClick={() => this.navigates("TermsConditions")}
                      data-test-id="navigatesS"
                    >
                      Terms & Conditions
                    </span>

                  </label>
                </div>
                {this.state.apiError && (
                  <Typography color="error" style={{ padding: "10px" }}>
                    {this.state.apiError}
                  </Typography>
                )}

                <button
                style={{
                  ...(styles.button as React.CSSProperties),
                  color: this.isButtonDisabled(passwordValidationPo.isValid) ?"#64748B":"#ffffff",
                  backgroundColor: this.isButtonDisabled(passwordValidationPo.isValid)?"#F1F5F9":"#EA0C78",
                }}
                  disabled={this.isButtonDisabled(passwordValidationPo.isValid)}
                  onClick={(event)=>this.handleApiCall(event,passwordValidationPo.isValid)}
                  data-test-id="handle_continue_click"
                >
                  Continue
                </button>
                <div style={styles.alreadyAccount as React.CSSProperties}>
                  <label style={styles.one15 as React.CSSProperties}>
                    Already have an account?{" "}
                    <Link to="/LogInPetOwner" style={{ textDecorationLine: "none" }}>
                      <span style={styles.signIn}>Sign In</span>{" "}
                    </Link>{" "}
                  </label>
                </div>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

const CustomIcon = styled(Box)(({ theme }) => ({
  width: 20,
  border: '1px solid #326478',
  borderRadius: 6,
  outline: 'none',
  height: 20,
  marginLeft: 5,
  backgroundColor: "#fff",
}));

const CustomCheckedIcon = styled(CheckBoxIcon)(({ theme }) => ({
  width: 28,
  color: "#334155",
  backgroundColor: "#fff",
  borderRadius: 6,
  height: 28,
}));

const styles = {
  // Customizable Area Start
  errorMsgText: {
    fontFamily: "inter",
    color: "#1E293B",
    fontSize: "12px",
  },
  mainButton: {
    width: "100%",
    height: "48px",
    fontFamily: "inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "8px",
    border: "none",
    marginTop: '112px'
  },
  inputEmailPassword: {
    width: "100%",
    height: "40px",
    marginBottom: "40px",
    borderRadius: "5px",
    fontSize: "14px",
    marginTop: "5px",
    fontWeight: 400,
  },
  button: {
    width: "100%",
    height: "40px",
    marginBottom: "15px",
    borderRadius: "5px",
    fontSize: "14px",
    marginTop: "5px",
    border: "none",
    backgroundColor: "#F1F5F9",
    color: "#64748B",
    fontWeight: 700,
    cursor: "pointer",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
  },
  labelsEmailPassword: {
    color: "#334155",
    fontWeight: 700,
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
  },
  appBar: {
    backgroundColor: "transparent",
    color: "#334155",
    boxShadow: "none",
  },
  navDiv: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  actionDiv: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  signBtn: {
    border: "1px solid #64748B",
    color: "#64748B",
    backgroundColor: "#FFFFFF",
  },
  headMaingrid: {
    margin: "auto",
  },
  headSecondgrid: {
    maxWidth: "500px",
    borderBottomRightRadius: "45px",
    padding: "30px",
    backgroundColor: "rgba(255, 255, 255)",
    boxShadow: "0px 8px 32px 0px #4386A108, 0px 4px 8px 0px #4386A108",
  },
  head: {
    fontWeight: 700,
    // marginBottom: "30px",
    color: "#334155",
    fontSize: "30px",
    textAlign: "left",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
  },
  privacyPolicy: {
    fontWeight: 400,
    borderBottom: "1px solid black",
    color: "#0F172A",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
  },
  termsAndConditions: {
    fontWeight: 400,
    color: "#0F172A",
    borderBottom: "1px solid black",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
  },
  accountaction: {
    textAlign: "center",
    fontSize: "16px",
    marginRight: "10px",
  },
  alreadyAccount: {
    marginTop: "40px",
    textAlign: "center",
  },
  one15: {
    font: "inter",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E293B",
    textTransform: "none",
    fontFamily: "inter",
  },
  signIn: {
    color: "#1E293B",
    font: "inter",
    fontWeight: 700,
    fontSize: "18px",
    cursor: "pointer",
    textDecorationLine: "underline",
  },
  // Customizable Area End
};
