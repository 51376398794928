import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import React from "react";
import { handleNavigation } from "../../../components/src/CommonFunctions";
import { Color } from "@material-ui/lab";
interface Details {
  firstName: string,
  surname: string,
  email: string,
  countrycode: string,
  phoneNumber: string,
  salutation: string
}
interface LinkObject {
  url: string;
  title: string;
}
interface AddressForm {
  addressLine1: string
  addressLine2: string;
  town: string;
  county: string;
  postcode: string;
  country: string

}
interface ProfileInfo {
  nameSalutation: string,
  firstName: string,
  surname: string,
  email: string,
  countryCode: string,
  phoneNumber: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  screencount: number;
  error: {
    [key: string]: string;
  };
  imageUrl: string;
  gender: string;
  selectedFile: File | null;
  fullName: string;
  email: string;
  phNoCountry: string;
  phoneNumber: string;
  country: string;
  streetAddress: string;
  flatNo: string;
  city: string,
  postCode: string,
  MesErrProfile: typeof configJSON.MesErrProfile;
  isVisible: boolean;
  islogoutVisible: boolean;
  confirmPassword: string;
  showPassword: boolean;
  showoldPassword: boolean;
  showYourPassword: boolean;
  password: string;
  oldpassword: string;
  token: any;
  samepass: boolean;
  savedPet: any[];
  selectedPetId: string;
  account_id: string;
  ispassvisible: boolean;
  sameoldpass: boolean;
  flashSlackPo: boolean;
  resetpassmodalbo: boolean;
  flashslack: boolean;
  mousehover: number;
  editpupup: boolean;
  errorpassword: string;
  passslack: boolean;
  anchorElMenu: boolean;
  modelOpen: boolean;
  isdisabled: boolean;
  isImageDeleted: boolean;
  deletepetmodal: boolean;
  navbarProfilePhoto: string;
  emergencyDetails: {
    firstName: string,
    surname: string,
    email: string,
    countrycode: string,
    phoneNumber: string,
    salutation: string
  },
  legalGuardianDetails: {
    firstName: string,
    surname: string,
    email: string,
    countrycode: string,
    phoneNumber: string,
    salutation: string
  },
  profileInfo: ProfileInfo;
  addressForm: AddressForm
  showAddress: boolean,
  showEmergencyDetails: boolean,
  showLeagalGaurdianDetails: boolean;
  guardiancheckboxStatus: boolean;
  openCountryDropdownStatus: boolean;
  salutation: string;
  salutationDropDownStatus: boolean
  emergencysalutationDropDownStatus: boolean;
  legalGuardiansalutationDropDownStatus: boolean;
  emergencyPhoneNumberDropDownStatus: boolean,
  legalguidancePhoneNumberDropDownStatus: boolean,
  phoneNumberDropDownStatus: boolean
  dropDownKeys: string;
  legalGuardianDetailsErrors: Details
  emergencyDetailsErrors: Details,
  addressFormErrors: any
  profileInfoErrors: ProfileInfo,
  salutationBo: any,
  firstNameBo: string,
  lastNameBo: string,
  emailBo: string,
  phonecountryBo: any,
  phoneNumberBo: string,
  account_id_bo: string,
  imageUrlBo: string,
  selectedFileBo: File | null;
  anchorElsalutation: any;
  anchorBocountry: any;
  pageLoader: boolean;
  updatedProfilePhotoBo: string;
  password_change_for: string;
  openMail: boolean;
  profileMailError: any;
  emailError: string;
  email_change_for: string;
  photoUploaded: boolean;
  socialLinksData: { icons: string, navigationUrl: string }[];
  poBOLinksData: LinkObject[];
  toasterStatus: boolean;
  toasterType: Color;
  toasterMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deleteAccountApiCallId: any
  fileInputRef = React.createRef() as any;
  resetPasswordApiCallId: any
  updateUserApicallId: any
  getpetApicallID: any
  getAccountApiCallId: any
  removepetcallID: any
  updatePetDetailsApiCallId: string = ""
  getBoAccountApiCallId: any
  updateUserBOApicallId: any
  deletePictureApiCallId: string = ""
  updateEmailCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      screencount: 1,
      selectedPetId: "",
      error: {},
      imageUrl: "",
      gender: "Male",
      selectedFile: null,
      fullName: "",
      email: "",
      phNoCountry: "United Kingdom (+44)",
      phoneNumber: "",
      country: "United Kingdom",
      streetAddress: "",
      flatNo: "",
      city: "",
      postCode: "",
      MesErrProfile: configJSON.MesErrProfile,
      isVisible: false,
      islogoutVisible: false,
      confirmPassword: "",
      showPassword: false,
      showoldPassword: false,
      showYourPassword: false,
      password: "",
      oldpassword: "",
      token: localStorage.getItem("authToken"),
      samepass: true,
      savedPet: [],
      account_id: "",
      ispassvisible: false,
      sameoldpass: false,
      flashSlackPo: false,
      resetpassmodalbo: false,
      flashslack: false,
      mousehover: 0,
      editpupup: false,
      errorpassword: "",
      passslack: false,
      anchorElMenu: false,
      modelOpen: false,
      isdisabled: true,
      isImageDeleted: false,
      deletepetmodal: false,
      navbarProfilePhoto: "",
      emergencyDetails: {
        firstName: "",
        surname: "",
        email: "",
        countrycode: "United Kingdom (+44)",
        phoneNumber: "",
        salutation: "Mr."
      },
      legalGuardianDetails: {
        firstName: "",
        surname: "",
        email: "",
        countrycode: "United Kingdom (+44)",
        phoneNumber: "",
        salutation: "Mr."
      },
      addressForm: {
        addressLine1: "",
        addressLine2: "",
        town: "",
        county: "",
        postcode: "",
        country: ""
      },
      profileInfo: {
        nameSalutation: "",
        firstName: "",
        surname: "",
        email: "",
        countryCode: "United Kingdom (+44)",
        phoneNumber: "",
      },
      showAddress: false,
      showEmergencyDetails: false,
      showLeagalGaurdianDetails: false,
      guardiancheckboxStatus: false,
      openCountryDropdownStatus: false,
      salutation: "Mr.",
      salutationDropDownStatus: false,
      emergencysalutationDropDownStatus: false,
      emergencyPhoneNumberDropDownStatus: false,
      legalguidancePhoneNumberDropDownStatus: false,
      legalGuardiansalutationDropDownStatus: false,
      phoneNumberDropDownStatus: false,
      dropDownKeys: "",
      legalGuardianDetailsErrors: {
        firstName: "",
        surname: "",
        email: "",
        countrycode: "",
        phoneNumber: "",
        salutation: ""
      },
      emergencyDetailsErrors: {
        firstName: "",
        surname: "",
        email: "",
        countrycode: "",
        phoneNumber: "",
        salutation: ""
      },
      addressFormErrors: {},
      profileInfoErrors: {
        nameSalutation: "",
        firstName: "",
        surname: "",
        email: "",
        countryCode: "",
        phoneNumber: ""
      },
      salutationBo: "Mr.",
      firstNameBo: "",
      lastNameBo: "",
      emailBo: "",
      phonecountryBo: "United Kingdom (+44)",
      phoneNumberBo: "",
      account_id_bo: "",
      imageUrlBo: "",
      selectedFileBo: null,
      anchorElsalutation: null,
      anchorBocountry: null,
      pageLoader: false,
      updatedProfilePhotoBo: "",
      password_change_for: "",
      openMail: false,
      profileMailError: "",
      emailError: "",
      email_change_for: "",
      photoUploaded: false,
      socialLinksData: [],
      poBOLinksData: [],
      toasterStatus: false,
      toasterType: 'error',
      toasterMessage: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleAllApiResponse(message);

    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    if (!this.state.token) {
      this.props.navigation.navigate("LandingPage")
    } else {
      this.callApiForBoAccount()
      this.callApiForAccount()
    }
    await removeStorageData('pagefrom')
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({ socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks", true)
    this.setState({ poBOLinksData: allLinksData })
  }

  handleAllApiResponse(message: Message) {

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );





    if (apiRequestCallId && responseJson && !responseJson?.error && !responseJson?.errors) {

      this.handleAllSuccessResponse(responseJson, apiRequestCallId)
    }
    else if (responseJson?.error || responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
      this.handleAllErrorResponse(responseJson, apiRequestCallId)
    }

    else {
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleAllErrorResponse = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId === this.resetPasswordApiCallId) {
      if (responseJson.error) {
        this.setState({ errorpassword: configJSON.message, ispassvisible: false })

      }
    }

    if (apiRequestCallId === this.updateEmailCallId) {
      if (responseJson.errors) {
        if (responseJson?.errors[0]?.token) {
          this.handleLogout()
        } else {
          this.setState({ openMail: true, emailError: responseJson?.errors[0]?.account })
        }
      }
    }
    if (apiRequestCallId === this.deleteAccountApiCallId) {
      if (responseJson && responseJson.errors) {
        this.hideModal()
        this.setState(({
          toasterStatus: true,
          toasterType: 'error',
          toasterMessage: responseJson.errors[0]?.message,
        }))

      }
    }
  }

  handleAllSuccessResponse = (responseJson: any, apiRequestCallId: any) => {


    if (apiRequestCallId === this.getAccountApiCallId) {
      this.handleAccountApiResponse(responseJson);
    }

    if (apiRequestCallId === this.deletePictureApiCallId) {
      this.callApiForAccount()
      this.callApiForBoAccount()
      this.setState({ updatedProfilePhotoBo: "", navbarProfilePhoto: "" })
    }

    if (apiRequestCallId === this.deleteAccountApiCallId) {
      this.handleLogout()
    }

    if (apiRequestCallId === this.updateUserApicallId) {
      if (responseJson && !responseJson.errors) {
        this.setState({
          flashSlackPo: true, flashslack: true,

          navbarProfilePhoto: responseJson.data.attributes.photo,
        }, () => {
          setStorageData('accountDetails', JSON.stringify(responseJson?.data?.attributes))
        })

      }
    }

    if (apiRequestCallId === this.resetPasswordApiCallId) {
      this.hideModal()
      this.setState({ passslack: true, password: "", oldpassword: "", confirmPassword: "" }, () => { this.state.password_change_for === "PO" ? this.handleLogoutPO() : this.handleLogoutBo() })
    }

    this.manageUpdateProfile(apiRequestCallId, responseJson)

    this.getAccountinfoBo(apiRequestCallId, responseJson)

    this.changeEmailResponse(apiRequestCallId, responseJson)
  }


  handleAccountApiResponse = (responseJson: any) => {
    this.callApiForgetpet();
    const profileDetails = {
      nameSalutation: responseJson.data?.attributes?.name_title || "",
      firstName: responseJson.data?.attributes?.full_name || "",
      surname: responseJson.data?.attributes?.surname || "",
      email: responseJson.data?.attributes?.email || "",
      countryCode: responseJson.data?.attributes?.phone_number_country_code || "",
      phoneNumber: responseJson.data?.attributes?.full_phone_number || ""
    };
    const addressForm = {
      addressLine1: responseJson.data?.attributes?.address || "",
      addressLine2: responseJson.data?.attributes?.flat_number || "",
      town: responseJson.data?.attributes?.city || "",
      county: responseJson.data?.attributes?.county || "",
      postcode: responseJson.data?.attributes?.postcode || "",
      country: responseJson.data?.attributes?.country || ""
    };
    this.setState({
      account_id: responseJson.data?.id,
      profileInfo: profileDetails,
      addressForm: addressForm,
      imageUrl: responseJson.data.attributes.photo ? responseJson.data.attributes.photo : null,
      photoUploaded: responseJson.data.attributes.photo ? true : false,
      navbarProfilePhoto: responseJson.data.attributes.photo,
      email: responseJson.data?.attributes?.email || ""
    },()=>{
      setStorageData('accountDetails', JSON.stringify(responseJson.data.attributes));

    });
  };
  manageUpdateProfile = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.updatePetDetailsApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.setState({ isdisabled: true })
      }
    }
    if (apiRequestCallId === this.getpetApicallID) {
      this.setState({ savedPet: responseJson.data }, () => {
        this.manageContactDetailsfromPet(responseJson?.data[0])
        this.managelegalGaurdianDetailsfromPet(responseJson?.data[0])
      })
    }
  }

  getAccountinfoBo = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getBoAccountApiCallId) {
      this.setState({
        imageUrlBo: responseJson.data.attributes.photo ? responseJson.data.attributes.photo : null,
        photoUploaded: responseJson.data.attributes.photo ? true : false,
        account_id_bo: responseJson.data?.id,
        salutationBo: responseJson.data?.attributes?.name_title || "Mr.",
        firstNameBo: responseJson.data?.attributes?.first_name || "",
        lastNameBo: responseJson.data?.attributes?.last_name || "",
        emailBo: responseJson.data?.attributes?.email || "",
        phonecountryBo: responseJson.data?.attributes?.phone_number_country_code || "(+44) United Kingdom",
        phoneNumberBo: responseJson.data?.attributes?.full_phone_number || "",
        pageLoader: false,
      })
    }

    if (apiRequestCallId === this.updateUserBOApicallId) {
      this.setState({
        flashSlackPo: true, flashslack: true, pageLoader: false,
        updatedProfilePhotoBo: responseJson.data.attributes.photo

      }, () => {
        setStorageData('accountDetails', JSON.stringify(responseJson?.data?.attributes))

      })
    }
  }


  changeEmailResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.updateEmailCallId) {
      if (!responseJson.errors) {

        this.closeMail()
        this.state.email_change_for === "BO" ? this.handleLogoutBo() : this.handleLogoutPO()
      }
    }
  }


  callApiForDelete = () => {
    const loginHeader = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteUserApiEndPoint
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(loginHeader)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMehod
    );
    this.deleteAccountApiCallId = loginRequestMessage.messageId;
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);

    this.handelClear()

  };

  handelClear = () => {
    localStorage.removeItem("Authtoken")
    removeStorageData("accountDetails")
    removeStorageData("allDataBoFirstStep")
    removeStorageData("pet_type")
    removeStorageData("firstFormThirdData")
    removeStorageData("selectedImagess")
    removeStorageData("allDataBoSecondStep")
    removeStorageData("BussinessOwnerDetailsSecondBack")
    removeStorageData("BussinessOwnerDetailsThirdBack")
    removeStorageData("selectedImages")
    removeStorageData("selectedServices")
    removeStorageData("timeServices")
    removeStorageData("additionalPrice")
    removeStorageData("allDataBoThirdStep")
    removeStorageData("BussinessOwnerDetailsFourBack")
    removeStorageData('accountDetails')
    removeStorageData('BussinessCount')
    sessionStorage.removeItem("firstFormData")
    sessionStorage.removeItem("secondFormData")
    sessionStorage.removeItem("BussinessOwnerDetailsFourBack")
  }
  callApiForgetpet = () => {
    const loginHeader = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/pets/pet_details`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(loginHeader)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    this.getpetApicallID = loginRequestMessage.messageId;
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);

  };

  callApiForAccount = () => {
    const loginHeader = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account_details`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(loginHeader)
    );



    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    this.getAccountApiCallId = loginRequestMessage.messageId;
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);

  };


  ResetPasswordForPO = (chengeFor: any) => {

    this.setState({ password_change_for: chengeFor })


    let formdata = new FormData();

    formdata.append("data[token]", this.state.token);
    formdata.append("data[old_password]", this.state.oldpassword);
    formdata.append("data[new_password]", this.state.password);
    formdata.append("data[confirm_password]", this.state.confirmPassword);

    const loginHeader = {};

    const signUpPoRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/reset_password`
    );

    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(loginHeader));


    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    this.resetPasswordApiCallId = signUpPoRequestMessage.messageId;
    runEngine.sendMessage(signUpPoRequestMessage.id, signUpPoRequestMessage);

  };
  saveinfo = () => {
    const fields: (keyof S)[] = [
      "firstNameBo",
      "lastNameBo",
      "emailBo",
      "phoneNumberBo",
    ];
    let hasErrorPO = false;
    fields.forEach((field) => {
      if (!this.checkfeildvalidation(field)) {
        hasErrorPO = true;
      }
    });
    if (!hasErrorPO) {
      this.UpdateProfileForBO()
      if (this.state.isImageDeleted) {
        this.callDeleteImageApi();
      }
    }
  }

  saveAddress = () => {
    const fields: (keyof S)[] = [
      "country",
      "postCode",
      "streetAddress",
      "city"
    ];
    let hasErrorPO = false;
    fields.forEach((field) => {
      if (!this.checkfeildvalidation(field)) {
        hasErrorPO = true;
      }
    });
    if (!hasErrorPO) {
      this.UpdateProfileForPO()
    }
  }

  UpdateProfileForPO = () => {

    let formdata = new FormData();
    this.state.selectedFile &&
      formdata.append("data[photo]", this.state.selectedFile);
    formdata.append("data[postcode]", this.state.addressForm.postcode);
    formdata.append("data[city]", this.state.addressForm.town);
    formdata.append("data[flat_number]", this.state.addressForm.addressLine2);
    formdata.append("data[address]", this.state.addressForm.addressLine1);
    formdata.append("data[phone_number_country_code]", this.state.profileInfo.countryCode);
    formdata.append("data[country]", this.state.addressForm.country);
    formdata.append("data[full_phone_number]", this.state.profileInfo.phoneNumber);
    formdata.append("data[email]", this.state.profileInfo.email);
    formdata.append("data[full_name]", this.state.profileInfo.firstName);
    formdata.append("data[name_title]", this.state.profileInfo.nameSalutation);
    formdata.append('data[county]', this.state.addressForm.county)
    formdata.append('data[surname]', this.state.profileInfo.surname)
    const loginHeader = { token: this.state.token };
    const signUpPoRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.account_id}`
    );
    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(loginHeader));
    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    this.updateUserApicallId = signUpPoRequestMessage.messageId;
    runEngine.sendMessage(signUpPoRequestMessage.id, signUpPoRequestMessage);
  };


  handleLogoutPO = () => {
    localStorage.clear()
    localStorage.removeItem("Authtoken")
    removeStorageData("accountDetails")
    removeStorageData("allDataBoFirstStep")
    removeStorageData("pet_type")
    removeStorageData("BOandPOLinks")
    this.props.navigation.navigate("LogInPetOwner")
  }

  handleLogoutBo = () => {
    localStorage.clear()
    removeStorageData("firstFormThirdData")
    removeStorageData("selectedImagess")
    removeStorageData("allDataBoSecondStep")
    removeStorageData("BussinessOwnerDetailsSecondBack")
    removeStorageData("BussinessOwnerDetailsThirdBack")
    removeStorageData("selectedImages")
    removeStorageData("selectedServices")
    removeStorageData("timeServices")
    removeStorageData("additionalPrice")
    removeStorageData("allDataBoThirdStep")
    removeStorageData("BussinessOwnerDetailsFourBack")
    removeStorageData("BOandPOLinks")
    sessionStorage.removeItem("firstFormData")
    sessionStorage.removeItem("secondFormData")
    sessionStorage.removeItem("BussinessOwnerDetailsFourBack")
    this.props.navigation.navigate("LogInBusinessOwner")
    localStorage.removeItem("Authtoken")

  }

  handleLogout = async () => {
    const userType = await getStorageData('userType')
    const userCred = await getStorageData("userCred")
    localStorage.clear()
    localStorage.removeItem("Authtoken")
    removeStorageData("accountDetails")
    removeStorageData("allDataBoFirstStep")
    removeStorageData("pet_type")
    removeStorageData("firstFormThirdData")
    removeStorageData("selectedImagess")
    removeStorageData("allDataBoSecondStep")
    removeStorageData("BussinessOwnerDetailsSecondBack")
    removeStorageData("BussinessOwnerDetailsThirdBack")
    removeStorageData("selectedImages")
    removeStorageData("selectedServices")
    removeStorageData("timeServices")
    removeStorageData("additionalPrice")
    removeStorageData("allDataBoThirdStep")
    removeStorageData("BussinessOwnerDetailsFourBack")
    sessionStorage.removeItem("firstFormData")
    sessionStorage.removeItem("secondFormData")
    sessionStorage.removeItem("BussinessOwnerDetailsFourBack")
    this.hideModal()
    if(userCred) setStorageData('userCred',userCred)
    if (userType === 'BO') {
      handleNavigation('LogInBusinessOwner', {}, this.send, this.props);
    } else {
      handleNavigation('LogInPetOwner', {}, this.send, this.props);
    }
  }

  handleInputChange = (
    event: { target: { type: string, name: string, value: string } }
  ) => {

    const { name, value, type } = event.target;

    const targetProperties = { name, value, type };

    let updatedValue = targetProperties.value;
    let inputError = "";

    if (targetProperties.type === "email") {
      const emailRegex = configJSON.emailRegex;
      if (!emailRegex?.test(targetProperties?.value)) {
        inputError = `${targetProperties.name} enter the right email error`;
      }
    }


    this.setState((prevState) => {


      return ({
        ...prevState,
        [targetProperties.name]: updatedValue,
        errors: {
          ...prevState.error,
          [`${targetProperties.name}Error`]: inputError,
        },
        isdisabled: false
      })
    });

  };


  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = event.target.files ? event.target.files[0] : null;
    let errorMsg = "";
    const fieldName = event.target.name;

    if (selected) {
      if (!selected.type.startsWith("image")) {
        errorMsg = `${fieldName} should be an image`;
      }

      this.setState((prevState) => ({
        selectedFile: selected,
        imageUrl: URL.createObjectURL(selected),
        error: {
          ...prevState.error,
          [`${fieldName}Error`]: errorMsg,
        },
        photoUploaded: true
      }));
    } else {
      this.setState({
        imageUrl: "",
        selectedFile: null,
        photoUploaded: false
      });
    }
    this.setState({ editpupup: false, isdisabled: false })
  };

  callDeleteImageApi = () => {

    const deleteHeader = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const deleteRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    deleteRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/remove_profile_photo`
    );

    deleteRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(deleteHeader)
    );
    deleteRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `DELETE`
    );
    this.deletePictureApiCallId = deleteRequestMessage.messageId;
    runEngine.sendMessage(deleteRequestMessage.id, deleteRequestMessage);


    this.setState({ isImageDeleted: false });
  }



  openModal = () => {
    this.setState({ isVisible: true })
  }

  hideModal = () => {
    this.setState({
      isVisible: false,
      islogoutVisible: false,
      ispassvisible: false,
      resetpassmodalbo: false,
      flashSlackPo: false,
      passslack: false,
      password: "",
      oldpassword: "",
      confirmPassword: "",
      errorpassword: "",
      deletepetmodal: false,
      anchorElMenu: false,

    })
  }

  openlogoutmodal = () => {
    this.setState({ islogoutVisible: true })
  }

  openpassmodal = () => {
    this.setState({ ispassvisible: true })
  }

  validatePasswordPo = () => {
    const setPassword = this.state.password;

    const isCapitalLetter = /[A-Z]/.test(setPassword);
    const isLowerCaseLetter = /[a-z]/.test(setPassword);
    const isNumber = /\d/.test(setPassword);
    const isMinLength = setPassword.length >= 8;
    const isSpecialChracter = /\W+/.test(setPassword)

    const isValid =
      isCapitalLetter && isLowerCaseLetter && isNumber && isMinLength;

    return {
      isValid,
      isCapitalLetter,
      isLowerCaseLetter,
      isNumber,
      isMinLength,
      isSpecialChracter
    };

  };

  handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (this.state.oldpassword !== "" && this.state.oldpassword === value) {
      this.setState({ sameoldpass: true, password: value })
    } else {
      this.setState({ password: value, sameoldpass: false }
        , () => {
          if (this.state.password === this.state.confirmPassword) {
            this.setState({ samepass: true });
          } else {
            this.setState({ samepass: false });
          }
        }
      );
    }

  };

  handleChangeoldPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ oldpassword: event.target.value });

  };

  handleTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleToggleoldPassword = () => {
    this.setState((prevState) => ({
      showoldPassword: !prevState.showoldPassword,
    }));
  };

  handleConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;

    this.setState({ confirmPassword: value }, () => {
      if (this.state.password === this.state.confirmPassword) {
        this.setState({ samepass: true });
      } else {
        this.setState({ samepass: false });
      }
    });
  };

  handleYourTogglePassword = () => {
    this.setState((prevState) => ({
      showYourPassword: !prevState.showYourPassword,
    }));
  };

  handlepopupclose = (event: { target: any; }) => {
    if (this.fileInputRef.current && !this.fileInputRef.current.contains(event.target)) {

      this.setState({ editpupup: false })
    }
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    this.setState({ anchorElMenu: true, selectedPetId: id });
  };
  handleClose = () => {
    this.setState({ anchorElMenu: false,selectedPetId:"" });
  }


  OnEditPet = (value: string, petValue?: string) => {
    setStorageData('pagecomefrom', "Settings2")
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EditPetDetails'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { selectedPetId: this.state.selectedPetId || petValue, value: value })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  OnAddPetDog = () => {
    removeStorageData('pagefrom')
    removeStorageData('pagecomefrom')
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddPetDetails'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { savepetnum: 2, dogselected: true, formvisible: true, typePet: "dog", selectedPetId: "" })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  OnAddPetCat = () => {
    removeStorageData('pagecomefrom')
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddPetDetails'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { savepetnum: 2, catselected: true, formvisible: true, typePet: "cat", selectedPetId: "" })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }



  handleRemovePet = async () => {

    let token = localStorage.getItem("authToken");
    const { selectedPetId } = this.state;

    if (!selectedPetId) {
      return;
    }


    const headers = {
      token: token,
    };

    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/pets/${selectedPetId}`
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    this.removepetcallID = requestMessage1.messageId;
    runEngine.sendMessage(requestMessage1.id, requestMessage1);

    this.handleClose()
    this.hideModal()

    this.setState((prevState) => ({
      savedPet: prevState.savedPet.filter((pet) => pet.id !== selectedPetId),
    }));


  };

  checkfeildvalidation = (fieldBoSettings: keyof S): boolean => {
    const MesErrProfile = configJSON.MesErrProfile;
    if (!this.state[fieldBoSettings]) {
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          [`${fieldBoSettings}Error`]: MesErrProfile[fieldBoSettings] ? MesErrProfile[fieldBoSettings]
            : `${fieldBoSettings} is required.`,
        },
      }));
      return false;
    } else {
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          [`${fieldBoSettings}Error`]: '',
        },
      }));
      return true;
    }
  };
  handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    const errors: any = {}
    const { name, value } = event.target;
    { name == 'addressLine1' && this.validateNumCharCheck(name, value, errors, "Address line 1") }
    { name == 'addressLine2' && this.validateAddress2(value, errors) }
    { name == 'town' && this.validateTown(value, errors) }
    { name == 'postcode' && this.validateNumCharCheck(name, value, errors, "Postcode") }
    this.setState((prevState) => ({
      addressForm: {
        ...prevState.addressForm,
        [event.target.name]: event.target.value
      },
      isdisabled: false,
      addressFormErrors: errors

    }))
  }
  validateAddress2 = (value: string, errors: any) => {
    const postcodeRegex = /^[a-zA-Z0-9\s]*$/
    if (!!value && !postcodeRegex.test(value)) {
      errors.addressLine2 = "Please enter the valid Address line 2 shouldbe alpha numeric";
    }
  }

  validateTown = (value: string, errors: any) => {
    const firstNameRegex = configJSON.nameRegex

    if (value) {
      errors.town = "Town/City is required"
    } else if (!firstNameRegex.test(value)) {
      errors.town = "Please enter valid town/city";
    }
  }
  handleEmergencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    const errors: any = {}
    const { name, value } = event.target
    { name === "firstName" && this.firstNameValidate(value, errors, "First name") }
    { name === "surname" && this.surNameValidate(value, errors) }
    { name === "email" && this.emailValidate(value, errors) }
    { name === "phoneNumber" && this.phoneNumberValidate(value, errors) }
    this.setState((prevState) => ({
      emergencyDetails: {
        ...prevState.emergencyDetails,
        [event.target.name]: event.target.value
      },
      isdisabled: false,
      emergencyDetailsErrors: errors

    }))
  };

  handleProfileInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    let errors = { ...this.state.profileInfoErrors };
    const { name, value } = event.target
    { name === "firstName" && this.firstNameValidate(value, errors, "First name") }
    { name === "surname" && this.surNameValidate(value, errors) }
    { name === "email" && this.emailValidate(value, errors) }
    { name === "phoneNumber" && this.phoneNumberValidate(value, errors) }

    this.setState((prevState) => ({
      profileInfo: {
        ...prevState.profileInfo,
        [event.target.name]: event.target.value
      },
      isdisabled: false,
      profileInfoErrors: errors

    }))
  };
  showAccorditionDetails = (accorditionType: string) => {
    if (accorditionType == "address") {
      this.setState({ showAddress: !this.state.showAddress })
    } else if (accorditionType == "emergencyDetails") {
      this.setState({ showEmergencyDetails: !this.state.showEmergencyDetails })
    } else if (accorditionType == "legalGuardian") {
      this.setState({ showLeagalGaurdianDetails: !this.state.showLeagalGaurdianDetails })
    }
  }

  navigateAddPets = () => {
    handleNavigation('AddPetDetails', {}, this.send, this.props);
  }
  manageContactDetailsfromPet = (petDetails: any) => {
    const contactDetails = {
      firstName: petDetails?.attributes?.emergency_contact_name || "",
      surname: petDetails?.attributes?.emergency_surname || "",
      email: petDetails?.attributes?.emergency_contact_email || "",
      countrycode: petDetails?.attributes?.emergency_country_code || "",
      phoneNumber: petDetails?.attributes?.emergency_contact_number || "",
      salutation: petDetails?.attributes?.emergency_salutation || "",
    }
    this.setState({ emergencyDetails: contactDetails })
  }
  handleLegalGaurdianChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    let errors: any = {}
    const { name, value } = event.target;

    { name === "firstName" && this.firstNameValidate(value, errors, "First name") }
    { name === "surname" && this.surNameValidate(value, errors) }
    { name === "email" && this.emailValidate(value, errors) }
    { name === "phoneNumber" && this.phoneNumberValidate(value, errors) }
    this.setState((prevState) => ({
      legalGuardianDetails: {
        ...prevState.legalGuardianDetails,
        [event.target.name]: event.target.value
      },
      isdisabled: false,
      legalGuardianDetailsErrors: errors

    }))
  }

  managelegalGaurdianDetailsfromPet = (petDetails: any) => {
    const legalGaurdianDetails = {
      firstName: petDetails?.attributes?.guardian_name || "",
      surname: petDetails?.attributes?.guardian_surname || "",
      email: petDetails?.attributes?.guardian_email || "",
      countrycode: petDetails?.attributes?.guardian_country_code || "",
      phoneNumber: petDetails?.attributes?.guardian_contact_number || "",
      salutation: petDetails?.attributes?.guardian_salutation || ""
    }
    this.setState({ legalGuardianDetails: legalGaurdianDetails })
  }
  manageLegalGaurdianDetails = () => {
    this.setState(prevState => ({ guardiancheckboxStatus: !prevState.guardiancheckboxStatus }), () => {
      if (this.state.guardiancheckboxStatus) {
        const legalDetails = {
          firstName: this.state.emergencyDetails.firstName,
          surname: this.state.emergencyDetails.surname,
          email: this.state.emergencyDetails.email,
          countrycode: this.state.emergencyDetails.countrycode,
          phoneNumber: this.state.emergencyDetails.phoneNumber,
          salutation: this.state.emergencyDetails.salutation,

        }
        this.setState({ legalGuardianDetails: legalDetails, isdisabled: false })
      }
    });
  }
  openDropDowns = (type: string) => {
    this.setState({ dropDownKeys: type })
    if (type == 'country') {
      this.setState({ openCountryDropdownStatus: true })
    } else if (type == 'salutation') {
      this.setState({ salutationDropDownStatus: true })
    } else if (type == "emergencySalutation") {
      this.setState({ emergencysalutationDropDownStatus: true })
    } else if (type == "legalGuidancesSalutation") {
      this.setState({ legalGuardiansalutationDropDownStatus: true })
    } else if (type == "phoneNumberDropDown") {
      this.setState({ phoneNumberDropDownStatus: true })
    } else if (type == "emergencyPhoneNumberDropDown") {
      this.setState({ emergencyPhoneNumberDropDownStatus: true })
    } else if (type == "legalGuardianPhoneNumberDropDown") {
      this.setState({ legalguidancePhoneNumberDropDownStatus: true })
    }
  }
  closeDropDowns = (type: string) => {
    if (type == 'country') {
      this.setState({ openCountryDropdownStatus: false })
    } else if (type == 'salutation') {
      this.setState({ salutationDropDownStatus: false })
    } else if (type == "emergencySalutation") {
      this.setState({ emergencysalutationDropDownStatus: false })
    } else if (type == "legalGuidancesSalutation") {
      this.setState({ legalGuardiansalutationDropDownStatus: false })
    } else if (type == "phoneNumberDropDown") {
      this.setState({ phoneNumberDropDownStatus: false })
    } else if (type == "emergencyPhoneNumberDropDown") {
      this.setState({ emergencyPhoneNumberDropDownStatus: false })
    } else if (type == "legalGuardianPhoneNumberDropDown") {
      this.setState({ legalguidancePhoneNumberDropDownStatus: false })
    }
  }

  selectSalutation = (salutation: string) => {
    if (this.state.dropDownKeys == 'salutation') {
      this.setState({ profileInfo: { ...this.state.profileInfo, nameSalutation: salutation } }, () => {
        this.closeDropDowns("salutation")
      })
    } else if (this.state.dropDownKeys == "emergencySalutation") {
      this.setState({ emergencyDetails: { ...this.state.emergencyDetails, salutation: salutation } }, () => {
        this.closeDropDowns("emergencySalutation")
      })
    } else if (this.state.dropDownKeys == "legalGuidancesSalutation") {
      this.setState({ legalGuardianDetails: { ...this.state.legalGuardianDetails, salutation: salutation } }, () => {
        this.closeDropDowns("legalGuidancesSalutation")
      })
    }
  }
  selectPhoneNumberCountry = (countryName: string, CountryCode: string) => {
    if (this.state.dropDownKeys == "phoneNumberDropDown") {
      this.setState({ profileInfo: { ...this.state.profileInfo, countryCode: `${countryName}${CountryCode}` } },
        () => {
          this.closeDropDowns("phoneNumberDropDown")
        })
    } else if (this.state.dropDownKeys == "emergencyPhoneNumberDropDown") {
      this.setState({ emergencyDetails: { ...this.state.emergencyDetails, countrycode: `${countryName}${CountryCode}` } }, () => {
        this.closeDropDowns("emergencyPhoneNumberDropDown")
      })
    } else if (this.state.dropDownKeys == "legalGuardianPhoneNumberDropDown") {
      this.setState({ legalGuardianDetails: { ...this.state.legalGuardianDetails, countrycode: `${countryName}${CountryCode}` } }, () => {
        this.closeDropDowns("legalGuardianPhoneNumberDropDown")
      })
    }
  }
  selectCountry = (countryName: string) => {
    this.setState({ addressForm: { ...this.state.addressForm, country: countryName } }, () => {
      this.closeDropDowns("country")
    })
  }
  validateNumCharCheck = (name: string, type: string, errors: any, KeyType: string) => {
    const postcodeRegex = /^[a-zA-Z0-9\s]*$/
    if (!type) {
      errors[name] = `${KeyType} is required`
    } else if (!postcodeRegex.test(type)) {
      errors[name] = `Please enter valid ${KeyType}`;
    }
  }
  validateAddressForm = (form: AddressForm) => {
    const errors: any = {}
    const postcodeRegex = /^[a-zA-Z0-9\s]*$/
    const firstNameRegex = configJSON.nameRegex

    if (!form.addressLine1) {
      errors.addressLine1 = "Address line 1 is required"
    } else if (!postcodeRegex.test(form.postcode)) {
      errors.addressLine1 = "Please enter valid Address line 1 should be alpha numeric";
    }
    if (!!form.addressLine2 && !postcodeRegex.test(form.addressLine2)) {
      errors.addressLine2 = "Please enter the valid Address line 2 should be alpha numeric";
    }
    if (!form.town) {
      errors.town = "Town/City is required"
    } else if (!firstNameRegex.test(form.town)) {
      errors.town = "Please enter valid town/city";
    }
    if (!form.postcode) {
      errors.postcode = "Postcode is required"
    } else if (!postcodeRegex.test(form.postcode)) {
      errors.postcode = "Please enter valid post code";
    }
    return errors
  }
  validatePetDetailsForm = (details: Details) => {
    const errors: any = {}
    this.firstNameValidate(details.firstName, errors, 'First name');
    this.surNameValidate(details.surname, errors)
    this.phoneNumberValidate(details.phoneNumber, errors);
    this.emailValidate(details.email, errors);
    return errors;
  }
  validateProfileInfoForm = (profileInfo: ProfileInfo) => {
    const errors: any = {}

    this.firstNameValidate(profileInfo.firstName, errors, "Full name");
    this.surNameValidate(profileInfo.surname, errors)
    this.phoneNumberValidate(profileInfo.phoneNumber, errors);
    this.emailValidate(profileInfo.email, errors);
    return errors
  }
  firstNameValidate = (firstName: string, error: any, keyType: string) => {
    const firstNameRegex = configJSON.nameRegex
    if (!firstName) {
      error.firstName = `${keyType} is required`;
    } else if (!firstNameRegex.test(firstName)) {
      error.firstName = "Please enter alphabetical characters";
    } else {
      delete error.firstName
    }
  }

  surNameValidate = (surname: string, error: any) => {
    const surNameRegex = configJSON.nameRegex;
    if (!surname) {
      error.surname = "Surname is required";
    } else if (!surNameRegex.test(surname)) {
      error.surname = "Please enter alphabetical characters";
    } else {
      delete error.surname
    }
  }
  phoneNumberValidate = (phoneNumber: string, error: any) => {
    const regexNumbers = configJSON.numerRegex
    if (!phoneNumber) {
      error.phoneNumber = "Phone number is required";
    } else if (!regexNumbers.test(phoneNumber)) {
      error.phoneNumber = "Please enter in numerical characters";
    } else {
      delete error.phoneNumber
    }
  }
  emailValidate = (email: string, error: any) => {
    const regexEmail = configJSON.emailRegex
    if (!!email && !regexEmail.test(email)) {
      error.email = "Please enter the valid email address";
    }
  }
  saveProfilePO = () => {
    this.setState({
      legalGuardianDetailsErrors: this.validatePetDetailsForm(this.state.legalGuardianDetails),
      emergencyDetailsErrors: this.validatePetDetailsForm(this.state.emergencyDetails),
      addressFormErrors: this.validateAddressForm(this.state.addressForm),
      profileInfoErrors: this.validateProfileInfoForm(this.state.profileInfo)

    }, () => {

      if (this.state.savedPet.length == 0) {
        if (Object.keys(this.state.profileInfoErrors).length === 0 && Object.keys(this.state.addressFormErrors).length === 0) {
          this.UpdateProfileForPO()
          if (this.state.isImageDeleted) {
            this.callDeleteImageApi();
          }
        }
      } else {
        if (Object.keys(this.state.profileInfoErrors).length === 0 &&
          Object.keys(this.state.addressFormErrors).length === 0 &&
          Object.keys(this.state.emergencyDetailsErrors).length === 0 &&
          Object.keys(this.state.legalGuardianDetailsErrors).length === 0) {
          this.UpdateProfileForPO()
          this.updatePetDetail()
          if (this.state.isImageDeleted) {
            this.callDeleteImageApi();
          }
        }
      }
    });
  }


  updatePetDetail = async () => {

    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };

    let formdata = new FormData();
    formdata.append("pet[emergency_salutation]", this.state.emergencyDetails.salutation);
    formdata.append("pet[emergency_surname]", this.state.emergencyDetails.surname);
    formdata.append("pet[emergency_contact_email]", this.state.emergencyDetails.email);
    formdata.append("pet[emergency_contact_name]", this.state.emergencyDetails.firstName);
    formdata.append("pet[emergency_country_code]", this.state.emergencyDetails.countrycode);
    formdata.append("pet[emergency_contact_number]", this.state.emergencyDetails.phoneNumber);
    formdata.append("pet[guardian_salutation]", this.state.legalGuardianDetails.salutation);
    formdata.append("pet[guardian_surname]", this.state.legalGuardianDetails.surname);
    formdata.append("pet[guardian_name]", this.state.legalGuardianDetails.firstName);
    formdata.append("pet[guardian_email]", this.state.legalGuardianDetails.email);
    formdata.append("pet[guardian_country_code]", this.state.legalGuardianDetails.countrycode);
    formdata.append("pet[guardian_contact_number]", this.state.legalGuardianDetails.phoneNumber);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/pets/${this.state.savedPet[0]?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    this.updatePetDetailsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  callApiForBoAccount = () => {
    const loginHeaderbo = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    this.setState({ pageLoader: true })

    const loginRequestMessagebo = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    loginRequestMessagebo.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account_details`
    );

    loginRequestMessagebo.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(loginHeaderbo)
    );

    loginRequestMessagebo.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    this.getBoAccountApiCallId = loginRequestMessagebo.messageId;
    runEngine.sendMessage(loginRequestMessagebo.id, loginRequestMessagebo);

  };


  UpdateProfileForBO = () => {

    let formdataBo = new FormData();
    this.setState({ pageLoader: true })
    this.state.selectedFileBo &&
      formdataBo.append("data[photo]", this.state.selectedFileBo);
    formdataBo.append("data[phone_number_country_code]", this.state.phonecountryBo);
    formdataBo.append("data[full_phone_number]", this.state.phoneNumberBo);
    formdataBo.append("data[email]", this.state.emailBo);
    formdataBo.append("data[first_name]", this.state.firstNameBo);
    formdataBo.append("data[name_title]", this.state.salutationBo);
    formdataBo.append('data[last_name]', this.state.lastNameBo)
    const loginHeaderBo1 = { token: this.state.token };
    const signUpPoRequestMessageBo = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    signUpPoRequestMessageBo.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.account_id_bo}`
    );
    signUpPoRequestMessageBo.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(loginHeaderBo1));
    signUpPoRequestMessageBo.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    signUpPoRequestMessageBo.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdataBo
    );
    this.updateUserBOApicallId = signUpPoRequestMessageBo.messageId;
    runEngine.sendMessage(signUpPoRequestMessageBo.id, signUpPoRequestMessageBo);
  };


  handleFileChangeBo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedBo = event.target.files ? event.target.files[0] : null;
    let errorMsgBo = "";
    const fieldName = event.target.name;

    if (selectedBo) {
      if (!selectedBo.type.startsWith("image")) {
        errorMsgBo = `${fieldName} should be an image`;
      }

      this.setState((prevState) => ({
        selectedFileBo: selectedBo,
        imageUrlBo: URL.createObjectURL(selectedBo),
        error: {
          ...prevState.error,
          [`${fieldName}Error`]: errorMsgBo,
        },
        photoUploaded: true
      }));
    } else {
      this.setState({
        imageUrlBo: "",
        selectedFileBo: null,
        photoUploaded: false
      });
    }
    this.setState({ editpupup: false, isdisabled: false })
  };

  handledeletephotoBo = () => {
    this.setState({
      imageUrlBo: '',
      selectedFileBo: null,
      editpupup: false,
      isdisabled: false,
      isImageDeleted: true
    })
  }
  handledeletephoto = () => {
    this.setState({
      editpupup: false,
      isdisabled: false,
      imageUrl: "",
      imageUrlBo: '',
      selectedFileBo: null,
      selectedFile: null,
      photoUploaded: false,
      isImageDeleted: true
    })
  }

  handlesalutationopen = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorElsalutation: event.currentTarget })
  }

  salutationClose = (value: any) => {
    this.setState({
      salutationBo: value ? value : "Mr.",
      anchorElsalutation: null
    })
  }

  BoCountryOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorBocountry: event.currentTarget });
  }

  handelBoCloses = (value1: any, code1: any) => {
    const countryCode1 = code1 ? code1 : "(+44)";
    const selectedValue1 = value1 ? value1 : "United Kingdom";
    const phNoCountry1 = selectedValue1 + countryCode1;

    this.setState({
      phonecountryBo: phNoCountry1,
      anchorBocountry: null
    });
  };
  closeStatus = (status: boolean) => {
    this.setState({ flashslack: status, passslack: status, flashSlackPo: status })

  }

  openMail = () => {
    this.setState({ openMail: true })
  }

  closeMail = () => {
    this.setState({ openMail: false, emailError: "" })
  }

  handleChangeMail = (mail: any, type: string) => {

    this.setState({ email_change_for: type })


    const loginHeaderBo1 = { token: this.state.token };

    const emailRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    emailRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/change_email?new_email=${mail}`
    );
    emailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(loginHeaderBo1));
    emailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    this.updateEmailCallId = emailRequestMessage.messageId;
    runEngine.sendMessage(emailRequestMessage.id, emailRequestMessage);


  }

  handleMailChange = (event: any) => {
    const { value, type } = event.target;

    if (type === "email") {
      const emailRegex = configJSON?.emailRegex;

      if (!emailRegex.test(value)) {
        this.setState({
          emailError: 'Please enter a valid email address',
          email: value,
          emailBo: value
        });
      } else {
        this.setState({
          emailError: '',
          email: value,
          emailBo: value
        });
      }
    } else {
      this.setState({
        emailError: '',
        email: value,
        emailBo: value
      });
    }
  };

  profileEditPopup = () => {
    this.setState({ editpupup: !this.state.editpupup })
  }

  settingsSelectionBox = (activeTab: number) => {
    this.setState({
      screencount: activeTab
    })

  }


  // Customizable Area End
}
