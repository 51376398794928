import React from "react";
// Customizable Area Start
import { TextField, Box, Button, ClickAwayListener, Dialog, DialogContent, Divider, Grid, MenuItem, Theme, Typography, createStyles, withStyles } from "@material-ui/core";
import { boxShadows, colors, theme } from "../../../components/src/theme";
import SideNav from "../../../components/src/SideNavbar";
import StripeAccountDetailsController, { Bussiness, Identity, Month, Props, VerfiedAccountsData } from "./StripeAccountDetailsController.web";
import { arrowDown, arrowUp } from "../../settings2/src/assets";
import { downArrow } from "../../contactus/src/assets";
import { configJSON } from "../../settings2/src/Settings2Controller";
export const configsJSON = require("./config");
import Footer from "../../../components/src/Footer.web";
import { calenderIcon, deleteIcon, tickIcon, uploadIcon } from "./assets";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import { SettingsDropDownField, SettingsTextFieldInput } from "../../settings2/src/Settings2.web";
import BussinessOwnerSideBar from "../../../components/src/SideBar";
import { CustomCheckboxSecondary } from "../../../components/src/CustomizedComponents.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Loader from "../../../components/src/Loader";
// Customizable Area End


export class StripeAccountDetails extends StripeAccountDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderDropdownAccordition = (title: string, subTitle: string, status: boolean, key: string) => {
        const { classes } = this.props;

        return (
            <>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h6" className={classes.accorditionText}>{title}
                                <Typography display="inline" variant="h6" className={classes.heading}>
                                    {subTitle}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img src={status ? arrowUp : arrowDown}
                                className={classes.cursor}
                                data-test-id={key}
                                onClick={() => this.openAccodition(key)}
                            />
                        </Grid>

                    </Grid>

                </Grid>

            </>
        )
    }

    dropDownRender = (inputType: string) => {
        const { classes } = this.props;
        return (
            <ClickAwayListener onClickAway={() => this.handleClosePopupData(inputType)}>
                <Box className={classes.continerOuter}>
                    <Box className={classes.innerBox}>
                        {inputType == 'phoneCountryCode' && this.phoneNumberDropDownRender(inputType)}
                        {inputType == 'companyPhone' && this.phoneNumberDropDownRender(inputType)}

                        {inputType == 'addressCountry' && this.countryDropDownRender()}
                        {inputType == 'bussinessCountry' && this.countryDropDownRender()}
                        {inputType == 'externalCountry' && this.countryDropDownRender()}
                        {inputType == 'openYear' && this.renderYearsList()}
                        {inputType == 'openMonth' && this.renderMonthsList()}
                        {inputType == 'openDay' && this.renderDayList()}

                    </Box>
                </Box>

            </ClickAwayListener>
        )
    }

    renderDayList = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.daysList.map((day: string, index: number) => {
                    return (
                        <>
                            <MenuItem className={classes.dateText}
                                data-test-id="select-day"
                                key={index}
                                value={day}
                                onClick={() => this.handleSelectDay(day)}
                            >
                                {day}
                            </MenuItem>
                            <Divider />

                        </>
                    )
                })

                }
            </>
        )
    }
    renderMonthsList = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.monthList.map((month: Month, index: number) => {
                    return (
                        <>
                            <MenuItem className={classes.dateText}
                                data-test-id="select-month"
                                key={index}
                                value={month.id}
                                onClick={() => this.handleSelectMonth(month)}
                            >
                                {month.monthName}
                            </MenuItem>
                            <Divider />

                        </>
                    )
                })

                }
            </>
        )

    }
    renderYearsList = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.yearsList.map((year: string, index: number) => {

                    return (
                        <>
                            <MenuItem className={classes.dateText}
                                data-test-id="select-year"
                                key={index}
                                value={year}
                                onClick={() => this.handleSelectYear(year)}
                            >
                                {year}
                            </MenuItem>
                            <Divider />
                        </>
                    )
                })}
            </>
        )

    }
    renderBussinessType = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.bussinessList.map((bussines: Bussiness) => {
                    return (
                        <>
                            <MenuItem className={classes.textItem}
                                data-test-id="select_bussiness"
                                key={bussines.bussiness_id}
                                value={bussines.bussiness_type}
                                onClick={() => this.handleSelectBussiness(bussines.bussiness_type)}
                            >
                                {bussines.bussiness_type}
                            </MenuItem>
                            <Divider />
                        </>
                    )

                })}

            </>
        )
    }

    salutationRender = () => {
        const { classes } = this.props;
        return (
            <>
                {["Mr.", "Miss", "Mrs.", "Mrx."].map((value: string, index: number) => {
                    return (
                        <>
                            <MenuItem className={classes.menuItemText}
                                data-test-id="select_Salutation"
                                key={index}
                                value={value}
                                onClick={() => this.handleSelectSalutation(value)}
                            >
                                {value}
                            </MenuItem>
                            <Divider />
                        </>
                    )
                })
                }
            </>
        )
    }

    countryDropDownRender = () => {
        const { classes } = this.props;
        return (
            <>
                {configJSON.countries.map((country: any, index: any) => (
                    <>
                        <MenuItem className={classes.menuItemText}
                            data-test-id="countryDropDown"
                            key={index}
                            value={country.label}
                            onClick={() => this.handleSelectCountry(country.label)}
                        >
                            {country.label}
                        </MenuItem>
                        <Divider />
                    </>
                ))}

            </>
        )
    }
    phoneNumberDropDownRender = (inputType: string) => {
        const { classes }: any = this.props;
        return (
            <>
                {configJSON.countries.map((country: any, index: number) => (
                    <MenuItem className={classes.menuItemText}
                        data-test-id="phoneNumberDropDownSelection"
                        key={index}
                        value={`${country.label} ${country.phone}`}
                        onClick={() => this.selectPhoneNumber(inputType, country.label, country.phone)}
                    >
                        {`${country.label} ${country.phone}`}
                    </MenuItem>
                ))}
            </>
        )
    }

    dropDownRenderTwo = (inputType: string) => {
        const { classes } = this.props;
        return (
            <ClickAwayListener onClickAway={() => this.handleClosePopupData(inputType)}>
                <Box className={classes.outerSalutation}>
                    <Box className={classes.innerBox}>
                        {inputType == 'bussiness' && this.renderBussinessType()}
                        {inputType == 'salutation' && this.salutationRender()}
                        {inputType == 'merchantSalutation' && this.salutationRender()}
                        {inputType == 'externalSalutation' && this.salutationRender()}
                        {inputType == 'identity' && this.renderIdentityDocuments()}
                        {inputType == 'verifiedAccounts' && this.renderVerifiedAccounts(inputType)}
                    </Box>
                </Box>

            </ClickAwayListener>
        )
    }

    renderVerifiedAccounts = (inputType: string) => {
        const { classes }: any = this.props;
        return (
            <>
                {this.state.connectedAccountList.map((list: VerfiedAccountsData) => (
                    <MenuItem className={classes.menuItemText}
                        data-test-id="previous_account_selection"
                        key={list?.id}
                        value={list?.attributes?.hotel?.id}
                        onClick={() => this.selectVerifiedHotel(list?.attributes?.hotel?.id, list?.attributes?.hotel?.name)}
                    >
                        {list.attributes.hotel.name}
                    </MenuItem>
                ))}
            </>
        )
    }


    renderIdentityDocuments = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.identityDocuments.map((identity: Identity) => {

                    return (
                        <Box display={'flex'} alignItems={'center'} className={classes.documentHover}>
                            <CustomCheckboxSecondary
                                data-test-id='select_identity'
                                name=""

                                checked={identity.selected}
                                onChange={() => this.selectLegalDocument(identity.identity_id, identity.identity_name)}
                            />
                            <MenuItem className={classes.identityMenuText} key={identity.identity_id}
                                value={identity.identity_name}
                            >
                                {identity.identity_name}
                            </MenuItem>
                        </Box>
                    )
                }
                )}
            </>
        )
    }

    openModel = () => {
        return (
            <>
                <Dialog
                    open={this.state.modelOpen}
                    onClose={this.handleModelClose}
                    data-test-id="handleModelClose"
                    maxWidth="lg"
                >
                    <DialogContent
                        style={{ width: "450px", padding: "30px", borderRadius: "15px" }}
                    >
                        <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
                        <div style={{ padding: "5%", margin: "20px 0px" }}>
                            <Typography
                                style={styles.modelHeadingText as React.CSSProperties}
                            >
                                Save as Draft?
                            </Typography>
                            <Typography style={styles.modelBodyText as React.CSSProperties}>
                                Draft will be saved for a period 1 month and then deleted from
                                the system
                            </Typography>
                        </div>
                        <div style={styles.modelBtnGrid}>
                            <Button onClick={this.handleModelClose} style={styles.discartBtn as React.CSSProperties}>
                                Discard
                            </Button>
                            <Button
                                style={styles.saveDraftBtn as React.CSSProperties}
                                onClick={this.createAccount}
                                data-test-id="saveDraftData"
                            >
                                Save Draft
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );
    };

    renderUploadDocuments = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.documentUploadStatus ?
                    <>
                        <Grid item xs={12}>
                            <Box className={classes.fileBox}>
                                <Box >
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Box>
                                                <img src={tickIcon} />

                                            </Box>
                                            <Box>
                                                <Typography>{this.state.PdfFile?.name && this.state.PdfFile.name}</Typography>
                                                <Typography>{this.state.fileSize}</Typography>

                                            </Box>
                                        </Grid>

                                    </Grid>


                                </Box>
                                <Box data-test-id='delete' onClick={() => this.deletePdfFile("upload_document")}>
                                    <img src={deleteIcon} style={{ cursor: 'pointer' }} />
                                </Box>
                            </Box>
                        </Grid>
                    </>

                    : <Grid item xs={12} >
                        <label htmlFor="icon-button-file">
                            <input
                                // accept="image/*"
                                type="file"
                                name="pdfPath"
                                id="icon-button-file"
                                onChange={this.handleUploadFile}
                                style={{ display: "none" }}
                                ref={this.fileInputRef}
                                data-test-id="pdfUpload"
                            />

                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item >
                                    <Typography color="error" variant="body1">{this.state.pdfError}</Typography>
                                </Grid>
                                <Grid item >

                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <img src={uploadIcon} width={20} height={20} />

                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2" display="inline" color="secondary" className={classes.Uploadtext}>Upload Document</Typography>

                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>


                        </label>
                    </Grid>}
            </>
        )
    }

    renderBusinessType = () => {
        const { classes } = this.props
        const { StripeForm, stripeFormErrors } = this.state;
        const { bussinessType, companyName, companyCity, companyEmail, companyPhone, companyPostalCode, companyAddress, companyTax, businessName,
            companyPhoneNumberCountryCode
        } = StripeForm
        return (
            <>

                {this.renderDropdownAccordition('Business Profile', "", this.state.bussinessprofileDropDownStatus, 'bussiness')}
                {this.state.bussinessprofileDropDownStatus &&
                    <>
                        {bussinessType !== "Company" &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Merchant Category Code-MCC*</Typography>
                                    <SettingsTextFieldInput
                                        variant="outlined"
                                        fullWidth
                                        placeholder="5815"
                                        value={this.state.mccCode + ""}
                                        name="merchantCode"
                                        data-test-id='merchantCode'
                                        disabled


                                    />
                                    <Typography variant="body1" color="error" gutterBottom>{this.state.stripeFormErrors.merchantCode}</Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Name*</Typography>
                                    <Grid container direction="row">
                                        <Grid item xs={3} sm={2} className={classes.position}>
                                            <SettingsDropDownField
                                                variant="outlined"
                                                fullWidth
                                                value={this.state.StripeForm.merchantSalutation}
                                                name="salutation"
                                                data-test-id="merchantSalutation"
                                                onClick={() => this.handleOpenPopupData('merchantSalutation')}

                                                InputProps={{
                                                    classes: {
                                                        input: classes.customCursor,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: <img src={downArrow} />
                                                }}
                                            />
                                            {this.state.merchantSalutationStatus && this.dropDownRenderTwo('merchantSalutation')}

                                        </Grid>
                                        <Grid item xs={9} sm={10}>
                                            <SettingsTextFieldInput
                                                variant="outlined"
                                                fullWidth
                                                className={classes.dropDown}
                                                placeholder="Full Name"
                                                value={this.state.StripeForm.merchantName}
                                                name="merchantName"
                                                data-test-id="merchantName"
                                                onChange={this.handleStripeForm}
                                            />

                                        </Grid>

                                    </Grid>
                                    <Typography variant="body1" color="error" gutterBottom>{this.state.stripeFormErrors.merchantName}</Typography>

                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.inputLabel} gutterBottom>URL*</Typography>
                            <SettingsTextFieldInput
                                variant="outlined"
                                fullWidth
                                placeholder="https://www.abchotel.com"
                                value={this.state.StripeForm.url}
                                onChange={this.handleStripeForm}
                                name="url"
                                data-test-id='url_input'
                            />
                            <Typography variant="body1" color="error" gutterBottom>{this.state.stripeFormErrors.url}</Typography>

                        </Grid>
                        {bussinessType !== "Company" && <Grid item xs={12} className={classes.position}>
                            <Typography variant="body1" className={classes.inputLabel} gutterBottom>{configsJSON.countyText}

                            </Typography>
                            <SettingsDropDownField
                                variant="outlined"
                                fullWidth
                                className={classes.countryDropDow}
                                data-test-id="bussinessCountry"
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <img src={downArrow} />
                                }}
                                onClick={() => this.handleOpenPopupData('bussinessCountry')}
                                value={this.state.StripeForm.bussinessCountry}

                            />
                            {this.state.merchantCountryStatus && this.dropDownRender('bussinessCountry')}
                        </Grid>}

                        {bussinessType === "Company" &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Business Name*</Typography>
                                    <SettingsTextFieldInput
                                        variant="outlined"
                                        fullWidth
                                        placeholder=""
                                        value={businessName}
                                        name="businessName"
                                        data-test-id='businessName'
                                        onChange={this.handleStripeForm}

                                    />
                                    <Typography variant="body1" color="error" gutterBottom>{stripeFormErrors.businessName}</Typography>


                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Company Name*</Typography>
                                    <SettingsTextFieldInput
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Company name"
                                        value={companyName}
                                        name="companyName"
                                        data-test-id='companyName'
                                        onChange={this.handleStripeForm}

                                    />
                                    <Typography variant="body1" color="error" gutterBottom>{stripeFormErrors.companyName}</Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Company Email*</Typography>
                                    <SettingsTextFieldInput
                                        variant="outlined"
                                        fullWidth
                                        placeholder="kevinbarrett@iamacat.com"
                                        value={companyEmail}
                                        name="companyEmail"
                                        data-test-id='companyEmail'
                                        onChange={this.handleStripeForm}
                                    />
                                    <Typography variant="body1" color="error" gutterBottom>{stripeFormErrors.companyEmail}</Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Company Phone Number*</Typography>
                                    <Grid container direction="row">
                                        <Grid item xs={6} sm={5} className={classes.position}>
                                            <SettingsDropDownField
                                                variant="outlined"
                                                fullWidth
                                                data-test-id="company_phone_code"
                                                value={companyPhoneNumberCountryCode}
                                                onClick={() => this.handleOpenPopupData('companyPhone')}
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: <img src={downArrow} />
                                                }}
                                            />
                                            {this.state.companyCodeStatus && this.dropDownRender('companyPhone')}
                                        </Grid>
                                        <Grid item xs={6} sm={7}>
                                            <SettingsTextFieldInput
                                                variant="outlined"
                                                fullWidth
                                                className={classes.dropDown}
                                                placeholder="Phone Number"
                                                value={companyPhone}
                                                onChange={this.handleStripeForm}
                                                name="companyPhone"
                                                data-test-id='companyPhone'
                                            />
                                        </Grid>
                                        <Typography variant="body1" color="error">{stripeFormErrors.companyPhone}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Company Tax &nbsp;
                                        <Typography variant="body1" component={"a"} display="inline" style={styles.labelText}
                                            href={configsJSON.companyTaxUrl} target="_blank"
                                        >{configsJSON.companyTaxText}</Typography>*</Typography>
                                    <SettingsTextFieldInput
                                        variant="outlined"
                                        fullWidth
                                        placeholder=""
                                        value={companyTax}
                                        name="companyTax"
                                        data-test-id='companyTax'
                                        onChange={this.handleStripeForm}

                                    />
                                    <Typography variant="body1" color="error" gutterBottom>{stripeFormErrors.companyTax}</Typography>


                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Company Address*</Typography>
                                    <SettingsTextFieldInput
                                        variant="outlined"
                                        fullWidth
                                        placeholder="125 High Street"
                                        value={companyAddress}
                                        name="companyAddress"
                                        data-test-id='companyAddress'
                                        onChange={this.handleStripeForm}

                                    />

                                    <Typography variant="body1" color="error" gutterBottom>{stripeFormErrors.companyAddress}</Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Company City*</Typography>
                                    <SettingsTextFieldInput
                                        variant="outlined"
                                        fullWidth
                                        placeholder="London"
                                        value={companyCity}
                                        name="companyCity"
                                        data-test-id='companyCity'
                                        onChange={this.handleStripeForm}

                                    />
                                    <Typography variant="body1" color="error" gutterBottom>{stripeFormErrors.companyCity}</Typography>

                                </Grid>


                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Company Postcode*</Typography>
                                    <SettingsTextFieldInput
                                        variant="outlined"
                                        fullWidth
                                        placeholder="E.g. SWA1A 1AA"
                                        value={companyPostalCode}
                                        name="companyPostalCode"
                                        data-test-id='companyPostalCode'
                                        onChange={this.handleStripeForm}
                                    />
                                    <Typography variant="body1" color="error" gutterBottom>{stripeFormErrors.companyPostalCode}</Typography>


                                </Grid>
                                <Grid item xs={12} className={classes.position}>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography variant="body1" className={classes.inputLabel} gutterBottom>
                                                Company document &nbsp;
                                                <Typography variant="body1" component={"a"} display="inline" style={styles.labelText}
                                                    href={configsJSON.stripeDocumentsList} target="_blank"
                                                >{configsJSON.documentText}</Typography>*
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            {!this.state.companyDocumentUploadStatus &&
                                                <label htmlFor="icon-button-file">
                                                    <input
                                                        // accept="image/*"
                                                        type="file"
                                                        name="pdfPath"
                                                        id="icon-button-file"
                                                        onChange={this.handleCompanyDocumentUpload}
                                                        style={{ display: "none" }}
                                                        ref={this.fileInputRef}
                                                        data-test-id="company_document_upload"
                                                    />

                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Grid item >
                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item>
                                                                    <img src={uploadIcon} width={20} height={20} />

                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle2" display="inline" color="secondary" className={classes.Uploadtext}>Upload Document</Typography>

                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </label>
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>
                                {this.state.pdfError &&
                                    <Grid item xs={12} >
                                        <Typography color="error" variant="body1">{this.state.pdfError}</Typography>
                                    </Grid>}
                                {this.renderCompanyDocument()}
                            </>
                        }

                    </>}
            </>
        )
    }

    renderCompanyDocument = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.companyDocumentUploadStatus &&
                    <>
                        <Grid item xs={12}>
                            <Box className={classes.fileBox}>
                                <Box >
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Box>
                                                <img src={tickIcon} />

                                            </Box>
                                            <Box>
                                                <Typography>{this.state.companyDocument?.name && this.state.companyDocument.name}</Typography>
                                                <Typography>{this.state.companyFileSize}</Typography>

                                            </Box>
                                        </Grid>

                                    </Grid>


                                </Box>
                                <Box data-test-id='delete_company_document' onClick={() => this.deletePdfFile('company_document')}>
                                    <img src={deleteIcon} style={{ cursor: 'pointer' }} />
                                </Box>
                            </Box>
                        </Grid>
                    </>}
            </>
        )
    }

    renderPreviousAccountOptions = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.connectedAccountList.length > 0 &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" className={classes.inputLabel}>{configsJSON.previousAccount}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item container style={{ width: 'auto' }}>
                                    <CustomCheckboxSecondary
                                        name="yes_check_box"
                                        checked={this.state.previousSelection}
                                        onChange={this.handleYesSelection}
                                        data-test-id="yes_check_box"
                                    />
                                    <Typography variant="subtitle2" style={styles.yesNoText}>Yes</Typography>

                                </Grid>
                                <Grid item container style={{ width: 'auto' }}>
                                    <CustomCheckboxSecondary
                                        name="no_check_box"
                                        checked={!this.state.previousSelection}
                                        onChange={this.handleNoSelection}
                                    />
                                    <Typography variant="subtitle2" style={styles.yesNoText} >No</Typography>

                                </Grid>

                            </Grid>

                        </Grid>
                        {this.state.previousSelection &&
                            <Grid item xs={12} className={classes.position}>
                                <SettingsDropDownField
                                    variant="outlined"
                                    placeholder="select the connected account"
                                    fullWidth
                                    value={this.state.selectedHotel}
                                    className={classes.countryDropDow}
                                    data-test-id='connected_account_dropdown'
                                    onClick={this.handle_connected_account_dropdown}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: <img src={downArrow} />
                                    }}
                                />
                                {this.state.connectedAccountDropdown && this.dropDownRenderTwo('verifiedAccounts')}
                            </Grid>}
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="error">{this.state.previousAccountError}</Typography>

                        </Grid>
                    </>
                }
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        return (
            <>
                <BussinessOwnerSideBar navigation={this.props.navigation} active={0} />
                <div className={classes.mainContentLayout}>
                    <CustomizedSnackbars openToaster={this.state.toasterStatus}
                        message={this.state.toasterText}
                        toasterType={this.state.toasterType}
                        closeStatus={this.closeToasterStatus}
                        duration={2000}
                    />
                    {this.openModel()}
                    <div style={{ padding: '0 160px' }}>
                        <Grid item xs={12} className={classes.contentLayout}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} className={classes.layoutHeading}>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography variant="h4" className={classes.inputLabel}>3. Information for Stripe Account</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={this.handlemodalOpen} className={classes.draftText}>Save Draft</Button>

                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className={classes.headingDivider} />
                                </Grid>
                                <Grid item xs={12} className={classes.confirmText}>
                                    <Typography variant="h4" className={classes.heading}>Confirm Account details</Typography>
                                </Grid>
                                {this.renderPreviousAccountOptions()}
                                {!this.state.previousSelection &&
                                    <>
                                        <Grid item xs={12} className={classes.position}>
                                            <Typography variant="body1" className={classes.inputLabel} gutterBottom>Business Type*</Typography>
                                            <SettingsDropDownField
                                                variant="outlined"
                                                fullWidth
                                                className={classes.countryDropDow}
                                                value={this.state.StripeForm.bussinessType}

                                                data-test-id='open_bussinessType'
                                                onClick={() => this.handleOpenPopupData('bussiness')}
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: <img src={downArrow} />
                                                }}

                                            />
                                            {this.state.bussinessDropDownStatus && this.dropDownRenderTwo('bussiness')}


                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel} gutterBottom>First Name*</Typography>
                                            <Grid container direction="row">
                                                <Grid item xs={3} sm={2} className={classes.position}>
                                                    <SettingsDropDownField
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.StripeForm.salutation}
                                                        name="salutation"
                                                        data-test-id="name_type"
                                                        onClick={() => this.handleOpenPopupData('salutation')}
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: <img src={downArrow} />
                                                        }}


                                                    />
                                                    {this.state.salutationDropDownStatus && this.dropDownRenderTwo('salutation')}

                                                </Grid>
                                                <Grid item xs={9} sm={10}>
                                                    <SettingsTextFieldInput
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.dropDown}
                                                        placeholder="First Name"
                                                        value={this.state.StripeForm.firstName}
                                                        name="firstName"
                                                        onChange={this.handleStripeForm}
                                                        data-test-id='first_name'
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography color="error" variant="body1">{this.state.stripeFormErrors?.firstName}</Typography>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel} gutterBottom>Surname*</Typography>
                                            <SettingsTextFieldInput
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Last Name"
                                                value={this.state.StripeForm.surname}
                                                name="surname"
                                                onChange={this.handleStripeForm}
                                                data-test-id='surname'

                                            />
                                            <Typography color="error" variant="body1">{this.state.stripeFormErrors?.surname}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel} gutterBottom>Email*</Typography>
                                            <SettingsTextFieldInput
                                                variant="outlined"
                                                fullWidth
                                                placeholder="kevinbarrett@iamacat.com"
                                                name="email"
                                                data-test-id="email"
                                                value={this.state.StripeForm.email}
                                                onChange={this.handleStripeForm}

                                            />
                                            <Typography color="error" variant="body1">{this.state.stripeFormErrors?.email}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel} gutterBottom>Phone Number*</Typography>
                                            <Grid container direction="row">
                                                <Grid item xs={6} sm={5} className={classes.position}>
                                                    <SettingsDropDownField
                                                        variant="outlined"
                                                        fullWidth
                                                        data-test-id="phone_code"
                                                        value={this.state.StripeForm.PhoneNumberCountryCode}
                                                        onClick={() => this.handleOpenPopupData('phoneCountryCode')}
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: <img src={downArrow} />
                                                        }}
                                                    />
                                                    {this.state.phoneCountryCodeDropDownStatus && this.dropDownRender('phoneCountryCode')}
                                                </Grid>
                                                <Grid item xs={6} sm={7}>
                                                    <SettingsTextFieldInput
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.dropDown}
                                                        placeholder="Phone Number"
                                                        value={this.state.StripeForm.phoneNumber}
                                                        onChange={this.handleStripeForm}
                                                        name="phoneNumber"
                                                        data-test-id='phone'

                                                    />
                                                </Grid>

                                                <Typography variant="body1" color="error">{this.state.stripeFormErrors?.phoneNumber}</Typography>

                                            </Grid>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel} gutterBottom>Date Of Birth*</Typography>

                                            <Grid container direction="row" spacing={3}>

                                                <Grid item xs={12} sm={4} className={classes.position}>
                                                    <SettingsTextFieldInput
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.StripeForm.day}
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.customCursor,
                                                            },
                                                            readOnly: true,
                                                            startAdornment: <img src={calenderIcon} style={{ margin: "0px 10px" }} />
                                                        }}
                                                        placeholder="Day"
                                                        data-test-id="openDay"
                                                        onClick={() => this.handleOpenPopupData('openDay')}

                                                    />
                                                    {this.state.openDayStatus && this.dropDownRender('openDay')}


                                                </Grid>
                                                <Grid item xs={12} sm={4} className={classes.position}>
                                                    <SettingsTextFieldInput
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.StripeForm.month}
                                                        name="month"

                                                        InputProps={{
                                                            classes: {
                                                                input: classes.customCursor,
                                                            },
                                                            readOnly: true,
                                                            startAdornment: <img src={calenderIcon} style={{ margin: "0px 10px" }} />
                                                        }}
                                                        placeholder="Month"
                                                        data-test-id='openMonth'
                                                        onClick={() => this.handleOpenPopupData('openMonth')}
                                                    />
                                                    {this.state.openMonthStatus && this.dropDownRender('openMonth')}

                                                </Grid>
                                                <Grid item xs={12} sm={4} className={classes.position}>
                                                    <SettingsTextFieldInput
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="Year"
                                                        value={this.state.StripeForm.year}
                                                        name="year"
                                                        data-test-id='yearopen'
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.customCursor,
                                                            },
                                                            readOnly: true,
                                                            startAdornment: <img src={calenderIcon} style={{ margin: "0px 10px" }} />
                                                        }}

                                                        onClick={() => this.handleOpenPopupData('openYear')}
                                                    />
                                                    {this.state.openYearStatus && this.dropDownRender('openYear')}
                                                </Grid>
                                                <Typography variant="body1" color="error" style={{ paddingLeft: "8px" }}>
                                                    {this.state.stripeFormErrors?.day || this.state.stripeFormErrors?.month || this.state.stripeFormErrors?.year}
                                                </Typography>

                                            </Grid>

                                        </Grid>
                                        {this.renderDropdownAccordition('Address', "(Address of person opening the account)", this.state.addressDropDownStatus, 'address')}
                                        {this.state.addressDropDownStatus &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Address Line 1*</Typography>
                                                    <SettingsTextFieldInput
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="E.g. 125 High Street"
                                                        value={this.state.StripeForm.addressLine1}
                                                        onChange={this.handleStripeForm}
                                                        name="addressLine1"
                                                        data-test-id="addressLine1"
                                                    />
                                                    <Typography variant="body1" color="error" gutterBottom>{this.state.stripeFormErrors?.addressLine1}</Typography>

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>City*</Typography>
                                                    <SettingsTextFieldInput
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="E.g. London"
                                                        value={this.state.StripeForm.city}
                                                        onChange={this.handleStripeForm}
                                                        name="city"
                                                        data-test-id="city"

                                                    />
                                                    <Typography variant="body1" color="error" gutterBottom>{this.state.stripeFormErrors?.city}</Typography>

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>{configsJSON.county}</Typography>
                                                    <SettingsTextFieldInput
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="E.g. London"
                                                        value={this.state.StripeForm.state}
                                                        onChange={this.handleStripeForm}
                                                        name="state"
                                                        data-test-id="state"

                                                    />
                                                    <Typography variant="body1" color="error" gutterBottom>{this.state.stripeFormErrors?.state}</Typography>

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Postcode*</Typography>
                                                    <SettingsTextFieldInput
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="E.g. SWA1A 1AA"
                                                        value={this.state.StripeForm.postCode}
                                                        onChange={this.handleStripeForm}
                                                        name="postCode"
                                                        data-test-id='postcode'
                                                    />
                                                    <Typography variant="body1" color="error" gutterBottom>{this.state.stripeFormErrors?.postCode}</Typography>

                                                </Grid>
                                                <Grid item xs={12} className={classes.position}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>{configsJSON.countyText}

                                                    </Typography>
                                                    <SettingsDropDownField
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.countryDropDow}
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: <img src={downArrow} />
                                                        }}
                                                        data-test-id='address_country'
                                                        onClick={() => this.handleOpenPopupData('addressCountry')}
                                                        value={this.state.StripeForm.addressCountry}

                                                    />
                                                    {this.state.addressCountryStatus && this.dropDownRender('addressCountry')}
                                                </Grid>
                                            </>}

                                        {this.renderBusinessType()}

                                        {this.renderDropdownAccordition('External Account', "", this.state.externalAccountDropDownStatus, 'external_account')}
                                        {this.state.externalAccountDropDownStatus &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Account Type

                                                    </Typography>
                                                    <StripeDropDownField
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                        className={classes.countryDropDow}
                                                        value={this.state.StripeForm.externalAccountType}

                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={classes.position}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>{configsJSON.country}

                                                    </Typography>
                                                    <StripeDropDownField
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                        className={classes.countryDropDow}
                                                        data-test-id="externalCountry"
                                                        value={this.state.StripeForm.externalCountry}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Currency*
                                                    </Typography>
                                                    <StripeDropDownField
                                                        disabled
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.countryDropDow}
                                                        value={this.state.StripeForm.currency}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Account Holder Name*</Typography>
                                                    <Grid container direction="row">
                                                        <Grid item xs={3} sm={2} className={classes.position}>
                                                            <SettingsDropDownField
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.StripeForm.externalSatulatation}
                                                                name="salutation"
                                                                data-test-id="externalSalutation"
                                                                onClick={() => this.handleOpenPopupData('externalSalutation')}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: <img src={downArrow} />
                                                                }}
                                                            />
                                                            {this.state.externalSalutationStatus && this.dropDownRenderTwo('externalSalutation')}

                                                        </Grid>
                                                        <Grid item xs={9} sm={10}>
                                                            <SettingsTextFieldInput
                                                                variant="outlined"
                                                                fullWidth
                                                                className={classes.dropDown}
                                                                placeholder="Full Name"
                                                                value={this.state.StripeForm.accountHolderName}
                                                                name="accountHolderName"
                                                                data-test-id="accountHolderName"
                                                                onChange={this.handleStripeForm}
                                                            />


                                                        </Grid>

                                                    </Grid>
                                                    <Typography variant="body1" color="error" gutterBottom>{this.state.stripeFormErrors.accountHolderName}</Typography>


                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Routing Number / Swift Code*</Typography>
                                                    <SettingsTextFieldInput
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="108800"
                                                        value={this.state.StripeForm.swiftCode}
                                                        name="swiftCode"
                                                        data-test-id="swiftcode"
                                                        onChange={this.handleStripeForm}
                                                    />
                                                    <Typography variant="body1" color="error" gutterBottom>{this.state.stripeFormErrors.swiftCode}</Typography>

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>Account Number*</Typography>
                                                    <SettingsTextFieldInput
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="00012345"
                                                        value={this.state.StripeForm.accountNumber}
                                                        name="accountNumber"
                                                        data-test-id="accountNumber"

                                                        onChange={this.handleStripeForm}
                                                    />
                                                    <Typography variant="body1" color="error" gutterBottom>{this.state.stripeFormErrors.accountNumber}</Typography>

                                                </Grid>
                                                <Grid item xs={12} className={classes.position}>
                                                    <Typography variant="body1" className={classes.inputLabel} gutterBottom>
                                                        Proof of Identity Document*
                                                    </Typography>
                                                    <SettingsDropDownField
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.StripeForm.document}
                                                        placeholder="Please select which document you’d like to upload in order to verify the identity"
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.customCursor,
                                                            },
                                                            readOnly: true,
                                                            endAdornment: <img src={downArrow} />
                                                        }}
                                                        className={classes.countryDropDow}
                                                        onClick={() => this.handleOpenPopupData('identity')}
                                                        data-test-id='identity'
                                                    />
                                                    {this.state.identityDocumentsdropDownStatus && this.dropDownRenderTwo('identity')}
                                                    <Typography color="error" variant="body1">{this.state.stripeFormErrors.document}</Typography>

                                                </Grid>
                                                {this.renderUploadDocuments()}

                                            </>
                                        }
                                        <Grid item xs={12} className={classes.dividerLayout}>
                                            <Divider className={classes.colorDivider} />

                                        </Grid>
                                    </>
                                }

                                <Grid item xs={12} className={classes.buttonLayout} >
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item onClick={this.goBack}>
                                            <Grid container spacing={1} alignItems="center" >
                                                <Grid item>
                                                    <ArrowBackIosIcon fontSize={'small'} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1" className={classes.inputLabel}>Back</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained"
                                                data-test-id='create_btn'
                                                onClick={this.createAccount}
                                                color="secondary" className={classes.createBtn}>
                                                Create Account
                                                <ArrowForwardIosIcon style={styles.arrowIcon} />

                                            </Button>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid className={classes.footerLayout}>
                        <Footer footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />


                    </Grid>

                </div>
                <Loader loading={this.state.stripePageLoader} />
            </>
        );
        // Customizable Area End

    }
}

// Customizable Area Start

export const AllPhotosStyles = (theme: Theme) =>
    createStyles({
        stripeLayout: {
            backgroundColor: theme.palette.primary.main,
            height: "max-content",
        },
        contentLayout: {
            backgroundColor: theme.palette.common.white,
            padding: '0 60px'
        },
        heading: {
            color: colors.darkBlack,
            fontWeight: theme.typography.fontWeightRegular
        },
        inputLabel: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold
        },
        cursor: {
            cursor: 'pointer'
        },
        dropDown: {
            "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #CBD5E1",
                borderRadius: " 0 8px 8px 0"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #CBD5E1",
                borderRadius: "0 8px 8px 0"
            },
        },
        countryDropDow: {
            "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #CBD5E1",
                borderRadius: 8
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #CBD5E1",
                borderRadius: 8
            },
        },
        outerSalutation: {
            left: 0,
            position: "absolute" as const,
            zIndex: 9,
            width: "100%",
            maxHeight: "fit-content",
            borderRadius: 8,
            backgroundColor: "#ffffff",
            boxShadow: boxShadows.whiteShadow,

        },
        continerOuter: {
            left: '0px',
            position: "absolute" as const,
            zIndex: 9,
            width: "100%",
            maxHeight: "fit-content",
            height: '300px',
            borderRadius: 8,
            backgroundColor: "#ffffff",
            boxShadow: boxShadows.whiteShadow,
            overflowY: "scroll"
        },
        innerBox: {
            padding: "0.6rem"
        },
        textItem: {
            '&:hover': {
                backgroundColor: colors.primaryBlue,
                borderRadius: 8
            },
            fontSize: 16,
            color: colors.darkBlack
        },
        dateText: {
            '&:hover': {
                backgroundColor: colors.primaryBlue,
                borderRadius: 8
            },
            fontSize: 16,
            color: colors.darkBlack,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'

        },
        position: {
            position: 'relative'
        },
        Uploadtext: {
            fontWeight: theme.typography.fontWeightBold,
            cursor: 'pointer',
            borderBottom: `1px solid ${theme.palette.secondary.main}`
        },
        colorDivider: {
            backgroundColor: theme.palette.primary.main,

        },
        createBtn: {
            width: '210px',
            height: "44px",
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            borderRadius: 8,
            paddingLeft: "23px",
            textTransform: 'none',
            fontSize: 16,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                fontSize: 12

            }
        },
        dividerLayout: {
            marginTop: 30,
            MarginBottom: 50
        },
        buttonLayout: {
            margin: "50px 0px"

        },
        footerLayout: {
            padding: '10px 30px',
            marginTop: 50
        },
        headingDivider: {
            backgroundColor: colors.primaryBlue,
            height: '3px',
            marginTop: 20
        },
        draftText: {
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
            textDecoration: 'underline',
            textTransform: "none"
        },
        layoutHeading: {
            marginTop: 50
        },
        confirmText: {
            marginTop: 20,
            marginBottom: 20
        },
        documentHover: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 8
            },
        },
        identityMenuText: {
            fontSize: 16,
            color: colors.darkBlack
        },
        fileBox: {
            display: "flex",
            height: 55,
            padding: "1px 20px 1px 20px",
            alignItems: "center",
            gap: "16px",
            justifyContent: 'space-between',
            background: theme.palette.primary.main,
            borderRadius: 8,

        }, mainContentLayout: {
            marginLeft: "140px",
            flexGrow: 1,
            backgroundColor: theme.palette.primary.main,
            overflowY: "auto",
            [theme.breakpoints.down(600)]:{
                marginLeft:"0px"
            }
        },
        customCursor: {
            cursor: 'pointer',
        },
    })
export default withStyles(AllPhotosStyles)(StripeAccountDetails);

const styles = {

    modelHeadingText: {
        textAlign: "center",
        fontSize: "24px",
        fontWeight: 700,
        color: "#224350",
    },
    modelBodyText: {
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 700,
        color: "#475569",
    },
    modelBtnGrid: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    discartBtn: {
        fontSize: "16px",
        fontWeight: 700,
        color: "#EA0C78",
        fontFamily: "inter",
        textTransform: "none",
        border: "1px solid #EA0C78",
        width: "192px",
        height: "59px",
        borderRadius: "8px",
    },
    saveDraftBtn: {
        fontSize: "16px",
        fontWeight: 700,
        color: "#fff",
        fontFamily: "inter",
        textTransform: "none",
        backgroundColor: "#EA0C78",
        width: "192px",
        height: "59px",
        borderRadius: "8px",
    },
    saveDraftWarn: {
        display: "flex",
        alignItems: "center",
        height: "50px",
        backgroundColor: "#FEF3C7",
        paddingLeft: "10px",
    },
    saveDraftText: {
        fontFamily: "Inter",
        paddingLeft: "10px",
        fontSize: "16px",
        fontWeight: 400,
    },
    arrowIcon: {
        fontSize: '1.2rem',
        marginLeft: '10px'
    },
    labelText: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightRegular
    },
    yesNoText: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold,
        marginLeft: "10px"
    },
    buttonLayout: {
        margin: "50px 0px"
    }
};

export const StripeDropDownField = withStyles({
    root: {
        "& .MuiInputBase-root": {
            background: `linear-gradient(0deg, #E0F4FC, #E0F4FC),linear-gradient(0deg, #CCEDFA, #CCEDFA)`,
            color: theme.palette.common.black,
            fontSize: 16,
            fontWeight: theme.typography.fontWeightRegular,
            textAlign: 'center',
            height: 44,
            cursor: 'pointer'
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primaryBlue,
            border: "1px solid #CCEDFA",
            borderRadius: "8px 0px 0px 8px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primaryBlue,
            border: "1px solid #CCEDFA",
            borderRadius: "8px 0px 0px 8px"
        },
        "& .MuiOutlinedInput-root": {
            cursor: 'pointer'
        },
    }
})(TextField);




// Customizable Area End
