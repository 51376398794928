import React from "react";
// Customizable Area Start
import { Box, Button, Divider, Grid, Typography, FormControlLabel, RadioGroup } from "@material-ui/core";
import { colors, theme } from "../../../components/src/theme";
export const configsJSON = require("./config");

import BussinessOwnerSideBar from "../../../components/src/SideBar";
import SubscriptionController, { Props, SubscriptionList, configJSON } from "./SubscriptionController.web";
import { CustomRadio } from "../../user-profile-basic/src/BussinessOwnerDetailsThird.web";
import StripePayments from "../../stripepayments/src/StripePayments.web";
import FooterWeb from "../../../components/src/Footer.web";
import CustomizedDailog from "../../../components/src/CustomizedDailog.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import Loader from "../../../components/src/Loader";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

// Customizable Area End

// Customizable Area Start

// Customizable Area End


export default class SubscriptionDetails extends SubscriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    subscriptionPlanDescription = () => {
        return (
            <>

                <Box paddingLeft={"30px"} paddingRight={"30px"} marginTop={"20px"}>
                    <Typography variant="body1" color="textPrimary" >
                        {this.state.description && this.state.description.split(/\r?\n/).map((line: string, index: number) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </Typography>
                </Box>
            </>
        )
    }

    renderSubscriptionList = () => {
        const { subscriptionList } = this.state;

        return (
            <>
                {subscriptionList.map((details: SubscriptionList) => {
                    const maxLength = 150;
                    const {id,name,duration_type,price,description}=details

                    const moreDescription = description || '';
                    const isLongDescription = description.length > maxLength;

                    return (
                        <>
                            <Box style={webStyle.detailsBox} key={id}>
                                <Grid item xs={12}>
                                    <Grid container direction="row" alignItems="center" style={webStyle.contentMain} >
                                        <Grid item xs={11}>
                                            <Typography variant="h3" style={webStyle.title}>
                                                {name} {duration_type !== "commission_base" && ` | £${price?.toFixed(2)}`}</Typography>
                                            <Typography variant="subtitle1" style={webStyle.description as React.CSSProperties}>
                                                {!isLongDescription
                                                    ? moreDescription
                                                    : `${description.slice(0, maxLength)}... `}
                                                {details?.description.length > 150 && <Typography style={webStyle.seeMoreBtn as React.CSSProperties}
                                                    onClick={() => this.opnePlanDescription(description)}
                                                    variant="subtitle1" display="inline"
                                                    data-test-id='see_more'
                                                >See More</Typography>}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <RadioGroup name="room_for" value={this.state.subscriptionType} onChange={() => this.selectSubscription(details)}
                                                data-test-id='select_subscription'
                                            >

                                                <FormControlLabel value={duration_type} control={<CustomRadio />} label="" style={{
                                                    justifyContent: 'flex-end',
                                                    margin: '0px !important'
                                                }} />
                                            </RadioGroup>

                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Box>
                        </>
                    )

                })}

                {subscriptionList.length === 0 &&
                    <Grid item xs={12} style={{ height: '200px' }} container justifyContent="center" alignItems="center">
                        <Typography variant="h4" style={webStyle.notFoundText}>No subscriptions Found</Typography>

                    </Grid>

                }
            </>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const {toasterMessage,toasterType,toasterStatus,activePaymentStep,selectedSubscription,subscriptionType}=this.state;
        return (
            <>
                <NavigationMenu navigation={this.props.navigation} active={1} id="" />
                <CustomizedSnackbars
                    message={toasterMessage}
                    toasterType={toasterType}
                    openToaster={toasterStatus}
                    duration={2000}

                />
                <div style={webStyle.mainContentLayout as React.CSSProperties} className="mobile-margin">
                    <div style={{ padding: '0 160px' }}>
                        <Grid item xs={12} style={webStyle.contentLayout}>
                            <Grid container >
                                <Grid item xs={12}>
                                    {activePaymentStep === 1 && <Typography variant="h4" style={webStyle.title}>{configJSON.textLabels.subscriptionLabel}</Typography>}
                                    {activePaymentStep === 2 && <Typography variant="h4" style={webStyle.title}>{configJSON.textLabels.paymentLabel}</Typography>}

                                    <Divider style={webStyle.headingDivider} />
                                </Grid>
                                <Grid item xs={12}>
                                    {activePaymentStep === 1 && <Typography variant="subtitle2" style={webStyle.subTitleText}>
                                        As a Business owner you can select the option for either a Monthly, Yearly <br /> subscription or a Commission based model So you can choose the appropriate <br /> payment model
                                    </Typography>}

                                    {activePaymentStep === 2 &&
                                        <>
                                            <Typography variant="h3" style={webStyle.paymentHeading}>{selectedSubscription?.name} | £ {selectedSubscription?.price?.toFixed(2)} </Typography>
                                            <Typography variant="body1" style={webStyle.planDescription}>you can change it anytime</Typography>
                                            <Typography variant="body1" style={webStyle.paymentHeading}> Enter your payment details for Subscription plan </Typography>
                                        </>
                                    }

                                </Grid>

                                {activePaymentStep === 1 && <Grid item xs={12} style={webStyle.confirmButtonLayout}>
                                    <Grid container spacing={2} direction="row">
                                        {this.renderSubscriptionList()}
                                    </Grid>
                                </Grid>}
                                {activePaymentStep === 2 &&
                                    <Grid item xs={12}>
                                        <StripePayments navigation={undefined} id={""}
                                            orderPrice={""}
                                            connectAccoutnID={""}
                                            getPaymentStatus={this.state.changePlanStatus ? this.changePlanPaymentStatus : this.getPaymentConfirmation}
                                            paymentPageType="subscription"
                                            backSubcription={this.backSubcription}
                                            hotelId={this.state.hotelId}
                                            paymentTriggered={this.state.changePlanStatus ? this.changePlanSubscription : this.createSubscription}
                                            pageComingFrom={this.state.pageComingFrom}
                                            changePlanStatus={this.state.changePlanStatus}
                                            stripeTermsConditions={this.state.termsAndCondiitionsUrl}

                                        />
                                    </Grid>
                                }

                                {activePaymentStep === 1 &&
                                    <Typography color="error" variant="subtitle2">{this.state.errorMessage}</Typography>
                                }
                                {activePaymentStep === 1 && <Grid item xs={12} style={webStyle.confirmButtonLayout}>
                                    <Grid container justifyContent="flex-end" alignItems="center">

                                        <Grid item>
                                            <Button variant="contained" color={'secondary'}
                                                style={webStyle.yesButton as React.CSSProperties}
                                                onClick={this.handleconfirmPaymentButton}
                                                data-test-id="payment_button"

                                            >
                                                {subscriptionType === 'commission_base' ? "Confirm" : "Pay Now"}
                                            </Button>
                                        </Grid>

                                    </Grid>

                                </Grid>}
                            </Grid>
                        </Grid>
                    </div>
                    <Grid item xs={12} style={webStyle.footerLayout}>
                        <FooterWeb />


                    </Grid>

                    <CustomizedDailog
                        open={this.state.descriptionDialog}
                        handleClose={this.closePlanDescription}
                        data-test-id="handle_PropertyModal_Close"
                        typeOfModel={'description'} dailogTitle={this.subscriptionPlanDescription} />


                </div>

                    <Loader loading={this.state.pageLoader}/>


            </>
        );
        // Customizable Area End

    }
}

// Customizable Area Start
const webStyle = {
    mainContentLayout: {
        marginLeft: "140px",
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        overflowY: "auto",
    },
    contentLayout: {
        backgroundColor: theme.palette.common.white,
        height: 'auto',
        borderRadius: '0px 0px 50px 0px',
        padding: '60px'

    },
    title: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold
    },
    headingDivider: {
        backgroundColor: colors.primaryBlue,
        height: '3px',
        marginTop: 20
    },
    subTitleText: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightRegular,
        marginTop: '30px'
    },
    planDescription: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightRegular,
        width: '85%'
    },
    detailsBox: {
        border: "1px solid #B2E4F8",
        Padding: "20px, 16px, 20px, 16px",
        borderRadius: 8,
        mineight: "134px",
        width: '100%',
        margin: '10px 0px',
        padding: '15px'
    },
    confirmButtonLayout: {
        margin: '50px 0px',
    },
    yesButton: {
        width: '210px',
        textTransform: 'capitalize',
        height: 44,
        borderRadius: 8,
        fontSize: 16,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        },
    },
    contentMain: {
        height: '100%',
        padding: '0px 10px'
    },
    paymentHeading: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold,
        marginTop: '30px'
    },
    footerLayout: {
        marginTop: '50px'
    },
    description: {
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.common.black,

    },
    seeMoreBtn: {
        cursor: 'pointer'
    },
    notFoundText: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold,
        display: 'flex',
        alignItems: 'center'
    }


}
// Customizable Area End