import React from "react";

// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Typography, Checkbox, InputAdornment, withStyles, styled, Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert/Alert";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// Customizable Area End

import EmailAccountRegistrationBusinessOwnerController, {
  Props,
} from "./EmailAccountRegistrationBusinessOwnerController.web";
import PetOwnerNavigationMenu from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";

export default class EmailAccountRegistrationBusinessOwner extends EmailAccountRegistrationBusinessOwnerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  showCondition = (key: string, value: boolean) => {
    let result: string | React.ReactChild = '';
    if (key == 'eye') {
      result = value ? "text" : "password";
    } else if (key == 'icon') {
      result = value ? <VisibilityOutlinedIcon style={{ color: "#94A3B8", cursor: "pointer" }} /> : <VisibilityOffOutlinedIcon style={{ color: "#94A3B8", cursor: "pointer" }} />;
    }
    return result;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      showPassword,
      isCheckboxChecked,
      password,
      confirmPassword,
      showYourPassword,
      flashEmailForBusiness,
    } = this.state;

    const pwdValidationBoRes = this.validatePasswordBoRes();

    const errorrs = (
      <Typography color="error" style={{ padding: "10px" }}>
        {this.state.apiError}
      </Typography>
    );
    const signInFormBo = () => {
      return (
        <Grid
          item
          lg={4}
          xs={12}
          style={formstyle.getEmailFn as React.CSSProperties}
        >
          <Typography variant="h5" style={{ ...formstyle.typographyFn, marginBottom: "10px" } as React.CSSProperties}>
            Welcome to <span style={{ ...formstyle.typographyFn, color: "#EA0B77" } as React.CSSProperties}>PETHOTEL</span>
          </Typography>
          <Typography
            variant="h6"
            style={{ ...formstyle.typographyFn, fontSize: "20px", marginBottom: "20px" } as React.CSSProperties}
          >
            Business Owner Registration
          </Typography>
          <form onSubmit={this.ApiForBoSignUp}>
            <Grid>
              <label style={formstyle.labels as React.CSSProperties}>Email</label>
              <br />
              <CssTextField
                type="email"
                placeholder="Your email"
                style={formstyle.inputbox as React.CSSProperties}
                variant="outlined"
                onChange={this.getEmail}
                data-test-id="getEmail"
                value={this.state.email}
              />
              <label style={formstyle.labels as React.CSSProperties}>Password</label>
              <br />
              <CssTextField
                type={this.showCondition('eye', showPassword) as string}
                style={formstyle.inputbox as React.CSSProperties}
                value={password}
                placeholder="Your password"
                variant="outlined"
                onChange={this.handlePasswordChange}
                data-test-id="password_change"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={this.handlePasswordToggle}
                    >
                      {this.showCondition('icon', showPassword)}
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              <label style={formstyle.labels as React.CSSProperties}>Confirm Password</label>
              <br />
              <CssTextField
                style={formstyle.inputbox}
                placeholder="Your password"
                type={this.showCondition('eye', showYourPassword) as string}
                data-test-id="confirm_password"
                value={confirmPassword}
                onChange={this.handleConfirmPasswordChange}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={this.handleYourPasswordToggle}
                    >
                      {this.showCondition('icon', showYourPassword)}
                    </InputAdornment>
                  ),
                }}
              />

              <div>
                {[
                  {
                    isValid: pwdValidationBoRes.isLowerCaseLetter,
                    messageText: "At least one lowercase letter",
                  },
                  {
                    isValid: pwdValidationBoRes.isCapitalLetter,
                    messageText: "At least one capital letter",
                  },
                  {
                    isValid: pwdValidationBoRes.isMinLength,
                    messageText: "Minimum character length is 8 characters",
                  },
                  {
                    isValid: pwdValidationBoRes.isNumber,
                    messageText: "At least one number",
                  },
                  {
                    isValid: pwdValidationBoRes.isSpecialChracter,
                    messageText: "At least one special character"
                  }

                ].map((validation, id) => (
                  <Grid
                    key={id}
                    style={{
                      alignItems: "center",
                      marginTop: "7px",
                      display: "flex",
                      height:'25px'
                    }}

                  >
                    {validation.isValid ? (
                      <CheckCircleIcon
                        style={{ marginRight: "5px", color: "#34D399" }}
                      />
                    ) : (
                      <>
                      {password&& <RemoveCircleIcon
                        style={{ marginRight: "5px", color: "#DC2626" }}
                      />}
                      </>
                     
                    )}
                    <span style={formstyle.errorMsg as React.CSSProperties}>{validation.messageText}</span>
                  </Grid>
                ))}
              </div>

              <br />
              <Checkbox
                icon={<CustomIcon />}
                checkedIcon={<CustomCheckedIcon />}
                inputProps={{ "aria-label": "checkbox with default color" }}
                color="default"
                onChange={this.handleCheckboxChange}
                data-test-id="checkBoxCheck"
                style={{ marginLeft: "-10px" }}
              />
              <label style={{
                color: "#64748B", fontSize: "14px", font: "inter",
                textTransform: "none",
                fontFamily: "inter"
              }}>
                {" "}
                I agree with{" "}

                <span style={formstyle.termsAndConditions as React.CSSProperties} onClick={() => this.navigate("PrivacyPolicy")} data-test-id="navigateF">Privacy Policy</span>
                {" "}
                and{" "}

                <span style={formstyle.termsAndConditions as React.CSSProperties} onClick={() => this.navigate("TermsConditions")}
                  data-test-id="navigateS">
                  Terms & Conditions
                </span>
              </label>
              {this.state.apiError && errorrs}
              <button
                style={{
                  ...(formstyle.button as React.CSSProperties),
                  color: this.isContinueButtonDisabled(pwdValidationBoRes.isValid) ? "#64748B" : "#ffffff",
                  backgroundColor: this.isContinueButtonDisabled(pwdValidationBoRes.isValid) ? "#F1F5F9" : "#EA0C78",
                }}
                data-test-id="handle_continue_click"
                onClick={(event)=>this.handleApiCall(event,pwdValidationBoRes.isValid)}
              >
                Continue
              </button>
              <div style={formstyle.alreadyAccount as React.CSSProperties}>
                <label style={formstyle.one15 as React.CSSProperties}>
                  Already have an account?{" "}
                  <Link to="/LogInBusinessOwner" style={{ textDecorationLine: "none" }}>
                    <span style={formstyle.signIn as React.CSSProperties}>Sign In</span>{" "}
                  </Link>{" "}
                </label>
              </div>
            </Grid>
          </form>
        </Grid>
      );
    };

    const getFormCurrent = () => {
      return signInFormBo();
    };

    return (
      <>
        <PetOwnerNavigationMenu navType="auth" authContent='Bo' navigation={this.props.navigation} id="" />
        <CustomizedSnackbars duration={20000} openToaster={flashEmailForBusiness} message={" Confirmation mail has be sent to the registered mail"} toasterType={"success"} />

        <div style={formstyle.divStyles}>
          <Grid container lg={12} style={formstyle.containersForGrid}>
            {getFormCurrent()}
          </Grid>
        </div>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomCheckedIcon = styled(CheckBoxIcon)(({ theme }) => ({
  color: "#334155",
  width: 28,
  height: 28,
  backgroundColor: "#fff",
  borderRadius: 6
}));

const CustomIcon = styled(Box)(({ theme }) => ({
  border: '1px solid #326478',
  borderRadius: 6,
  width: 20,
  height: 20,
  outline: 'none',
  backgroundColor: "#fff",
  marginLeft: 5
}));



const CssTextField = withStyles({
  root: {

    '& .MuiInput-underline:after': {
      borderBottomColor: '#54A7C9',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius: "8px"
      },

      '&.Mui-focused fieldset': {
        borderColor: '#54A7C9',
        borderWidth: "2px",
        borderRadius: "8px"
      },
    },
  },
})(TextField);
const formstyle = {
  errorMsg: {
    color: "#1E293B",
    fontSize: "12px",
    fontFamily: "inter"
  },
  divStyles: { marginTop: '112px', padding: "30px", paddingTop: "5px" },

  inputbox: {
    width: "100%",
    height: "40px",
    marginBottom: "40px",
    borderRadius: "8px",
    fontSize: "14px",
    marginTop: "5px",
    fontWeight: 400,
    fontFamily: "inter",
  },
  button: {
    width: "100%",
    height: "40px",
    marginBottom: "15px",
    borderRadius: "5px",
    fontSize: "14px",
    marginTop: "20px",
    border: "none",
    backgroundColor: "#F1F5F9",
    color: "#64748B",
    fontWeight: 700,
    cursor: "pointer",
  },
  labels: {
    fontWeight: 700,
    color: "#334155",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter"
  },
  listTag: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    padding: "30px",
    borderRadius: " 0 0 15px 0",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  childList: {
    fontWeight: 400,
    fontSize: "20px",
    marginBottom: "15px",
    color: "rgba(30, 41, 59, 1)",
  },
  unOrderList: {
    paddingTop: "20px",
    paddingBottom: "20px",
    lineHeight: "28px",
  },

  getEmailFn: {
    padding: "40px",
    backgroundColor: "#ffffff",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    borderRadius: " 0 0 15px 0",
    maxWidth: "520px",
    minWidth: "470px",

  },
  typographyFn: {
    color: "#334155",
    fontWeight: 700,
    marginBottom: "30px",
    fontSize: "30px",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter"
  },
  termsAndConditions: {
    borderBottom: "1px solid black",
    fontWeight: 400,
    color: "#0F172A",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter", cursor: "pointer"
  },
  containersForGrid: {
    display: "flex",
    height: "auto",
    minHeight: "85vh",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#B2E4F8",
    padding: "30px",
    marginBottom: "10px",
    borderRadius: "0 0 30px 0",
  },

  gridStyles: {
    height: "720px",
    flexFlow: "wrap",
    display: "flex",
    textAlign: "justify",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "20px",
    marginBottom: '39px',
  },

  formGrids: {
    fontSize: "16px",
    color: "rgba(51, 65, 85, 1)",
    fontWeight: 400,
  },

  alreadyAccount: {
    marginTop: "40px",
    textAlign: "center",
  },
  one15: {
    font: "inter",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E293B",
    textTransform: "none",
    fontFamily: "inter"
  },
  signIn: {
    color: "#1E293B",
    font: "inter",
    fontWeight: 700,
    fontSize: "18px",
    cursor: "pointer",
    textTransform: "none",
    fontFamily: "inter",
    textDecorationLine: "underline",
  },
};
// Customizable Area End
