import { logoutUser, removeStorageData, setStorageData } from "framework/src/Utilities";
import { dummyImage } from "../../blocks/catalogue/src/assets"
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

export const emailRegex=/^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;
export const nameRegex=/^[a-zA-Z\s!$^*()_\-=\[\]{};':"\\|,.<>\/~`]+$/; 
const numerRegex= /^\s*\d+\s*$/;
const pdfRegex = /\.pdf$/i;

export const errorImage=(event:any)=>{

    event.target.src=dummyImage

}

export const checkApiErrorResponce=(responseJson:any,navigation:any)=>{
   if (responseJson?.errors[0].token == "Token has Expired" ||
   responseJson?.errors[0].token ===  "Invalid token") { 
    handleLogoutUser()
     setTimeout(async() => {
        navigation.navigate('LandingPage')
    }, 1000);
   }  
 }

 export const handleNavigation = (screenName: string,data:any, sendMessage:any,props:any) => {
  const navigationMessage: Message = new Message(getName(MessageEnum.HotelDetails));
  navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
  navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), data);
  navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);
  sendMessage(navigationMessage)
}
export const testingMockApi = (instance: any, apiCallId: string, apiData: object) => {
    const msgValidationAPI = new Message(getName(MessageEnum.RestAPIResponceMessage));
    msgValidationAPI.addData(getName(MessageEnum.RestAPIResponceDataMessage), msgValidationAPI.messageId);
    msgValidationAPI.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), apiData);
    instance[apiCallId] = msgValidationAPI.messageId
    runEngine.sendMessage("Unit Test", msgValidationAPI);
  }

  export const goBack=(navigation:any)=>{
    navigation.goBack()
  
  }
  export const  base64toFile=(base64Data:any, fileName:any, mimeType:any)=> {
    mimeType = mimeType || '';
  
    // Convert base64 to binary
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
  
    // Create Blob from byteArray
    const blob = new Blob([byteArray], { type: mimeType });
  
    // Create File from Blob
    const file = new File([blob], fileName, { type: mimeType });
    return file;

  }
  // validdation functions

  export const textValidation = (value: string, name: string, key: string, errors: any) => {

   if (!!value &&!nameRegex.test(value)) {
        errors[name] = "Please enter valid name";
    }
}

  export const nameValidation = (value: string, name: string, key: string, errors: any) => {

    if (!value) {
        errors[name] = `${key} is required`
    } else if (!nameRegex.test(value)) {
        errors[name] = "Please enter alphabetical or special characters (except @, %, #, &, + and ?)";
    }
}
export const emailValidation = (value: string, name: string, key: string, errors: any) => {
    if (!value) {
        errors[name] = "Email Address is required";
    } else if (!emailRegex.test(value)) {
        errors[name] = "Please enter the valid email address";
    }
}
export const numeracalCharacterValidation = (value: string, name: string, key: string, errors: any) => {
    const postcodeRegex = /^[a-zA-Z0-9\s]*$/

    if (!value) {
        errors[name] = `${key} is required`
    } else if (!postcodeRegex.test(value)) {
        errors[name] = `Please enter valid ${key}`;
    }
}
export const numberValidation = (value: string, name: string, type: string, errors: any) => {
    if (!value) {
        errors[name] = `${type} is required`;
    } else if (!numerRegex.test(value)) {
        errors[name] = ` ${type} in numerical characters`;
    }
}

export const validateUploadedPdf = (pdfNAme: any,name:string,errors:any) => {
    const pdfRegex = /\.pdf$/i;
    if (!pdfNAme) {
      errors[name]="Please upload a identity document"
    }
    else if (!pdfRegex.test(pdfNAme)) {
      errors[name]="Please select the only pdf document."
    }
  }


  export const handleLogoutUser = () => {
    localStorage.clear()
  }
  
  export const getReviewMessage = (reviewsCount:number) => {
    if (reviewsCount === 1) {
      return "1 Review";
    }
    if (reviewsCount > 1) {
      return `${reviewsCount} Reviews`;
    }
    return "No reviews";
  };