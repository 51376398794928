import React from 'react';
import { Modal, Box, Button, Typography, Divider } from '@material-ui/core'; 
import { cancel } from '../../blocks/shoppingcart/src/assets';

const CustomSettingModal = ({ isVisible, hideModal, callApi, typography1, typography2,typography3,typography4,imgsrc,buttontext1,buttontext2 }:any) => {
  return (
    <Modal
      open={isVisible}
      onClose={hideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box 
        style={{
          position: "absolute",
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width:imgsrc?660:572,
          height:imgsrc?362:280,
          backgroundColor: "#fff",
          boxShadow: "24px",
          padding: 4,
          borderRadius: 8
        }}
      >
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", height:imgsrc?48:35 ,marginRight:imgsrc?20:0}}>
          <Button onClick={hideModal} >
            <img src={cancel} alt="" />
          </Button>
        </Box>
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height:imgsrc?220:160,width:"100%" }}>
          <Typography style={{ fontSize: 24, fontWeight: 700, color: "#000",marginBottom:"10px" }} data-test-id="typography1">{typography1}</Typography>
          <Typography style={{ fontSize: 15, fontWeight: 400, color: "#000" }} >{typography2}</Typography>
          <Typography style={{ fontSize: 15, fontWeight: 400, color: "#000" }}>{typography3}</Typography>
          {imgsrc &&<Box style={{display:"flex",marginTop:"30px",alignItems:"center",height:"16px"}}>
           <img src={imgsrc} style={{ width: "15px", height: "15px", marginRight: "5px" }} alt="" />
          <Typography style={{ fontSize: 15, fontWeight: 700, color: "#000", }}>{typography4}</Typography>
          </Box>}
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: 80
          }}
        >
          <Button
            data-test-id="btnCloseModal"
            variant="contained"
            onClick={callApi}
            style={{
              width:imgsrc?263: 250, height: 56, color: "#EB0c78",
              display: "flex", justifyContent: "center",marginTop:imgsrc?10:0,
              alignItems: "center", backgroundColor: "#fff", border: "2px solid #EB0c78",
              borderRadius: 8, fontSize: 16, fontWeight: 700, textTransform: "none"
            }}
          >
            {buttontext1}
          </Button>
          <Button
            data-test-id="btnCloseModal"
            variant="contained"
            onClick={hideModal}
            style={{
              width: imgsrc?263: 250, height: 56, color: "#fff",
              display: "flex", justifyContent: "center",
              alignItems: "center", backgroundColor: "#EB0c78",marginTop:imgsrc?10:0,
              borderRadius: 8, fontSize: 16, fontWeight: 700, textTransform: "none"
            }}
          >
            {buttontext2}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomSettingModal;
