import React from "react";

// Customizable Area Start
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import Avatar from '@material-ui/core/Avatar';
import { Typography, Grid, Box, IconButton, ClickAwayListener } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { theme } from "../../../components/src/theme";
import { Logo } from "../../../components/src/SideBar";
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core'
import CustomSettingModal from "../../../components/src/CustomSettingsmodal";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { menuIcon } from "../../landingpage/src/assets";


const StyledBadge = withStyles(() => ({
  badge: {
    height: '12px', // Change this to desired height
    minWidth: '12px', // Change this to desired width
    fontSize: '12px', // Optionally change font size if needed
    borderRadius: '50%',
    padding: '4px', // Adjust padding to fit content
  },
}))(Badge);
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMenuItem = (activeIndex: number, Icon: any, label: string, showBadge = false) => {
    const isActive = this.props.active === activeIndex;
    const iconElement = (
      <Icon style={{ fontSize: isActive ? "32px" : "28px" }} />
    );
    return (
      <div style={isActive ? webStyle.activeLink as React.CSSProperties : webStyle.nonActiveLink as React.CSSProperties}
        onClick={() => this.handleNavigation(label)}
        data-test-id={label}
      >
        {showBadge && !isActive ? (
          <StyledBadge color="secondary" badgeContent=" ">
            {iconElement}
          </StyledBadge>
        ) : (
          iconElement
        )}
        <Typography variant='body2' style={isActive ? webStyle.activeText : webStyle.nonActiveText}
          data-test-id={`sub_${label}`}
        >{label}</Typography>
      </div>
    );
  }

  renderNavigatioNButtons = () => {
    const { activity, } = this.state

    return (
      <>
        <div style={{ ...webStyle.scrollableContent as React.CSSProperties, ...webStyle.hideScrollbar as React.CSSProperties }}>
          {this.renderMenuItem(1, HomeOutlinedIcon, configJSON.navigationButtons.dashboard)}
          {this.renderMenuItem(2, EventAvailableIcon, configJSON.navigationButtons.reservations, activity?.new_reservations)}
          {this.renderMenuItem(3, CalendarTodayIcon, configJSON.navigationButtons.calendar)}
          {this.renderMenuItem(4, CommentOutlinedIcon, configJSON.navigationButtons.inbox, activity?.new_chat)}
          {this.renderMenuItem(5, CreditCardIcon, configJSON.navigationButtons.transactions)}
          {this.renderMenuItem(6, SettingsIcon, configJSON.navigationButtons.settings)}
          {this.renderMenuItem(8, ExitToAppIcon, configJSON.navigationButtons.logout)}
        </div>
      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { profilePicture, activity, logoutModal, navigationToaster, navigationToasterMessage, toasterType } = this.state
    return (
      <>
        <CustomizedSnackbars
          openToaster={navigationToaster}
          message={navigationToasterMessage}
          duration={3000}
          toasterType={toasterType}
          closeStatus={this.closeNaviagtionToaster}
        />
        {this.state.navbar ?

          <>
            <Box style={{ background: '#fff', width: '100%', padding: '20px', height: "50px", position: 'relative' }} >

              <Box display={"flex"} alignItems={'center'} justifyContent={'space-between'} width={"90%"}>
                <Box display={'flex'} alignItems={'center'}>
                  <IconButton
                    edge="start"
                    onClick={() => this.closePopUp()}
                    data-test-id="menuIcon"
                  >
                    <img src={menuIcon} width={44} height={44} />
                  </IconButton>
                  <Grid
                    item
                    data-test-id="logo_icon"
                    onClick={() => this.handleNavigation(configJSON.navigationButtons.dashboard)}
                  >
                    <img alt="logo" src={Logo} style={{ width: '40px', height: '40px' }} />
                  </Grid>
                </Box>
                <Box>
                  <Grid
                    item
                    onClick={() => this.sideBarNavigation(configJSON.navigationButtons.avatar)}
                    data-test-id="profile_avatar"
                  >
                    <Avatar style={webStyle.mobileAvatarPhoto} src={profilePicture} />
                  </Grid>
                </Box>
              </Box>
              {
                this.state.drawerStatus &&
                <ClickAwayListener onClickAway={this.closePopUp}>
                  <Box style={{
                    position: 'absolute',
                    top: '0px',
                    width: '150px',
                    zIndex:99,
                    background: '#fff',
                    left:'0px'
                  }}>
                    <Box padding={'1.5rem 0.5rem'}>
                      <IconButton
                      onClick={this.closePopUp}
                      >
                      <ArrowBackIcon/>
                      </IconButton>
                      {this.renderMenuItem(1, HomeOutlinedIcon, configJSON.navigationButtons.dashboard)}
                      {this.renderMenuItem(2, EventAvailableIcon, configJSON.navigationButtons.reservations, activity?.new_reservations)}
                      {this.renderMenuItem(3, CalendarTodayIcon, configJSON.navigationButtons.calendar)}
                      {this.renderMenuItem(4, CommentOutlinedIcon, configJSON.navigationButtons.inbox, activity?.new_chat)}
                      {this.renderMenuItem(5, CreditCardIcon, configJSON.navigationButtons.transactions)}
                      {this.renderMenuItem(6, SettingsIcon, configJSON.navigationButtons.settings)}
                      {this.renderMenuItem(8, ExitToAppIcon, configJSON.navigationButtons.logout)}
                    </Box>
                  </Box>

                </ClickAwayListener>
              }

            </Box>
          </> :
          <div style={{ ...webStyle.sidebar as React.CSSProperties }}>
            {/* Fixed Top Section for the Pet Hotel Icon */}
            <div style={webStyle.topFixedSection as React.CSSProperties}>
              <Grid
                item
                data-test-id="logo_icon"
                onClick={() => this.handleNavigation(configJSON.navigationButtons.dashboard)}
              >
                <img alt="logo" src={Logo} style={{ marginTop: 20, width: '80px', height: '80px' }} />
              </Grid>
            </div>

            {/* Scrollable Middle Section */}

            {this.renderNavigatioNButtons()}

            {/* Fixed Bottom Section for Profile Avatar */}
            {this.props.iconPage !== 'profileBo' && (
              <div style={webStyle.bottomFixedSection as React.CSSProperties}>
                <Grid
                  item
                  onClick={() => this.sideBarNavigation(configJSON.navigationButtons.avatar)}
                  data-test-id="profile_avatar"
                >
                  <Avatar style={webStyle.avatarPhoto} src={profilePicture} />
                </Grid>
              </div>
            )}
          </div>
        }


        <CustomSettingModal
          isVisible={logoutModal}
          hideModal={this.closeLogOutModal}
          callApi={this.handleLogOut}
          typography1={configJSON.logout.heading}
          typography2={configJSON.logout.subHeading}
          buttontext2={configJSON.logout.stay}
          buttontext1={configJSON.logout.exit}
        />



      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  nonActiveLink: {
    display: "flex" as const,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.info.dark,
    marginBottom: "35px",
    cursor: 'pointer'
  },
  activeLink: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.secondary.main,
    marginBottom: "35px",
    cursor: 'pointer'
  },
  activeText: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'none'
  },
  nonActiveText: {
    color: theme.palette.info.dark,
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'none'

  },
  sidebar: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '140px',
    overflowY: 'auto', // Enable vertical scrolling
  },
  hideScrollbar: {
    scrollbarWidth: 'none', // Firefox
    '-ms-overflow-style': 'none', // Internet Explorer
    '&::-webkit-scrollbar': {
      display: 'none', // Webkit (Chrome, Safari, etc.)
    },
  },
  profileAvatarLayout: {
    marginTop: 60,
    paddingBottom: 40,
    position: 'fixed' as const,
    bottom: '-2%'
  },
  avatarPhoto: {
    backgroundColor: '#B2E4F8',
    width: '70px',
    height: '70px'
  },
  mobileAvatarPhoto: {
    backgroundColor: '#B2E4F8',
    width: '40px',
    height: '40px'
  },
  topFixedSection: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '140px',
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  scrollableContent: {
    marginTop: '120px', // Space for the top section
    marginBottom: '20px', // Space for the bottom section
    overflowY: 'auto',
    flex: 1,
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  bottomFixedSection: {
    position: 'fixed',
    bottom: '20px',
    left: 0,
    width: '140px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  },
}

// Customizable Area End
