import React from "react";
// Customizable Area Start
import { Avatar, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ReviewsPoBoController, { Datum, Props } from "./ReviewsPoBoController.web";
import {  Start } from "../../catalogue/src/assets";
import CustomRating from "../../../components/src/CustomizedComponents.web";
import { getReviewMessage } from "../../../components/src/CommonFunctions";
// Customizable Area End

// Customizable Area Start


// Customizable Area End
export default class ReviewsPreviewPo extends ReviewsPoBoController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderPopupReviews = () => {
        const { classes } = this.props
        return (
            <Grid container alignItems="center" spacing={2}>
                {this.props.reviewsList?.hotel_reviews?.data?.map((review: Datum,index:number) => {
                    return (<>
                        <Grid style={{marginLeft:"-13px"}} item xs={12} key={index}>
                            <Grid container alignItems="center">
                                <div>
                                    <Avatar
                                    src={review?.attributes?.account_id?.photo}
                                    />
                                </div>
                                <div style={{marginLeft: "10px"}}>
                                    <Typography variant="body1" className={classes.nameheading}>{review?.attributes?.account_id.full_name}</Typography>
                                    <Typography variant="body1" style={{fontSize:"12px"}} color="textSecondary">{review?.attributes?.review_date}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid style={{padding:"10px 0px"}} item xs={12}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <CustomRating rating={review?.attributes?.rating} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" className={classes.nameheading}>{review?.attributes?.rating}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{lineHeight:"22px",fontSize:"14px"}} variant="body1" color="textSecondary">
                                        {review?.attributes?.comment}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    )
                })}

            </Grid>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        return (
            <>
                <Dialog open={this.props?.open} maxWidth={'md'}
                    onClose={this.props.handleClose} aria-labelledby="form-dialog-title"
                    PaperProps={
                        {
                            style: {
                                width: "670px",
                                maxHeight: "642px",
                                height: "100%",
                                overflowY: 'scroll',
                                borderRadius: 15
                            }
                        }
                    }
                >
                    <DialogTitle className={classes.closeIconLayout}>
                        <IconButton aria-label="close" data-test-id="close_dailog" onClick={this.props.handleClose} >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid item style={{padding:"0px 35px"}} xs={12}>
                            <Grid container direction="row" justifyContent="space-between">
                                <Grid item xs={12} sm={5}>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography variant="h4" className={classes.nameheading}>{getReviewMessage(this.props.reviewsList?.hotel_reviews_count)}</Typography>
                                        </Grid>
                                        <Grid item xs={12} container alignItems="center">
                                            <img src={Start} alt="Start" />&nbsp;
                                            <Typography variant="body1" className={classes.nameheading}>{this.props.reviewsList?.hotel_rating}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    {this.renderPopupReviews()}
                                </Grid>

                            </Grid>

                        </Grid>

                    </DialogContent>
                </Dialog>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

// Customizable Area End