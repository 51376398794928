import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { boxShadows, colors, theme } from "../../../components/src/theme";
import BussinessOwnerSideBar from "../../../components/src/SideBar";
import { Pagination } from "@material-ui/lab";
import FooterWeb from "../../../components/src/Footer.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid #CBD5E1'
    },
    head: {
      backgroundColor: colors.primaryBlue,
      color: colors.darkBlack,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 14
    },
    body: {
      fontSize: 14,
      color: theme.palette.common.black,
      lineHeight: "22px"
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
    },
  }),
)(TableRow);

// Customizable Area End

import CollecttransactionfeesController, {
  Props,
  TransactionsData,
  configJSON,
} from "./CollecttransactionfeesController";

export default class Collecttransactionfees extends CollecttransactionfeesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPaymentStatus = (status: string,transaction_type:string) => {
    let styles;
    let message;
    if (status === 'checked_in') {
      styles = webStyle.successText;
      message="Payment Completed"
    } else if (status === 'pending') {
      styles = webStyle.warningText;
      message="Partial Payment Completed"
    } else if (status === 'cancelled'&& transaction_type === 'refund') {
      styles = webStyle.errorText;
      message="Cancelled, Refunded to Pet Owner"
    }else if (status === 'cancelled' && transaction_type === 'Non_Refundable') {
      styles = webStyle.errorText;
      message="Canceled, Transferred to connected account "
    } else if (status === 'no_show') {
      styles = webStyle.errorText;
      message="No Show"
    }else if(status ==='transfer to connected account') {
      styles = webStyle.successText;
      message="Transfer to connected account"
    }else if(status ==='free amendment,refund') {
      styles = webStyle.errorText;
      message="Free amendment refund"
    }
    else {
      styles = webStyle.errorText;
      message=""
    }

    return (
      <>
        <Typography variant="body1" style={styles} data-test-id="statusText">
          {message}
        </Typography>
      </>
    )
  };

  renderExportButton = () => {

    return (
        <Button
            variant="outlined"
            data-test-id="exportButtonId"
            onClick={this.selectExport}
            style={webStyle.exportButton}
        >
            <CloudDownloadOutlinedIcon />
        </Button>
    );
};
  // Customizable Area End

  render() {
    const ResultsPerPage = 9;

    return (
      // Customizable Area Start
      <>
        <NavigationMenu active={5} navigation={this.props.navigation} id="" />
        <div style={webStyle.transcationContent as React.CSSProperties} className="mobile-margin">
          <Container maxWidth={false}>
          <Grid item xs={12}>
          <Grid container direction="row" spacing={3} >
            <Grid item xs={12} style={{ margin: "30px 20px -15px 0px" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid container direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                  {this.renderExportButton()}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={webStyle.transactionTable}>
              <TableContainer component={Paper} style={webStyle.tableContainer}>
                <Table stickyHeader aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={webStyle.tableCell} data-test-id="orderId">{configJSON.tableTitles.orderId}</StyledTableCell>
                      <StyledTableCell style={webStyle.tableCell}>{configJSON.tableTitles.orderDate}</StyledTableCell>
                      <StyledTableCell style={webStyle.tableCell}>{configJSON.tableTitles.propertyName}</StyledTableCell>
                      <StyledTableCell style={webStyle.tableCell}>{configJSON.tableTitles.roomType}</StyledTableCell>
                      <StyledTableCell style={webStyle.tableCell}>{configJSON.tableTitles.price}</StyledTableCell>
                      <StyledTableCell style={webStyle.tableCell}>{configJSON.tableTitles.status}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.transactions.map((transaction: TransactionsData) => {
                      return (
                        <>
                          <StyledTableRow key={transaction.id}>
                            <StyledTableCell component="th" scope="row"style={webStyle.tableCell} >
                            <Typography variant="body1" style={webStyle.labelText}>
                              {transaction.attributes?.booking_id}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" style={webStyle.tableCell}>
                            <Typography variant="body1" style={webStyle.labelText}>
                              {transaction?.attributes?.order_date}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row"style={webStyle.tableCell} >
                            <Typography variant="body1" style={webStyle.labelText}>
                              {transaction?.attributes?.hotel}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" style={webStyle.tableCell}>
                              {transaction?.attributes.room_type.dog_rooms.map((item: string) => {
                                return (
                                  <>
                                    <Typography variant="body1" style={webStyle.labelText}>

                                      {item} for dog
                                    </Typography>
                                  </>
                                )
                              })}
                              {transaction?.attributes.room_type.cat_rooms.map((item: string) => {
                                return (
                                  <>
                                    <Typography variant="body1" style={webStyle.labelText}>

                                      {item} for cat
                                    </Typography>
                                  </>

                                )
                              })}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row"style={webStyle.tableCell}>
                              <Typography variant="body1" style={webStyle.notFoundText}>
                                {transaction.attributes?.amount}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" style={webStyle.tableCell}>
                              {this.renderPaymentStatus(transaction.attributes.status,transaction.attributes.transaction_type)}
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      )
                    })}
                    {this.state.transactions.length === 0 && (
                      <StyledTableRow>
                        <TableCell colSpan={8} align="center" style={{ height: 400, borderBottom: 'none' }}>
                          <Typography variant="h5" style={webStyle.notFoundText} >No Transactions Found</Typography>
                        </TableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid item xs={12} style={webStyle.paginationLayout}>
                <Grid container direction="row" justifyContent="center" alignItems="center" style={{ padding: '25px' }}>
                  <Grid item xs={4}>

                  </Grid>
                  <Grid item xs={4} container justifyContent="center">
                    <Pagination data-test-id='change_page' count={this.state.totalPageCount} page={this.state.pageNo} onChange={this.handlePagination} />

                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-end">
                    <Typography variant="body2" color="textSecondary">
                      {(this.state.pageNo - 1) * ResultsPerPage + 1}-
                      {this.getRecordPerPage(ResultsPerPage) - 1} of {this.state.totalCount} results
                    </Typography>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            </Grid>
            </Grid>
          </Container>
          <Grid item xs={12} style={webStyle.footerLayout}>
            <FooterWeb />

          </Grid>
        </div>


      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  exportButton: {
    borderRadius: 10,
    background: "#fff",
    textTransform: "none" as "none",
    fontSize: 16,
    height: "44px",
    color: "#334155",
    border: "1.5px solid #65C9F1"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  transcationContent: {
    marginLeft: "140px", // Adjust based on the width of your sidebar
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    overflowY: "auto", // Allow main reservationContent area to scroll
  },
  transactionTable: {
    marginTop: '30px'
  },
  tableContainer: {
    minHeight: 440,
    boxShadow: boxShadows.whiteShadow
  },
  paginationLayout: {
    borderTop: '1px solid #CBD5E1',
    backgroundColor: theme.palette.common.white,
  },
  footerLayout: {
    marginTop: '40px'
  },
  notFoundText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black
  },
  warningText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.warning.main
  },
  errorText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.main
  },
  successText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.success.main
  },
  labelText: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.black
  },
  tableCell:{
    width:'16%'
  }
};
// Customizable Area End
