import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
interface SignUpResponse {
  meta: {
    token: string;
  };
  errors?:
    | {
        account?: string;
      }[]
    | { password_confirmation?: string[]; account?: string };
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  id: string;
  navigation: {
    navigate: (str: string) => void;
  };
}

export interface S {
  // Customizable Area Start
  confirmPassword: string;
  password: string;
  showPassword: boolean;
  checkboxTrue: boolean;
  email: string;
  checkboxChecked: boolean;
  showYourPassword: boolean;
  apiError: string;
  flashEmailSlack:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signUpCallPOId: string = "";
  emailVerifactionPOId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      confirmPassword: "",
      showPassword: false,
      showYourPassword: false,
      checkboxChecked: false,
      email: "",
      password: "",
      checkboxTrue: false,
      apiError: "",
       flashEmailSlack:false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.signUpCallPOId) {
      this.handleResSignUpForPo(responseJson);
    }

    // Customizable Area End
  }
  // Customizable Area Start
  handleResSignUpForPo = (responseJson: SignUpResponse) => {
    localStorage.removeItem("registerPo")
    if (responseJson.meta) {
      localStorage.setItem("userType", "PO");
      this.setState({
        confirmPassword: "",
        apiError: "",
        password: "",
        email: "",
        flashEmailSlack:true
      },()=>{this.OnLoginpage()});
    } else if (Array.isArray(responseJson.errors)) {
      if (responseJson.errors[0].account) {
        this.setState({ apiError: responseJson.errors[0].account });
      }
    } else if (responseJson.errors?.password_confirmation) {this.setState({
        apiError: 'The passwords you entered did not match',
      });
    } else if (responseJson.errors?.account) {this.setState({ apiError: 'Password does not meet the requirements' });
    }
  };

  async componentDidMount() {
    localStorage.setItem("route", "/EmailAccountRegistrationWeb");
    const dataPo = (localStorage.getItem("registerPo"));
   const parsedDataPo = JSON.parse(dataPo as any)

  this.setAllData(parsedDataPo)
  }

  setAllData = (parsedDataPo: { email: string; password: string; confirmPassword: string; }) => {
    this.setState({
      email: parsedDataPo.email || "",
      password: parsedDataPo.password || "",
      confirmPassword: parsedDataPo.confirmPassword || "",
    });
  };
  handleTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  navigates = (routes: string) => {
    const registerPo = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };
    localStorage.setItem("registerPo", JSON.stringify(registerPo));
    const pathNamepo = window.location?.pathname
    const trimmedNextRoutPO = pathNamepo?.substring(1);
    setStorageData("pathName",trimmedNextRoutPO)
    this.props.navigation.navigate(routes);
  }
  handleChangeCheckbox = () => {
    this.setState((prevState) => ({
      checkboxTrue: !prevState.checkboxTrue,
    }));
  };
 handleCloseEmailPo =()=>{
    this.setState({flashEmailSlack:false})
  }
  handleConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ confirmPassword: event.target.value });
  };
  handleYourTogglePassword = () => {
    this.setState((prevState) => ({
      showYourPassword: !prevState.showYourPassword,
    }));
  };
  handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    this.setState({ email });
  };


  handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
  };

  SignUpApiForPo = (event: any) => {
    event.preventDefault()
    const loginHeader = { "Content-Type": "application/json" };

    const signUpPoRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts`
    );

    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(loginHeader));

    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.confirmPassword,
            is_accepted_terms_and_conditions: this.state.checkboxTrue.toString(),
          },
          type: "pet_owner",
        },
      })
    );

    signUpPoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),"POST");

    this.signUpCallPOId = signUpPoRequestMessage.messageId;
    runEngine.sendMessage(signUpPoRequestMessage.id, signUpPoRequestMessage);
  };

  validatePasswordPo = () => {
    const setPassword = this.state.password;

    const isCapitalLetter = /[A-Z]/.test(setPassword);
    const isLowerCaseLetter = /[a-z]/.test(setPassword);
    const isNumber = /\d/.test(setPassword);
    const isMinLength = setPassword.length >= 8;
    const isSpecialChracter= /\W+/.test(setPassword)


    const isValid =
      isCapitalLetter && isLowerCaseLetter && isNumber && isMinLength;

    return {
      isValid,
      isCapitalLetter,
      isLowerCaseLetter,
      isNumber,
      isMinLength,
      isSpecialChracter
    };
  };


  OnLoginpage = () => {
    
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'LogInPetOwner');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);


    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { flashSlack:this.state.flashEmailSlack });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }

  isButtonDisabled = (passwordValidationPo:boolean) => {
    const {checkboxTrue,password,confirmPassword} =this.state
    return !(
      checkboxTrue &&
      passwordValidationPo &&
      this.state.email.trim() !== "" &&
      password.trim() !== "" &&
      confirmPassword.trim() !== ""
    );
  };


    
  handleApiCall = (event: React.MouseEvent<HTMLButtonElement>,passwordValidationPo:boolean) => {
    const isDisabled = this.isButtonDisabled(passwordValidationPo);

    if (isDisabled) {
      event.preventDefault(); // Prevent action on invalid state
      return;
    }

    this.SignUpApiForPo
    (event);
  };
  // Customizable Area End
}
