import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { handleNavigation } from "../../../components/src/CommonFunctions";
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab";
import { AccountDetailsAttributes } from "../../landingpage/src/types";

export interface Activity {
  new_reservations: boolean
  new_chat: boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  active?: number,
  profilePhoto?: any,
  iconPage?: string,
  activeLink?: number
  pageName?: string
  authContent?: string
  navType?: string
  classes:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  profilePicture: string,
  pinkDotActivity: Activity,
  toasterStatus: boolean,
  toasterType: Color,
  toasterMessage: string,
  navbar: boolean
  is_loggedin: boolean,
  petOwnerInformation: AccountDetailsAttributes,
  navigationToaster: boolean
  navigationToasterMessage: string,
  is_profile_complete:boolean;
  drawerStatus:boolean;
  iconType:string

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PetOwnerNavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdGetActivity: string = "";
  activityTimeInterval: NodeJS.Timeout | null = null;
  apiCallIdGetAccountInformatation: string = ""
  apiGetDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      profilePicture: "",
      pinkDotActivity: {
        new_reservations: false,
        new_chat: false,
      },
      toasterStatus: false,
      toasterType: "success",
      toasterMessage: "",
      navbar: false,
      is_loggedin: false,
      petOwnerInformation: {} as AccountDetailsAttributes,
      navigationToaster: false,
      navigationToasterMessage: "",
      is_profile_complete:false,
      drawerStatus:false,
      iconType:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if (apiRequestCallId === this.apiCallIdGetAccountInformatation) {
      if (responseJson && !responseJson.errors) {
        this.setState({petOwnerInformation: responseJson?.data?.attributes, profilePicture: responseJson?.data?.attributes?.photo });
      }
    }
    if (apiRequestCallId == this.apiCallIdGetActivity) {
      if (responseJson && !responseJson.errors) {
        this.setState({ pinkDotActivity: responseJson.data })

      }
    }
   

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    const userType=await getStorageData('userType')
    if (userType != 'BO') {
      document.body.classList.add('po-user');
    }
    this.navBarResize()
    const loggedin = Boolean(await getStorageData('authToken'));
    const accountDetails=await getStorageData('accountDetails',true)
    if(loggedin){
      this.setState({ is_loggedin: loggedin,is_profile_complete:accountDetails?.is_profile_complete }, () => {
        this.pinkDotActivity();
        this.getPetOwnerDetails()
        this.activityTimeInterval = setInterval(this.pinkDotActivity, 20000);
      })
    }
   

  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.pinkDotActivity?.new_reservations !== this.state.pinkDotActivity.new_reservations) {
      this.setState((prevState) => ({
        pinkDotActivity: prevState.pinkDotActivity
      }))
    }
    if (prevState.pinkDotActivity?.new_chat !== this.state.pinkDotActivity.new_chat) {
      this.setState((prevState) => ({
        pinkDotActivity: prevState.pinkDotActivity
      }))
    }
  }

  async componentWillUnmount(): Promise<void> {
    // Clean up the interval when the component is unmounted
    if (this.activityTimeInterval) {
      clearInterval(this.activityTimeInterval);
    }
  }

  navBarResize = () => {
    return window.innerWidth <= 967 ?
      this.setState((prevState) => ({ ...prevState, navbar: true }))
      : this.setState((prevState) => ({ ...prevState, navbar: false }))

  }

  navBarNavigation = (pageType: string) => {
    if (pageType === configJSON.navigationButtons.find_hotels) {
      handleNavigation("LandingPage", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.doggy_daycare) {
      handleNavigation("HotelsCatalogue", { filterType: "day_care" }, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.about_us) {
      handleNavigation("AboutUs", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.why_register) {
      handleNavigation("WhyRegister", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.help) {
      handleNavigation("Contactus", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.faqs) {
      handleNavigation("Faqs", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.my_reservations) {
      handleNavigation("ReservationHistory", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.avatar) {
      handleNavigation("Settings2", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.pet_owner_signin) {
      handleNavigation("LogInPetOwner", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.pet_owner_register) {
      handleNavigation("EmailAccountRegistrationWeb", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.business_owner_signin) {
      handleNavigation("LogInBusinessOwner", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.bussiness_owner_register) {
      handleNavigation("EmailAccountRegistrationBusinessOwner", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.inbox) {
      handleNavigation("Chat", {}, this.send, this.props)
    }
  }


  pinkDotActivity = async () => {

    let tokenValue = await getStorageData("authToken");
    const headers = {
      token: tokenValue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdGetActivity = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getActivityEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPetOwnerDetails = async () => {

    let tokenValue = await getStorageData("authToken");
    const headers = {
      token: tokenValue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getAccountDetailsEndPoint
        );
        this.apiCallIdGetAccountInformatation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  petOwnerValidateNavigation = async() => {
 
    if (!this.state.is_profile_complete) {
      this.setState({ navigationToaster: true, navigationToasterMessage: configJSON.profileSetup, toasterType: 'error' })
      return false;
    } else {
      this.setState({ navigationToaster: false, navigationToasterMessage: "", toasterType: 'error' })

      return true;
    }

  }

  petOwnerHandleNavigation = async (type: string) => {
    const { is_loggedin } = this.state;
    const shouldNavigate = !is_loggedin || (is_loggedin && await this.petOwnerValidateNavigation());
    if (shouldNavigate) {
      this.navBarNavigation(type);
    }
  };
  

  closeToasterNavigation = () => {
    this.setState({ navigationToaster: false })
  }
  handleMenuOpen = (iconType: string) => {
    this.setState({ drawerStatus: true }, () => {
      this.setState({ iconType: iconType })
    });
  };
  closePopUp = () => {
    this.setState({ drawerStatus: false })
  }

  // Customizable Area End
}
