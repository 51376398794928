Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productList="Product List"
exports.cancel="cancel"
exports.approved="Approved"
exports.pending="Pending"
exports.addProduct='Add Your Product'
exports.title='enter the Title'
exports.description='enter the Description'
exports.price='enter the Price'
exports.quantity='enter the Quantity'
exports.productImages='add Product Images'
exports.btnExampleTitle = "Submit";
exports.users="User's";
exports.addNewButton='Add New Product'
exports.deleteText="Are you sure you want to delete product?"
exports.delete="delete"
exports.validationsMessage="Please enter all fields"
exports.numberRegex =/^\d+$/
exports.upload ="Upload"
exports.validationApiContentType = "application/json";
exports.formData='multipart/form-data'
exports.userListUrl="https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/user_type?user_type="
exports.getMethod='GET'
exports.postMethod='POST'
exports.addDataUrl ='https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/content_managements'
exports.status='Staus :'
exports.success='Product Added Successfully'
exports.countries = [
  { label: 'United Kingdom', 
  phone: '(+44)' },
  { code: 'AF', label: 'Afghanistan', phone: '(+93)' },
  { code: 'AL', label: 'Albania', phone: '(+355)' },
  { code: 'AX',
   label: 'Alland Islands', phone: '(+358)' },
  { code: 'AD', label: 'Andorra', phone: '(+376)' },
  { ce: 'AI', label: 'Anguilla', phone: '(+1-264)' },
  { code: 'AG',
   label: 'Antigua and Barbuda', phone: '(+1-268)' },
  { code: 'AR', label: 'Argentina', phone: '(+54)' },
  { label: 'Armenia', phone: '(+374)' },
  { coe: 'AW',
   label: 'Aruba', phone: '(+297)' },
  { label: 'Australia', phone: '(+61)' },
  { code: 'AT', label: 'Austria', phone: '(+43)' },
  { label: 'Azerbaijan',
   phone: '(+994)' },
  { code: 'BS', label: 'Bahamas', phone: '(+1-242)' },
  { label: 'Bermuda', phone: '(+1-441)' },
  { o: 'BT', label: 'Bhutan',
   phone: '(+975)' },
  { ce: 'BO', label: 'Bolivia', phone: '(+591)' },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '(+387)' },
  { label: 'Bouvet Island', phone: '(+47)' },
  { label: 'Brazil', phone: '(+55)' },
  { label: 'British Indian Ocean Territory',
   phone: '(+246)' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '(+673)' },
  { label: 'Burundi', phone: '(+257)', co: 'hdfj' },
  { coe: 'CM', label: 'Cameroon', phone: '(+237)' },
  { code: 'CV', label: 'Cape Verde', phone: '(+238)' },
  { code: 'CN', label: 'China', phone: '(+86)' },
  { ce: 'CX', label: 'Christmas Island', phone: '(+61)' },
  { label: 'Colombia', phone: '(+57)' },
  { code: 'KM', label: 'Comoros', phone: '(+269)' },
  { code: 'CK', label: 'Cook Islands', phone: '(+682)' },
  { code: 'CR', label: 'Costa Rica', phone: '(+506)' },
  { label: 'Cuba', phone: '(+53)' },
  { code: 'CW', label: 'Curacao', phone: '(+599)' },
  { label: 'Cyprus', phone: '(+357)' },
  { code: 'CZ', label: 'Czech Republic', phone: '(+420)' },
  { ode: 'FO', label: 'Faroe Islands', phone: '(+298)' },
  { code: 'FR', label: 'France', phone: '(+33)' },
  { label: 'French Guiana', phone: '(+594)' },
  { ce: 'GM', label: 'Gambia', phone: '(+220)' },
  { label: 'Georgia', phone: '(+995)' },
  { ode: 'DE', label: 'Germany', phone: '(+49)' },
  { code: 'GH', label: 'Ghana', phone: '(+233)' },
  { label: 'Grenada', phone: '(+1-473)' },
  { label: 'Guadeloupe', phone: '(+590)' },
  { code: 'GG', label: 'Guernsey', phone: '(+44)' },
  { code: 'GN', label: 'Guinea', phone: '(+224)' },
  { code: 'HU', label: 'Hungary', phone: '(+36)' },
  { code: 'IN', label: 'India', phone: '(+91)' },
  { label: 'Iraq', phone: '(+964)' },
  { code: 'IE', label: 'Ireland', phone: '(+353)' },
  { label: 'Isle of Man', phone: '(+44)' },
  { code: 'IL', label: 'Israel', phone: '(+972)' },
  { ode: 'KR', label: 'Korea, Republic of', phone: '(+82)' },
  { code: 'KW', label: 'Kuwait', phone: '(+965)' },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '(+856)'
  },
  { label: 'Madagascar', phone: '(+261)' },
  { code: 'NG', label: 'Nigeria', phone: '(+234)' },
  { code: 'NU', label: 'Niue', phone: '(+683)' },
  { code: 'Rs', label: 'Russian Federation', phone: '(+7)' },
  { cde: 'RW', label: 'Rwanda', phone: '(+250)' },
  { label: 'Saint Barthelemy', phone: '(+590)' },
  { code: 'MF', label: 'Saint Martin (French part)', phone: '(+590)' },
  { label: 'Serbia', phone: '(+381)', code: 'SB' },
  { label: 'Seychelles', phone: '(+248)' },
  { code: 'SB', label: 'Solomon Islands', phone: '(+677)' },
  { code: 'AE', label: 'United Arab Emirates', phone: '(+971)' },
  
  { code: 'US', label: 'United States', phone: '(+1)' },
  { odc: 'YE', 
  label: 'Yemen', phone: '(+967)' },
  { ce: 'ZM', label: 'Zambia', 
  phone: '(+260)' },
  { label: 'Zimbabwe', phone: '(+263)' }
]


exports.Dogbreed = ["Afghan Hound", "Alaskan Malamute", "Border Collie", "Bulldog", "Chihuahua", "Dachshund", "French Bulldog",
  "German Shepherd", "Golden Retriever", "Labrador Retriever", "Poodle", "Siberian Husky", "Other"
]

exports.Catbreed = ["Abyssinian","American Shorthair","Birman","British Shorthair","Burmese cat","Exotic Shorthair","Maine Coon",
"Persian cat","Ragdoll","Scottish Fold","Siamese cat","Sphynx cat","Other"
]

exports.MesErr = {
  imageUrl: "Please upload your pet's image",
  uploadedFileName: "Please upload the vaccination certificate",
  inputValueMonth: "Please provide the birth month of your pet",
  inputValueYear: "Please provide the birth year of your pet",
  inputValueDateVacction: "Please provide the vaccination date",
  inputValueMonthVacction: "Please provide the vaccination month",
  inputValueYearVacction: "Please provide the vaccination year",
  inputValueDateLastTreatment: "Please provide the date of the last treatment",
  inputValueMonthLastTreatment: "Please provide the month of the last treatment",
  inputValueYearLastTreatment: "Please provide the year of the last treatment",
  inputValueDateWorming: "Please provide the date of the last worming",
  inputValueMonthWorming: "Please provide the month of the last worming",
  inputValueYearWorming: "Please provide the year of the last worming",
  selectedValueSpayed: "Please select one of the above",
  petBreed: "Please enter your pet's breed",
  petName: "Please enter your pet's name",
  sex: "Please specify your pet's gender",
  petColor: "Please enter your pet's colour",
  petWeight: "Please enter your pet's weight",
  clinicName: "Please enter the vet clinic's name",
  vetName:"Please enter the vet name",
  phoneNumber: "Please enter a valid phone number",
  clinicAddress: "Please enter the vet clinic's address",
  daietary: "Please enter your pet's dietary information",
  behavior: "Please provide information about your pet's behavior",
  petType: "Please enter your pet's type",
  medicalTreatment: "Please select at least one option for medical treatment",
  phoneCountry: "Please enter your phone number's country code",
  emergency_contact_name:"Please enter emergency contact name",
  emergency_contact_number:"Please enter emergency contact number",
  emergencyemail:"Please enter emergency contact email",
  guardian_name:"Please enter legal guardian name",
  guardianmail:"Please enter legal guardian email",
  guardian_contact_number:"Please enter legal guardian contact number",
  vaccination_date:"Please select last vaccination date",
  flea_date:"Please select last flea treatment date",
  worming_date:"Please select last worming treatment date",
  dogbirthdate:"Please select the dog's birth date",
  catbirthdate:"Please select the cat's birth date",
  emergencysurname:"Please enter last name for emergency contact",
  guardiansurname:"Please enter last name for legal guardian",
  proceduredate:"Please provide last procedure date",
  selectedBreed:"Please provide the breed name",
  microchipNumber:"Please enter the microchip number",
};

exports.emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

exports.MesErrProfile = {
  imageUplode:"Please upload valid image",
  imageUrl: "Please upload your image",
  gender: "Please specify your gender",
  fullName: "Please enter your first name",
  lastName: "Please enter your last name",
  email: "Please enter your email",
  country: "Please enter your country",
  phoneNumber: "Please enter a valid phone number",
  streetAddress: "Please enter the street address",
  flatNo: "Please enter your flat Number",
  city: "Please enter your city",
  postCode: "Please enter your post code",
  phoneCountry: "Please enter your phone number's country code",
};
exports.emailRegex=/^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;
exports.nameRegex=/^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
exports.numerRegex= /^\s*\d+\s*$/;

exports.aboutUsEndPoint='bx_block_content_management/about_us';
exports.whyRegisterEndPoint='bx_block_content_management/why_registers';
exports.regex = /^[0-9]*(\.[0-9]{0,2})?$/;

// Customizable Area End
