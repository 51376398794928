Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getTransactionsList = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "collecttransactionfees";
exports.labelBodyText = "collecttransactionfees Body";

exports.transactionApiEndPoint="bx_block_stripe_integration/payments"

exports.btnExampleTitle = "CLICK ME";

exports.tableTitles={
  orderId:"Order Id",
  orderDate:"Order Date",
  propertyName:"Property Name",
  roomType:"Room Type",
  price:"Price",
  status:"Status"

}
// Customizable Area End