import React from "react";
// Customizable Area Start
import {
    Avatar,
    Grid, Theme, Typography, createStyles, withStyles
} from "@material-ui/core";
import { Start } from "../../catalogue/src/assets";
import ReviewsPoBoController, { Datum, Props } from "./ReviewsPoBoController.web";
import ReviewsPreviewPo from "./ReviewsPreviewPo.web";
import CustomRating from "../../../components/src/CustomizedComponents.web";
import { getReviewMessage } from "../../../components/src/CommonFunctions";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class ReviewsPo extends ReviewsPoBoController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderReview = (reviewsList: Datum) => {
        const { classes } = this.props
        return (
            <>
                <Grid container alignItems="center" spacing={2} key={reviewsList?.id}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">

                            <div>
                                <Avatar
                                    src={reviewsList?.attributes?.account_id.photo}
                                />

                            </div>
                            <div >
                                <Typography variant="body1" style={{marginLeft:"10px"}} className={classes.nameheading}>{reviewsList?.attributes?.account_id.full_name}</Typography>
                                <Typography variant="body1" style={{marginLeft:"10px"}} color="textSecondary">{reviewsList?.attributes?.review_date}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <CustomRating rating={reviewsList?.attributes?.rating ?? 0} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" className={classes.nameheading}>{reviewsList?.attributes?.rating}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" color="textSecondary">
                                    {reviewsList?.attributes?.comment}
                                </Typography>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { hotel_reviews_count, hotel_rating } = this.state.reviewsList;
        return (
            <>
                <Grid item xs={12} className={classes.layout}>
                    <Grid container direction="row" spacing={6} alignItems="center">
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6} sm={12}>
                                    <Typography variant="subtitle1" className={classes.reviewHeading} >Reviews</Typography>
                                </Grid>
                                <Grid item xs={6} sm={12} >
                                    <Grid container style={{marginTop:"-10px"}} alignItems="center">
                                        <img src={Start} alt="Start" />
                                        <Typography variant="body1" className={classes.nameheading} style={{marginLeft:"18px"}}>{hotel_rating??0}</Typography>
                                        &nbsp;&nbsp;&nbsp;
                                        <Typography className={hotel_reviews_count>0 ? classes.reviewPopupText:classes.secondaryText} style={{fontFamily:"Inter",fontWeight:"bold",textDecorationLine:"underline"}} variant="body1" color="secondary"
                                        data-test-id='open_more_reviews'
                                            onClick={this.openPreviewReviewsPopup}
                                        >{getReviewMessage(hotel_reviews_count)}</Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        {this.state.reviewsList?.hotel_reviews && this.state.reviewsList?.hotel_reviews?.data.length > 0 &&
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={6}>
                                    <Grid item sm={6} xs={12}>
                                        {this.renderReview(this.state.reviewsList?.hotel_reviews?.data[0])}
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        {this.state.reviewsList?.hotel_reviews?.data[1] && this.renderReview(this.state.reviewsList?.hotel_reviews?.data[1])}
                                    </Grid>

                                </Grid>
                            </Grid>
                        }




                    </Grid>

                </Grid>
                {this.state.previewReviewsModal &&

                    <ReviewsPreviewPo
                        reviewsList={this.state.reviewsList}
                        open={this.state.previewReviewsModal}
                        handleClose={this.closePreviewReviewsPopup}
                        navigation={this.props.navigation}
                        id={""}
                        classes={this.props.classes}
                        selectedRoomId={undefined}
                    />
                }

            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export const ReviewsPoStyles = (theme: Theme) =>
    createStyles({
        layout: {
            marginTop: 0,
            marginBottom: 0
        },
        reviewHeading: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.black,
            [theme.breakpoints.down('xs')]: {
                fontSize: 14
            }
        },
        reviewPopupText: {
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.common.black
            }
        },
        nameheading: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,

        },
        cercleImg: {
            marginRight: "10px",
            borderRadius: "150px"
        },
        closeIconLayout: {
            padding: 10,
            display: 'flex',
            justifyContent: "flex-end",
            alignItems: 'center'
        },
        secondaryText:{
            color: theme.palette.secondary.main,
        }
    })
export default withStyles(ReviewsPoStyles)(ReviewsPo);
// Customizable Area End
