import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  Menu,
  MenuItem,

  Grid,
  LinearProgress,
  Dialog,
  DialogContent,
  ClickAwayListener,
  Box,
} from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClearIcons from "@material-ui/icons/Clear";
import {backgroundUploadLogo, uploadImage } from "./assets";
import Footer from "../../../components/src/Footer.web";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { boxShadows, colors, theme } from "../../../components/src/theme";
import BussinessOwnerSideBar  from "../../../components/src/SideBar";
import PetOwnerNavigationMenu from "../../navigationmenu/src/PetOwnerNavigationMenu.web";

// Customizable Area End

import BussinessOwnerDetailsSecondController, {
  Props
} from "./BussinessOwnerDetailsSecondController.web";
import { CssLinearProgress } from "./BussinessOwnerDetailsFirst.web";
import { addPlus } from "../../dashboard/src/assets";
import { AdditionalQuestionsTextField } from "./CancellationPolicyFirst.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

// Customizable Area Start
const Styles = {
  root: {
    borderBottom: "1px solid #E0F4FC",
    padding: "10px",
    fontSize:16
  }
}
// Customizable Area End

export default class BussinessOwnerDetailsSecond extends BussinessOwnerDetailsSecondController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start


  openDilog = () => {
    return (
      <>
        <Dialog
          open={this.state.modelOpen}
          onClose={this.handleModelClose}
          data-test-id="handleModelClose"
          maxWidth="lg"
        >
          <DialogContent style={{ width: "450px", padding: "30px", borderRadius: "15px" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ClearIcons onClick={this.handleModelClose} />
            </div>
            <div style={{ padding: "5%", margin: "20px 0px" }}>
              <Typography
                style={step4Style.modelHeadingTexts as React.CSSProperties}
              >
                Save as Draft?
              </Typography>
              <Typography
                style={step4Style.modelBodyTexts as React.CSSProperties}
              >
                Draft will be saved for a period 1 month and then deleted from
                the system
              </Typography>
            </div>
            <div style={step4Style.modelBtnsDiv}>
              <Button
                style={step4Style.discartBtns as React.CSSProperties}
                onClick={this.handleModelClose}
              >
                Discard
              </Button>
              <Button style={step4Style.saveDraftButton as React.CSSProperties}
                onClick={this.saveDraftData}
                data-test-id="saveDraftData"
              >Save Draft</Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  getValError = (field: string[]): React.ReactNode | undefined => {
    const isInValid = field.findIndex(
      (item) => this.state.error[`${item}Error`]
    );

    if (isInValid >= 0) {
      return (
        <span style={step4Style.errorText}>
          {this.state.MesErrFormThird[`${field[isInValid]}`]}
        </span>
      );
    }
    return undefined;
  };

  generateImageGrid = () => {
    return (
      <>
        <input
          type="file"
          ref={this.fileInputRef}
          style={{ display: 'none' }}
          onChange={this.handleEditFileChange}
          data-test-id="handleEditFileChange"
          multiple={false}
        />
        <Grid container spacing={2} >
          {this.state.selectedImages.map((image, index) => index!=0 &&

            <Grid item lg={6} xs={12} style={step4Style.movertIcommainGrid as React.CSSProperties} key={index}>
              <Grid style={step4Style.movertIconmainDiv}>
                <MoreVertIcon
                  style={step4Style.movertIcon as React.CSSProperties}
                  onClick={(event) => this.handleMenuClick(event, index)}
                  data-test-id="handleMenuClicks"
                />
{this.state.threeDotsPopupStaus===index&&
                  <>
                  <ClickAwayListener onClickAway={this.handleMenuClose}>
                    <Box style={step4Style.popupLayoutTwo as React.CSSProperties}>
                      <Box style={{padding:'0.5rem'}} >
                      <MenuItem onClick={() => this.handleMenuItemClick("Edit")} style={Styles.root}
                    data-test-id="handleMenuItemClickEdit"
                  >
                    Edit
                  </MenuItem>
                  <MenuItem onClick={() => this.handleMenuItemClick("MoveForward")} style={Styles.root}
                    data-test-id="handleMenuItemClickMoveF">
                    Move Forward
                  </MenuItem>
                  <MenuItem onClick={() => this.handleMenuItemClick("Delete")} data-test-id="handleMenuItemClickRemove"
                  style={Styles.root}
                  >
                    Delete
                  </MenuItem>
                

                      </Box>
                    </Box>

                  </ClickAwayListener>
                  </>}
                <img
                  src={image.imageUrl}
                  alt={`Image ${index + 1}`}
                  style={step4Style.imgLogomainDiv as React.CSSProperties}
                />
              </Grid>
            </Grid>
  )}

          <Grid item lg={6} xs={12}>
            <div>
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                style={{ display: "none" }}
                onChange={this.handleFileChangeAdd}
                data-test-id="handleFileChangeAdd"
              />
              <label htmlFor="contained-button-file">
                <Grid style={step4Style.AddCircleOutlineRoundedIconDiv as React.CSSProperties}>
                <img src={addPlus} width={32} height={32} />
                 
                  <Typography variant="body1" color="secondary" style={step4Style.addMoreText as React.CSSProperties}>
                    Add more
                  </Typography>
                </Grid>
              </label>
            </div>
          </Grid>
        </Grid>
      </>
    )

  };
  firstSteps = () => {
    return (
      <>
        <Grid style={step4Style.thirdGrid as React.CSSProperties}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Typography variant="h4" style={step4Style.descriptionText}>
                Add Some Photos Of Your 
                &nbsp;
                  <Typography display="inline" variant="h4" color="secondary"
                  style={{fontWeight: 700}}
                  >PETHOTEL &nbsp;
                    </Typography>
              </Typography>
              <Typography variant="body1" style={step4Style.descriptionText}>
                You'll need 5 photos to get started. You can add more or make changes later.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={step4Style.uplodCompt as React.CSSProperties}
          onDragOver={this.handelDragOver}
          onDrop={this.handleFileDrop} data-test-id="handleFileDrop">
          <div>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              style={step4Style.GridFileuplod as React.CSSProperties}
              onChange={this.handleFileChange}

              data-test-id="handleFileChange"
            />
            <label htmlFor="contained-button-file">
              <img
                src={uploadImage}
                alt="DarkIcon"
                style={step4Style.darkIconImg}
              />
            </label>
          </div>
        </Grid>
        <p style={{ color: "red",fontFamily:"inter" }}>{this.state.imgError}</p>
      </>
    );
  };

  secondSteps = () => {
    const selectedImage = this.state.selectedImages[0];

    return (
      <>
        <Grid style={step4Style.thirdGrid as React.CSSProperties}>

        <Grid container direction="column">
            <Typography variant="h4" style={step4Style.descriptionText}>
            Time To Show Off Your Place
            </Typography>
            <Typography variant="body1" style={step4Style.descriptionText}>
            (Minimum of 5 pictures required)
            </Typography>
          </Grid>
        </Grid>
        <Grid style={step4Style.GridSecondStep}>
          {selectedImage && (
            <Grid item lg={12} xs={12}  style={step4Style.position as React.CSSProperties}>
              <Grid  container direction="row" justifyContent="space-between" style={{position:'absolute',top:'20px'}}>
                <Grid item>
                  <Button style={step4Style.coverBtn as React.CSSProperties}>Cover Photo</Button>
                </Grid>
                <Grid item>
                  <MoreVertIcon
                    style={step4Style.movertIconFirst as React.CSSProperties}
                    onClick={(event) =>
                      this.handleMenuClick(event, 0)
                    }
                    data-test-id="handleMenuClick"
                  />
                  {this.state.threeDotsPopupStaus===0&&
                  <>
                  <ClickAwayListener onClickAway={this.handleMenuClose}>
                    <Box style={step4Style.popupLayout as React.CSSProperties}>
                      <Box style={{padding:'0.5rem'}} >
                      <MenuItem
                      onClick={() => this.handleMenuItemClick("Edit")}
                      data-test-id="handleMenuItemClick"
                      style={Styles.root}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.handleMenuItemClick("Delete")}
                      data-test-id="handleMenuItemClickDelete"
                      style={Styles.root}

                    >
                      Delete
                    </MenuItem>

                      </Box>
                    </Box>

                  </ClickAwayListener>
                  </>}
                </Grid>
              </Grid>

              <Grid style={step4Style.imglogoDiv}>
                {selectedImage && (
                  <img
                    src={selectedImage.imageUrl}
                    alt="wrong"
                    style={step4Style.imglogo as React.CSSProperties}
                  />
                )}
              </Grid>
            </Grid>
          )}

          {this.generateImageGrid()}
        </Grid>

        <p style={{ color: "red" , fontFamily:"inter"}}>{this.state.imgError}</p>
      </>
    );
  };


  thirdSteps = () => {
    return (
      <Grid item xs={12}>
        <Grid container style={step4Style.thirdGrid}>
          <Grid item xs={12}>
            <Typography variant="h4" style={step4Style.descriptionText}>
              Now, let's describe your Place
            </Typography>
            <Typography variant="body1" style={step4Style.descriptionText}>
               Have fun with it as you can always change it later.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container  spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" style={step4Style.titeleGiveText}>
                Title*
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AdditionalQuestionsTextField
                variant="outlined"
                minRows={4}
                maxRows={6}
                multiline
                fullWidth
                placeholder="In one sentence, describe your place"
                value={this.state.titleText}
                name="titleText"
                data-test-id="handleTextareaChange"
                onChange={this.handleTextareaChange}
              />
              {this.getValError(["titleText"])}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" style={step4Style.titeleGiveText as React.CSSProperties}>

                Description*
              </Typography>
              <Grid>


              </Grid>

              <Grid>

              </Grid>
            </Grid>
            <Grid item xs={12}>
            <AdditionalQuestionsTextField
            variant="outlined"
                minRows={6}
                maxRows={8}
                multiline
                fullWidth
                placeholder="Please provide more details about your place"
                value={this.state.descriptionText}
                name="descriptionText"
                data-test-id="changedescriptionText"
                onChange={this.handleTextareaChange}
              />
              {this.getValError(["descriptionText"])}
            </Grid>
           
          </Grid>
        </Grid>
      </Grid>
    );
  };

  button = () => {
    return (
      <Grid style={step4Style.buttonGrid as React.CSSProperties}>
        <Grid>
          <Button
            style={step4Style.backButton as React.CSSProperties}
            onClick={() => this.handleBack()}
            data-test-id="handleBack"
          >
            <div style={step4Style.backBtnText}>
              <div style={step4Style.leftArrowDiv}>
                <KeyboardArrowLeft style={step4Style.backBtnIcon} />
              </div>
              <div style={step4Style.backBtnDiv}>
                <Typography variant="subtitle2" style={step4Style.backBtn}>Back</Typography>
              </div>
            </div>
          </Button>
        </Grid>
        <Grid>
          <Button
            style={step4Style.nextButton as React.CSSProperties}
            onClick={this.handleNext}
            data-test-id="handleNext"
          >
            <div style={step4Style.nextBtnDiv}>
              <div style={step4Style.nextBtnSecDiv}>
                <span>Save and Continue</span>
              </div>
              <div style={step4Style.keyRightArrowDiv}>
                <KeyboardArrowRight />
              </div>
            </div>
          </Button>
        </Grid>
      </Grid>
    );
  };

  saveDraftWarnings = () => {
    return (
      <>
        {(
          (this.state.activeStep === 2 && this.state.saveDraftWarnSec) ||
          (this.state.activeStep === 3 && this.state.saveDraftWarnThird)) &&
          <div style={step4Style.saveDraftWarns as React.CSSProperties}>
            <ReportProblemOutlinedIcon style={{ color: "rgb(195 129 0)" }} />
            <Typography style={step4Style.saveDraftTexts as React.CSSProperties}>
              Draft will be saved for a period of 1 month and then deleted from the system
            </Typography>
          </div>}
      </>
    );
  }

  openStep = () => {
    let componentRender = null;

    switch (this.state.activeStep) {
      case 1:
        componentRender = this.firstSteps();
        break;
      case 2:
        componentRender = this.secondSteps();
        break;
      case 3:
        componentRender = this.thirdSteps();
        break;
      default:
    }

    return componentRender;
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
     
     <NavigationMenu active={0} navigation={this.props.navigation} id={""} />
      
      <div style={step4Style.bo2Content as React.CSSProperties} className="mobile-margin">
        
          <Grid style={step4Style.topGrid}>
            {this.openDilog()}
            <div style={step4Style.secondGrid}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center" direction="row" spacing={3}>
                      <Grid item>
                        <Typography variant="h4" style={step4Style.firstTextCompt as React.CSSProperties}>
                          1. Tell Us More About Your Place
                        </Typography>
                      </Grid>
                      <Grid item>
                        <span
                          style={{
                            textDecoration: "underline",
                            fontSize: "18px",
                            fontWeight: 700,
                            color: "#54A7C9",
                            fontFamily: "inter",
                            cursor: "pointer"
                          }}
                          onClick={this.saveDraftModelOpen}
                          data-test-id="saveDraftModelOpen"
                        >
                          Save Draft
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CssLinearProgress 
                    
                    variant="determinate"
                    />
                    
                  </Grid>
                </Grid>
                {this.saveDraftWarnings()}

                {this.openStep()}
                {this.button()}
              </Grid>
            </div>
          </Grid>
          <Footer color="#E0F4FC" footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
        </div>
    </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const step4Style = {
  errorText:{ color: "red", fontFamily:"inter" },
  saveDraftWarns: { display: "flex", alignItems: "center", height: "50px", backgroundColor: "#FEF3C7", paddingLeft: "10px" },
  saveDraftTexts: {
    fontFamily: "Inter",
    paddingLeft: "10px",
    fontSize: "16px",
    fontWeight: 400,
  },
  modelHeadingTexts: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 700,
    color: "#224350",
  },
  discartBtns: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#EA0C78",
    fontFamily: "inter",
    textTransform: "none",
    border: "1px solid #EA0C78",
    width: "192px",
    height: "59px",
    borderRadius: "8px"
  }, saveDraftButton: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#EA0C78",
    fontFamily: "inter",
    textTransform: "none",
    height: "59px",
    width: "192px",
    borderRadius: "8px"

  },
  modelBtnsDiv: {
    display: "flex",
    justifyContent: "space-between", alignItems: "center"
  },
  modelBodyTexts: {
    textAlign: "center",
    color: "#475569",
    fontSize: "14px",
    fontWeight: 700,
  },
  servicesDiv: {
    border: "1px solid #F15DA5",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    margin: "10px 10px 10px 0px",
    paddingLeft: "10px",
    height: "41px",
    width: "97.7%"
  },
  clearDiv: {
    width: "45px",
    border: "1px solid #F15DA5",
    borderRadius: "8px",
    margin: "10px 0px 10px 10px",
    height: "40px",
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#1E293B",
    cursor: "pointer",
  },
  thirdStepPriceInput: {
    border: "1px solid #F15DA5",
    borderRadius: "8px",
    margin: "10px 0px",
    paddingLeft: "10px",
    height: "41px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#1E293B",
    width: "100%",
  },
  threeStepSelectLable: {
    color: "#334155",
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: 700
  },
  threeStepInputLable: {
    color: "#64748B",
    fontWeight: 700,
    fontSize: "12px"
  },
  countrynameselect: {
    width: "98%",
    marginTop: "10px",
    paddingLeft: "10px",
    height: "49px",
    border: "1px solid #B2E4F8",
    backgroundColor: "#E0F4FC",
    display: "flex",
    borderRadius: "8px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fontHading: {
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "40px",
    color: "#334155",
    marginBottom: "0px",
    fontFamily: "inter"
  },
  nextButton: {
    minWidth: "210px",
    borderRadius: "8px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    margin: "10px 0px 0px 0px",
    backgroundColor: "#EA0C78",
    color: "#fff",
    fontWeight: "700",
    textTransform: "none",
    paddingLeft:"30px"
  },
  mainContainerSecDiv: {
    marginTop: "5px",
    cursor: "pointer"
  },
  onClickNextButton: {
    width: "210px",
    height: "44px",
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    backgroundColor: "#EA0C78",
    margin: "10px 0px 0px 0px",
    color: "#fff",
    fontWeight: "700",
    paddingLeft:"30px"
  },
  backButton: {
    width: "76px",
    height: "26px",
    backgroundColor: "#fff",
    padding: "10px 16px 10px 16px",
    color: "#64748B",
    fontWeight: "700",
    textTransform: "none"
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
    margin: "40px 0px 30px 0px",
  },
  uplodCompt: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column",
    backgroundImage: `url(${backgroundUploadLogo})`,
    height: "410px",
    textAlign: "center",
    borderRadius: "15px",
    cursor: "pointer",
    backgroundRepeat:'no-repeat',
    backgroundSize:'100%',
    backgroundPosition:'center'
  },
  firstTextCompt: {
    fontWeight: theme.typography.fontWeightBold,
    color: colors.darkBlack,
    margin: "0px",
    marginBottom: "10px",
  },
  topGrid: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "50px",
    backgroundColor: "rgb(224, 244, 252)",
    minHeight: "82vh",
  },
  secondGrid: {
    width: "60%",
    padding: "3% 8%",
    backgroundColor: "#fff",
    borderBottomRightRadius: "60px",
  },
  thirdGrid: {
    margin: "50px 0px 30px 0px",
  },
  fourthGrid: {
    color: "#334155",
    marginTop: "9%"
  },
  fourthGridText: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#334155",
    fontFamily: "inter"
  },
  darkIconImg: {
    width: "100%",
    height: "100%",
    cursor: "pointer"
  },
  GridFileuplod: {
    display: "none",
  },
  GridSecondStep: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  titeleGiveText: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
  },
  firstTextarea: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #B2E4F8",
    fontFamily: "inter",
    fontSize: "16px",
    fontWeight: 400,
    padding: "1%",
    marginBottom:"2%"
  },
  firstTextareaText: {
    textAlign: "end",
    color: "rgba(51, 65, 85, 1)",
  },
  secTextarea: {
    padding: "1%",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #B2E4F8",
    fontFamily: "inter",
    fontSize: "16px",
    fontWeight: 400,
  },
  secTextareaText: {
    textAlign: "end",
    color: "rgba(51, 65, 85, 1)",
  },
  backBtnText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  leftArrowDiv: {
    height: "24px",
    width: "24px",
    marginRight:10
  },
  backBtnDiv: {
    height: "24px",
    width: "37px",
  },
  nextBtnDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nextBtnSecDiv: {
    height: "24px",
    marginRight:10
  },
  keyRightArrowDiv: {
    height: "24px",
    width: "24px",
  },
  coverBtnDiv: {
    marginBottom: "20px",
  },
  coverBtn: {
    color: "#334155",
    fontSize: "12px",
    fontWeight: "700",
    backgroundColor: "white",
    zIndex: "20",
    marginLeft: "10px",
    borderRadius:8,
    textTransform:"capitalize"
  },
  movertIconFirst: {
    borderRadius: "15px",
    backgroundColor: "white",
    color: "#64748B",
    zIndex: "20",
    position: "relative",
    right: "26%",
  },
  movertIcon: {
    borderRadius: "50%",
    backgroundColor: "white",
    color: "#64748B",
    position: "absolute",
    right: "25px",
    top: "20px",
    zIndex: "99",
  },
  imglogoDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent:'center',
    height: "420px",
    borderRadius:8,
    backgroundColor:theme.palette.primary.main
  },
  imglogo: {
    maxWidth:'100%',
    marginTop:15,
    paddingBottom: "20px",
    width: "auto",
    height:'auto',
    maxHeight: "420px",
    // maxWidth: "99.7%",
    borderRadius:8
  },
  movertIcommainGrid: {
    marginTop: "20px",
    position: "relative",
  },
  movertIconmainDiv: {
    height: "240px",
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:"8px",
    backgroundColor:theme.palette.primary.main,
  },
  imgLogomainDiv: {
    width:'auto',
    maxWidth: "100%",
    height:'auto',
    maxHeight: "240px",
    position: "relative",
    borderRadius:8
  },
  AddCircleOutlineRoundedIconDiv: {
    height: "210px",
    padding: "15px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "dashed",
    borderColor:theme.palette.secondary.main,
    marginTop: "20px",
  },
  AddCircleOutlineRoundedIcon: {
    color: "#F485BB",
  },
  addMoreText: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    fontWeight: "400",
    fontFamily:theme.typography.fontFamily,
    marginTop:'5px'
  },
  descriptionText: {
    fontWeight: theme.typography.fontWeightRegular,
    color: colors.darkBlack,
  },
  popupLayout:{
    background:theme.palette.common.white,
    boxShadow:boxShadows.whiteShadow,
    position:'absolute',
    zIndex:99,
    borderRadius:8,
    right:20,
    top:35
  },
  position:{position:"relative"},
  popupLayoutTwo:{
    background:theme.palette.common.white,
    boxShadow:boxShadows.whiteShadow,
    position:'absolute',
    zIndex:99,
    borderRadius:8,
    right:25,
    top:40
  },
  bo2Content:{
    marginLeft: "140px", // Adjust based on the width of your sidebar
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    overflowY: "auto",
  },
  backBtn:{
    fontWeight:theme.typography.fontWeightBold,
    color:theme.palette.common.black
  },
  backBtnIcon:{
    color:theme.palette.common.black

  }

};
// Customizable Area End
