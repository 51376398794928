import React, { Component } from 'react';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Box, ClickAwayListener, MenuItem } from '@material-ui/core';

interface CustomMenuProps {
    anchorEl: boolean;
    onClose: () => void;
    onEdit: () => void;
    onDelete: () => void;
    customProp: string;
  }

class CustomMenu extends Component <CustomMenuProps> {
  state = {
    mouseHover: 0,
  };

  handleMouseEnter = (index: number) => {
    this.setState({ mouseHover: index });
  };

  handleMouseLeave = () => {
    this.setState({ mouseHover: 0 });
  };

  render() {
    const { onClose, onEdit } = this.props;
    const { mouseHover } = this.state;

    return (
      <>
      <ClickAwayListener onClickAway={onClose}>
        <Box style={webStyle.layout as React.CSSProperties}>
          <Box padding={{padding:'0.5rem'}}>
            <MenuItem
             onClick={onEdit}
                   data-test-id="editData"
                   style={{
                     backgroundColor: mouseHover === 1 ? '#B2E4F8' : 'white',
                     justifyContent: 'flex-start', 
                     borderRadius: '8px',
                     width: '95%',
                     margin: '5px',
                   }}
                   onMouseLeave={this.handleMouseLeave}
                   onMouseEnter={() => this.handleMouseEnter(1)}
            >
            <EditIcon style={{ marginRight: '7px' }} />&nbsp;Edit 
            </MenuItem>

          </Box>
        </Box>

      </ClickAwayListener>
      </>


      // <Menu
      //   anchorEl={anchorEl}
      //   id="long-menu"
      //   open={Boolean(anchorEl)}
      //   keepMounted
      //   onClose={onClose}
      //   PaperProps={{
      //     style: {
      //       width: '20ch',
      //       maxHeight: 200,
      //       borderRadius: '8px',
      //       marginTop: '5%',
      //     },
      //   }}
      //   transformOrigin={{
      //     horizontal: 'right',
      //     vertical: 'top',
      //   }}
      //   anchorOrigin={{
      //     horizontal: 'right',
      //     vertical: 'bottom',
      //   }}
      // >
      //   <div>
      //     <Button
      //       onClick={onEdit}
      //       data-test-id="editData"
      //       style={{
      //         backgroundColor: mouseHover === 1 ? '#B2E4F8' : 'white',
      //         justifyContent: 'flex-start',
      //         borderRadius: '8px',
      //         width: '95%',
      //         margin: '5px',
      //       }}
      //       onMouseLeave={this.handleMouseLeave}
      //       onMouseEnter={() => this.handleMouseEnter(1)}
      //     >
      //       <EditIcon style={{ marginRight: '7px' }} />
      //       <span>Edit</span>
      //     </Button>
      //   </div>
      // </Menu>
    );
  }
}

export default CustomMenu;


const webStyle={
 layout:{
  position: 'absolute',
  borderRadius: '8px',
  width: '100%',
  background: "#ffffff",
  right:50,
  overflowY: "auto",
  top: 50,
  border: "1px solid  #E2E8F0",
  boxShadow: "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  zIndex: 99 as const,
  padding:'5px',

 }
}
