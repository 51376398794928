import React from "react";
// Customizable Area Start
import {
    styled, Avatar, Box, Button, ClickAwayListener, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, TextField, Theme, Typography,
    IconButton,
    createStyles, withStyles, Dialog, DialogActions, DialogContent, DialogTitle,
} from "@material-ui/core";
import Footer from "../../../components/src/Footer.web";
import SinglePropertyController, { Tabs, configJSON, Props } from "./SinglePropertDetailsController.web";
import { boxShadows, colors, theme } from "../../../components/src/theme";
import CustomRating, { CustomizedTextFieldAutoComplete } from "../../../components/src/CustomizedComponents.web";
import { Calendar, DateObject } from "react-multi-date-picker";
import ClearIcon from '@material-ui/icons/Clear';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { calenderIcon, threeDotsIcon } from "../../user-profile-basic/src/assets";
import { addIcon, arrowDown } from "../../settings2/src/assets";
import PaymentInformation from "./paymentInformation.web";
import AboutListing from "./AboutListing.web";
import CancellationPolicyDetails from "./CancellationPolicyDetails.web";
import { arrowback } from "../../shoppingcart/src/assets";
import Loader from "../../../components/src/Loader.web";
import { goBack } from "../../../components/src/CommonFunctions";
import { CreateYourOwnService, HotelAdditionalService, RoomManipulate, ServiceItem } from "./types";
import { CustomRadio, StyledDialog } from "../../user-profile-basic/src/BussinessOwnerDetailsThird.web";
import { addPlus, smallAddPlus, smallMinus } from "./assets";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import Switch from '@material-ui/core/Switch';
import CloseIcon from '@material-ui/icons/Close';
import { green, red } from "@material-ui/core/colors";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Customizable Area End

// Customizable Area Start
const CustomSwitch = withStyles((theme) => ({
    switchBase: {
        color: red[500],
        "&$checked": {
            color: green[500]
        },
        "&$checked + $track": {
            backgroundColor: green[500]
        },
        '& + $track': {
            backgroundColor: red[500],
        },
    },
    checked: {},
    track: {},
}))(Switch);

export const CustomizedTextField = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiInputBase-root": {
                borderRadius: 8,
                height: 42,
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.common.black,
                borderColor: colors.primaryBlue,

            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primaryBlue,

            },
            "& .MuiOutlinedInput-notchedOutline:hover": {
                borderColor: colors.primaryBlue,

            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.primaryBlue,
                borderWidth: "2px"
            },
            "& .MuiOutlinedInput-root:hover": {
                borderColor: colors.primaryBlue,

            },
            "& .MuiSelect-select:focus": {
                borderColor: colors.primaryBlue,
                backgroundColor: "white"
            },
            "& .MuiSvgIcon-root": {
                fontSize: "25px"
            },
        }
    }))(TextField);

// Customizable Area End

export class SingleProperty extends SinglePropertyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderPhotos = () => {
        const { classes } = this.props;
        const { allUploadedFiles, editPhotosStatus } = this.state
        return (
            <>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                                <Grid item>
                                    <Typography variant="h6" className={classes.labelText}>{configJSON.propertyText.photos}</Typography>

                                </Grid>
                                <Grid item>
                                    <Typography data-test-id='edit_photos'
                                        onClick={editPhotosStatus ? this.handlePhotosSave : this.editPhotos}
                                        variant="body1" className={classes.editText}>
                                        {editPhotosStatus ? configJSON.propertyText.save : configJSON.propertyText.edit}
                                    </Typography>

                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={2} style={{ gap: 10 }} >
                                        {allUploadedFiles.map((image: any, index: number) => {
                                            return (
                                                <>
                                                    <Grid item xs={12} sm={image?.is_cover_image ? 12 : 6}
                                                        key={index} className={image?.is_cover_image ? classes.bigImage : classes.smallImages}>
                                                        <img src={image?.url || image?.Url} className={classes.imageGridSize} />
                                                        {this.state.editPhotosStatus &&
                                                            <>
                                                                <img src={threeDotsIcon} className={classes.threeDotsIcon}

                                                                    onClick={() => this.handleopenthreeDots(image?.id)} data-test-id="open_threedots" />
                                                                {image?.id === this.state.selectImageId &&
                                                                    this.renderThreeDotsPopUp(image, index, image.cover)}
                                                            </>}
                                                    </Grid>
                                                </>
                                            )
                                        })}

                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                variant="outlined"
                                                component="label"
                                                disableRipple
                                                disableElevation
                                                disableFocusRipple
                                                className={classes.uplodLayout}
                                            >
                                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                                    <img src={addPlus} width={32} height={32} />
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="secondary"
                                                        className={classes.addText}
                                                    >
                                                        <input
                                                            hidden
                                                            disabled={!this.state.editPhotosStatus}
                                                            type="file"
                                                            accept="image/*"
                                                            multiple
                                                            data-test-id="uploadFiles"
                                                            onChange={this.handleUploadFiles}

                                                        />
                                                        Add More
                                                    </Typography>
                                                </Box>
                                            </Button>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </>
        )
    }
    renderThreeDotsPopUp = (file: any, index: number, cover: boolean) => {
        const { classes } = this.props;
        return (
            <>
                <ClickAwayListener onClickAway={this.handleclosethreeDots}>
                    <Box className={classes.dotsOuterContainer}>

                        <Box className={classes.dotsinnerContainer}>
                            {!file.is_cover_image &&
                                <>
                                    {cover === false ? "" : <MenuItem className={classes.menuText}
                                        data-test-id='move_forward'
                                        onClick={() => this.moveForwardImages(file.id)}
                                    >Move forward</MenuItem>
                                    }
                                </>}

                            {!file.is_cover_image &&
                                <>
                                    {cover === false ? "" : <MenuItem className={classes.menuText}
                                        data-test-id='moveCoverPhoto'
                                        onClick={() => this.updateCoverRemovePhoto(file.id, 'update')}
                                    >Move to cover photo</MenuItem>}
                                </>

                            }

                            <MenuItem className={classes.menuText}
                                data-test-id="deleteFile"
                                onClick={
                                    cover === false ? () => this.removeUploadFile(file, index) : () => this.handleDeletePhotos(file.id, "delete")
                                }
                            >Delete</MenuItem>
                        </Box>
                    </Box>
                </ClickAwayListener>

            </>
        )

    }

    renderReviews = () => {
        const { classes } = this.props;
        return (
            <>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>

                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center" spacing={3} style={{ position: 'relative' }}>
                                <Grid item>
                                    <Typography variant="body1" className={classes.labelText}>{configJSON.propertyText.reviews}</Typography>

                                </Grid>
                                <Grid item style={webStyle.selectDates as React.CSSProperties}
                                    onClick={this.openSelectDate}
                                    data-test-id="open_calender"
                                >
                                    <Grid container direction="row" alignItems="center">
                                        <img src={calenderIcon} />
                                        {this.state.dateRange != "" ?
                                            <Typography variant="body1">{this.state.dateRange}</Typography>
                                            :
                                            <Typography variant="body1" color="secondary" style={webStyle.selectDateText as React.CSSProperties}>Select dates</Typography>
                                        }
                                        <ExpandLessIcon />
                                    </Grid>
                                </Grid>
                                {this.state.openDateStatus &&
                                    this.renderReviewsCalender()}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="body1" className={classes.reviewCount} color="secondary">
                                        {this.state.reviewsList?.hotel_reviews_count} &nbsp;reviews
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.overallRatingText} color="secondary">
                                                {this.state.reviewsList?.hotel_rating}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            {this.state.reviewsList?.hotel_rating ?

                                                <CustomRating rating={this.state.reviewsList?.hotel_rating} /> :
                                                <CustomRating rating={0} />
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                        {this.state.reviewsList?.hotel_reviews?.data?.map((review: any) => {
                            return (<>

                                <Grid item xs={12} key={review?.id}>
                                    <Grid container alignItems="center">
                                        <div>
                                            <Avatar
                                                src={review?.attributes?.account_id?.photo}
                                            />
                                        </div>
                                        <div style={{ marginLeft: 10 }}>
                                            <Typography variant="body1" className={classes.labelText}>{review?.attributes?.account_id?.full_name}</Typography>
                                            <Typography variant="body1" color="textSecondary">{review?.attributes?.review_date}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <CustomRating rating={review?.attributes?.rating} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="textSecondary">
                                                {review?.attributes?.comment}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>)
                        })}
                        {this.state.reviewsList?.hotel_reviews?.data.length === 0 &&
                            <Grid item xs={12}>
                                <Grid container direction="row" alignItems="center" justifyContent="center" style={webStyle.noReviewsFoundLayout}>
                                    <Typography variant="h6" className={classes.labelText}>{configJSON.NoReviews}</Typography>
                                </Grid>
                            </Grid>}
                    </Grid>

                </Grid >
            </>



        )
    }
    calender = () => {
        const { classes } = this.props
        return (
            <div style={{ overflow: "hidden" }}>
                <Calendar
                    onChange={this.handleDateChange}
                    data-test-id="calender"
                    range
                    numberOfMonths={2}
                    minDate={new DateObject().subtract(1, 'years')} // Min date one year back
                    maxDate={new DateObject()} // Max date today
                    value={this.state.selectedRange}
                    className={classes.customCalender}
                >

                </Calendar>
            </div>
        )
    }
    renderReviewsCalender = () => {
        const { classes } = this.props
        return (
            <>
                <ClickAwayListener onClickAway={this.closeSelectDate} >
                    <Box className={classes.claenderLayout} >
                        <Box style={webStyle.calendarOuter}>
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="h6" className={classes.labelText}>
                                                    {configJSON.propertyText.select}
                                                </Typography>

                                            </Grid>
                                            <Grid item>
                                                <IconButton onClick={this.closeSelectDate} data-test-id="close_icon">
                                                    <ClearIcon className={classes.crossicon} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} justifyContent="flex-end" alignItems="center">
                                            <Typography align="right" className={classes.selectedTabText}
                                                color="secondary" variant="body1"
                                                onClick={this.clearSelectedDates}
                                                data-test-id="clear_dates"
                                                style={{ cursor: "pointer" }}
                                            >
                                                {configJSON.propertyText.clear}</Typography>

                                        </Grid>

                                    </Grid>

                                </Grid>

                            </Grid>
                            {this.calender()}
                        </Box>
                    </Box>
                </ClickAwayListener>
            </>
        );
    }

    renderSwitch = (room: RoomManipulate) => {
        return (
            <>
                {this.state.selectedHotelIdEdit != room.room_id ?
                    <>
                        {room.status === "active" ? <Typography variant="subtitle2" style={webStyle.activeText}>Active
                        </Typography> : <Typography variant="subtitle2" style={webStyle.disableText}>Disabled
                        </Typography>}
                    </>
                    :
                    <>
                        <CustomSwitch
                            checked={room.status === "active"}
                            color="primary"
                            name="status"
                            onChange={(event) => { this.handleDisable(event, room.room_id) }}
                            data-test-id={`roomCallId${room.room_id}`}
                        />
                    </>}
            </>
        )
    }

    typeOfRooms = () => {
        const { classes } = this.props;
        return (
            <>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="subtitle2" className={classes.labelText}>{configJSON.typeofRooms.setupMore}</Typography>

                                </Grid>
                                <Grid item>
                                    <Typography data-test-id='add_room'
                                        variant="body1" className={classes.editText}
                                        onClick={this.openAddRoomType}
                                    >
                                        {configJSON.typeofRooms.addMore}
                                    </Typography>

                                </Grid>
                            </Grid>

                        </Grid>
                        {this.state.chnagedRoomData?.map((room: RoomManipulate, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Typography variant="subtitle2" className={classes.labelText}>Room Type({index + 1})</Typography>

                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="row" spacing={2}>
                                                        <Typography data-test-id='delete_room'
                                                            onClick={() => this.openDeleteRoomModal(room.room_id)}
                                                            variant="body1" style={webStyle.editText}>
                                                            Delete
                                                        </Typography>
                                                        <Typography data-test-id='edit_room'
                                                            onClick={this.state.selectedHotelIdEdit == room.room_id ? () => this.handleSaveUpdateRoom(room) : () => this.handleRoomEdit(room.room_id)}
                                                            variant="body1" className={classes.editText}>
                                                            {this.state.selectedHotelIdEdit == room.room_id ? "Save" : "Edit"}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Typography variant="body1" className={classes.labelText}>Room Type</Typography>

                                                </Grid>
                                                <Grid item>
                                                    {this.renderSwitch(room)}
                                                </Grid>


                                                <Grid item xs={12}>
                                                    {this.state.selectedHotelIdEdit != room.room_id
                                                        ?

                                                        <>
                                                            <Box className={classes.outlinedBox} >
                                                                <Box>
                                                                    <Typography variant="body1" className={classes.tabText}>Room type</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="h6" className={classes.labelText}>
                                                                        {room.room_type}
                                                                    </Typography>
                                                                </Box>

                                                            </Box>
                                                        </> :
                                                        <CustomizedTextField

                                                            data-test-id="room_type"
                                                            value={room.room_type}
                                                            name="room_type"
                                                            onChange={(event) => this.updateRoomNameDesctiption(event, index)}
                                                            variant="outlined"
                                                            fullWidth
                                                            type="text"
                                                            placeholder="E.g. Single Room, Double Room, Standard, etc."
                                                        />

                                                    }


                                                    {this.state.roomErrors[index] && this.state.roomErrors[index].room_type && (
                                                        <Typography variant="body1" color="error">{this.state.roomErrors[index].room_type}</Typography>
                                                    )}

                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.labelText}>Description of the Room</Typography>

                                                </Grid>
                                                <Grid item xs={12}>


                                                    <CustomizedTextField
                                                        minRows={5}
                                                        disabled={this.state.selectedHotelIdEdit != room.room_id}
                                                        data-test-id="description"
                                                        value={room.description}
                                                        name="description"
                                                        multiline
                                                        onChange={(event) => this.updateRoomNameDesctiption(event, index)}
                                                        className={classes.descriptionInput}
                                                        placeholder="Add Description for room"
                                                        variant="outlined"
                                                        fullWidth

                                                    />
                                                    {this.state.roomErrors[index] && this.state.roomErrors[index].description && (
                                                        <Typography variant="body1" color="error">{this.state.roomErrors[index].description}</Typography>
                                                    )}

                                                </Grid>

                                            </Grid>

                                        </Grid>


                                        <Grid item xs={12}>

                                            <Box className={classes.outlinedBox} >
                                                <Box>
                                                    <Typography variant="body1" className={classes.tabText}>Room type</Typography>
                                                </Box>
                                                <Box>
                                                    {this.state.selectedHotelIdEdit === room.room_id ? <RadioGroup name="room_for" style={{ display: 'block' }} value={room.room_for} onChange={(event) => this.handleSelectUpdatePetType(event, index)}>
                                                        {this.renderPetTypesRadio()}
                                                    </RadioGroup> :
                                                        <Typography variant="h6" className={classes.labelText}>
                                                            {room.room_for}
                                                        </Typography>
                                                    }
                                                </Box>

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box className={classes.outlinedBox} >
                                                <Box>
                                                    <Typography variant="body1" className={classes.tabText}>How many rooms are at the property?</Typography>
                                                </Box>
                                                <Box>
                                                    <Grid item xs={12}>
                                                        <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                                                            {this.state.selectedHotelIdEdit === room.room_id ?
                                                                <>
                                                                    <Grid item className={classes.cursor}
                                                                        data-test-id='minusUpdateRooms'

                                                                        onClick={() => this.minusUpdateRooms(index, 'rooms')}>
                                                                        <img src={smallMinus} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="h6" className={classes.petCount}>
                                                                            {room.num_rooms}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item className={classes.cursor}
                                                                        data-test-id='addUpdateRooms'
                                                                        onClick={() => this.addUpdateRooms(index, 'rooms')}>
                                                                        <img src={smallAddPlus} />

                                                                    </Grid>
                                                                </>
                                                                : <Grid item>
                                                                    <Typography variant="h6" className={classes.petCount}>
                                                                        {room.num_rooms}
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                            </Box>
                                            {
                                                this.state.selectedHotelIdEdit === room.room_id &&
                                                <>

                                                    {this.state.roomErrors[index] && this.state.roomErrors[index].num_roomsError && (
                                                        <Typography variant="body1" color="error">{this.state.roomErrors[index].num_roomsError}</Typography>
                                                    )}
                                                </>

                                            }


                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box className={classes.outlinedBox} >
                                                <Box>
                                                    <Typography variant="body1" className={classes.tabText}>{`Maximum ${room.room_for.toLowerCase()}s in one room`}</Typography>
                                                </Box>
                                                <Box>
                                                    <Grid item xs={12}>
                                                        <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                                                            {this.state.selectedHotelIdEdit === room.room_id ?
                                                                <>

                                                                    <Grid item className={classes.cursor}
                                                                        data-test-id="minusUpdateRooms"
                                                                        onClick={() => this.minusUpdateRooms(index, "pets")}>
                                                                        <img src={smallMinus} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="h6" className={classes.petCount}>
                                                                            {room.pet_count}


                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item className={classes.cursor}
                                                                        data-test-id="addUpdateRooms"

                                                                        onClick={() => this.addUpdateRooms(index, "pets")}>
                                                                        <img src={smallAddPlus} />

                                                                    </Grid>
                                                                </> :
                                                                <Grid item>
                                                                    <Typography variant="h6" className={classes.petCount}>
                                                                        {room.pet_count}
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                            </Box>
                                            {this.state.selectedHotelIdEdit === room.room_id &&
                                                <>
                                                    {this.state.roomErrors[index] && this.state.roomErrors[index].petCountError && (
                                                        <Typography variant="body1" color="error">{this.state.roomErrors[index].petCountError}</Typography>
                                                    )}
                                                </>

                                            }

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                {room.pet_room_prices.length > 0 && (
                                                    <>

                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" className={classes.labelText}>
                                                                Room Prices
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.renderPetPrices(room)}

                                                            {this.state.roomErrors[index] && this.state.roomErrors[index].priceError && (
                                                                <Typography variant="body1" color="error">{this.state.roomErrors[index].priceError}</Typography>
                                                            )}
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>

                                        </Grid>


                                    </Grid>

                                </Grid>
                            )

                        })}
                        {this.state.chnagedRoomData.length === 0 &&
                            <Grid item xs={12}>
                                <Grid container direction="row" style={{ height: "250px" }} justifyContent="center" alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h4" align="center" style={webStyle.dailogHeading} >No Rooms Found</Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        }
                        <Grid item xs={12}>
                            {this.rednerAdditionalServicesOfferred()}

                        </Grid>
                        <Grid item xs={12}>
                            {this.renderCheckInCheckOutDetails()}

                        </Grid>
                    </Grid>

                </Grid>
            </>
        )
    }
    rednerAdditionalServicesOfferred = () => {
        const { classes } = this.props;
        return (
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="subtitle2" className={classes.labelText}>{'Additional Services Offered'}</Typography>

                        </Grid>
                        <Grid item>
                            <Typography data-test-id='edit_service'
                                variant="body1" className={classes.editText}
                                onClick={this.state.additionalServicesStatus ? this.handleUpdateOwnServices : this.editAdditionalServices}
                            >
                                {this.state.additionalServicesStatus ? "Save" : "Edit"}
                            </Typography>

                        </Grid>
                    </Grid>

                </Grid>
                {this.state.additionalServicesStatus && <Grid item xs={12}>
                    <Box className={classes.outlinedBox} style={{ background: theme.palette.primary.main }}
                        onClick={this.openAdditionalServices}
                        data-test-id="openAdditionalServices"
                    >
                        <Box>
                            <Typography variant="body1" className={classes.tabText}>Select the services</Typography>
                        </Box>
                        <Box>
                            <img src={arrowDown} />
                        </Box>
                        {this.state.newAdditionalServicesStatus && this.renderAdditionalServices()}

                    </Box>

                </Grid>}
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        {this.renderAdditionalServicesList()}
                        {this.renderOwnServices()}






                    </Grid>

                </Grid>

            </Grid>
        )
    }

    renderOwnServices = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.ownServices.map((ownService: CreateYourOwnService) => {
                    return (
                        <>
                            {this.state.additionalServicesStatus ?
                                <Grid item xs={12} key={ownService.id}>
                                    <Grid container direction="row" alignItems="center" spacing={2}>
                                        <Grid item xs={12} sm={6} >
                                            <Box className={classes.ownServicesBox}>
                                                <CustomizedTextFieldAutoComplete
                                                    placeholder="Enter the service name"
                                                    value={ownService.name}
                                                    variant="outlined"
                                                    fullWidth
                                                    data-test-id='service_name'
                                                    onChange={(event) => this.handleServiceNameChange(ownService.id, event.target.value)}
                                                    className={classes.inputSearch}
                                                />
                                            </Box>

                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <div style={stepStyle.serViceBox}>
                                                <div key={ownService.id}>
                                                    <RadioGroup
                                                        value={ownService.service_provided_type || "OneTime"}
                                                        onChange={(event) =>
                                                            this.handleServiceType(event.target.value, ownService.id)
                                                        }
                                                        style={{ display: "flex !important", flexDirection: "row" }}
                                                        data-test-id="radiotype_own"
                                                    >
                                                        <FormControlLabel
                                                            value="OneTime"
                                                            label="One Time"
                                                            control={<Radio />}
                                                            style={{
                                                                borderRadius: "8px",
                                                                height: "33px",
                                                                width: "100%",
                                                                whiteSpace: "nowrap"
                                                            }}
                                                            data-test-id="mrvalue"
                                                        />
                                                        <FormControlLabel
                                                            control={<Radio />}
                                                            value="Daily"
                                                            label="Daily"
                                                            style={{
                                                                width: "90%",
                                                                height: "33px",
                                                                borderRadius: "8px",
                                                            }}
                                                            data-test-id="missvalue"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                                <div key={ownService.id}>
                                                    <RadioGroup
                                                        value={ownService.charge_type || "EachPet"}
                                                        onChange={(event) => this.handleChargeType(event.target.value, ownService.id)}
                                                        style={stepStyle.radioGroup}
                                                        data-test-id="radioTypeCharge"
                                                    >
                                                        <FormControlLabel
                                                            value="EachPet"
                                                            control={<Radio />}
                                                            label="Per Pet"
                                                            style={stepStyle.control}
                                                            data-test-id="mrvalue"
                                                        />
                                                        <FormControlLabel
                                                            value="All"
                                                            control={<Radio />}
                                                            label="All Pets"
                                                            style={stepStyle.control1}
                                                            data-test-id="missvalue"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={2} >
                                            <Box className={classes.ownServicesBox}>
                                                <CustomizedTextFieldAutoComplete
                                                    value={ownService.price != null ? `£ ${ownService.price}` : ""}
                                                    variant="outlined"
                                                    fullWidth
                                                    data-test-id='service_price'
                                                    onChange={(event) => this.handlePriceChange(ownService.id, event)}
                                                    className={classes.inputSearch}
                                                />

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={1} >
                                            <Box className={classes.ownServicesBoxPriceBox}
                                                onClick={(id) => this.handleDeleteService(ownService.id, true)}
                                                data-test-id='deleteOwnAdditional'
                                            >

                                                <ClearIcon />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.ownServiceInputErrors[`service_${ownService.id}`] && (

                                                <Typography variant="body1" color="error">{this.state.ownServiceInputErrors[`service_${ownService.id}`]}</Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.ownServiceInputErrors[`price_${ownService.id}`] && (

                                                <Typography variant="body1" color="error">{this.state.ownServiceInputErrors[`price_${ownService.id}`]}</Typography>
                                            )}
                                        </Grid>


                                    </Grid>

                                </Grid> :
                                <Grid item xs={12}>
                                    <Box className={classes.ownpreviewServicesBox}>
                                        <Box padding={'0px 20px'}>

                                            <Typography variant="subtitle2">{ownService.name}</Typography>
                                        </Box>
                                        <Box className={classes.servicePropertyBox}>
                                            <Box className={classes.ownPriceBox}>
                                                <Typography className={classes.labelText} align="center" variant="subtitle2">{ownService.service_provided_type}</Typography>
                                            </Box>
                                            <Box className={classes.priceBox}>
                                                <Typography className={classes.labelText} align="center" variant="subtitle2">{ownService.charge_type === "All" ? "All Pets" : "Per Pet"}</Typography>
                                            </Box>
                                            <Box className={classes.ownPriceBox}>
                                                <Typography className={classes.labelText} align="center" variant="subtitle2">£{ownService.price}</Typography>

                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                            }
                        </>

                    )
                })}
            </>

        )
    }
    renderAdditionalServicesList = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.additionalServicesList.map((services: HotelAdditionalService) => {
                    return (
                        <>
                            {this.state.additionalServicesStatus ?
                                <Grid item xs={12}>
                                    <Grid container direction="row" alignItems="center" spacing={2}>
                                        <Grid item xs={9} sm={6} >
                                            <Box className={classes.additionalServicesBox}>
                                                <Typography variant="subtitle2">{services.name}</Typography>

                                            </Box>

                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <div style={stepStyle.serViceBox}>
                                                <div key={services.id}>
                                                    <RadioGroup
                                                        value={services.service_provided_type || "OneTime"}
                                                        onChange={(event) => this.handleServiceType(event.target.value, services.id)}
                                                        style={{ display: "flex !important", flexDirection: "row" }}
                                                        data-test-id="radiotype"
                                                    >
                                                        <FormControlLabel
                                                            value="OneTime"
                                                            control={<Radio />}
                                                            label="One Time"
                                                            style={{
                                                                borderRadius: "8px",
                                                                width: "100%",
                                                                height: "33px",
                                                                whiteSpace: "nowrap"
                                                            }}
                                                            data-test-id="mrvalue"
                                                        />
                                                        <FormControlLabel
                                                            value="Daily"
                                                            control={<Radio />}
                                                            label="Daily"
                                                            style={{
                                                                borderRadius: "8px",
                                                                width: "90%",
                                                                height: "33px",
                                                            }}
                                                            data-test-id="missvalue"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                                <div key={services.id}>
                                                    <RadioGroup
                                                        value={services.charge_type || "EachPet"}
                                                        onChange={(event) => this.handleChargeType(event.target.value, services.id)}
                                                        style={{ display: "flex !important", flexDirection: "row" }}
                                                        data-test-id="radioTypeCharge"
                                                    >
                                                        <FormControlLabel
                                                            value="EachPet"
                                                            control={<Radio />}
                                                            label="Per Pet"
                                                            style={stepStyle.control}
                                                            data-test-id="mrvalue"
                                                        />
                                                        <FormControlLabel
                                                            value="All"
                                                            control={<Radio />}
                                                            label="All Pets"
                                                            style={stepStyle.control1}
                                                            data-test-id="missvalue"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={2} >
                                            <Box className={classes.additionalServicesBox}>
                                                <CustomizedTextFieldAutoComplete
                                                    value={services.price != null ? `£ ${services.price}` : ""}
                                                    variant="outlined"
                                                    fullWidth
                                                    data-test-id='additional_price'
                                                    onChange={(event) => this.handlePriceChange(services.id, event)}
                                                    className={classes.inputSearch}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={1} >
                                            <Box className={classes.additionalServiceBox}
                                                data-test-id='deleteAdditional'
                                                onClick={(id) => this.handleDeleteService(services.id, false)}
                                            >

                                                <ClearIcon />

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.additionalInputErrors[`price_${services.id}`] && (

                                                <Typography variant="body1" color="error">{this.state.additionalInputErrors[`price_${services.id}`]}</Typography>
                                            )}

                                        </Grid>
                                    </Grid>

                                </Grid> :
                                <Grid item xs={12} >
                                    <Box className={classes.previewServicesBox}>
                                        <Box padding={'0px 20px'}>
                                            <Typography variant="subtitle2">{services.name}</Typography>

                                        </Box>
                                        <Box className={classes.servicePropertyBox}>
                                            <Box className={classes.priceBox}>
                                                <Typography className={classes.labelText} align="center" variant="subtitle2">{services.service_provided_type}</Typography>
                                            </Box>
                                            <Box className={classes.priceBox}>
                                                <Typography className={classes.labelText} align="center" variant="subtitle2">{services.charge_type === "All" ? "All Pets" : "Per Pet"}</Typography>
                                            </Box>
                                            <Box className={classes.priceBox}>
                                                <Typography className={classes.labelText} align="center" variant="subtitle2">£{services.price}</Typography>

                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                            }
                        </>
                    )
                })}
            </>
        )
    }

    renderAdditionalServices = () => {
        const { classes } = this.props
        return (
            <>
                <ClickAwayListener onClickAway={this.closeAdditionalServices}>
                    <Box className={classes.additionalServicesPOup}>
                        <Box style={{ padding: '0.5rem' }}>
                            {this.state.filteredServicesList.map((service: ServiceItem) => {
                                return (
                                    <MenuItem className={classes.valuesText}
                                        data-test-id='selectService'
                                        onClick={() => this.handleSelectSevices(service)}
                                    >{service.attributes.service_name}</MenuItem>
                                )
                            })}

                        </Box>
                    </Box>

                </ClickAwayListener>
            </>
        )
    }
    renderPetTypesRadio = () => {
        return (
            <>
                {this.state.hotelDetails?.attributes.pet_type === 'dog_hotel' && <FormControlLabel value="Dog" control={<CustomRadio />} label="Dog" />}
                {this.state.hotelDetails?.attributes.pet_type === 'cat_hotel' && <FormControlLabel value="Cat" control={<CustomRadio />} label="Cat" />}
                {this.state.hotelDetails?.attributes.pet_type === 'dog_cat_hotel' &&
                    <>
                        <FormControlLabel value="Dog" control={<CustomRadio />} label="Dog" />
                        <FormControlLabel value="Cat" control={<CustomRadio />} label="Cat" />
                    </>}</>
        )
    }
    openModel = () => {
        const { classes } = this.props;
        return (
            <>
                <StyledDialog
                    open={true}
                    onClose={this.closeAddRoomType}
                    data-test-id="handleModelClose"
                    maxWidth="lg"
                    style={{ borderRadius: "15px" }}
                >


                    <DialogContent style={{ width: "700px" }}>
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={2} >
                                <Grid item xs={12} container justifyContent="flex-end" alignItems="center" className={classes.cursor}>
                                    <ClearIcon onClick={this.closeAddRoomType} />

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>Room type</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomizedTextField
                                                value={this.state.RoomFields.roomName}
                                                name="roomName"
                                                data-test-id="room_name"
                                                onChange={this.handleAddRoomDescription}
                                                variant="outlined"
                                                fullWidth
                                                type="text"
                                                placeholder="E.g. Single Room, Double Room, Standard, etc."
                                            />
                                            <Typography color="error" variant="body1">{this.state.validationErrors.roomName}</Typography>

                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>Description of the room</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomizedTextField
                                                value={this.state.RoomFields.roomDescription}
                                                name="roomDescription"
                                                data-test-id="roomDescription"
                                                onChange={this.handleAddRoomDescription}
                                                className={classes.descriptionInput}
                                                multiline
                                                minRows={5}
                                                variant="outlined"
                                                fullWidth
                                                type="text"
                                                placeholder="Add Description for room"
                                            />
                                            <Typography color="error" variant="body1">{this.state.validationErrors.roomDescription}</Typography>

                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Grid item xs={12}>
                                    <Box className={classes.outlinedBox} >
                                        <Box>
                                            <Typography variant="body1" className={classes.tabText}>The room for</Typography>
                                        </Box>
                                        <Box>
                                            <Grid item xs={12}>
                                                <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                                                    <Grid item>
                                                        <RadioGroup name="petType" style={{ display: 'block' }} value={this.state.petType} onChange={this.handleSelectPetType}>

                                                            {this.renderPetTypesRadio()}

                                                        </RadioGroup>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box className={classes.outlinedBox} >
                                        <Box>
                                            <Typography variant="body1" className={classes.tabText}>How many rooms are at the property?</Typography>
                                        </Box>
                                        <Box>
                                            <Grid item xs={12}>
                                                <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                                                    <Grid item className={classes.cursor}
                                                        data-test-id="minusProperties"
                                                        onClick={this.minusProperties}>
                                                        <img src={smallMinus} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="h6" className={classes.petCount}>
                                                            {this.state.propertiesCount}

                                                        </Typography>
                                                    </Grid>
                                                    <Grid item className={classes.cursor}
                                                        data-test-id="addProperties"

                                                        onClick={this.addProperties}>
                                                        <img src={smallAddPlus} />

                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Box>

                                    </Box>
                                    <Box>
                                        <Typography color="error" variant="body1">{this.state.validationErrors.propertiesError}</Typography>

                                    </Box>

                                </Grid>
                                <Grid item xs={12}>
                                    <Box className={classes.outlinedBox} >
                                        <Box>
                                            <Typography variant="body1" className={classes.tabText}>Maximum {this.state.petType.toLowerCase()}s in one room</Typography>
                                        </Box>
                                        <Box>
                                            <Grid item xs={12}>
                                                <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                                                    <Grid item className={classes.cursor}
                                                        data-test-id="minusPets"
                                                        onClick={this.minusPets}>
                                                        <img src={smallMinus} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="h6" className={classes.petCount}>
                                                            {this.state.petsCount}

                                                        </Typography>
                                                    </Grid>
                                                    <Grid item className={classes.cursor}
                                                        data-test-id="addPets"
                                                        onClick={this.addPets}>
                                                        <img src={smallAddPlus} />

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                    </Box>
                                    <Box>
                                        <Typography color="error" variant="body1">{this.state.validationErrors.petsError}</Typography>

                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {this.state.roomPricesArray && this.state.roomPricesArray.length > 0 && (
                                            <>

                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.labelText}>
                                                        Room prices
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {this.renderRoomPrices()}

                                                    <Typography variant="body1" color="error">{this.state.validationErrors.roomPriceError}</Typography>
                                                </Grid>


                                            </>
                                        )}
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: "5% 0px 3%",
                            }}
                        >
                            <Button
                                style={{
                                    width: "325px",
                                    height: "50px",
                                    backgroundColor: "#EA0C78",
                                    borderRadius: "8px",
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: 700,
                                    textTransform: "none",
                                    fontFamily: "inter",
                                }}
                                onClick={this.handleCreateRoom}
                                data-test-id="save_room"
                            >
                                Save
                            </Button>
                        </div>
                    </DialogContent>
                </StyledDialog>
            </>
        );
    };

    renderRoomPrices = () => {
        const { classes } = this.props;
        const { roomPricesArray } = this.state;
        return roomPricesArray.map((value, index) => (
            <>
                <Box className={classes.outlinedBox} style={{ padding: '0px 0px 0px 20px', marginBottom: '5px' }}>
                    <Box>
                        <Typography variant="body1" className={classes.tabText}>The room price for {value.name} {value.name != "one" ? `${this.state.petType.toLowerCase()}s` : `${this.state.petType.toLowerCase()}`}  per day</Typography>
                    </Box>
                    <Box >
                        <CustomizedTextField
                            style={{ width: '125px' }}
                            variant="outlined"
                            className={classes.PricesInputSearch}
                            data-test-id="priceValue"
                            placeholder="£ 000"

                            value={value.price !== null ? `£ ${value.price}` : ""}
                            onChange={(event) => this.handlePriceChanges(event, value.name)}
                        />
                    </Box>
                </Box>
            </>
        ));
    };

    renderPetPrices = (value: RoomManipulate) => {
        const { classes } = this.props;
        return (
            <>
                {value.pet_room_prices.map((price: string, index: number) => {

                    return (<Box className={classes.outlinedBox} style={{ padding: '0px 0px 0px 20px', marginBottom: '5px' }}>
                        <Box>
                            <Typography variant="body1" className={classes.tabText}>The room price for {this.numberToWord(index + 1)}  {this.numberToWord(index + 1) !== 'one' ? value.room_for.toLowerCase() + 's' : value.room_for.toLowerCase()}  per day</Typography>
                        </Box>
                        <Box >
                            <CustomizedTextField
                                style={{ width: '125px' }}
                                variant="outlined"
                                className={classes.PricesInputSearch}
                                InputProps={{
                                    readOnly: this.state.selectedHotelIdEdit !== value.room_id
                                }}
                                data-test-id="price_value"
                                placeholder="£ 000"
                                value={price != null ? `£ ${price}` : ""}
                                onChange={(event) => this.updatePetPrices(event, index, value.room_id)}
                            />
                        </Box>
                    </Box>)
                })
                }
            </>
        )

    };


    renderTabs = () => {
        return (
            <>
                <Grid container direction="row" spacing={2} style={{ padding: "16px" }}>
                    {this.state.selectedTab === 1 && <AboutListing navigation={this.props.navigation} classes={this.props.classes} id={""} hotelName={this.state.hotelDetails?.attributes?.name} />}
                    {this.state.selectedTab === 2 && this.renderPhotos()}
                    {this.state.selectedTab === 3 && (this.state.hotelDetails?.attributes?.pet_type === "daycare_services" ? this.dayCareService() : this.typeOfRooms())}
                    {this.state.selectedTab === 4 && this.renderReviews()}
                    {this.state.selectedTab === 5 && <PaymentInformation navigation={this.props.navigation} classes={this.props.classes} />}
                    {this.state.selectedTab === 6 && <CancellationPolicyDetails id={""} navigation={this.props.navigation} classes={this.props.classes} />}
                </Grid>
            </>
        )
    }
    dayCareService = () => {
        return (
            <>
                <MainBox>
                    <Box className="petSetupBox">
                        <Box className="contentBox">
                            <Box className="contentHead">
                                <Typography variant="body1" data-test-id="priceCallId1">{configJSON.price}</Typography>
                                <Typography variant="body2">
                                    {configJSON.changeAnyTime}
                                </Typography>
                            </Box>
                            <Box className="contentBody">
                                <Box className="priceCard">
                                    <Typography variant="body2">
                                        {configJSON.spacesText}
                                    </Typography>
                                    <input
                                        type="text"
                                        placeholder=""
                                        value={this.state.spaces}
                                        onChange={this.updateSpace}
                                        data-test-id="spacesCallId"
                                        style={stepStyle.priceOfDogInput}
                                    />
                                </Box>
                                <Typography variant="body1">
                                    {configJSON.priceSettings}
                                </Typography>
                                {this.state.oneTimePrice.map((price, priceIndex) => (
                                    <Box className="priceCard">
                                        <Typography variant="body2">
                                            {configJSON.priceFor} {priceIndex + 1} {priceIndex === 0 ? configJSON.oneDay : configJSON.days}{" "}
                                        </Typography>
                                        <input
                                            type="text"
                                            placeholder="£ 000.00"
                                            value={price !== "" ? `£ ${price}` : ""}
                                            onChange={(event) => this.updatePrice(event, priceIndex, "onetime")}
                                            data-test-id={`oneTimeCallId${priceIndex}`}
                                            style={stepStyle.priceOfDogInput}
                                        />
                                    </Box>
                                ))}
                                <br /><br /><br />
                                <Typography variant="body1">{configJSON.weeklyPrice}</Typography>
                                {this.state.weeklyPrice.map((price, weeklyPriceIndex) => (
                                    <Box className="priceCard">
                                        <Typography variant="body2">
                                            {configJSON.priceFor} {weeklyPriceIndex + 1} {weeklyPriceIndex === 0 ? configJSON.oneDay : configJSON.days}{" "}
                                        </Typography>
                                        <input
                                            style={stepStyle.priceOfDogInput}
                                            placeholder="£ 000.00"
                                            type="text"
                                            value={price !== "" ? `£ ${price}` : ""}
                                            data-test-id={`weeklyCallId${weeklyPriceIndex}`}
                                            onChange={(event) => this.updatePrice(event, weeklyPriceIndex, "weekly")}
                                        />
                                    </Box>
                                ))}
                            </Box>
                            {this.state.errorMsg ? <Box className="errorBox"><Typography className="errorMessage" data-test-id="errorMsgId">{this.state.errorMsg}</Typography></Box> : null}
                            <Box className="btnBox">
                                <Button className="saveBtn" data-test-id="saveButtonTestId" onClick={this.submitForm}>
                                    {configJSON.saveAndContinue}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </MainBox>
                <Grid item xs={12} style={webStyle.serviceBox}>
                    {this.rednerAdditionalServicesOfferred()}
                </Grid>
            </>
        );
    };


    deleteRoomDailog = () => {
        const { deleteRoomDailogStatus } = this.state
        const { closeDeleteModalDailog, deleteHotelroom } = this
        return (
            <>
                <Dialog open={deleteRoomDailogStatus} maxWidth={'md'}
                    onClose={closeDeleteModalDailog} aria-labelledby="form-dialog-title"
                    PaperProps={{
                        style: {
                            maxWidth: "634px",
                            width: '100%',
                            height: "300px",
                            borderRadius: '8px',
                        },
                    }}
                >
                    <DialogTitle disableTypography style={webStyle.onlyIcon}>

                        <IconButton aria-label="close"
                            data-test-id="close_dailog"
                            onClick={closeDeleteModalDailog} >
                            <CloseIcon />

                        </IconButton>

                    </DialogTitle>
                    <DialogContent style={webStyle.dailogContent as React.CSSProperties}>

                        <Typography variant="h4" style={webStyle.dailogHeading} gutterBottom>
                            Delete room
                        </Typography>
                        <Typography variant="subtitle2" style={webStyle.dailogSubHeading} gutterBottom>
                            Are you sure you want to delete the room?
                        </Typography>

                    </DialogContent>
                    <DialogActions
                        style={webStyle.padding15}
                    >
                        <>
                            <Button variant="outlined" color={'secondary'}
                                style={webStyle.filterButton as React.CSSProperties}
                                data-test-id="clearFilters"
                                onClick={closeDeleteModalDailog}
                            >
                                No
                            </Button>
                            <Button variant="contained" color={'secondary'}
                                style={webStyle.filterSearchButton as React.CSSProperties}
                                data-test-id="delete_hotel_room"
                                onClick={deleteHotelroom}

                            >
                                Yes
                            </Button>
                        </>

                    </DialogActions>
                </Dialog>


            </>
        )

    }

    renderCheckInCheckOutDetails = () => {
        const { props, editCheckIncheckOutDates, state, select_time_mandatory, updateCheck_in_check_out_timing,editTimingsCheck } = this
        const { classes } = props;
        const { checkInCheckOut, checkInCheckOutStatus, status_checked, selectTiming } = state;
        const { checkInStart, checkOutStart, checkInEnd, checkOutEnd } = checkInCheckOut
        const { checkInStartStatus, checkInEndStatus, checkOutStartStatus, checkOutEndStatus } = status_checked

        return (
            <>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="subtitle2" className={classes.labelText}>{configJSON.checkinCheckOut}</Typography>

                            </Grid>
                            <Grid item>
                                <Typography data-test-id='edit_checkIn_check_out'
                                    variant="body1" className={classes.editText}
                                    onClick={checkInCheckOutStatus ? updateCheck_in_check_out_timing : editCheckIncheckOutDates}
                                >
                                    {checkInCheckOutStatus ? "Save" : "Edit"}
                                </Typography>

                            </Grid>
                        </Grid>

                    </Grid>
                    {checkInCheckOutStatus ? <Grid item xs={12}>
                        <div>
                            <Typography variant="body1" className={classes.labelText} >
                                Do you have a set check-in and check-out times ?
                            </Typography>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div
                                    style={{
                                        alignItems: "center",
                                        display: "flex",
                                        marginRight: "2%",
                                    } as React.CSSProperties}
                                >
                                    <CustomRadio
                                        data-test-id="yes_option"
                                        checked={selectTiming}
                                        onChange={() => select_time_mandatory(true)}

                                    />
                                    <Typography
                                        style={webStyle.radioButoonText as React.CSSProperties}
                                    >
                                        Yes
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <CustomRadio
                                        data-test-id="no_option"
                                        checked={!selectTiming}
                                        onChange={() => select_time_mandatory(false)}

                                    />
                                    <Typography style={webStyle.radioButoonText}>No</Typography>
                                </div>
                            </div>
                        </div>

                    </Grid> :
                        <>
                            <Grid item xs={12}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="body1" className={classes.labelText} >
                                            Do you have a set check-in and check-out times ?
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" style={selectTiming ? webStyle.activeText : webStyle.disableText} >
                                            {selectTiming ? "Yes" : "No"}
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </Grid>

                        </>
                    }
                    {selectTiming && <><Grid item xs={12}>
                        <Grid container direction="row" spacing={3}>
                            <Grid item xs={12}>
                                <Typography  variant="subtitle2" style={webStyle.dailogHeading} >{"Check-in"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={12} sm={6} container direction="row">
                                        <div style={webStyle.divOfInput}>From</div>
                                        <div
                                            onClick={()=>editTimingsCheck('check_in_start')}
                                            
                                            data-test-id="check_in_start"
                                            style={webStyle.selectTime}
                                        >
                                            <Typography variant="subtitle2" style={webStyle.dailogSubHeading}>{checkInStart}</Typography>

                                            {checkInCheckOutStatus&&<ExpandMoreIcon style={webStyle.expandMoreIcon} />}
                                    
                                            {checkInStartStatus && this.renderCheckInPoup('check_in_start')}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} container direction="row">
                                        <div style={webStyle.divOfInput}>Until</div>
                                        <div
                                            onClick={()=>editTimingsCheck('check_in_end')}
                                            data-test-id="check_in_end"
                                            style={webStyle.selectTime}
                                        >
                                            <Typography variant="subtitle2" style={webStyle.dailogSubHeading}>{checkInEnd}</Typography>
                                            {checkInCheckOutStatus && <ExpandMoreIcon style={webStyle.expandMoreIcon} />}
                                            {checkInEndStatus && this.renderCheckInPoup('check_in_end')}

                                        </div>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" className={classes.labelText}>{"Check-out"}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12} sm={6} container direction="row">
                                            <div style={webStyle.divOfInput}>From</div>
                                            <div
                                                onClick={()=>editTimingsCheck('check_out_start')}

                                                data-test-id="check_out_start"
                                                style={webStyle.selectTime}
                                            >
                                                <Typography variant="subtitle2" style={webStyle.dailogSubHeading}>{checkOutStart}</Typography>
                                                {checkInCheckOutStatus && <ExpandMoreIcon style={webStyle.expandMoreIcon} />}

                                                {checkOutStartStatus && this.renderCheckInPoup('check_out_start')}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} container direction="row">
                                            <div style={webStyle.divOfInput}>Until</div>
                                            <div
                                             onClick={()=>editTimingsCheck('check_out_end')}
                                                data-test-id="check_out_end"
                                                style={webStyle.selectTime}
                                            >
                                                <Typography variant="subtitle2" style={webStyle.dailogSubHeading}>{checkOutEnd}</Typography>
                                                {checkInCheckOutStatus && <ExpandMoreIcon style={webStyle.expandMoreIcon} />}

                                                {checkOutEndStatus && this.renderCheckInPoup('check_out_end')}
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </>}
                </Grid>
            </>
        )

    }

    renderCheckInPoup = (type: string) => {
        const { state, select_check_in_check_out, closeCheckedTimingsPopup } = this
        const { checkInCheckOut } = state;
        const { checkInStart, checkOutStart } = checkInCheckOut
        const selectedCheckInHour = checkInStart ? parseInt(checkInStart.split(':')[0]) : null;
        const selectedCheckOutHour = checkOutStart ? parseInt(checkOutStart.split(':')[0]) : null;

        return (
            <ClickAwayListener onClickAway={closeCheckedTimingsPopup}>
                <Box style={webStyle.dropDown as React.CSSProperties}>
                    <Box style={{ padding: "0.5rem" }}>
                        {Array.from({ length: 24 }, (_, index) => {
                            const hour = String(index).padStart(2, '0');
                            let isDisabled = false;

                            if (type === 'check_in_end' && selectedCheckInHour !== null && index <= selectedCheckInHour) {
                                isDisabled = true;
                            }

                            if (type === 'check_out_end' && selectedCheckOutHour !== null && index <= selectedCheckOutHour) {
                                isDisabled = true;
                            }

                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => !isDisabled && select_check_in_check_out(`${hour}:00`, type)}
                                    data-test-id="select_timing"
                                    style={webStyle.dropDownText}
                                    disabled={isDisabled}
                                >
                                    {`${hour}:00`}
                                </MenuItem>
                            );
                        })}
                    </Box>
                </Box>
            </ClickAwayListener>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        return (
            <>
                <CustomizedSnackbars duration={2000} openToaster={this.state.isSuccess} closeStatus={this.closeToaster} message={"Updated Successfully"} toasterType={"success"} />
                <NavigationMenu active={1} navigation={this.props.navigation} id={""} />
                <CustomizedSnackbars toasterType="error" openToaster={this.state.toasterStatus} message={this.state.toasterText} duration={2000}
                    closeStatus={this.closeToaster}
                />
                <div className={classes.content}>
                    {this.deleteRoomDailog()}

                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} className={classes.headingMargin}>
                                    <Grid container direction="row" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <img src={arrowback} onClick={() => goBack(this.props.navigation)} />

                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.labelText} variant="h3">{this.props.hotelName ? this.props.hotelName : this.state.hotelDetails?.attributes?.name || this.props.hotelName}</Typography>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.searchBar}>
                                        <Grid container direction="row" alignItems="center">
                                            {this.state.tabsList.map((tab: Tabs) => {
                                                const totalTabs = this.state.tabsList.length;
                                                return (

                                                    <>
                                                        <Box className={this.state.selectedTab === tab.id ? classes.selectedTab : classes.tab}
                                                            onClick={() => this.selectedTab(tab.id)}
                                                            style={{ flex: 1, minWidth: 0, maxWidth: (100 / totalTabs) + '%' }}
                                                            data-test-id='select_tab'
                                                            key={tab.id}>
                                                            <Typography
                                                                className={this.state.selectedTab === tab.id ? classes.selectedTabText : classes.tabText}
                                                                variant="body1">{tab.tabName}</Typography>

                                                        </Box>


                                                    </>
                                                )
                                            })}
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} className={this.state.selectedTab != 6 ? classes.propertiesLayout : classes.cancellationLayout}>
                                        {this.renderTabs()}
                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item xs={12} className={this.state.selectedTab === 6 ? classes.cancellationFooter : classes.normalFooter}>
                        <Footer footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
                    </Grid>
                </div>
                {this.state.addRoomType && this.openModel()}
                <Loader loading={this.state.pageLoading} />
            </>

        );
    }
    // Customizable Area End
}

// Customizable Area Start
const stepStyle = {
    radioGroup: {
        display: "flex !important",
        flexDirection: "row" as "row"
    },
    serViceBox: {
        width: "25%",
        paddingLeft: "20px",
        display: "flex",
        gap: "30px"
    },
    control: {
        borderRadius: "8px",
        width: "100%",
        height: "33px",
        whiteSpace: "nowrap" as "nowrap"
    },
    control1: {
        borderRadius: "8px",
        width: "90%",
        height: "33px",
    },
    disableBox: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px 0px",
        "& .MuiSwitch-colorPrimary .Mui-checked": {
            color: "#CCEDFA !important"
        },
        "& .MuiSwitch-thumb": {
            color: "#CCEDFA !important"
        }
    },
    button: {
        "& .MuiSwitch-colorPrimary.Mui-checked": {
            color: "#CCEDFA !important"
        },
        "& .MuiSwitch-thumb": {
            color: "#CCEDFA !important"
        }
    },
    display: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    priceOfDogInput: {
        width: "19%",
        height: "41px",
        borderRadius: "8px",
        border: "1px solid #B2E4F8",
        backgroundColor: "#CCEDFA",
        fontSize: "16px",
        fontWeight: 400,
        textAlign: "right" as const,
        padding: "0 15px",
    },
};

export const MainBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    "& .petSetupBox": {
        borderBottomRightRadius: "60px",
        background: "white",
        padding: "0px 115px",
        width: "900px",
        marginBottom: "100px"
    },
    "& .headBox": {
        padding: "45px 0px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        "& .MuiDivider-root": {
            height: "4px",
            background: "#B2E4F8",
        },
    },
    "& .heading": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        "& .MuiTypography-body1": {
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "32px",
            fontFamily: "Inter",
            color: "#334155",
        },
        "& .MuiButton-root": {
            color: "#54A7C9",
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "26px",
            textTransform: "none",
            textDecoration: "underline",
        },
    },
    "& .contentBox": {
        display: "flex",
        flexDirection: "column",
        "& .MuiDivider-root": {
            height: "1px",
            background: "#B2E4F8",
        },
    },
    "& .contentHead": {
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        padding: "20px 0px",
        "& .MuiTypography-body2": {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
            fontFamily: "Inter",
            color: "#334155",
        },
        "& .MuiTypography-body1": {
            fontSize: "30px",
            lineHeight: "40px",
            fontFamily: "Inter",
            color: "#334155",
            fontWeight: 700,
        },
    },
    "& .contentBody": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        "& .MuiTypography-body1": {
            color: "#334155",
            fontSize: "14px",
            fontFamily: "Inter",
            lineHeight: "22px",
            fontWeight: 700,
        },
        "& .MuiTypography-body2": {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
            fontFamily: "Inter",
            color: "#334155",
        },
    },
    "& .priceCard": {
        display: "flex",
        paddingLeft: "20px",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid #B2E4F8",
        borderRadius: "8px",
    },
    "& .priceBox": {
        backgroundColor: "#CCEDFA",
        borderRadius: "8px",
        padding: "10px 20px",
        border: "1px solid #B2E4F8",
        color: "#224350 !important",
        width: "125px",
        textAlign: "right",
    },
    "& .btnBox": {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "30px 0px 45px 0px",
        "& .MuiButton-root": {
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 700,
            borderRadius: "8px",
            lineHeight: "22px",
            textTransform: "none",
            padding: "10px 16px",
        },
    },
    "& .saveBtn": {
        color: "white",
        background: "#EA0C78",
    },
    "& .backBtn": {
        color: "#0F172A"
    },
    "& .errorMessage": {
        color: "red"
    },
    "& .errorBox": {
        paddingTop: "10px",
        width: "100%"
    }
});

export const SinglePropertieStyles = (theme: Theme) =>
    createStyles({
        bgLayout: {
            backgroundColor: "#E0F4FC",
            minHeight: "100vh",
            width: "100%",
        },
        propertyLayout: {
            padding: "0 40px"
        },
        headingMargin: {
            marginTop: 30
        },
        searchBar: {
            width: '100%',
            marginTop: 40,
            // minHeight: "66px",
            padding: '8px',
            backgroundColor: theme.palette.info.main,
            borderRadius: 8,

        },
        tab: {
            // width: 165,
            height: 50,
            background: theme.palette.common.white,
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            margin: '0 5px',
            // [theme.breakpoints.up('xl')]:{
            //     width:'195px'
            // }

        },
        selectedTab: {
            borderColor: theme.palette.secondary.main,
            border: `1.5px solid ${theme.palette.secondary.main}`,
            // width: 165,
            height: 50,
            background: theme.palette.common.white,
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            // [theme.breakpoints.up('xl')]:{
            //     width:'195px'
            // }
        },
        tabText: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightRegular
        }, selectedTabText: {
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightBold


        },
        tabsLayout: {
            marginTop: 'auto !important'
        },
        propertiesLayout: {
            background: theme.palette.common.white,
            width: '100%',
            height: 'auto',
            marginTop: 20,
            borderRadius: 15,
            padding: 10
        },
        cancellationLayout: {
            background: theme.palette.common.white,
            width: '100%',
            minHeight: 'auto',
            height: '100%',
            marginTop: 20,
            borderRadius: 15,
        },
        editText: {
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightBold,
            cursor: 'pointer'
        },
        labelText: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold

        },
        licienceText: {
            color: theme.palette.text.disabled,
            fontWeight: theme.typography.fontWeightRegular
        },
        imageGridSize: {
            maxHeight: '450px',
            borderRadius: 8,
            maxWidth: '100%'
        },
        uplodLayout: {
            width: '100%',
            height: '450px',
            cursor: 'pointer',
            backgroundColor: theme.palette.common.white,
            boxShadow: boxShadows.whiteShadow,
            border: '1px dashed #CBD5E1',
            '&:hover': {
                backgroundColor: theme.palette.common.white,

            }
        },
        imageSize: {
            width: '100%',
            height: '560px',
            borderRadius: 8
        },
        threeDotsIcon: {
            position: 'absolute',
            right: 20,
            top: 30
        },
        dotsOuterContainer: {
            position: "absolute",
            zIndex: 9,
            width: "max-content",
            borderRadius: 8,
            backgroundColor: "#ffffff",
            boxShadow: boxShadows.whiteShadow,
            top: 60,
            right: 40,
        },
        menuText: {
            color: colors.darkBlack,
            fontWeight: theme.typography.fontWeightRegular
        },
        dotsinnerContainer: {
            padding: '0.5rem'
        },
        position: {
            position: 'relative'
        },
        claenderLayout: {
            position: 'absolute',
            right: 10,
            top: 40,
            boxShadow: boxShadows.whiteShadow,
            zIndex: 99,
            background: theme.palette.common.white,
            border: '16px'
        }, crossicon: {
            cursor: 'pointer'
        },
        reviewCount: {
            textDecoration: "underline",
            fontWeight: theme.typography.fontWeightBold
        },
        overallRatingText: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.info.dark

        },
        checkBoxBackground: {
            background: colors.primaryBlue,
            borderRadius: 8,
            color: colors.darkBlack,
            fontWeight: theme.typography.fontWeightRegular,
            width: '100%',
            fontSize: 16,
            margin: 'auto'
        },
        borerCheckBox: {
            border: `1px solid ${colors.primaryBlue}`,
            borderRadius: 8,
            padding: "0px !important",
            margin: 10
        },
        checkboxLabel: {
            color: colors.darkBlack,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: 16,
            margin: 'auto'

        },
        questionsText: {
            color: "#94A3B8",
            fontWeight: theme.typography.fontWeightRegular

        },
        conditionsInput: {
            "& .MuiInputBase-root": {
                height: '100%',
                background: 'none'
            }
        },
        box: {
            width: 128,
            height: 44,
            border: `1px solid ${colors.primaryBlue}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 8,
            backgroundColor: theme.palette.primary.main
        },
        'input': {
            '&::placeholder': {
                textOverflow: 'ellipsis !important',
                color: colors.darkBlack,
                opacity: 10
            }
        },
        textInput: {
            "& .MuiInputBase-root": {
                cursor: 'not-allowed',
                position: 'relative',
                pointerEvents: 'none'
            },


        },
        dropdownInput: {
            "& .MuiInputBase-root": {
                width: 128,
                position: "absolute",
                background: theme.palette.primary.main,
                right: 0,
                cursor: 'pointer'
            },
            "& .MuiOutlinedInput-input": {
                fontSize: 16,
                fontWeight: theme.typography.fontWeightBold,
                textAlign: 'center'
            }
        },
        Input: {
            "& .MuiInputBase-root": {
                width: 128,
                position: "absolute",
                background: theme.palette.primary.main,
                right: 0,
                cursor: 'pointer'
            },
            "& .MuiOutlinedInput-input": {
                textAlign: 'end',
                fontSize: 16,
                fontWeight: theme.typography.fontWeightBold
            }
        },
        inner: {
            width: 128,
            position: "absolute",
            right: 0,
            background: theme.palette.common.white,
            borderRadius: 8,
            zIndex: 99,
            boxShadow: boxShadows.whiteShadow,

        },
        outer: {
            padding: '0.5rem'
        },
        daysInner: {
            width: 128,
            position: "absolute",
            height: '200px',
            right: 0,
            background: theme.palette.common.white,
            borderRadius: 8,
            zIndex: 99,
            boxShadow: boxShadows.whiteShadow,
            overflowY: 'scroll'

        },
        singleInputField: {
            "& .MuiInputBase-root": {
                background: theme.palette.primary.main,
            },

        },
        dropDown: {
            "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: " 0 8px 8px 0"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderRadius: "0 8px 8px 0"
            },
        },
        countryDropDow: {
            "& .MuiInputBase-root": {
                background: theme.palette.primary.main,
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px 0px 0px 8px"

            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px 0px 0px 8px"
            },
        },
        typeInner: {
            width: "100%",
            position: "absolute",
            background: theme.palette.common.white,
            borderRadius: 8,
            zIndex: 99,
            boxShadow: boxShadows.whiteShadow,
            height: ''
        }, dropDownText: {
            color: theme.palette.common.black,
            fontSize: 14,
            padding: '0.5rem',
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover': {
                background: colors.primaryBlue,
                borderRadius: 8
            }
        },
        descriptionInput: {
            "& .MuiInputBase-root": {
                height: '100% !important'
            },
        },
        openAccorditionLayout: {
            marginTop: 20,
            margiBotton: 20
        },
        nonOpenAccorditionLayout: {
            marginTop: 20,
            margiBotton: 80
        },
        outerBox: {
            border: `1px solid ${theme.palette.primary.main} `,
            width: '100%',
            borderRadius: 8,
            padding: '12px 0px 11px 8px',
            display: 'flex',
            alignItem: 'center',
            position: 'relative',
            cursor: 'pointer'

        },
        dobDropDownslayout: {
            background: theme.palette.common.white,
            borderRadius: 8,
            height: 230,
            overflowY: 'scroll',
            position: 'absolute',
            zIndex: 99,
            width: '100%',
            boxShadow: boxShadows.whiteShadow,
            top: 47
        },
        addText: {
            textTransform: 'none',
            fontWeight: theme.typography.fontWeightRegular
        },
        notFoundLayout: {
            marginTop: 50
        },
        successText: {
            color: theme.palette.success.main,
            fontWeight: theme.typography.fontWeightBold
        },
        warningText: {
            color: theme.palette.warning.main,
            fontWeight: theme.typography.fontWeightBold
        },
        failedText: {
            color: theme.palette.error.main,
            fontWeight: theme.typography.fontWeightBold
        },
        disabledInput: {
            '& ..MuiInputBase-root.Mui-disabled': {
                background: theme.palette.primary.main
            }
        }, boxFile: {
            display: "flex",
            height: 55,
            padding: "1px 20px 1px 20px",
            alignItems: "center",
            gap: "16px",
            justifyContent: 'space-between',
            background: theme.palette.primary.main,
            border: `1px solid ${theme.palette.error.main}`,
            borderRadius: 8
        },
        customCalender: {
            fontFamily: "Inter",
            boxShadow: 'none',
            [theme.breakpoints.down('xs')]: {
                width: "-webkit-fill-available !important",
                padding: "0.4rem",
            },
            "& .rmdp-header": {
                fontSize: "18px",
                [theme.breakpoints.down('xs')]: {
                    fontSize: "14px",
                }
            },
            "& .rmdp-calendar": {
                width: "-webkit-fill-available",
                padding: "0.2rem"
            },
            "& .rmdp-day-picker": {
                [theme.breakpoints.down('xs')]: {

                    display: "block !important"
                }
            },
            "& .rmdp-week-day": {
                fontSize: "15px",
                fontWeight: 400
            },
            "& .rmdp-range": {
                background: "pink",
                opacity: '0.5',
                boxShadow: 'none',
                color: theme.palette.secondary.main
            },
            '& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
                background: 'pink',
                color: theme.palette.common.white
            },
            "& .rmdp-day.rmdp-today span": {
                background: 'none',
                color: theme.palette.common.black
            }
        },
        serviceOutlined: {
            border: `1px solid ${colors.primaryBlue}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: "space-between",
            padding: 10,
            borderRadius: 8,
            width: "100%"
        },
        content: {
            marginLeft: "140px", // Adjust based on the width of your sidebar
            flexGrow: 1,
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(3),
            overflowY: "auto", // Allow main content area to scroll
           [theme.breakpoints.down(600)]:{
            marginLeft: "0px"
           }
        },
        cancellationDivider: {
            height: '1.5px',
            backgroundColor: theme.palette.primary.main,
            width: "100%",
            margin: "15px 0"
        },
        valuesText: {
            color: theme.palette.common.black,
            fontSize: 16,
            padding: '10px 30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            fontWeight: theme.typography.fontWeightRegular,
            '&:hover': {
                background: colors.primaryBlue,
                borderRadius: 8
            }
        },
        checkedBoxIcon: {
            border: '1px solid #475569',
            borderRadius: 6,
            width: 20,
            height: 20,
            backgroundColor: theme.palette.common.white,
            '$root.Mui-focusVisible &': {
                outline: `1px auto ${theme.palette.secondary.main}`,
                outlineOffset: 2,
            },
        },
        tickIcon: {
            backgroundColor: theme.palette.secondary.main,
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 20,
                height: 20,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        cancellationFooter: {
            marginTop: 200
        },
        normalFooter: {
            marginTop: 90

        },
        petCount: {
            color: theme.palette.info.dark,
            fontWeight: theme.typography.fontWeightBold
        },
        outlinedBox: {
            border: `1px solid ${colors.primaryBlue}`,
            height: 46,
            padding: '4px 20px',
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: "center",
            position: 'relative'
        },
        servicesBox: {
            border: `1px solid ${colors.primaryBlue}`,
            background: theme.palette.common.white,
            padding: '4px 20px',
            height: 46,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: "center",
            position: 'relative'
        },
        additionalServicesBox: {
            border: `1px solid ${theme.palette.secondary.main}`,
            background: theme.palette.common.white,
            padding: '4px 20px',
            height: 46,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: "center",
            position: 'relative'
        },
        ownServicesBox: {
            border: `1px solid ${colors.primaryBlue}`,
            background: theme.palette.common.white,
            padding: '4px 20px',
            height: 46,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: "center",
            position: 'relative'
        },
        additionalServiceBox: {
            border: `1px solid ${theme.palette.secondary.main}`,
            background: theme.palette.common.white,
            padding: '4px 20px',
            height: 46,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            position: 'relative'
        },
        ownServicesBoxPriceBox: {
            border: `1px solid ${colors.primaryBlue}`,
            background: theme.palette.common.white,
            padding: '4px 20px',
            height: 46,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            position: 'relative'
        },

        inputSearch: {
            "& .MuiInputBase-root": {
                fontFamily: "Inter",
                color: "#000000",
                backgroundColor: "#ffffff",
                height: "25px"
            },
        },

        PricesInputSearch: {
            "& .MuiInputBase-root": {
                fontFamily: "Inter",
                color: "#000000",
                backgroundColor: "#ffffff",
                height: "46px",
                background: `${theme.palette.primary.main} !important`
            },
        }, additionalServicesPOup: {
            background: theme.palette.common.white,
            borderRadius: 8,
            minHeight: '250px',
            height: 'auto',
            overflowY: 'auto',
            position: 'absolute',
            zIndex: 99,
            width: '100%',
            boxShadow: boxShadows.whiteShadow,
            top: 50,
            left: 0
        },
        bigImage: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme.palette.primary.main,
            borderRadius: 8,
            position: 'relative',
            height: '450px'
        },
        smallImages: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme.palette.primary.main,
            borderRadius: 8,
            position: 'relative',
            height: '450px',
            maxWidth: '49%'

        },
        servicesPopupText: {
            position: "absolute",
            zIndex: 9,
            width: "max-content",
            borderRadius: 8,
            backgroundColor: "#ffffff",
            boxShadow: boxShadows.whiteShadow,
            top: 60,
            right: 40,

        },
        priceBox: {
            backgroundColor: "#E0F4FC",
            minWidth: "150px",
            maxWidth: '100%',
            padding: '4px 0px',
            height: '46px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'

        },
        previewServicesBox: {
            border: `1px solid #B2E4F8`,
            background: theme.palette.common.white,
            padding: '4px 0px',
            height: 46,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: "center",
            position: 'relative'
        },
        servicePropertyBox: {
            display: "flex",
            gap: "20px"
        },
        ownPriceBox: {
            backgroundColor: theme.palette.primary.main,
            minWidth: "150px",
            maxWidth: '100%',
            padding: '4px 0px',
            height: '46px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',


        },
        ownpreviewServicesBox: {
            border: `1px solid ${colors.primaryBlue}`,
            background: theme.palette.common.white,
            padding: '4px 0px',
            height: 46,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: "center",
            position: 'relative'
        },
        cursorPointer: {
            cursor: 'pointer'
        }, typeInnerCountry: {
            width: "100%",
            position: "absolute",
            background: theme.palette.common.white,
            borderRadius: 8,
            zIndex: 99,
            boxShadow: boxShadows.whiteShadow,
            height: '200px',
            overflowY: 'scroll'
        },
        descriptionTextLayout: {
            fontWeight: theme.typography.fontWeightRegular,
            display: '-webkit-box',
            WebkitLineClamp: 6,
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            minHeight: 50,
            overflowWrap: 'anywhere'
        },
        blockOffDaysBox: {
            border: `1px solid ${colors.primaryBlue}`,
            background: theme.palette.common.white,
            padding: '4px 20px',
            height: 46,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: "center",
            position: 'relative',
            marginTop: "10px",
            cursor: 'pointer'
        },
        calenderSection: {
            overFlow: "hidden",
            position: 'absolute',
            zIndex: 99,
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        blockoffDaysCalender: {
            fontFamily: "Inter",
            boxShadow: 'none',
            [theme.breakpoints.down('xs')]: {
                width: "-webkit-fill-available !important",
                padding: "0.4rem",
            },
            "& .rmdp-wrapper": {
                padding: '0.5rem 1rem',

            },
            "& .rmdp-header": {
                fontSize: "18px",
                [theme.breakpoints.down('xs')]: {
                    fontSize: "14px",
                }
            },
            "& .rmdp-calendar": {
                width: "-webkit-fill-available",
                padding: "0.2rem"
            },
            "& .rmdp-range": {
                background: "#DC2626",
                boxShadow: 'none',
                color: theme.palette.common.white
            },

            "& .rmdp-week-day": {
                fontSize: "15px",
                fontWeight: 400
            },
            "& .rmdp-day.rmdp-today span": {
                background: 'none',
                color: theme.palette.common.black
            },
            '& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
                background: "#DC2626",
                color: theme.palette.common.white
            },
            "& .rmdp-day-picker": {
                [theme.breakpoints.down('xs')]: {

                    display: "block !important"
                }
            },
        },
        statusDropDownBox: {
            height: 44,
            minWidth: 134,
            borderRadius: 8,
            backgroundColor: theme.palette.primary.main,
            cursor: 'pointer',
            position: 'relative'
        },
        approveStatusBox: {
            height: 44,
            minWidth: 134,
            borderRadius: 8,
            background: "#D1FAE5",
            cursor: 'pointer',
            position: 'relative'
        },
        rejectStatusBox: {
            height: 44,
            minWidth: 134,
            borderRadius: 8,
            background: "#FEE2E2",
            cursor: 'pointer',
            position: 'relative'
        },
        activeTextStatus: {
            color: theme.palette.success.main,
            fontSize: 14,
            fontWeight: 700,
            textTransform: 'capitalize'



        }, rejectTextStatus: {
            color: theme.palette.error.main,
            fontSize: 14,
            fontWeight: 700,
            textTransform: 'capitalize'

        },
        statusDropDownBoxContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            padding: "0 10px"
        },
        statusOuter: {
            position: 'absolute',
            zIndex: 99,
            width: '100%',
            height: 'auto',
            boxShadow: boxShadows.whiteShadow,
            backgroundColor: theme.palette.common.white,
            borderRadius: 8,
            top: "40px",
            left: '0px'

        }, statusInner: {
            padding: '0.5rem'
        },
        approveText: {
            color: theme.palette.success.main,
            background: "#D1FAE5",
            padding: "10px 20px 10px 16px",
            fontWeight: theme.typography.fontWeightBold,
            borderRadius: 8,
            fontSize: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            "&:hover": {
                background: "#D1FAE5",

            }


        },
        rejectText: {
            marginTop: 10,
            color: theme.palette.error.main,
            background: "#FEE2E2",
            padding: "10px 20px 10px 16px",
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 14,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            "&:hover": {
                background: "#FEE2E2",

            }
        },



    })
export default withStyles(SinglePropertieStyles)(SingleProperty);


const webStyle = {
    serviceBox: { margin: "-140px 0 0 0" },
    selectDateText: {
        fontWeight: theme.typography.fontWeightBold

    },
    dailogHeading: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold
    },
    dailogSubHeading: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightRegular
    },
    closeIconLayout: {
        padding: 10,
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        fontSize: 24,

    }, onlyIcon: {
        padding: 10,
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: 'center',
        fontSize: 24,

    },
    filterButton: {
        width: '50%',
        textTransform: 'capitalize',
        height: 56,
        borderRadius: 8,
        fontSize: 16,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            backgroundColor: theme.palette.common.white
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    filterSearchButton: {

        width: '50%',
        textTransform: 'capitalize',
        height: 56,
        borderRadius: 8,
        fontSize: 16,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    padding15: {
        padding: 15
    },
    dailogContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'

    },
    editText: {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightBold,
        cursor: 'pointer',
        marginRight: '20px'
    },
    activeText: {
        color: theme.palette.success.main,
        fontWeight: theme.typography.fontWeightBold,
    },
    disableText: {
        color: theme.palette.error.main,
        fontWeight: theme.typography.fontWeightBold,
    },
    selectDates: {
        position: "relative",
        cursor: "pointer"
    },
    noReviewsFoundLayout: {
        height: '350px'
    },
    calendarOuter: {
        padding: "1rem"
    },
    cursor: {
        cursor: 'pointer'
    },
    dropDown: {
        position: 'absolute',
        borderRadius: '8px',
        width: '100%',
        background: "#ffffff",
        height: '250px',
        left: 0,
        overflowY: "auto",
        top: 50,
        border: "1px solid  #E2E8F0",
        boxShadow: "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
        zIndex: 99 as const
    },
    dropDownText: {
        fontSize: '16px',
        borderBottom: "1px solid #E0F4FC",
        padding: "10px",
        cursor: "pointer",
    },
    divOfInput: {
        width: "30%",
        height: "45px",
        backgroundColor: "#E0F4FC",
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
        color: "var(--Neutrals-Cool-gray-700, #334155)",
    },
    divOfInputText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
    },
    expandMoreIcon: {
        color: "#64748B",
        height: "24px",
        width: "24px",
    },
    selectTime: {
        width: "59%",
        height: "44px",
        padding: "0px 5%",
        border: "1px solid #B2E4F8",
        borderRadius: "0px 8px 8px 0px",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        position: 'relative' as const
    },
    radioButoonText: {

        fontSize: "16px",
        fontWeight: 700,
        color: "#334155",

    },
}

// Customizable Area End
