import React from "react";

// Customizable Area Start
import {
  Avatar,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  RadioGroup,
  Box,
  MobileStepper,
  Divider,
  ClickAwayListener
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { UplodeFile, uploadPetIcon } from "../src/assets";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import { styled, withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddPetDetailsController, { Props, configJSON } from "./AddPetDetailsController.web";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "react-datepicker/dist/react-datepicker.css"
import CustomDate from "../../../components/src/CustomizeCalender";
import PetOwnerNavigationMenu from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import Footer from "../../../components/src/Footer.web";
import { CatImg, DogImg } from "../../user-profile-basic/src/assets";
import CountryMenu from "../../../components/src/CountryMenu";
import CustomsettingModal from "../../../components/src/CustomSettingsmodal";
import { boxShadows, colors, theme } from "../../../components/src/theme";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { CustomPopper } from "../../../components/src/CustomizedComponents.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start
const CustomRadio = withStyles((theme) => ({
  root: {
    color: "default",
    "&$checked": {
      color: "red",
    },
  },
  checked: {},
}))(({ ...props }) => (
  <Radio
    disableRipple
    color="default"
    checkedIcon={
      <span
        style={{
          backgroundColor: "white",
          borderRadius: "50%",
          border: "6px solid #224350",
          width: 7,
          height: 7,
          display: "inline-block",
        }}
      />
    }
    icon={
      <span
        style={{
          border: "1px solid #224350",
          borderRadius: "50%",
          width: 16,
          height: 16,
          display: "inline-block",
        }}
      />
    }
    {...props}
  />


));



// Customizable Area End
export default class AddPetDetails extends AddPetDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderPhotoContent = () => {
    return (
      <>
        {this.state.imageUrl ? <img
          style={
            Group.uplodePetPhoto as React.CSSProperties
          }
          src={this.state.imageUrl}
          alt="Upload dog photo"
        /> :
          <Box style={Group.notUplodePetPhoto as React.CSSProperties} >
            <img src={uploadPetIcon} />
            <Typography variant="body1" style={Group.uploadPhotoText as React.CSSProperties}>Upload photo</Typography>
          </Box>
        }
      </>
    )
  }

  getValError = (field: string[]): React.ReactNode | undefined => {
    const isInValid = field.findIndex(
      (item) => this.state.error[`${item}Error`]
    );

    if (isInValid >= 0) {
      return (
        <span style={{ color: "red" }}>
          {this.state.MesErr[`${field[isInValid]}`]}
        </span>
      );
    }
    return undefined;
  };


  renderGendrePopUp = () => {
    return (
      <ClickAwayListener onClickAway={this.closeGenderPopUp}>
        <Box style={Group.outerBox as React.CSSProperties}>
          <Box style={{ padding: "0.5rem" }}>
            <MenuItem value="Male" onClick={() => this.handlepetsexclose("Male")}
              style={{ backgroundColor: this.state.sex === "Male" ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "33px" }}
              data-test-id="male_item"
            >Male
            </MenuItem>
            <Divider style={{ margin: "2px", backgroundColor: "##F1F5F9" }} />
            <MenuItem value="Female" onClick={() => this.handlepetsexclose("Female")}
              style={{ backgroundColor: this.state.sex === "Miss" ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "33px" }}
              data-test-id="female"
            >Female
            </MenuItem>
          </Box>

        </Box>

      </ClickAwayListener>

    )
  }

  renderOldDataSection = () => {
    return (
      <div style={{ width: "100%" }}>
        {this.state.savedPet?.length !== 0 &&
          this.state.selectedPetId?.length === 0 && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2%",
                }}
              >
                <Checkbox
                  onChange={this.state.savepetinfonumber === 3 ? this.setContactData : this.setOldDataHandle}
                  data-test-id="setOldDataHandle"
                  value={this.state.oldDataCheck}
                  style={{
                    width: "14px",
                    marginRight: "7px",
                    padding: "0",
                    color: "#326478",
                    borderRadius: "6px",
                  }}
                  inputProps={{
                    "aria-label": "checkbox with default color",
                  }}
                />
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: this.state.oldDataCheck ? 700 : 400,
                    color: "#64748B",
                  }}
                >
                  Do you want to copy from a previously added pet?{" "}
                </Typography>
              </div>

              {this.state.savedPet.length > 1 && this.state.oldDataCheck && (
                <div>
                  <select
                    id="breed"
                    name="petBreed"
                    onChange={this.state.savepetinfonumber === 3 ? this.handleContactFill : this.handleSelectedDataFill}
                    data-test-id="formDataHandleChange"
                    style={{
                      width: "99.8%",
                      border: "1px solid rgb(203, 213, 225)",
                      height: "40px",
                      backgroundColor: "#E0F4FC",
                      borderRadius: "7px",
                      margin: "1% 0% 2%",
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: "inter"
                    }}
                  >
                    <option defaultChecked style={{ fontSize: "16px", fontWeight: 400, fontFamily: "inter" }}>Select Pet Name</option>
                    {this.state.savedPet.map((item: any, index: number) => (
                      <option style={{
                        color: "#64748B", fontSize: "16px",
                        fontWeight: 400, fontFamily: "inter"
                      }} value={item.id}>
                        {item.attributes.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </>
          )}
      </div>
    );
  };

  RenderSavedPet = () => {
    return (
      <>


        {
          this.state.savepetinfonumber > 0 ? null :

            <div>
              {Array.isArray(this.state.savedPet) &&
                this.state.savedPet?.map((pet) => {
                  const dateOfBirthData = pet?.attributes?.date_of_birth ? pet?.attributes?.date_of_birth.toString()
                    .split("-") : [new Date().getFullYear().toString(), (new Date().getMonth() + 1).toString()];




                  const birthYear = parseInt(dateOfBirthData[0]);
                  const birthMonth = parseInt(dateOfBirthData[1]);


                  const now = new Date();
                  const currentYear = now.getFullYear();


                  const currentMonth = now.getMonth() + 1;

                  let yearsDiff = currentYear - birthYear;
                  let monthsDiff = currentMonth - birthMonth;




                  if (monthsDiff < 0) {
                    yearsDiff--;
                    monthsDiff = 12 - birthMonth + currentMonth;
                  }
                  return (
                    <div key={pet.id}>
                      <Paper
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          marginLeft: "11%",
                          padding: "10px",
                          flexWrap: "nowrap",
                          marginTop: "2%",
                          position: "relative",
                          boxShadow:
                            "rgb(223 223 223 / 10%) 0px 8px 32px 0px, rgba(67, 134, 161, 0.3) 1px 1px 8px 0px",
                          width: "70%",
                          borderBottom: "2px solid #8BD7F5"
                        }}
                      >
                        <Grid item style={Group.Grid3 as React.CSSProperties}>
                          <div style={{ marginRight: "17px" }}>
                            <Avatar
                              alt="pet Photo"
                              src={pet?.attributes?.pet_photo}
                              style={Group.Grid4 as React.CSSProperties}
                            />
                          </div>
                          <div>
                            <Typography style={Group.Grid5}>
                              {pet?.attributes?.name}
                            </Typography>
                            <Typography style={Group.Grid6}>
                              Pet Type: <span style={{ textTransform: 'capitalize' }}>{pet?.attributes?.pet_type}</span> | Pet
                              Sex: <span>{pet?.attributes?.gender}</span> | Pet Age:
                              <span>
                                {` ${yearsDiff} Years and ${monthsDiff} Months`}
                              </span>
                            </Typography>
                          </div>
                        </Grid>

                        <Grid item style={{ textAlign: "right",position:'relative' }}>
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            data-test-id="menuopen"
                            onClick={(e) => this.handleClick(e, pet.id)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          {this.state.selectedPetId === pet.id &&
                            <>
                              {this.renderPetProfileManage()}
                            </>
                          }
                        </Grid>
                      </Paper>
                    </div>
                  );
                })}
            </div>




        }
        {!this.state.isFormVisible && (
          <>
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1% 0%",
                margin: "2% 7% 0%",

                width: "76%"
              }}
            >


              <Button
                style={Group.Grid7 as React.CSSProperties}
                onClick={this.toggleForm}
                data-test-id="toggleForm"
              >
                <ControlPointIcon style={Group.Grid8} />

                Add Pet
              </Button>

              {this.state.afterDetaisFill && <button
                onClick={this.handleClickModelOpenAddCart}
                data-test-id="handleClickModelOpenAddCart"
                style={Group.Grid13 as React.CSSProperties}
              >
                Save and Continue <ChevronRightIcon />
              </button>
              }
            </Grid>
          </>
        )}
      </>
    );
  };

  renderDogDetails = () => {
    const filter = createFilterOptions();
    return (
      <div style={{ padding: "1% 11.5%", marginTop: 20 }}>
        <Grid style={Group.uplodePhotoCompt}>
          <div>
            <input
              id="contained-button-file"
              accept="image/*"
              data-test-id="handleFileImgChange"
              onChange={this.handleFileImgChange}
              style={Group.style2}
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button
                component="span"
                style={{
                  boxShadow: boxShadows.whiteShadow,
                  padding: "1px 0px",
                  borderRadius: "15px",
                  marginBottom: "5%",
                }}
              >
                {this.renderPhotoContent()}
              </Button>
            </label>
          </div>
          {this.getValError(["imageUrl"])}
          <Typography variant="subtitle2" color="error">{this.state.error.imageUrlSizeError}</Typography>
        </Grid>


        <Grid container style={Group.style5}>
          <label style={Group.label1 as React.CSSProperties}>
            Dog Name*
          </label>
          <br />
          <input
            type="text"
            name="petName"
            value={this.state.petName}
            placeholder="Enter dog name"
            style={Group.inputStyle}
            onChange={this.handleInputChange}
            data-test-id="formDataHandleChange1"
          >

          </input>
          {this.getValError(["petName"])}
        </Grid>
        <Grid style={Group.style5} container>
          <label
            style={Group.label1 as React.CSSProperties}> Breed*
          </label><br />
          <Autocomplete
            value={this.state.petBreed}
            data-test-id="select_breed"
            onChange={(event, newValue) => {
              this.breedClose(newValue);
            }}
            fullWidth
            options={this.state.DogbreedList}
            PopperComponent={CustomPopper}
            filterOptions={(options, state) => {
              const filtered = filter(options, state);
              if (filtered.length === 0) {
                return ['Other'];
              }
              return filtered;
            }}

            noOptionsText={""} // No need to show "No breed found"
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  name='petBreed'
                  placeholder="Select dog breed"
                  style={Group.twoinputStyle} {...params.inputProps} />
              </div>
            )}
          />



          {!this.state.petBreed && this.getValError(["petBreed"])}</Grid>

        {this.state.isOtherBreed === true ? <Grid container style={Group.style5}>
          <label style={Group.label1 as React.CSSProperties}> Breed Name*</label>
          <br />
          <input
            type="text" name="petBreed"
            placeholder="Enter dog breed name"
            value={this.state.selectedBreed} style={Group.inputStyle}
            onChange={this.handleInputChange1} data-test-id="formDataHandleChange1"
          ></input>
          {!this.state.selectedBreed && this.getValError(["selectedBreed"])} </Grid> : null}

        <Grid
          container
          spacing={2}>
          <Grid
            item
            xs={12}
            lg={6} style={{ position: 'relative' }}>
            <div
              style={Group.style5}>
              <label style={Group.label1 as React.CSSProperties}
              > Sex*
              </label>
              <br />
              <div
                style={{ ...Group.countryPhnameselect, marginTop: "5px", width: "94%", paddingRight: "10px", cursor: "pointer", borderRadius: "8px", height: "33px" } as React.CSSProperties}
                onClick={this.openGenderPopup}
              >
                <span style={{ ...Group.countrySpan, textTransform: "none" } as React.CSSProperties}>
                  {this.state.sex || "Select dog sex"}
                </span>
                <ExpandMoreIcon />
              </div>
              {this.state.genderPopup &&
                <>
                  {this.renderGendrePopUp()}
                </>
              }

            </div> {!this.state.sex && this.getValError(["sex"])}
          </Grid>
          <Grid
            lg={6}
            item
            xs={12}
          >
            <div
              style={Group.style5}>
              <label style={Group.label1 as React.CSSProperties}
              >
                Dog's Date of Birth*
              </label>
              <br />
              <Box style={{ width: "37.5%" }}>


                <CustomCalendar style={{ marginTop: "5px", cursor: "pointer", position: "relative", fontFamily: "inter" }}>
                  <CustomDate
                    data-test-id="dogbirthdate"
                    onChange={this.handleBirthChange}
                    value={this.state.dogbirthdate}
                    readOnly={false}
                  />
                  <CalendarTodayIcon
                    style={{
                      top: "17%",
                      position: "absolute",
                      left: "238%",
                      zIndex: 2,
                      color: "#4385A1",
                    }}
                  />
                </CustomCalendar>

              </Box>
              {!this.state.dogbirthdate && this.getValError(["dogbirthdate"])}
            </div></Grid></Grid>

        <Grid
          style={{ display: "flex", flexWrap: "nowrap" }}
          spacing={2}
          container
        >
          <Grid
            lg={6}
            xs={12}
            item
          >
            <div
              style={Group.style5}
            >
              <label style={Group.label1 as React.CSSProperties}> Colour</label><br />
              <input type="text"
                name="petColor"
                value={this.state.petColor}
                onChange={this.handleInputChange}
                data-test-id="formDataHandleChange"
                placeholder="Enter pet colour"
                style={Group.twoinputStyle}
              ></input>
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div style={Group.style5}>
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Weight (kg)*
              </label>
              <br />
              <input
                name="petWeight"
                value={this.state.petWeight}
                onChange={this.handleInputChange}
                data-test-id="pet_weight"
                placeholder="Enter pet weight"
                style={Group.twoinputStyle}
              ></input>
            </div>
            {this.getValError([`petWeight`])}
          </Grid>
        </Grid>

        <Grid style={Group.style5 as React.CSSProperties}>
          <label style={Group.label1 as React.CSSProperties}>
            Spayed / Neutered*
          </label>
          <br />
          <div style={{ marginTop: "1%" }}>
            <RadioGroup
              name="selectedValueSpayed"
              value={this.state.selectedValueSpayed}
              onChange={this.handleInputChange}
              data-test-id="formDataHandleChange"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                color: "#224350",
              }}
            >
              <FormControlLabel
                value="Spayed"
                control={<CustomRadio />}
                label={
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: "16px",
                      color: "#0F172A",
                      fontWeight:
                        this.state.selectedValueSpayed ===
                          "Spayed"
                          ? 700
                          : 400,
                    }}
                  >
                    Spayed
                  </span>
                }
              />
              <FormControlLabel
                value="Neutered"
                control={<CustomRadio />}
                label={
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: "16px",
                      color: "#0F172A",
                      fontWeight:
                        this.state.selectedValueSpayed ===
                          "Neutered"
                          ? 700
                          : 400,
                    }}
                  >
                    Neutered
                  </span>
                }
              />
              <FormControlLabel
                value="ChemicallyCastrated"
                control={<CustomRadio />}
                label={
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: "16px",
                      color: "#0F172A",
                      fontWeight:
                        this.state.selectedValueSpayed ===
                          "ChemicallyCastrated"
                          ? 700
                          : 400,
                    }}
                  >
                    Chemically Castrated
                  </span>
                }
              />
              <FormControlLabel
                value="none"
                control={<CustomRadio />}
                label={
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: "16px",
                      color: "#0F172A",
                      fontWeight:
                        this.state.selectedValueSpayed ===
                          "none"
                          ? 700
                          : 400,
                    }}
                  >
                    None
                  </span>
                }
              />
            </RadioGroup>
            {this.getValError(["selectedValueSpayed"])}
          </div>
        </Grid>

        {this.state.selectedValueSpayed === "ChemicallyCastrated" ?

          <div style={Group.style5}>
            <label
              style={Group.label1 as React.CSSProperties}
            >
              Last Procedure Date*
            </label>
            <br />
            <Box style={{ width: "37.5%" }}>
              <CustomCalendar style={{ marginTop: "5px", position: "relative", width: "49%" }}>
                <CustomDate
                  data-test-id="proceduredate"
                  value={this.state.proceduredate}
                  onChange={this.handlproceduredate}
                  readOnly={false}
                />
                <CalendarTodayIcon
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    top: "17%",
                    color: "#4385A1",
                    zIndex: 2,
                    left: "238%"
                  }}
                />
              </CustomCalendar>
            </Box>
            {this.procedureError()}
          </div>

          : null}



      </div>
    );
  };

  procedureError = () => {
    return (
      <>
        {!this.state.proceduredate && this.getValError(["proceduredate"])}
        {!this.state.proceduredate && this.state.procedureerror ? <span style={{ color: "red" }}> &nbsp; Procedure date should be greater then birth date</span> : null}

      </>
    )
  }


  renderCatDetails = () => {
    const filter = createFilterOptions();

    return (
      <div style={{ padding: "0% 11.5%", marginTop: 20 }}>
        <Grid style={Group.uplodePhotoCompt}>
          <div>
            <input
              accept="image/*"
              id="contained-button-file"
              onChange={this.handleFileImgChange}
              data-test-id="handleFileImgChange"
              type="file"
              style={Group.style2}
            />
            <label htmlFor="contained-button-file">
              <Button
                component="span"
                style={{
                  padding: "1px 0px",
                  boxShadow:
                    "rgb(223 223 223 / 10%) 0px 8px 32px 0px, rgba(67, 134, 161, 0.3) 1px 1px 8px 0px", marginBottom: "5%", borderRadius: "15px"
                }}
              >
                {this.renderPhotoContent()}

              </Button>
            </label>
          </div>
          {this.getValError(["imageUrl"])}
          <Typography variant="subtitle2" color="error">{this.state.error.imageUrlSizeError}</Typography>
        </Grid>


        <Grid container style={Group.style5}>
          <label style={Group.label1 as React.CSSProperties}>
            Cat Name*
          </label>
          <br />
          <input
            type="text"
            data-test-id="formDataHandleChange"
            style={Group.inputStyle}
            name="petName"
            value={this.state.petName}
            onChange={this.handleInputChange}
            placeholder="Enter cat name"
          ></input>
          {this.getValError(["petName"])}
        </Grid>
        <Grid container style={Group.style5}>
          <label style={Group.label1 as React.CSSProperties}>
            Breed*
          </label>
          <br />
          <Autocomplete
            filterOptions={(options, state) => {
              const filtered = filter(options, state);
              if (filtered.length === 0) {
                return ['Other'];
              }
              return filtered;
            }}
            data-test-id="cat_breed"
            value={this.state.petBreed}
            onChange={(event, newValue) => {
              this.breedClose(newValue);
            }}
            options={this.state.catBreedList}
            noOptionsText={"No breed found"}
            PopperComponent={CustomPopper}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input placeholder="Select cat breed"
                  style={Group.twoinputStyle} {...params.inputProps} />
              </div>
            )}
            fullWidth
          />
          {!this.state.petBreed && this.getValError(["petBreed"])}
        </Grid>

        {this.state.isOtherBreed === true ? <Grid container style={Group.style5}>
          <label style={Group.label1 as React.CSSProperties}>
            Breed Name*
          </label>
          <br />
          <input
            type="text"
            name="petBreed"
            value={this.state.selectedBreed}
            placeholder="Enter cat breed name"
            style={Group.inputStyle}
            onChange={this.handleInputChange1}
            data-test-id="formDataHandleChange1"
          >

          </input>
          {!this.state.selectedBreed && this.getValError(["selectedBreed"])}
        </Grid> : null}

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} style={{ position: 'relative' }}>
            <div style={Group.style5}>
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Sex*
              </label>
              <br />
              <div
                style={{ ...Group.countryPhnameselect, marginTop: "5px", width: "94%", paddingRight: "10px", cursor: "pointer", borderRadius: "8px", height: "33px" } as React.CSSProperties}
                onClick={this.openGenderPopup}
                data-test-id="open_gender"
              >
                <span style={{ ...Group.countrySpan, textTransform: "none" } as React.CSSProperties}>{this.state.sex || "Select cat sex"}</span>
                <ExpandMoreIcon

                />
              </div>
              {this.state.genderPopup && <>
                {this.renderGendrePopUp()}
              </>
              }
            </div>
            {!this.state.sex && this.getValError(["sex"])}
          </Grid>
          <Grid item xs={12} lg={6}>
            <div style={Group.style5}>
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Cat's Date of Birth*
              </label>
              <br />
              <Box style={{ width: "37.5%", marginTop: "5px" }}>

                <CustomCalendar style={{ position: "relative" }}>
                  <CustomDate
                    data-test-id="catbirth"
                    onChange={this.handlecatBirthChange}
                    value={this.state.catbirthdate}
                    readOnly={false}
                  />
                  <CalendarTodayIcon
                    style={{
                      position: "absolute",
                      top: "17%",
                      color: "#4385A1",
                      zIndex: 2,
                      left: "238%",
                      cursor: "pointer"
                    }}
                  />

                </CustomCalendar>
              </Box>
              {!this.state.catbirthdate && this.getValError(["catbirthdate"])}



            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ display: "flex", flexWrap: "nowrap" }}>
          <Grid item xs={12} lg={6}>
            <div style={Group.style5}>
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Colour
              </label>
              <br />
              <input
                name="petColor"
                type="text"
                onChange={this.handleInputChange}
                value={this.state.petColor}
                style={Group.twoinputStyle}
                placeholder="  Enter pet color"
                data-test-id="formDataHandleChange"
              ></input>
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div style={Group.style5}>
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Weight (kg)*
              </label>
              <br />
              <input
                name="petWeight"
                value={this.state.petWeight}
                placeholder="  Enter pet weight"
                data-test-id="pet_weight"
                style={Group.twoinputStyle}
                onChange={this.handleInputChange}
              ></input>
            </div>
            {this.getValError([`petWeight`])}
          </Grid>
        </Grid>

        <Grid style={Group.style5 as React.CSSProperties}>
          <label style={Group.label1 as React.CSSProperties}>
            Spayed / Neutered*
          </label>
          <br />
          <div style={{ marginTop: "1%" }}>
            <RadioGroup
              data-test-id="formDataHandleChange"
              value={this.state.selectedValueSpayed}
              onChange={this.handleInputChange}
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                color: "#224350",
              }}
              name="selectedValueSpayed"
            >
              <FormControlLabel
                control={<CustomRadio />}
                value="Spayed"
                label={
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: "16px",
                      color: "#0F172A",
                      fontWeight:
                        this.state.selectedValueSpayed ===
                          "Spayed"
                          ? 700
                          : 400,
                    }}
                  >
                    Yes
                  </span>
                }
              />
              <FormControlLabel
                value="None"
                control={<CustomRadio />}
                label={
                  <span
                    style={{
                      fontSize: "16px",
                      fontFamily: "inter",
                      fontWeight:
                        this.state.selectedValueSpayed ===
                          "None"
                          ? 700
                          : 400,
                      color: "#0F172A",
                    }}
                  >
                    No
                  </span>
                }
              />


            </RadioGroup>
            {this.getValError(["selectedValueSpayed"])}
          </div>
        </Grid>
      </div>
    );
  };

  renderPetDetailsSection = () => {
    const { savepetinfonumber, dogselected, catselected } = this.state;

    if (savepetinfonumber === 2) {
      if (dogselected) {
        return this.renderDogDetails();
      } else if (catselected) {
        return this.renderCatDetails();
      }
    }

    return null;
  };


  getStepButton = () => {


    const { savepetinfonumber, dogselected, catselected } = this.state;
    const isLastStep = savepetinfonumber === 5;
    const isSelectionValid = dogselected || catselected;

    const buttonLabel = isLastStep ? 'Done' : 'Next';

    const handleClickFunctions = [
      null,
      this.handleNext,
      this.handleNext1,
      this.handleNext2,
      this.handleNext3,
      this.handleApi
    ];

    const handleClick = handleClickFunctions[savepetinfonumber] as React.MouseEventHandler<HTMLButtonElement>;



    const buttonTypographyStyle: React.CSSProperties = {
      fontSize: 16,
      fontWeight: 700,
      textDecorationLine: 'underline',
      color: !isSelectionValid ? '#CBD5E1' : '#EA0C78',
      textTransform: 'none',
    };

    const buttonArrowStyle: React.CSSProperties = {
      fontSize: 18,
      fontWeight: 700,
      textDecorationLine: 'underline',
      color: !isSelectionValid ? '#CBD5E1' : '#EA0C78',
      textTransform: 'none',
      marginLeft: "10px"
    };

    return (
      <Button
        onClick={handleClick}
        disabled={!isSelectionValid}
        data-test-id="handleApi"
      >
        <Typography style={buttonTypographyStyle}>{buttonLabel}</Typography>
        <KeyboardArrowRight style={buttonArrowStyle} />
      </Button>
    );
  };


  renderPetSelectionButtons = () => {
    if (this.state.savepetinfonumber === 1) {
      return (
        <Box style={Group.selectBtnsLayout}>
          <Button onClick={this.handleOptionDog}
            data-test-id="dog-button"
            style={{
              ...Group.selectdogbtn,
              backgroundColor: this.state.dogselected ? 'B2E4F8' : '#F0FAFE',
              border: this.state.dogselected ? '1px solid #4386A1' : 'none', textTransform: 'none'
            }}
          >
            <img src={DogImg} alt="" />
            <Typography variant="subtitle2" style={Group.selectedPet}>Dog</Typography>
          </Button>
          <Button onClick={this.handleOptionCat}
            data-test-id="cat-button"
            style={{
              ...Group.selectcatbtn,
              border: this.state.catselected ? '1px solid #4386A1' : 'none',
              backgroundColor: this.state.catselected ? 'B2E4F8' : '#F0FAFE', textTransform: 'none'
            }}
          >
            <img src={CatImg} alt="" />
            <Typography variant="subtitle2" style={Group.selectedPet}>Cat</Typography>
          </Button>
        </Box>
      );
    }
    return null;
  };

  RenderMedicalTreatment = () => (
    <Grid>
      <label style={Group.label1 as React.CSSProperties}>
        Medical Treatment*
      </label>
      <RadioGroup
        style={Group.style5}
        name="medicalTreatment"
        value={this.state.medicalTreatment}
        onChange={this.handleInputChange}
        data-test-id="formDataHandleChange"
      >
        <Grid style={Group.style3}>
          <FormControlLabel
            value="true"
            control={<CustomRadio />}
            label={
              <span
                style={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  color: "#0F172A",
                  fontWeight:
                    this.state
                      .medicalTreatment === "true"
                      ? 700
                      : 400,
                }}
              >
                Yes
              </span>
            }
          />
          <FormControlLabel
            value="false"
            control={<CustomRadio />}
            label={
              <span
                style={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  color: "#0F172A",
                  fontWeight:
                    this.state
                      .medicalTreatment === "false"
                      ? 700
                      : 400,
                }}
              >
                No
              </span>
            }
          />
        </Grid>
      </RadioGroup>
      {this.getValError(["medicalTreatment"])}
    </Grid>
  );


  renderTreatmentDetails = () => {

    return (
      <>
        <Grid style={Group.style5}>
          <textarea
            style={
              Group.textArea as React.CSSProperties
            }
            placeholder="Is your pet currently on any medication?"
            name="description"
            value={this.state.description}
            onChange={this.handleInputChange}
            data-test-id="formDataHandleChange"
            rows={4}
          ></textarea>
        </Grid>

        {this.state.catselected && <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={6}
            style={Group.style5}
          >
            <label
              style={
                Group.label1 as React.CSSProperties
              }
            >
              Vaccination Date*
            </label>
            <br />
            <Box style={{ width: "37.5%", display: "flex", justifyContent: "space-between" }}>

              <CustomCalendar style={{ marginTop: "5px", width: "100%", position: "relative" }}>
                <CustomDate data-test-id="vaccinedate"
                  value={this.state.vaccination_date}
                  onChange={this.handlevaccinedate}
                  readOnly={false}
                />
                <CalendarTodayIcon
                  style={{
                    position: "absolute",
                    top: "17%",
                    color: "#4385A1",
                    zIndex: 2,
                    cursor: "pointer",
                    left: "238%"
                  }}
                />
              </CustomCalendar>
            </Box>
            {!this.state.vaccination_date && this.getValError(["vaccination_date"])}
            {this.state.vaccineerror ? <Typography style={{ color: "red" }}>Vaccination date should be greater than birthdate</Typography> : null}
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            style={Group.style5}
          >
            <div>
              <label
                style={
                  Group.label1 as React.CSSProperties
                }
              >
                Vaccination Certificate*
              </label>
              <div
                style={{
                  cursor: "pointer",
                  marginTop: "5px",
                }}
              >
                <input
                  type="file"
                  id={"vacc"}
                  style={{ ...Group.style15, backgroundColor: "E0F4FC" }}
                  onChange={this.handleFileChangeVac}
                  data-test-id="handleFileChangeVac"
                />
                <label htmlFor="vacc">
                  <div
                    color="primary"
                    style={{ ...Group.uplodeButton, backgroundColor: "E0F4FC" }}
                  >
                    {this.state.uploadedFileName ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "95%"
                        }}
                      >


                        <span
                          style={{
                            marginLeft: "5px", overflow: "hidden",
                            whiteSpace: "nowrap", width: "90%", color: "#000"
                          }}
                        >
                          {
                            this.state
                              .uploadedFileName
                          }
                        </span>
                        <img
                          src={UplodeFile}
                          alt="Uplode"
                          style={{ flex: "0 0 auto" }}
                        />
                      </div>
                    ) : (
                      <div style={Group.style16}>

                        <span
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap", width: "90%", marginLeft: "5px", color: "#000"
                          }}
                        >
                          Upload Certificate
                        </span>
                        <img
                          src={UplodeFile}
                          alt="Uplode"
                          style={{ flex: "0 0 auto" }}
                        />
                      </div>
                    )}
                  </div>
                </label>
              </div>
            </div>
            {this.getValError(["uploadedFileName"])}
          </Grid>
        </Grid>}

        {this.state.dogselected ? this.renderVaccineCheckbox() : null}

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={6}
            style={Group.style17}
          >
            <label
              style={
                Group.label1 as React.CSSProperties
              }
            >
              Last Flea Treatment Date*
            </label>
            <br />
            <Box style={{ width: "37.5%" }}>

              <CustomCalendar style={{ marginTop: "5px", position: "relative" }}>
                <CustomDate value={this.state.flea_date} data-test-id="fleadate" readOnly={false} onChange={this.handlefleadate} />
                <CalendarTodayIcon
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "17%",
                    color: "#4385A1",
                    zIndex: 2,
                    left: "238%"
                  }}
                />
              </CustomCalendar>
            </Box>
            {this.getValError(["flea_date"])}
            {this.state.fleaerror ? <Typography style={{ color: "red" }}>flea treatment date should be greater than birthdate</Typography> : null}

          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            style={Group.style5}
          >
            <label
              style={
                Group.label1 as React.CSSProperties
              }
            >
              Last Worming Date*
            </label>
            <br />
            <Box style={{ width: "37.5%" }}>

              <CustomCalendar style={{ marginTop: "5px", position: "relative" }}>
                <CustomDate value={this.state.worming_date}
                  readOnly={false}
                  data-test-id="wormingdate" onChange={this.handlewormingdate} />
                <CalendarTodayIcon
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "17%",
                    color: "#4385A1",
                    zIndex: 2,
                    left: "238%"
                  }}
                />
              </CustomCalendar>
            </Box>
            {this.state.wormingError ? <Typography style={{ color: "red" }}>Worming treatment date should be greater than birthdate</Typography> : null}

          </Grid>
        </Grid>
      </>
    )

  }






  RenderMedicalNotes = () => {
    return (
      <>
        {this.state.savepetinfonumber === 4 ? <div style={{ padding: "1.5% 10%" }}>
          <Grid style={{ marginLeft: "2%" }}>
            <Grid style={Group.medicalNotesGrid}>
              <Grid container>
                <Grid item lg={12}>

                  {this.RenderMedicalTreatment()}

                  {this.renderTreatmentDetails()}

                  <Grid style={Group.style5}>
                    <label
                      style={
                        Group.label1 as React.CSSProperties
                      }
                    >
                      Dietary Requirements*
                    </label>
                    <textarea
                      style={
                        Group.textArea as React.CSSProperties
                      }
                      name="daietary"
                      value={this.state.daietary}
                      onChange={this.handleInputChange}
                      data-test-id="formDataHandleChange"
                      placeholder="Describe here"
                      rows={4}
                    ></textarea>
                    {this.getValError(["daietary"])}
                  </Grid>
                  <Grid style={Group.style5}>
                    <label
                      style={
                        Group.label1 as React.CSSProperties
                      }
                    >
                      Behavioral Notes
                    </label>
                    <textarea
                      style={
                        Group.textArea as React.CSSProperties
                      }
                      name="behavior"
                      value={this.state.behavior}
                      onChange={this.handleInputChange}
                      data-test-id="formDataHandleChange"
                      placeholder="Describe here"
                      rows={4}
                    ></textarea>

                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </div> : null}
      </>
    )
  }



  RenderEmergency = () => {
    return (
      <>
        {this.renderOldDataSection()}
        <Grid item >
          <Typography variant="h4">Emergency Contact Details</Typography>
          <div style={{ marginBottom: "5px", marginTop: "30px" }}><label style={Group.label1 as React.CSSProperties}>Emergency Contact Name*</label></div>
          <Adddatadiv style={{ width: "100%", display: "flex", height: "41px", backgroundColor: "#E0F4FC", position: 'relative' }}>

            <div
              onClick={() => this.handleEmergencyopen('emergency')}
              data-test-id='openEmergency'
              style={{ ...Group.countryPhnameselect, cursor: "pointer", width: "15%", } as React.CSSProperties}
            >
              <span
                style={Group.countrySpan as React.CSSProperties}>
                {this.state.genderemergency}
              </span>
              <ExpandMoreIcon
              />
            </div>

            {this.state.anchorElgenderemergency &&
              <>
                {this.renderLegalNameSalutation('emergency')}
              </>

            }
            <input
              type="text"
              name="emergency_contact_name"
              value={this.state.emergency_contact_name}
              onChange={this.handleInputtextChange}
              data-test-id="handleInputtextChange"
              placeholder="First Name"
              style={{
                border: "1px solid #CBD5E1",
                width: "95%",
                borderLeft: "none",
                fontSize: "16px",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                paddingLeft: "15px",
              }}
            ></input>
          </Adddatadiv>
          {!this.state.emergency_contact_name && this.getValError(["emergency_contact_name"])}
          <div style={{ marginTop: "3%" }}>
            <label
              style={Group.label1 as React.CSSProperties}
            >
              Surname*
            </label>
            <input
              type="text"
              name="emergencysurname"
              value={this.state.emergencysurname}
              onChange={this.handleInputtextChange}
              data-test-id="handleInputChange"
              style={Group.inputStyle}
              placeholder="Last Name"
            />
          </div>
          {!this.state.emergencysurname && this.getValError(["emergencysurname"])}



          <div style={{ marginTop: "3%" }}>
            <label
              style={Group.label1 as React.CSSProperties}
            >
              Emergency Contact Email*
            </label>
            <input
              type="email"
              name="emergencyemail"
              value={this.state.emergencyemail}
              onChange={this.handleInputtextChange}
              data-test-id="handleInputChange"
              style={Group.inputStyle}
              placeholder="kevinbarrett@iamcat.com"
            />
          </div>

          {(!this.state.emergencyemail || this.state.error.emergencyemailError) && this.getValError(["emergencyemail"])}


          <Grid style={{ margin: "20px 0px" } as React.CSSProperties}>
            <label
              style={
                Group.label1 as React.CSSProperties
              }
            >
              Emergency Contact Number*
            </label>
            <Adddatadiv style={Group.style9 as React.CSSProperties}>


              <div
                style={Group.countryPhnameselect as React.CSSProperties}
                onClick={() => this.emergencyCountryOpen('emergency')}
                data-test-id="openEmergencyCountry"
              >
                <span style={Group.countrySpan as React.CSSProperties}>{`${this.state.emergencyContactCountry}`}</span>
                <ExpandMoreIcon
                />
              </div>


              {this.state.anchorEmergencycountry && this.renderLegalCountryDetails('emergency')}


              <input
                name="emergency_contact_number"
                type="number"
                onChange={this.handleInputChange}
                value={this.state.emergency_contact_number}
                placeholder="Phone Number"
                data-test-id="formDataHandleChange"
                style={Group.phoneNoInput as React.CSSProperties}
              ></input>
            </Adddatadiv>
            {(!this.state.emergency_contact_number) && this.getValError(["emergency_contact_number"])}
          </Grid>

        </Grid>




        <Typography variant="h4" style={{ marginTop: "50px" }}>Legal Guardian Contact Details</Typography>

        <Box style={{ display: 'flex', alignItems: 'center', marginLeft: "-12px" }}>
          <Checkbox
            onChange={() => this.emergencyCheckbox()}
            data-test-id={`checkBoxCheck`}
            inputProps={{
              'aria-label': 'checkbox with default color',
            }}
            checked={this.state.guardiancheckbox}
            color="default"
          />

          <Typography
            style={{
              display: 'flex',
              color: this.state.guardiancheckbox ? "black" : "grey",
            }}>
            The legal guardian is the same person as the emergency contact
          </Typography>
        </Box>

        {this.state.guardiancheckbox ? null : <Grid item >
          <div style={{ marginBottom: "5px", marginTop: "30px" }}><label style={Group.label1 as React.CSSProperties}>Legal Guardian Contact Name*</label></div>
          <Adddatadiv
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "#E0F4FC",
              height: "41px",
              position: 'relative'
            }}>
            <div onClick={() => this.handleEmergencyopen('guardian')}
              data-test-id='openGuardian'

              style={{ ...Group.countryPhnameselect, width: "15%", cursor: "pointer", } as React.CSSProperties} >
              <span style={Group.countrySpan as React.CSSProperties}>
                {this.state.genderguardian}</span> <ExpandMoreIcon />
            </div>

            {this.state.anchorElgenderguardian &&
              <>
                {this.renderLegalNameSalutation('guardian')}
              </>

            }
            <input
              type="text"
              name="guardian_name"
              value={this.state.guardian_name}
              onChange={this.handleInputtextChange}
              data-test-id="handleInputChange"
              placeholder="First Name"
              style={{
                border: "1px solid #CBD5E1",
                width: "95%",
                borderLeft: "none",
                fontSize: "16px",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                paddingLeft: "15px",
              }}
            ></input>
          </Adddatadiv>
          {!this.state.guardian_name && this.getValError(["guardian_name"])}
          <div style={{ marginTop: "3%" }}>
            <label
              style={Group.label1 as React.CSSProperties}
            >
              Surname*
            </label>
            <input
              type="text"
              name="guardiansurname"
              value={this.state.guardiansurname}
              onChange={this.handleInputtextChange}
              data-test-id="handleInputChange"
              style={Group.inputStyle}
              placeholder="Last Name"
            />
          </div>
          {!this.state.guardiansurname && this.getValError(["guardiansurname"])}
          <div style={{ marginTop: "3%" }}>
            <label
              style={Group.label1 as React.CSSProperties}
            >
              Legal Guardian Email*
            </label>
            <input
              type="email"
              name="guardianmail"
              value={this.state.guardianmail}
              onChange={this.handleInputtextChange}
              data-test-id="handleInputChange"
              style={Group.inputStyle}
              placeholder="kevinbarrett@iamcat.com"
            />
          </div>
          {(!this.state.guardianmail || this.state.error.guardianmailError) && this.getValError(["guardianmail"])}

          <Grid style={{ margin: "20px 0px" } as React.CSSProperties}>
            <label
              style={
                Group.label1 as React.CSSProperties
              }
            >
              Legal Guardian Contact Number*
            </label>
            <Adddatadiv style={Group.style9 as React.CSSProperties}>

              <div
                style={Group.countryPhnameselect as React.CSSProperties}
                onClick={() => this.emergencyCountryOpen('guardian')}
                data-test-id='openGuardianCountry'

              >
                <span style={Group.countrySpan as React.CSSProperties}>{`${this.state.legalContactCountry}`}</span>
                <ExpandMoreIcon
                />
              </div>
              {this.state.anchorguardiancountry && this.renderLegalCountryDetails('guardian')}




              <input
                type="number"
                name="guardian_contact_number"
                value={this.state.guardian_contact_number}
                onChange={this.handleInputChange}
                data-test-id="formDataHandleChange"
                placeholder="Phone Number"
                style={Group.phoneNoInput as React.CSSProperties}
              ></input>
            </Adddatadiv>
            {(!this.state.guardian_contact_number) && this.getValError(["guardian_contact_number"])}
          </Grid>

        </Grid>}
      </>
    )
  }


  RenderPetForm = () => {
    return (
      <Grid container style={{ padding: "0% 1%" }}>
        {this.state.isFormVisible && (

          <Box style={{
            width: "100%",
          }}>

            {this.renderPetSelectionButtons()}




            {/* Pet information */}
            {this.renderPetDetailsSection()}






            {/* Vet clinic details */}

            {this.state.savepetinfonumber === 5 ? <div style={{ padding: "1% 11.3%" }}>
              <Grid>
                <Grid>
                  <Grid container>
                    <>{this.renderOldDataSection()}</>

                    <Grid item xs={12} lg={12} style={Group.Grid14 as React.CSSProperties}>
                      <Grid style={Group.style5 as React.CSSProperties}>
                        <label
                          style={
                            Group.label1 as React.CSSProperties
                          }
                        >
                          Vet Clinic Name*
                        </label>
                        <br />
                        <input
                          type="text"
                          name="clinicName"
                          value={this.state.clinicName}
                          onChange={this.handleInputChange}
                          data-test-id="formDataHandleChange"
                          placeholder="Enter vet clinic name"
                          style={Group.inputStyle as React.CSSProperties}
                        ></input>
                        {!this.state.clinicName && this.getValError(["clinicName"])}
                      </Grid>
                      <Grid item style={Group.style5 as React.CSSProperties}>
                        <label
                          style={
                            Group.label1 as React.CSSProperties
                          }
                        >
                          Vet  Name*
                        </label>
                        <br />
                        <input
                          type="text"
                          name="vetName"
                          value={this.state.vetName}
                          onChange={this.handleInputChange}
                          data-test-id="formDataHandleChange"
                          placeholder="Enter vet name"
                          style={Group.inputStyle as React.CSSProperties}
                        ></input>
                        {!this.state.vetName && this.getValError(["vetName"])}
                      </Grid>

                      <Grid style={Group.style5 as React.CSSProperties}>
                        <label
                          style={
                            Group.label1 as React.CSSProperties
                          }
                        >
                          Vet Clinic Phone Number*
                        </label>
                        <Adddatadiv style={Group.style9 as React.CSSProperties}>


                          <div
                            style={Group.countryPhnameselect as React.CSSProperties}
                            onClick={this.clinicCountryOpen}
                          >
                            <span style={Group.countrySpan as React.CSSProperties}>{`${this.state.phoneCountry}`}</span>
                            <ExpandMoreIcon
                            />
                          </div>


                          <CountryMenu anchorEl={this.state.anchorcliniccountry} onClose={this.handelcliniccountryCloses} configJSON={configJSON} listType="countries" user="po" width="19.3%" />

                          <input
                            type="number"
                            name="phoneNumber"
                            value={this.state.phoneNumber}
                            onChange={this.handleInputChange}
                            data-test-id="formDataHandleChange"
                            placeholder="Phone Number"
                            style={Group.phoneNoInput as React.CSSProperties}
                          ></input>
                        </Adddatadiv>
                        {!this.state.phoneNumber && this.getValError(["phoneNumber"])}
                      </Grid>

                      <Grid style={Group.style5 as React.CSSProperties}>
                        <label
                          style={
                            Group.label1 as React.CSSProperties
                          }
                        >
                          Vet Clinic Address*
                        </label>
                        <br />
                        <textarea
                          style={
                            Group.textArea as React.CSSProperties
                          }
                          name="clinicAddress"
                          value={this.state.clinicAddress}
                          onChange={this.handleInputChange}
                          data-test-id="formDataHandleChange"
                          placeholder="Enter vet clinic address here"
                          rows={4}
                        ></textarea>
                        {!this.state.clinicAddress && this.getValError(["clinicAddress"])}
                      </Grid>

                      <Grid style={{ ...Group.style5, height: "75px", display: "flex", alignItems: "center" } as React.CSSProperties}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            fontWeight: 700,
                            color: "#7295A3",
                          }}
                        >Insurance Details</Typography>
                        <Divider style={{ border: "0.5px solid #CBD5E2", marginLeft: "10px", backgroundColor: "#CBD5E2", width: "78%" }} />
                      </Grid>

                      <Grid style={Group.style5 as React.CSSProperties}>
                        <label
                          style={
                            Group.label1 as React.CSSProperties
                          }
                        >
                          Insurance Provider

                        </label>
                        {/* <span style={{ color: "#334155" }}>
                          (Optional)
                        </span> */}
                        <br />
                        <input
                          type="text"
                          name="insurenceProvider"
                          value={this.state.insurenceProvider}
                          onChange={this.handleInputChange}
                          data-test-id="formDataHandleChange"
                          placeholder="Enter pet insurance provider"
                          style={Group.inputStyle as React.CSSProperties}
                        ></input>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          lg={6}
                          style={Group.style5 as React.CSSProperties}
                        >
                          <label
                            style={
                              Group.label1 as React.CSSProperties
                            }
                          >
                            Policy Number

                          </label>

                          <br />
                          <input
                            type="text"
                            name="policyNumber"
                            value={this.state.policyNumber}
                            onChange={this.handleInputChange}
                            data-test-id="formDataHandleChange"
                            placeholder="Enter pet policy number"
                            style={Group.twoinputStyle as React.CSSProperties}
                          ></input>

                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={6}
                          style={Group.style5 as React.CSSProperties}
                        >
                          <label
                            style={
                              Group.label1 as React.CSSProperties
                            }
                          >
                            Microchip Number*

                          </label>

                          <br />
                          <input
                            type="text"
                            name="microchipNumber"
                            value={this.state.microchipNumber}
                            onChange={this.handleInputChange}
                            data-test-id="formDataHandleChange"
                            placeholder="Enter microchip number"
                            style={Group.twoinputStyle as React.CSSProperties}
                          ></input>
                          {!this.state.microchipNumber && this.getValError(["microchipNumber"])}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div> : null}




            {/* Medical notes */}
            {this.RenderMedicalNotes()}





            {/* Emergency contact */}

            {this.state.savepetinfonumber === 3 ? <div style={{ padding: "1.5% 11.5%" }}>

              {this.RenderEmergency()}


            </div> : null}


            <CustomMobileStepper
              variant="progress"
              steps={6}
              position="static"
              data-test-id="stepper"
              activeStep={this.state.savepetinfonumber}
              // className={classes.root}
              style={{
                maxWidth: 400,
                flexGrow: 1,
                marginBottom: "15px",
                backgroundColor: "#fff",
                display: this.state.savepetinfonumber === 0 ? "none" : "visible",
                alignItems: 'center',
                padding: "1.5% 11.5%"

              }}

              nextButton={
                this.getStepButton()
              }

              backButton={
                <Button size="small" onClick={this.handleBack}
                  data-test-id="back-button"
                  style={{ width: 100, height: 30, display: "flex", alignItems: "center", textTransform: 'none', }}
                >
                  <KeyboardArrowLeft style={{ fontSize: 22, fontWeight: 700, textDecorationLine: "underline", color: "#64748B", marginRight: "10px" }} />
                  <Typography style={{ fontSize: 16, fontWeight: 700, textDecorationLine: "underline", color: "#64748B" }}>Previous</Typography>

                </Button>
              }
            />

          </Box>
        )}
      </Grid>
    );
  };

  renderPetInfoText = () => {
    const petInfoOptions: any = {
      1: `${this.state.savepetinfonumber}. Select Pet type`,
      2: this.state.dogselected ? `${this.state.savepetinfonumber}. Dog Details` : `${this.state.savepetinfonumber}. Cat Details`,
      3: `${this.state.savepetinfonumber}. Emergency Contact & Legal Guardian Detail Information`,
      4: `${this.state.savepetinfonumber}. Medical Notes`,
      5: `${this.state.savepetinfonumber}. Vet Clinic and Insurance Details`,
    };

    const petInfoText: any = petInfoOptions[this.state.savepetinfonumber];

    return (
      <span style={{ ...Group.Grid1, fontWeight: 400, marginTop: "18px", marginBottom: "10px" }}>{petInfoText}</span>
    );
  };

  renderPetProfileManage=()=>{
    return(
      <>
      <ClickAwayListener onClickAway={this.handleClose} data-test-id='clickWay'>
        <Box style={Group.petContainer as React.CSSProperties}>
          <Box padding={"0.5rem"}>
          <Button
            onClick={() => this.onViewPet("View")}
            data-test-id="viewPet"
            style={{ backgroundColor: this.state.onHover === 0 ? "#B2E4F8" : "white", justifyContent: "flex-start", borderRadius: "8px", textTransform: "none", width: "95%", margin: "5px" }}

            onMouseLeave={() => this.setState({ onHover: -1 })}
            onMouseEnter={() => this.setState({ onHover: 0 })}
          >
            <VisibilityIcon style={{ marginRight: "7px" }} />
            <span>View</span>
          </Button>
          <Button
            onClick={() => this.naviateToNewTicketScreen()}
            data-test-id="editPet"
            style={{ backgroundColor: this.state.onHover === 1 ? "#B2E4F8" : "white", justifyContent: "flex-start", borderRadius: "8px", textTransform: "none", width: "95%", margin: "5px" }}
            onMouseLeave={() => this.setState({ onHover: 0 })}
            onMouseEnter={() => this.setState({ onHover: 1 })}
          >
            <EditIcon style={{ marginRight: "7px" }} />
            <span>Edit</span>
          </Button>

          <Button
            onClick={this.handleClickModelOpen}
            data-test-id="deletePet"
            style={{ borderRadius: "8px", backgroundColor: this.state.onHover === 2 ? "#B2E4F8" : "white", textTransform: "none", width: "95%", margin: "5px", justifyContent: "flex-start" }}
            onMouseLeave={() => this.setState({ onHover: 0 })}
            onMouseEnter={() => this.setState({ onHover: 2 })}
          >
            <DeleteOutlineIcon style={{ marginRight: "7px" }} />
            <span>Delete</span>
          </Button>
          </Box>
        </Box>

      </ClickAwayListener>
      </>
    )
  }



  checkBoxInnerBox = (
    checkBoxID: string,
    checkboxState: any,
    handleCheckboxChange: any,
    typographyText: any,
    vaccinationDate: any,
    handleVaccinationDateChange: any,
    dateErrorMessage: boolean,
    certifiateErroMessage: boolean
  ) => {
    return (
      <Box style={{
        width: "100%",
        margin: '10px 0'
      }}>
        <Box display={'flex'} alignItems={'center'}>
          <Checkbox
            inputProps={{
              "aria-label": "checkbox with default color",
            }}
            color="default"
            style={{ padding: "0px" }}
            checked={checkboxState}
            onChange={handleCheckboxChange}
          />
          <Typography>
            {typographyText}
          </Typography>
        </Box>

        {checkboxState && <Box>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              lg={6}
              style={Group.style5}
            >
              <label
                style={
                  Group.label1 as React.CSSProperties
                }
              >
                Vaccination Date*
              </label>
              <br />
              <Box style={{ width: "37.5%", display: "flex", justifyContent: "space-between" }}>

                <CustomCalendar style={{ marginTop: "5px", width: "100%", position: "relative" }}>
                  <CustomDate data-test-id="vaccinedate"
                    value={vaccinationDate}
                    onChange={handleVaccinationDateChange}
                    readOnly={false}
                  />
                  <CalendarTodayIcon
                    style={{
                      position: "absolute",
                      top: "17%",
                      color: "#4385A1",
                      zIndex: 2,
                      cursor: "pointer",
                      left: "238%"
                    }}
                  />
                </CustomCalendar>
              </Box>
              {dateErrorMessage && <Typography style={{ color: "red" }}>{this.state.dateErrorMessage}</Typography>}
            </Grid>
          </Grid>

        </Box>}
      </Box>
    );
  };


  renderVaccineCheckbox = () => {
    return (
      <Box style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
        marginBottom: "10px",
      }}>

        {this.rendervaccinationCertificate()}
        <Typography variant="body1" style={{ fontSize: "14px", fontWeight: 700 }}>Please confirm your dog is vaccinated against:</Typography>
        {this.checkBoxInnerBox('pravo', this.state.canineParvo, this.canineParvo, "Canine Parvovirus (a.k.a. Parvo)", this.state.parvoDate, this.parvoDate, this.state.pravoDateError, this.state.pravoFileError)}
        {this.checkBoxInnerBox('infect', this.state.canineInfect, this.canineInfect, "Canine Infectious Hepatitis", this.state.infectDate, this.infectDate, this.state.infectDateError, this.state.infectFileError)}
        {this.checkBoxInnerBox('distemper', this.state.distemper, this.distemper, "Distemper", this.state.distemperDate, this.distemperDate, this.state.disTemperDateError, this.state.disTemperFileError)}
        {this.checkBoxInnerBox('lepto', this.state.lepto, this.lepto, "Leptospirosis", this.state.leptoDate, this.leptoDate, this.state.leptoDateError, this.state.leptoFileError)}
        {this.checkBoxInnerBox('kennel', this.state.kennel, this.kennel, "Kennel Cough", this.state.kennelDate, this.kennelDate, this.state.kennelDateError, this.state.kennelFileError)}

        {this.state.vaccineerror && <Typography variant="subtitle2" color="error">Please select at least one vaccination</Typography>}
      </Box>
    )
  }

  rendervaccinationCertificate = () => {
    const { vaccinationCertificate } = this.state
    return (
      <>
        <Grid
          item
          xs={12}
          lg={6}
          style={Group.style5}
        >
          <div>
            <label
              style={
                Group.label1 as React.CSSProperties
              }
            >
              Vaccination Certificate*
            </label>
            <div
              style={{
                cursor: "pointer",
                marginTop: "5px",
              }}
            >
              <input
                type="file"
                id={"vacc"}
                style={{ ...Group.style15, backgroundColor: "E0F4FC" }}
                onChange={this.handleVaccCertificate}
                data-test-id="handleFileChangeVac"
              />
              <label htmlFor={"vacc"}>
                <div
                  color="primary"
                  style={{ ...Group.uplodeButton, backgroundColor: "E0F4FC" }}
                >
                  {vaccinationCertificate ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "95%"
                      }}
                    >


                      <span
                        style={{
                          marginLeft: "5px", overflow: "hidden",
                          whiteSpace: "nowrap", width: "90%", color: "#000"
                        }}
                      >
                        {vaccinationCertificate.name}
                      </span>
                      <img
                        src={UplodeFile}
                        alt="Uplode"
                        style={{ flex: "0 0 auto" }}
                      />
                    </div>
                  ) : (
                    <div style={Group.style16}>

                      <span
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap", width: "90%", marginLeft: "5px", color: "#000"
                        }}
                      >
                        Upload Certificate
                      </span>
                      <img
                        src={UplodeFile}
                        alt="Uplode"
                        style={{ flex: "0 0 auto" }}
                      />
                    </div>
                  )}
                </div>
              </label>
            </div>
          </div>
        </Grid>
      </>
    )
  }
  renderLegalNameSalutation = (type: string) => {
    let typeOf = type === 'emergency' ? this.state.genderemergency : this.state.genderguardian
    return (
      <>
        <ClickAwayListener onClickAway={() => this.emergencyGenderClose('', type)}>
          <Box style={Group.salutationBox as React.CSSProperties}>
            <Box padding={'0.5rem'}>
              {['Mr.', "Miss", "Mrs.", "Mx."].map((item: string, index: number) => {
                return (
                  <>
                    <MenuItem
                      onClick={() => this.emergencyGenderClose(item, type)}
                      value={item}
                      key={index}
                      data-test-id="detail_name"
                      style={{ backgroundColor: typeOf === item ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
                    >{item}
                    </MenuItem>
                  </>
                )

              })}
            </Box>
          </Box>
        </ClickAwayListener>

      </>
    )
  }

  renderLegalCountryDetails = (type: string) => {
    return (
      <>
        <ClickAwayListener onClickAway={() => this.handelPhCountryCloses('', "", type)}>
          <Box style={Group.countryBox as React.CSSProperties}>
            <Box padding={'0.5rem'}>
              {configJSON.countries.map((value: { label: string; phone: string; }) => (
                <React.Fragment key={value.label}>
                  <MenuItem
                    value={value.label}
                    data-test-id="countrymap"
                    style={{ borderRadius: '8px', width: '90%', height: '44px' }}
                    onClick={() => this.handelPhCountryCloses(value.label, value.phone, type)}
                  >
                    {`${value.label} ${value.phone}`}
                  </MenuItem>
                  <Divider style={{ margin: '2px', backgroundColor: "#F1F5F9" }} />
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </ClickAwayListener>

      </>
    )
  }

  // Customizable Area End
  render() {
    // Customizable Area Start

    return (
      <div style={{ backgroundColor: "E0F4FC", height: "100vh" }}>
        <div
          style={{
            backgroundColor: "E0F4FC",
            fontFamily: "inter",
            padding: "0% 12%",
            marginBottom: "auto"
          }}
        >
          <PetOwnerNavigationMenu navigation={this.props.navigation} id="" navType="fullNavbar" />
          <Container
            style={{
              textTransform: "none",
              fontFamily: "inter",
              height: "auto",
              minHeight: "78vh",
              marginTop: "92px",
              paddingBottom: "100px",
              backgroundColor: "E0F4FC"
            }}
          >
            <Paper
              style={{
                borderBottomRightRadius: "30px",
                // padding: "0% 0% 3%"
                boxShadow: "rgb(223 223 223 / 10%) 0px 8px 32px 0px, rgba(67, 134, 161, 0.3) 1px 1px 8px 0px",
                width: "100%"
              }}
            >
              <Grid container style={Group.mainGrid1}>

                <Grid item lg={12} xs={12}>
                  <div
                  >
                    <Grid
                      // onClick={this.handleNavigatePrev}
                      data-test-id="handleNavigatePrev"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        // padding: "2.4% 1% 2% 1%",
                        padding: " 30px 28px",
                        cursor: "pointer",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        boxShadow: "rgb(223 223 223 / 10%) 0px 8px 32px 0px, rgba(67, 134, 161, 0.3) 1px 1px 8px 0px",
                      }}
                    >
                      <Box style={{ width: "80%", display: "flex", flexDirection: "column", margin: "auto", marginTop: "20px" }}>
                        <span style={Group.Grid1}>Add Pet Information</span>

                        {this.renderPetInfoText()}

                      </Box>

                    </Grid>
                  </div>

                  {
                    this.RenderSavedPet()
                  }

                  {this.RenderPetForm()}


                  <CustomsettingModal
                    isVisible={this.state.modelOpen}
                    hideModal={this.handleCloseModel}
                    callApi={this.handleDeletData}
                    typography1="Delete Pet Profile"
                    typography2="Are you sure you want to delete this pet profile?"
                    buttontext1="Yes"
                    buttontext2="No"
                  />

                </Grid>
              </Grid>
            </Paper>
          </Container>
        </div>
        <Footer color={'#E0F4FC'} footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />

        <Loader loading={this.state.EditPetLoader} />
      </div>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const Adddatadiv = styled(Box)({

})



const CustomCalendar = styled(Box)({

  "& .rmdp-input": {
    cursor: "pointer",
    position: "relative",
    zIndex: 22,
    backgroundColor: "#61b0cf26",
    fontFamily: "inter",
    fontSize: "15px",
    height: "34px",

    width: "265%",

    borderRadius: "8px",
    border: "solid 1px #B2E4F8",
    paddingLeft: "10px"
  },
  "& .rmdp-header": {
    backgroundColor: "#CCEDFB",
    borderRadius: 8,
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .rmdp-week-day": {
    color: "#000"
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight) ": {
    backgroundColor: "#EA0C78",
    color: "#fff"
  },
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: "#fff",
    color: "#000"
  },
  "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
    backgroundColor: "#EA0C78",
    color: "#fff",
    borderColor: "#EA0C78"
  },
  "& .rmdp-arrow": {
    borderColor: "#EA0C78"
  },

  "& .rmdp-arrow-container.disabled .rmdp-arrow, .rmdp-arrow-container.disabled:hover .rmdp-arrow": {
    borderColor: "#EA0C78"
  }

})


const CustomMobileStepper = styled(MobileStepper)({
  maxWidth: " 98% !important",
  "& .MuiMobileStepper-progress": {
    width: "55%",
    position: "absolute",
    top: "235px",
    backgroundColor: "#B2E4F8",
    left: "0px",
    right: "0px",
    margin: "auto",

  },
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#4386A1",
  },
})


const Group = {

  mainGrid1: {
    // marginTop: "10px",
    backgroundColor: "#fff",
    borderBottomRightRadius: "30px"
  },
  style17: {
    margin: "10px 0px ",
    width: "100%",
  },
  style16: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "95%"
  },
  style15: {
    display: "none",
    marginTop: "5px",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "inter"
  },
  style14: {
    color: "rgba(100, 116, 139, 1)",
  },
  style9: {
    marginTop: "5px",
    display: "flex",
    width: "100%",
    position: 'relative'
  },
  style5: {
    margin: "10px 0px",
  },

  style3: {
    display: "flex",
  },
  style2: {
    display: "none",
  },
  label1: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#334155",
    fontFamily: "inter",
    marginBottom: "5px",
  },
  label2: {
    fontWeight: 400,
    fontSize: "18px",
    color: "#0F172A",
    fontFamily: "inter",
  },
  style1: {
    width: "100%",
    padding: "2%",
  },
  uplodeButton: {
    color: "#757575",
    width: "97.5%",
    height: "33px",
    border: "1px solid rgba(203, 213, 225, 1)",
    borderRadius: "7px",
    backgroundColor: "#fff",
    boxShadow: "none",
    paddingLeft: "7px",
    display: "flex",
    alignItems: "center",
  },
  medicalNotesGrid: {
    paddingBottom: "20px",
  },
  selectCountry: {
    width: "40%",
    height: "37px",
    // border: "1px solid aqua",
    textAlign: "center",
    paddingTop: "3px",
    backgroundColor: "#E0F4FC",
    borderTopLeftRadius: "7px",
    borderBottomLeftRadius: "7px",
  },
  phoneNoInput: {
    width: "69.9%",
    height: "41px",
    border: "1px solid rgba(203, 213, 225, 1)",
    borderTopRightRadius: "7px",
    borderBottomRightRadius: "7px",
    paddingLeft: "5px",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "inter"
  },
  textArea: {
    width: "99.8%",
    paddingLeft: "10px",
    paddingTop: "3px",
    resize: "none",
    border: "1px solid rgba(203, 213, 225, 1)",
    borderRadius: "7px",
    marginTop: "5px",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "inter"
  },
  selectInputStyle: {
    width: "99.8%",
    height: "37px",
    marginTop: "5px",
    border: "1px solid rgba(203, 213, 225, 1)",
    borderRadius: "7px",
    paddingLeft: "7px",
    backgroundColor: "#E0F4FC",
  },
  uplodePhotoCompt: {
    width: "40%",
    minWidth: "200px",
  },
  inputStyle: {
    marginTop: "5px",
    width: "99.5%",
    height: "41px",
    border: "1px solid rgba(203, 213, 225, 1)",
    borderRadius: "7px",
    paddingLeft: "10px",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "inter"
  },

  uplodePetPhoto: {
    height: "200px",
    width: "310px",
    margin: "2.5%",
    overFlow: "hidden",
    objectFit: "cover",
    borderRadius: "15px",
  },
  twoinputStyle: {
    width: "100%",
    marginTop: "5px",
    height: "40px",
    border: "1px solid rgba(203, 213, 225, 1)",
    borderRadius: "7px",
    paddingLeft: "7px",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "inter"
  },
  mainGrid2: {
    padding: "30px",
    background:
      "linear-gradient(90deg, rgba(240,250,254,1) 0%, rgba(204,237,250,1) 100%)",
    height: "auto",
    borderBottomRightRadius: "35px",
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    fontSize: "24px",
    fontWeight: 700,
    color: "white",
    backgroundColor: "#65C9F1",
    height: "70px",
    width: "70px",
  },
  avatar0: {
    padding: "10px",
    marginLeft: "3%",
  },
  avatar1: {
    fontSize: "24px",
    fontWeight: 400,
    color: "#4386A1",
  },
  avatar2: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#4386A1",
  },
  Grid1: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#0F172A",
    width: "auto",
    fontFamily: "inter",
  },
  Grid7: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#EA0C78",
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
    marginTop: "30px",
    textTransform: "none",
    width: "20%",
    textDecoration: "underline #EA0C78 2px",
  },
  Grid8: {
    // width: "20%",
    color: "#EA0C78",
    marginLeft: "8.2%",
    marginRight: 6,
  },
  Grid9: {
    textAlign: "right",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  GridBtn: {
    marginLeft: "3%",
    textAlign: "right",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  Grid10: {
    display: "flex",
    justifyContent: "space-between",
  },
  Grid11: {
    display: "flex",
    alignItems: "center",
  },

  Grid13: {
    fontFamily: "inter",
    fontWeight: 700,
    fontSize: "16px",
    backgroundColor: "#EA0C78",
    color: "white",
    padding: "5px 16px",
    border: "none",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    cursor: "pointer",
    height: "56px",
  },
  Grid3: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  Grid4: {
    backgroundColor: " rgba(203, 213, 225, 1)",
    borderRadius: "50%",
    padding: "4px",
    height: "60px",
    width: "60px",
    objectFit: "cover",
  },
  Grid5: {
    fontWeight: 700,
    fontSize: "16px",

    color: "rgba(51, 65, 85, 1)",
  },
  Grid6: {
    fontWeight: 400,
    fontSize: "14px",

    color: "rgba(100, 116, 139, 1)",
  },
  Grid14: {
    width: "100%",
  },
  selectdogbtn: {
    width: "35%",
    height: "104px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    padding: '20px 40px'

  },
  selectcatbtn: {
    width: "35%",
    height: "104px",
    backgroundColor: "#F0FAFE",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    padding: '20px 40px'

  },
  countryPhnameselect: {
    width: "40%",
    paddingLeft: "10px",
    border: "1px solid #B2E4F8",
    height: "39px",
    backgroundColor: "#E0F4FC",
    borderRadius: "8px 0px 0px 8px",
    display: "flex",
    position: "relative",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    paddingRight: "10px"
  },
  breedselect: {
    height: "35px",
    paddingLeft: "10px",
    width: "97%",
    borderRadius: "8px",
    border: "1px solid #B2E4F8",
    position: "relative",
    backgroundColor: "#E0F4FC",
    justifyContent: "space-between",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    paddingRight: "10px"
  },
  countrySpan: {
    color: "#000",
    fontSize: "16px",
    fontWeight: 400, fontFamily: "inter", textTransform: "capitalize"
  },
  selectedPet: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
    marginLeft: "30px"
  },
  selectBtnsLayout: {
    width: '100%',
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: "25px",
    columnGap: "12%",
    gap: '20px',
    padding: '40px 0px'
  },
  notUplodePetPhoto: {
    height: "200px",
    width: "310px",
    margin: "2.5%",
    overFlow: "hidden",
    objectFit: "cover",
    borderRadius: "15px",
    background: colors.primaryBlue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  uploadPhotoText: {
    marginTop: "5px",
    color: theme.palette.common.black,
    fontWeight: 700,
    textTransform: 'none'
  },
  outerBox: {
    position: 'absolute',
    zIndex: 99,
    background: "#fff",
    borderRadius: 8,
    width: '94%',
    boxShadow: boxShadows.whiteShadow
  },

  countryBox: {
    position: 'absolute',
    zIndex: 99,
    background: "#fff",
    borderRadius: 8,
    width: '40%',
    boxShadow: boxShadows.whiteShadow
  },
  salutationBox: {
    position: 'absolute',
    zIndex: 99,
    background: "#fff",
    borderRadius: 8,
    width: '15%',
    boxShadow: boxShadows.whiteShadow
  },
  petContainer: {
    position: "absolute" as const,
    zIndex: 9,
    width: "150px",
    maxHeight: "fit-content",
    borderRadius: 8,
    backgroundColor: "#ffffff",
    boxShadow: boxShadows.whiteShadow,
    right:'10px'
  }

};
// Customizable Area End
