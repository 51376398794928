import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { WithStyles } from "@material-ui/core";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";
import { HotelListData, HotelListDataAttributes, PetFilter, StatusFilter } from "./types";
import { checkApiErrorResponce, handleNavigation } from "../../../components/src/CommonFunctions";
import { Color } from "@material-ui/lab";

// Customizable Area Start

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}
interface LinkObject {
    url: string;
    title: string;
}
interface SocialLinksData {
    id: number,
    type: string,
    attributes: {
        id: number,
        social_link: string,
        icon: {
            url: string,
            name: string,
            content_type: string,
            size: number
        }
    }
}
interface SocialLinks {
    social_links: {
        data: Array<SocialLinksData>
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    // Customizable Area Start
    id: string;
    navigation: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    hotelsList: HotelListData[],
    searchHotel: string;
    pagLoader: boolean;
    filterPopUpStatus: boolean;
    dropDownStatus: boolean;
    statusdropDownStatus: boolean;
    StatusType: StatusFilter[];
    petType: PetFilter[];
    statusName: string;
    statusValue: string;
    petName: string,
    petValue: string
    filtersSeleted: boolean

    linksData: { icons: string, navigationUrl: string }[]
    boLinksData: LinkObject[];
    hotelToasterStatus: boolean;
    hotelMessage: string;
    hotelToasterType: Color;
    popupType: string;
    hotelId: string;
    page: number,
    totalPageCount: number,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class PropertyDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIdgetHotelsList: string = '';
    apiCallIdSearchHotelsList: string = "";
    socialLinksCallId: string = "";
    boLinksCallId: string = "";
    apiCallIdDeleteHotel: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            hotelsList: [],
            searchHotel: "",
            pagLoader: false,
            filterPopUpStatus: false,
            dropDownStatus: false,
            statusdropDownStatus: false,
            StatusType: [
                { id: 1, StatusType: "All status", StatusValue: "All status" },
                { id: 2, StatusType: "Active", StatusValue: "Active" },
                { id: 3, StatusType: "Draft", StatusValue: "Draft" },
                { id: 4, StatusType: "Under Review", StatusValue: "Under Review" }
            ],
            petType: [
                { id: 1, petType: "All Pets", petValue: "All Pets" },
                { id: 2, petType: "Dogs", petValue: "Dogs" },
                { id: 3, petType: "Cats", petValue: "Cats" }

            ],
            statusName: "All status",
            statusValue: "All status",
            petName: "All Pets",
            petValue: "All Pets",
            filtersSeleted: false,
            linksData: [],
            boLinksData: [],
            hotelToasterStatus: false,
            hotelMessage: "",
            hotelToasterType: 'success',
            popupType: "",
            hotelId: "",
            page: 1,
            totalPageCount: 0,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    // Customizable Area Start
    async componentDidMount() {
        this.getHotelsListBo()
        this.getSocialLinks();
        this.getBOLink()
    }

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (apiRequestCallId === this.apiCallIdgetHotelsList) {
            this.hotelDetailsApiResponec(responseJson)

        }
        if (apiRequestCallId === this.apiCallIdSearchHotelsList) {
            this.hotelDetailsApiResponec(responseJson)
        }
        if (apiRequestCallId === this.socialLinksCallId) {
            this.socialLinksSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.boLinksCallId) {
            this.boLinksSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.apiCallIdDeleteHotel) {
            this.deleteHotelApiResponce(responseJson)
        }

        // Customizable Area End
    }

    // Customizable Area Start

    deleteHotelApiResponce = (responseJson: HotelListData) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                pagLoader: false,
                hotelToasterStatus: true,
                hotelMessage: 'Hotel Deleted Successfuly',
                filterPopUpStatus: false
            }, () => {
                this.getHotelsListBo()
            })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                pagLoader: false,
                hotelToasterStatus: true,
                hotelMessage: responseJson.errors[0].message,
                filterPopUpStatus: false,
                hotelToasterType: 'error'
            })

        }

        else {
            checkApiErrorResponce(responseJson, this.props.navigation)
        }

    }

    hotelDetailsApiResponec = (responseJson: HotelListData) => {
        if (responseJson && !responseJson.errors && !responseJson.message) {
            const updatedHotelList = responseJson.data
            updatedHotelList.sort((first: any, second: any) => second.id - first.id)
            this.setState({
                hotelsList: updatedHotelList, pagLoader: false,
                totalPageCount: responseJson?.total_page
            })


        } else if (responseJson.message === 'No hotels found') {
            this.setState({ hotelsList: [], pagLoader: false,totalPageCount:0 })
        } else if (responseJson.errors) {
            checkApiErrorResponce(responseJson, this.props.navigation)
        }

    }

    addproperty = () => {
        this.props.navigation.navigate('BussinessOwnerDetailsFirst')
    }



    handleHotelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchHotel: event.target.value })

    }
    handeSearch = () => {
        this.setState({
            page:1
        },()=>{
            
            this.searchHotelsListBo(this.state.searchHotel)
        })

    }

    getHotelsListBo = async () => {

        let tokenValue = await getStorageData("authToken");
        const headers = {
            token: tokenValue,
        };


        this.setState({ pagLoader: true })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.HotelListEndPoint}?pet_type=${this.state.petValue}&status=${this.state.statusValue}&page=${this.state.page}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType
        );
        this.apiCallIdgetHotelsList = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    searchHotelsListBo = async (searchValue: string) => {

        let tokenValue = await getStorageData("authToken");
        const headers = {
            token: tokenValue,
        };
        this.setState({ pagLoader: true })

        let apiEndPoint = `${configJSON.HotelListEndPoint}?name=${searchValue}&pet_type=${this.state.petValue}&status=${this.state.statusValue}&page=${this.state.page}`


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint

        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType
        );
        this.apiCallIdSearchHotelsList = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Filters

    openFiltersPopup = (popUpType: string, hotelId: string) => {
        this.setState({ filterPopUpStatus: true, popupType: popUpType, hotelId: hotelId })
    }
    closeFiltersPopup = () => {
        this.setState({ filterPopUpStatus: false }, () => {

            // this.clearFilters()
        })
    }

    openFilterDropDowns = (dropDownType: string) => {
        if (dropDownType === 'pet_type') {
            this.setState({ dropDownStatus: true })
        } else if (dropDownType === 'status') {
            this.setState({ statusdropDownStatus: true })

        }

    }
    closeFilterDropDowns = (dropDownType: string) => {
        if (dropDownType === 'pet_type') {
            this.setState({ dropDownStatus: false })
        } else if (dropDownType === 'status') {
            this.setState({ statusdropDownStatus: false })

        }
    }
    selectStatusFilter = (value: StatusFilter) => {
        this.setState({ statusValue: value.StatusValue, statusName: value.StatusType }, () => {
            this.closeFilterDropDowns('status')
        })
    }
    selectpetsFilter = (value: PetFilter) => {
        this.setState({ petValue: value.petValue, petName: value.petType }, () => {
            this.closeFilterDropDowns('pet_type')
        })
    }

    clearStatus = () => {
        this.setState({ statusValue: "all", statusName: "All status" })
    }

    searchFilter = async () => {
        this.setState({ filtersSeleted: true,page:1 })
        this.closeFiltersPopup()
        if (this.state.searchHotel === "") {
            this.getHotelsListBo()
        } else {
            this.searchHotelsListBo(this.state.searchHotel)
        }
    }

    clearSearch = () => {
        this.setState({ searchHotel: "" }, () => {
            this.getHotelsListBo()
        })
    }

    handleDraftNavigation = (details: HotelListDataAttributes) => {
        if (details.redirect_value.step_1) {
            //pet typeSelection
            handleNavigation("BussinessOwnerDetailsFirst", { activeStep: 2, hotelId: details.id }, this.send, this.props)
        } else if (details.redirect_value.step_2) {
            //hotel details
            handleNavigation("BussinessOwnerDetailsFirst", { activeStep: 3, hotelId: details.id }, this.send, this.props)
        }
        else if (details.redirect_value.step_3) {
            //representataive details
            handleNavigation("BussinessOwnerDetailsFirst", { activeStep: 3, hotelId: details.id }, this.send, this.props)
        } else if (details.redirect_value.step_4) {
            //images selection-title-to-description
            handleNavigation("BussinessOwnerDetailsSecond", { activeStep: 3, hotelId: details.id }, this.send, this.props)
        }
        else if (details.redirect_value.step_5) {
            // title&description to room details
            handleNavigation("BussinessOwnerDetailsThird", { activeStep: 1, hotelId: details.id }, this.send, this.props)
        }
        else if (details.redirect_value.step_6) {
            // room details to  CancellationPolicy
            handleNavigation("CancellationPolicyFirst", { activeStep: 1, hotelId: details.id }, this.send, this.props)
        } else if (details.redirect_value.step_7) {
            // ancellation policy to stripe
            setStorageData("hotel_id", details.id)

            handleNavigation("StripeAccountDetails", { hotelId: details.id }, this.send, this.props)
        }
        else if (details.redirect_value.step_8) {
            //see properties
            this.seeHotelDetails(details.id)
        }

    }
    seeHotelDetails = (hotelId: number) => {
        handleNavigation("SingleProperty", {}, this.send, this.props)
        setStorageData('BoHotelId', hotelId)

    }

    clearFilters = () => {
        this.setState({
            statusValue: "All status", statusName: "All status",
            petValue: "All Pets", petName: "All Pets",
            filtersSeleted: false
        }, () => {
            this.closeFiltersPopup();
            this.getHotelsListBo();

        });
    }

    socialLinksSuccessCallBack = (responseJson: SocialLinks) => {
        const linksDataIcon: { icons: string, navigationUrl: string }[] = [];
        const socialLinksData = responseJson.social_links.data;
        socialLinksData.forEach(function (socialLink: SocialLinksData) {
            linksDataIcon.push({
                navigationUrl: socialLink.attributes.social_link,
                icons: socialLink.attributes.icon.url
            })
        })
        setStorageData("footerNavigationUrl", JSON.stringify(linksDataIcon));
        this.setState({ linksData: linksDataIcon });
    };
    boLinksSuccessCallBack = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            const boLinks = responseJson?.bo_links?.data
            const boLinksMapping = boLinks.map((link: any) => {
                return {
                    title: link.attributes.title,
                    url: link.attributes.url,
                };
            });
            this.setState({ boLinksData: boLinksMapping })
            localStorage.setItem("BOandPOLinks", JSON.stringify(boLinksMapping));
        }
    };

    apiCall = async (apiData: APIPayloadType) => {

        const { method, endPoint, body, type } = apiData;

        const header = {
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        body && type !== "formData"
            ? requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getSocialLinks = async () => {
        this.socialLinksCallId = await this.apiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.socialLinksEndPoint,
            contentType: configJSON.dashboarContentType
        });
    };
    getBOLink = async () => {
        this.boLinksCallId = await this.apiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.boLinksEndPoint,
            contentType: configJSON.dashboarContentType
        });
    };
    deleteHotel = async () => {
        this.apiCallIdDeleteHotel = await this.apiCall({
            method: configJSON.deleteMethiodType,
            endPoint: `${configJSON.updateHotelEndPoint}/${this.state.hotelId}`,
            contentType: configJSON.dashboarContentType
        });
    };

    closeToaster = () => {
        this.setState({ hotelToasterStatus: false })
    }

    handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        this.setState({ page: value }, () => {
            if (this.state.searchHotel === "") {
                this.getHotelsListBo()
            } else {
                this.searchHotelsListBo(this.state.searchHotel)
            }
        })

    }
    // Customizable Area End
}
