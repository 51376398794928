import React from "react";
// Customizable Area Start
import {
    RadioProps,
    Grid, Typography, Box, MenuItem, Button, ClickAwayListener, Container, TextField, Divider, Radio,
    FormHelperText
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ContactUsHelpController, { CountryCode, InquiryType, NameType, Props } from "./ContactUsHelpController.web";
import  PetOwnerNavigationMenu  from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import Footer from "../../../components/src/Footer.web";
import { attachment, downArrow } from "./assets";
import { boxShadows, theme } from "../../../components/src/theme";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
export const configJSON = require("../../contentmanagement/src/config.js");
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Loader from "../../../components/src/Loader.web";
import { CustomizedCalenderTextField } from "../../../components/src/CustomizeCalender";

// Customizable Area End

// Customizable Area Start
export const ContactUsTextField = withStyles({
    root: {
        "& .MuiInputBase-root": {
            fontFamily: "Inter",
            color: theme.palette.common.black,
            backgroundColor: "#ffffff",
            fontSize: 16,
            fontWeight: theme.typography.fontWeightRegular,
             height: 44
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #CBD5E1",
            borderRadius: 8
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #CBD5E1",
            borderRadius: 8
        },
        "& .MuiOutlinedInput-root:hover": {
            borderRadius: 8
        },
        "& .MuiSvgIcon-root": {
            display: 'none'
        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: 0
        },
        "& .MuiFormHelperText-root": {
            marginTop: 5,
            fontSize: 14
        }
    }
})(TextField);
export const ContactUsTextFieldDropDownField = withStyles({
    root: {
        "& .MuiInputBase-root": {
            fontFamily: "Inter",
            background: `linear-gradient(0deg, #E0F4FC, #E0F4FC),linear-gradient(0deg, #CCEDFA, #CCEDFA)`,
            color: theme.palette.common.black,
            fontSize: 16,
            fontWeight: theme.typography.fontWeightRegular,
            textAlign: 'center',
            height:44
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #CCEDFA",
            borderRadius: "8px 0px 0px 8px"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #CCEDFA",
            borderRadius: "8px 0px 0px 8px"
        },
    }
})(TextField);


interface CustomRadioProp extends RadioProps {
    "data-test-id": string;
}

const CustomRadio = withStyles(() => ({
    root: {
        marginRight: "8px",
        padding: "0px",
        "&$checked": {
            color: "red",
        },
        color: "default",
    },
    checked: {},
}))((props: CustomRadioProp) => (
    <Radio
        disableRipple
        color="default"
        checkedIcon={
            <span
                style={{
                    backgroundColor: "white",
                    display: "inline-block",
                    borderRadius: "50%",
                    height: 7,
                    border: "6px solid #224350",
                    width: 7,
                }}
            />
        }
        icon={
            <span
                style={{
                    display: "inline-block",
                    border: "1px solid #224350",
                    width: 16,
                    height: 16,
                    borderRadius: "50%",
                }}
            />
        }
        {...props}
    />
));

// Customizable Area End
export class ContactUsHelp extends ContactUsHelpController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderDropdown = (inputType: string) => {
        const { classes } = this.props
        return (
            <>
                <ClickAwayListener onClickAway={() => this.handleClosePopup(inputType)}>
                    <Box className={inputType === 'nameType' ? classes.nameTypeOuterBox : classes.countryOuterBox}>
                        <Box className={classes.popUpInnerBox}>
                            {inputType === 'nameType' && this.renderNameTypePopup()}
                            {inputType === 'countryCode' && this.renderCountryCodePopupPopup()}
                            {inputType === 'inquiry' && this.renderInquiryTypePopup(inputType)}
                        </Box>
                    </Box>
                </ClickAwayListener>
            </>
        )
    }
    renderNameTypePopup = () => {
        const { classes } = this.props
        return (
            <>
                {this.state.NameType.map((item: NameType) => (
                    <>
                        <MenuItem className={classes.menuItemText}
                            data-test-id="select_nameType"
                            key={item.id}
                            value={item.type}
                            onClick={() => this.selectNameType(item.type)}

                        >
                            {item.type}
                        </MenuItem>
                        <Divider className={classes.divider} />
                    </>
                ))}
            </>
        )
    }
    renderCountryCodePopupPopup = () => {
        const { classes } = this.props;
        return (
            <>
                {configJSON.countries.map((item: CountryCode, index: number) => (
                    <>
                        <MenuItem className={classes.menuItemText}
                            data-test-id="select_nameType"
                            key={index}
                            value={`${item.label},${item.phone}`}
                            onClick={() => this.selectCountryCode(item.label, item.phone)}
                        >
                            {item.label}&nbsp;{item.phone}
                        </MenuItem>
                        <Divider className={classes.divider} />
                    </>
                ))}
            </>
        )
    }
    renderInquiryTypePopup = (type: string) => {
        const { classes } = this.props;

        return (
            <>
                {this.state.inquiryType.map((inquiry: InquiryType,) => (
                    <>
                        <MenuItem className={classes.menuItemText}
                            data-test-id="select_inquiry"
                            key={inquiry.id}
                            value={inquiry.inquiryName}
                            onClick={() => this.handleSelectInquiryType(inquiry, type)}
                        >
                            {inquiry.inquiryName}
                        </MenuItem>
                        <Divider className={classes.divider} />
                    </>
                ))}
            </>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start

        const { classes } = this.props;
        return (
            <>
                <PetOwnerNavigationMenu navigation={this.props.navigation} id=""  navType="fullNavbar" activeLink={this.state.activeTab} />
                <CustomizedSnackbars
                    message={this.state.sentRequest}
                    toasterType="success"
                    duration={4000}
                    openToaster={this.state.openToasterStatus} />
                <Container maxWidth={false} className={classes.outerContainer}>
                    <Container maxWidth={'md'} className={classes.innerContainer}>
                        <Grid item xs={12} className={classes.contentLayout}>
                            <Grid container direction="row" justifyContent="center" spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.inputLabel}>Need to get in touch?</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" className={classes.inputLabelLight}>
                                        If you do have a specific question or need help resolving a problem,
                                        you can connect with our support team.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.inputLabelLight}>
                                        Send your feedback
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel}>
                                                First name*
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row">
                                                <Grid item xs={2} className={classes.position}>

                                                    <ContactUsTextFieldDropDownField
                                                        fullWidth
                                                        value={this.state.contactUSForm.nameType}
                                                        name="countryCode"
                                                        variant="outlined"
                                                       
                                                        InputProps={{
                                                            classes:{
                                                                input:classes.pointer
                                                            },
                                                            readOnly: true,
                                                            endAdornment: <img src={downArrow} />
                                                        }}
                                                        onClick={this.handleOpenNameTypePopup}
                                                        data-test-id="name_type_popup"
                                                    />
                                                    {this.state.namePopupStatus &&
                                                        this.renderDropdown('nameType')}
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <ContactUsTextField
                                                        placeholder="First Name"
                                                        onChange={this.handleContactUsForm}
                                                        data-test-id="full_name_input"
                                                        fullWidth
                                                        className={classes.numberInput}
                                                        value={this.state.contactUSForm.fullName}
                                                        name="fullName"
                                                        variant="outlined"
                                                        error={Boolean(this.state.contactUsErrorForm.fullName)}
                                                
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormHelperText component="p" style={{fontSize:14}}  error >{this.state.contactUsErrorForm.fullName}

                                                    </FormHelperText>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justifyContent="center" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel}>
                                                Last name *
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ContactUsTextField
                                                onChange={this.handleContactUsForm}
                                                fullWidth
                                                data-test-id="last_name_input"
                                                name="lastName"
                                                value={this.state.contactUSForm.lastName}
                                                variant="outlined"
                                                placeholder="Last Name"
                                                error={Boolean(this.state.contactUsErrorForm.lastName)}
                                                helperText={this.state.contactUsErrorForm.lastName}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justifyContent="center" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel}>
                                                Email *
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ContactUsTextField
                                                onChange={this.handleContactUsForm}
                                                fullWidth
                                                data-test-id="email_input"
                                                name="email"
                                                value={this.state.contactUSForm.email}
                                                variant="outlined"
                                                placeholder="kevinbarrett@iamacat.com"
                                                error={Boolean(this.state.contactUsErrorForm.email)}
                                                helperText={this.state.contactUsErrorForm.email}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justifyContent="center" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel}>
                                                Phone number *
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row">
                                                <Grid item xs={7} sm={6} className={classes.position}>

                                                    <ContactUsTextFieldDropDownField
                                                        fullWidth
                                                        value={this.state.contactUSForm.countryCode}
                                                        name="countryCode"
                                                        variant="outlined"
                                                        InputProps={{
                                                            classes:{
                                                                input:classes.pointer
                                                            },
                                                            readOnly: true,
                                                            endAdornment: <img src={downArrow} />
                                                        }}
                                                        onClick={this.handleOpenCountryCodePopup}
                                                        data-test-id="countryCode_popup"
                                                    />
                                                    {this.state.custoryCodeStatus &&
                                                        this.renderDropdown('countryCode')}
                                                </Grid>
                                                <Grid item xs={5} sm={6}>
                                                    <ContactUsTextField
                                                        onChange={this.handleContactUsForm}
                                                        data-test-id="phoneNumber_input"
                                                        fullWidth
                                                        className={classes.numberInput}
                                                        value={this.state.contactUSForm.phoneNumber}
                                                        name="phoneNumber"
                                                        variant="outlined"
                                                        error={Boolean(this.state.contactUsErrorForm.phoneNumber)}
                                    
                                                        placeholder="Phone Number"
                                                        InputProps={{ classes: { input: classes['input'] } }}

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormHelperText component="p" style={{fontSize:14}}  error >
                                                        {this.state.contactUsErrorForm.phoneNumber}

                                                    </FormHelperText>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel}>
                                                Contact Preference*
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} container >
                                            <div className={classes.radioFlex}>
                                                <CustomRadio
                                                    checked={this.state.contactUSForm.contactType === "Email"}
                                                    onChange={() => this.handleRadioSelectContact('Email')}
                                                    data-test-id="email_radio"
                                                />
                                                <Typography variant="subtitle2"
                                                    className={this.state.contactUSForm.contactType === "Email" ? classes.radioSelected : classes.radioNotSeleted}
                                                >Email</Typography>
                                            </div>
                                            <div className={classes.radioFlex} style={{ marginLeft: '35px' }}>
                                                <CustomRadio
                                                    data-test-id="phone_radio"
                                                    checked={this.state.contactUSForm.contactType === "Phone Number"}

                                                    onChange={() => this.handleRadioSelectContact('Phone Number')}
                                                />
                                                <Typography variant="subtitle2"
                                                    className={this.state.contactUSForm.contactType === "Phone Number" ? classes.radioSelected : classes.radioNotSeleted}

                                                >Phone Number</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="error">{this.state.contactUsErrorForm.contactType}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justifyContent="center" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel}>
                                                Inquiry Type
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box style={{ position: 'relative' }}>

                                                <Box className={classes.inquiryBox}
                                                    data-test-id='inquiry_box'
                                                    onClick={this.handleOpenEnquiryBox}
                                                >
                                                    <Box>
                                                        <Typography variant="subtitle2" className={classes.inputLabelLight}>
                                                            {this.state.contactUSForm.inquiryType ? this.state.contactUSForm.inquiryType : "Select"}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <KeyboardArrowDown />
                                                    </Box>
                                                </Box>
                                                {this.state.inquiryBoxStatus && this.renderDropdown('inquiry')}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {this.state.contactUSForm.inquiryType === 'Others' &&
                                    <Grid item xs={12}>
                                        <ContactUsTextField
                                            onChange={this.handleContactUsForm}
                                            data-test-id="message_input"
                                            multiline
                                            minRows={3}
                                            name='otherValue'
                                            value={this.state.contactUSForm.otherValue}
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(this.state.contactUsErrorForm.otherValue)}
                                            helperText={this.state.contactUsErrorForm.otherValue}
                                            placeholder="Write your inquiry here"
                                            className={classes.messageInput}

                                        />
                                    </Grid>}
                                <Grid item xs={12}>
                                    <Grid container direction="row" justifyContent="center" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel}>
                                                Message*
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ContactUsTextField
                                                onChange={this.handleContactUsForm}
                                                data-test-id="message_input"
                                                multiline
                                                minRows={5}
                                                maxRows={6}
                                                className={classes.messageInput}
                                                name='message'
                                                value={this.state.contactUSForm.message}
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(this.state.contactUsErrorForm.message)}
                                                helperText={this.state.contactUsErrorForm.message}
                                                placeholder="Write your message here"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justifyContent="center" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.inputLabel}>
                                                Attachment
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box style={{ position: 'relative' }}>

                                                <Box className={classes.inquiryBox}>
                                                    <Box>
                                                        <Typography variant="subtitle2"
                                                            className={classes.radioNotSeleted}
                                                        >
                                                            {this.state.documentUploadStatus
                                                                ? (this.state.contactUSForm.file && this.state.contactUSForm.file.name) || null
                                                                : "File Upload"}
                                                        </Typography>
                                                    </Box>
                                                    <Box style={{ cursor: 'pointer' }}>
                                                        <label htmlFor="icon-button-file">
                                                            <input

                                                                type="file"
                                                                name="pdfPath"
                                                                id="icon-button-file"
                                                                onChange={this.handleUploadFile}
                                                                style={{ display: "none" }}

                                                                data-test-id="upload_files"
                                                            />
                                                            <img src={attachment} style={{ cursor: 'pointer' }}/>

                                                        </label>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="error">{this.state.pdfError}</Typography>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullWidth variant="contained" color="secondary"
                                        onClick={this.handleDetailsSubmit}
                                        data-test-id="submit_button"
                                        className={classes.submitButton}>Submit Request</Button>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Container>
                    <Grid item xs={12} className={classes.footerLayout}>
                        <Footer footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData}/>
                    </Grid>
                </Container>
                <Loader loading={this.state.pageLoader}/>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export const ContactUsHelpStyles = (theme: Theme) =>
    createStyles({
        outerContainer: {
            background: theme.palette.primary.main,
            marginTop: 120,
            height: 'auto'
        },
        innerContainer: {
            background: theme.palette.common.white,
            minHeight: 792
        },
        contentLayout: {
            padding: "2.8rem",
            [theme.breakpoints.down('xs')]: {
                padding: '0.5rem'
            }
        },
        inputLabel: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold
        },

        inputLabelLight: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightRegular
        },
        numberInput: {
            "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #CBD5E1",
                borderRadius: " 0 8px 8px 0"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #CBD5E1",
                borderRadius: "0 8px 8px 0"
            },
        },
        nameType: {
            width: "120px"
        },
        submitButton: {
            color: theme.palette.common.white,
            fontSize: 16,
            height: 56,
            borderRadius: 8,
            textTransform: 'capitalize',
            fontWeight: theme.typography.fontWeightBold,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.secondary.main
            }
        },

        nameTypeOuterBox: {
            position: "absolute" as const,
            zIndex: 9,
            width: "100%",
            heigth: '100%',
            maxHeight: "fit-content",
            borderRadius: 8,
            backgroundColor: "#ffffff",
            boxShadow: boxShadows.whiteShadow,

        },
        countryOuterBox: {
            position: "absolute" as const,
            zIndex: 9,
            width: "100%",
            minHeight: "fit-content",
            borderRadius: 8,
            backgroundColor: "#ffffff",
            boxShadow: boxShadows.whiteShadow,
            height: '320px',
            overflowY: 'auto'

        },
        popUpInnerBox: {
            padding: "1rem"
        },
        position: {
            position: 'relative'
        },
        footerLayout: {
            marginTop: 120,

        },
        divider: {
            background: "#F1F5F9"
        },
        menuItemText: {
            color: theme.palette.common.black,
            fontSize: 14,
            padding: '1rem',
            fontWeight: theme.typography.fontWeightRegular
        },
        inquiryBox: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            border: "1px solid #CCEDFA",
            background: "#E0F4FC",
            height: '44px',
            padding: "5px 16px",
            borderRadius: 8,
            position: 'relative'

        },
        radioFlex: {
            display: 'flex',
            alignItems: 'center'
        },
        radioSelected: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold
        },
        radioNotSeleted: {
            color: "#A6B0BF",
            fontWeight: theme.typography.fontWeightRegular
        },
        'input': {
            '&::placeholder': {
                textOverflow: 'ellipsis !important',
                color: "#64748B",
                [theme.breakpoints.down('xs')]: {
                    fontSize: 12
                }
            }
        },
        pointer:{
        cursor:'pointer'
        },
        messageInput:{
        
                "& .MuiInputBase-root": {
                     height: 'auto !important'
                },
               
    }
    })
export default withStyles(ContactUsHelpStyles)(ContactUsHelp);
// Customizable Area End