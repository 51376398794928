import React from "react";
// Customizable Area Start
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography,
    FormControlLabel,
    Box,
    RadioGroup
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { theme } from "../../../components/src/theme";
import { CustomRadio } from "../../user-profile-basic/src/BussinessOwnerDetailsThird.web";
import { SubscriptionList } from "./SubscriptionController.web";
import CustomizedDailog from "../../../components/src/CustomizedDailog.web";

// Customizable Area End

// Customizable Area Start
interface Props {
    dailogOpen: boolean;
    dailogType: string;
    closeDailog: () => void;
    handleChangePopupPlan: () => void
    cancelSubscription: () => void
    subscriptionList: SubscriptionList[];
    subscriptionType: string;
    selectSubscription: any;
    opnePlanDescription: any;
    descriptionDialog: boolean
    handleClose: any;
    description: string
    changePlanPyment: () => void
    errorMessage: string | null
}

// Customizable Area End


export default class SubscriptionDailogs extends React.Component <Props>{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    subscriptionSeeMore = () => {
        const { description } = this.props;
        return (
            <>

                <Box paddingLeft={"30px"} paddingRight={"30px"} marginTop={"20px"}>
                    <Typography variant="body1" color="textPrimary" >
                        {description && description.split(/\r?\n/).map((line: string, index: number) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </Typography>
                </Box>
            </>
        )
    }

    renderDescription = () => {
        return (

            <>
                {this.props.subscriptionList.map((subList: SubscriptionList) => {
                    const maxLength = 20;

                    const description = subList?.description || '';
                    const isLongDescription = description.length > maxLength;
                    return (<Box style={webStyles.planBox} key={subList.id}>
                        <RadioGroup name="room_for" value={this.props.subscriptionType} onChange={() => this.props.selectSubscription(subList)}
                            data-test-id='select_subscription'
                        >
                            <FormControlLabel value={subList.duration_type} control={<CustomRadio />} label="" style={{ marginLeft: '10px' }} />
                        </RadioGroup>
                        <Grid item xs={12} style={webStyles.planContent as React.CSSProperties}>
                            <Typography variant="h3" style={webStyles.dailogHeading} gutterBottom>
                                {subList?.name} {subList?.duration_type !== "commission_base" && ` | £${subList?.price?.toFixed(2)}`}</Typography>
                            <Typography variant="h6" style={webStyles.dailogHeading} gutterBottom>
                                {!isLongDescription
                                    ? description
                                    : `${description.slice(0, maxLength)}... `}
                                {subList.description.length > 20 && <Typography style={webStyles.dailogSubHeading as React.CSSProperties}
                                    onClick={() => this.props.opnePlanDescription(subList?.description)}
                                    variant="subtitle1" display="inline"
                                    data-test-id='see_more_description'
                                >See More</Typography>}
                            </Typography>
                        </Grid>


                    </Box>)
                })}
            </>

        )
    }



    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <>
                <Dialog open={this.props.dailogOpen} maxWidth={'md'}
                    aria-labelledby="form-dialog-title"
                    onClose={this.props.closeDailog}
                    PaperProps={{
                        style: {
                            maxWidth: "670px",
                            width: '100%',
                            height: this.props.dailogType === 'planSelection' ? 'auto' : "312px",
                            borderRadius: this.props.dailogType === 'planSelection' ? "8px 8px 32px 8px" : '8px',
                        },
                    }}
                >
                    <DialogTitle style={webStyles.closeIconLayout}>
                        <CloseIcon onClick={this.props.closeDailog} style={webStyles.iconCursor} data-test-id="closeDailog" />
                    </DialogTitle>
                    <DialogContent style={this.props.dailogType === 'planSelection' ? webStyles.planBoxLayout as React.CSSProperties : webStyles.dailogContent as React.CSSProperties}>

                        {this.props.dailogType === 'planSelection' && <div style={{ width: '100%' }}>
                            {this.renderDescription()}
                        </div>}
                        {this.props.dailogType === 'planSelection' &&
                            <Grid item xs={12}>
                                <Typography color="error" variant="subtitle2">{this.props.errorMessage}</Typography>

                            </Grid>
                        }
                        {this.props.dailogType === 'changePlan' &&
                            <>
                                <Typography variant="h4" style={webStyles.dailogHeading} gutterBottom>
                                    Change Plan?

                                </Typography>
                                <Typography variant="subtitle2" style={webStyles.dailogSubHeading} gutterBottom>

                                    Are you sure you want to change your plan
                                </Typography>
                            </>
                        }
                        {this.props.dailogType === 'cancel' &&
                            <>
                                <Typography variant="h4" style={webStyles.dailogHeading} gutterBottom>
                                    Cancel Subscription
                                </Typography>
                                <Typography variant="subtitle2" style={webStyles.dailogSubHeading} gutterBottom>
                                    Are you sure you want to cancel your subscription?
                                </Typography>
                            </>
                        }
                    </DialogContent>
                    <Divider />


                    <DialogActions style={this.props.dailogType === 'planSelection' ? webStyles.planSelectionPadding : webStyles.dailogButtonPadding}>

                        {this.props.dailogType === 'planSelection' ?


                            <Button variant="contained" color={'secondary'}
                                style={webStyles.changePlanButton as React.CSSProperties}
                                data-test-id='planButton'
                                onClick={this.props.changePlanPyment}
                            >
                                Change Plan
                            </Button>
                            :
                            <>
                                <Button variant="outlined" color={'secondary'}
                                    style={webStyles.noButton as React.CSSProperties}
                                    onClick={this.props.closeDailog}
                                    data-test-id='NoButton'
                                >
                                    No
                                </Button>
                                <Button variant="contained" color={'secondary'}
                                    style={webStyles.yesButton as React.CSSProperties}
                                    onClick={this.props.dailogType === 'cancel' ? this.props.cancelSubscription : this.props.handleChangePopupPlan}
                                    data-test-id='cancel_subscription'
                                >
                                    Yes
                                </Button>
                            </>

                        }



                    </DialogActions>
                </Dialog>
                <CustomizedDailog
                    open={this.props.descriptionDialog}
                    handleClose={this.props.handleClose}
                    data-test-id="handle_PropertyModal_Close"
                    typeOfModel={'description'} dailogTitle={this.subscriptionSeeMore} />



            </>
        );
        // Customizable Area End

    }
}

// Customizable Area Start
const webStyles = {
    confirmText: {
        color: theme.palette.common.black,
        fontWeight: 400,
        fontSize: "18px",
    },
    dailogButtonPadding: {
        padding: '32px'
    },
    yesButton: {
        width: '100%',
        textTransform: 'capitalize',
        height: 56,
        borderRadius: 8,
        fontSize: 16,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        },
    }, noButton: {
        border: `2px solid ${theme.palette.secondary.main}`,
        textTransform: 'capitalize',
        height: 56,
        borderRadius: 8,
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            border: `2px solid ${theme.palette.secondary.main}`,

        },
        width: '100%'
    },
    iconCursor: {
        cursor: 'pointer'
    },
    closeIconLayout: {
        padding: 10,
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: 'center'
    },
    dailogHeading: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold
    },
    dailogSubHeading: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightRegular
    },
    dailogContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'

    },
    planBoxLayout: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: "50px"

    },
    planSelectionPadding: {
        padding: '16px'
    },
    planBox: {
        border: "1px solid #CBD5E1",
        height: '106px',
        borderRadius: "8px",
        width: '100%',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center'
    },
    changePlanButton: {
        width: '70%',
        textTransform: 'capitalize',
        height: 56,
        borderRadius: 8,
        fontSize: 16,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        },
        margin: 'auto'
    }, planText: {
        color: '#64748B',
        fontWeight: theme.typography.fontWeightRegular
    },
    planContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    }
}
// Customizable Area End