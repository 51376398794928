import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { DateObject } from "react-multi-date-picker";
import { WithStyles } from "@material-ui/core";
import { setStorageData, getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { dummyImage } from "../../catalogue/src/assets";
import { AccountDetails, HotelList, Root } from "./types";
import { checkApiErrorResponce, handleNavigation } from "../../../components/src/CommonFunctions";
import { handleLogoutUser } from "../../../components/src/CommonFunctions";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

type HotelsListFunction = () => void;
export interface LandingPageCardInterface {
  hotels: string;
}
export interface CardData {
  address?: string;
  name?: string;
  description?: string;
  daycare_price?: string;
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  token?: string;
  body?: object;
  type?: string;
}

interface SocialLinksData {
  id: number,
  type: string,
  attributes: {
    id: number,
    social_link: string,
    icon: {
      url: string,
      size: number
      name: string,
      content_type: string,
    }
  };
}
interface LinkObject {
  title: string;
  url: string;
}
interface SocialLinks {
  social_links: {
    data: Array<SocialLinksData>
  };
}
// Customizable Area End
export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  searchType?: string
  hotelsList?: HotelsListFunction
  searchFilterPage?: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuOpen: boolean;
  anchorEl: HTMLDivElement | null;
  petMenuOpen: boolean;
  anchorElPet: HTMLButtonElement | null | HTMLDivElement;
  addRoomCat: number;
  addRoomDog: number;
  checkInOut: boolean;
  inOutAnchorElPet: HTMLButtonElement | null;
  cardData: any[];
  selectedRange: DateObject[];
  inputRange: string;
  selectedOption: string;
  locationName: string;
  locationPopupStatus: boolean;
  landingPageLoader: boolean;
  selectedDateRange: string;
  openSnckBar: boolean;
  navigationPath: string;
  check_in_date: any,
  check_out_date: any;
  dogHotelList: HotelList[];
  catHotelList: HotelList[],
  selectedType: string,
  imageUrl: string,
  toasterStatus: boolean,
  toasterText: string,
  linksData: { icons: string, navigationUrl: string }[]
  poLinksData: LinkObject[];

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdGetHotelsList: string = "";
  apiCallIdGetCatHotelsList: string = "";
  apiCallIdGetDogHotelsList: string = "";
  apiCallIdAccountInformation: string = ''
  socialLinksCallId: string = "";
  poLinksCallId: string = "";
  boLinksCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      menuOpen: false,
      anchorEl: null,
      petMenuOpen: false,
      anchorElPet: null,
      addRoomCat: 0,
      addRoomDog: 0,

      checkInOut: false,
      inOutAnchorElPet: null,
      cardData: [],
      inputRange: "",
      selectedRange: [],
      selectedDateRange: "",
      selectedOption: "",
      locationName: "",
      locationPopupStatus: false,
      landingPageLoader: false,
      openSnckBar: false,
      navigationPath: "",
      check_in_date: null,
      check_out_date: null,
      dogHotelList: [],
      catHotelList: [],
      selectedType: "",
      imageUrl: "",
      toasterStatus: false,
      toasterText: '',
      linksData: [],
      poLinksData: [],


      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = (await getStorageData("authToken")) || "";
    const userType = await getStorageData("userType") || "";
    if (userType === "BO") {
      handleNavigation("PropertyDetails", {}, this.send, this.props);
    }

    this.getSocialLinks();
    {
      token && this.getPOLink();
    }
    {
      token && this.getAccountDetails();
    }

    const LandingPagePO = window.location.pathname === '/LandingPagePO';
    if (LandingPagePO) {
      this.getDogHotelList("dog_hotel");
      this.getCatHotelList("cat_hotel");
      this.getHotelsList("dog_cat_hotel");
    }
    const selectHotelID = JSON.parse(await getStorageData('serchBarData')) || {};
    if (LandingPagePO) {
      this.setState({
        locationName: "",
        selectedDateRange: "",
        addRoomDog: 0,
        addRoomCat: 0,
      });
    } else {
      this.setState({
        locationName: selectHotelID.locationName || "",
        selectedDateRange: selectHotelID.selectedDateRange || "",
        addRoomDog: selectHotelID.addRoomDog || 0,
        addRoomCat: selectHotelID.addRoomCat || 0,
      });
      if (selectHotelID.selectedDateRange) {
        this.setState({
          navigationPath: "LandingPagePO",
        });
      }
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    switch (apiRequestCallId) {
      case this.apiCallIdGetHotelsList:
        this.handleHotelListResponse(responseJson, "cardData");
        break;
      case this.apiCallIdGetCatHotelsList:
        this.handleHotelListResponse(responseJson, "catHotelList");
        break;
      case this.apiCallIdGetDogHotelsList:
        this.handleHotelListResponse(responseJson, "dogHotelList");
        break;
      case this.apiCallIdAccountInformation:
        this.handleAccountInfoResponse(responseJson);
        break;
      case this.socialLinksCallId:
        this.socialLinksSuccessCallBack(responseJson);
        break;
      case this.poLinksCallId:
        this.poLinksSuccessCallBack(responseJson);
        break;
      default:
        break;
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handleHotelListResponse(responseJson: Root, stateKey: keyof S) {
    if (responseJson && !responseJson.errors) {
      window.scrollTo(0, 0);
      const newState: Partial<S> = {
        landingPageLoader: false,
      };
      newState[stateKey] = responseJson.hotels.data || [];
      this.setState(newState as S);
    }
  }

  handleLogout = async () => {
    const userCred = await getStorageData("userCred");
    const myToken = sessionStorage.getItem("randomKey") || null;
    if (!userCred && !myToken) {
      const userCred = await getStorageData("userCred");
      handleLogoutUser();
      if (userCred) setStorageData("userCred", userCred);
      handleNavigation("LogInPetOwner", {}, this.send, this.props);
    }
  };

  handleAccountInfoResponse = (responseJson: AccountDetails) => {
    if (responseJson && !responseJson.errors) {
      window.scrollTo(0, 0);
      this.setState({ imageUrl: responseJson.data.attributes.photo }, () => {
        setStorageData(
          "accountDetails",
          JSON.stringify(responseJson.data.attributes)
        );
        this.handleLogout();
      });
    } else if (responseJson && responseJson.errors) {
      this.setState({
        toasterStatus: true,
        toasterText: "Your session expired. Login in again to proceed",
      });
      checkApiErrorResponce(responseJson, this.props.navigation);
    }
  };
  catsIncrement = () => {
    if (this.state.addRoomCat < 30) {
      this.setState((prevState) => ({
        addRoomCat: prevState.addRoomCat + 1,
      }));
    }
  };

  handleCloseSnackBar = () => {
    this.setState({
      openSnckBar: false,
    });
  };

  handleOpenSnackBar = () => {
    this.setState({
      openSnckBar: true,
    });
  };
  catsDecrement = () => {
    if (this.state.addRoomCat > 0) {
      this.setState((prevState) => ({
        addRoomCat: prevState.addRoomCat - 1,
      }));
    }
  };

  addPetsPopupOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElPet: event.currentTarget, petMenuOpen: true });
  };

  decrementDogs = () => {
    if (this.state.addRoomDog > 0) {
      this.setState({ addRoomDog: this.state.addRoomDog - 1 });
    }
  };
  incrementDogs = () => {
    if (this.state.addRoomDog < 30) {
      this.setState({ addRoomDog: this.state.addRoomDog + 1 });
    }
  };
  addPetsPopupClose = () => {
    this.setState({ anchorElPet: null, petMenuOpen: false });
  };
  datePopupOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ inOutAnchorElPet: event.currentTarget, checkInOut: true });
  };

  datePopupClose = () => {
    this.setState({ checkInOut: false });
  };
  handleDateChange = (values: DateObject[]) => {
    if (values.length === 2 && values[0] && values[1]) {
      const startDate = values[0].format("DD MMM");
      const endDate = values[1].format("DD MMM");
      const startDate1 = values[0].format("YYYY-MM-DD");
      const endDate1 = values[1].format("YYYY-MM-DD");
      const inputRange = `${startDate} - ${endDate}`;
      this.setState({
        selectedRange: values,
        inputRange,
        selectedDateRange: inputRange,
        check_in_date: startDate1,
        check_out_date: endDate1,
      });
    }
  };

  handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ locationName: event.target.value });
  };

  handleLocationListPopup = () => {
    this.setState({ locationPopupStatus: true });
  };
  handleLoactionListclose = () => {
    this.setState({ locationPopupStatus: false });
  };
  checkHotelDetails = (HotelId: number | string) => {
    setStorageData("selectedHotelId", HotelId);
    setStorageData("pagecomingFrom", "landingPage");
    this.props.navigation.navigate("PetDetailsPage");
  };

  handleSearch = async () => {
    const searchData = await getStorageData("serchBarData", true);

    const checkIn = searchData && searchData.checkIn;
    const checkOut = searchData && searchData.checkOut;

    if (this.state.selectedDateRange) {
      const serchBarData = {
        locationName: this.state.locationName,
        selectedDateRange: this.state.selectedDateRange,
        addRoomDog: this.state.addRoomDog,
        addRoomCat: this.state.addRoomCat,
        checkIn: this.state.check_in_date ? this.state.check_in_date : checkIn,
        checkOut: this.state.check_out_date
          ? this.state.check_out_date
          : checkOut,
      };
      setStorageData("serchBarData", JSON.stringify(serchBarData));

      if (this.props.searchType === "catalogue") {
        this.props.hotelsList && this.props.hotelsList();
        this.props.searchFilterPage && this.props.searchFilterPage();
      } else {
        handleNavigation("HotelsCatalogue", {}, this.send, this.props);
      }
    } else {
      this.handleOpenSnackBar();
    }
  };

  // `${configJSON.dayCareEndpoint.replace("{hotelId}",selectHotelID)}`

  getCatHotelList = async (petType: string) => {
    this.apiCallIdGetCatHotelsList = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.featuredHotelListEndPoint.replace(
        "{petType}",
        petType
      )}`,
    });
  };

  getDogHotelList = async (petType: string) => {
    this.apiCallIdGetDogHotelsList = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.featuredHotelListEndPoint.replace(
        "{petType}",
        petType
      )}`,
    });
  };
  getHotelsList = async (petType: string) => {
    this.apiCallIdGetHotelsList = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.featuredHotelListEndPoint.replace(
        "{petType}",
        petType
      )}`,
    });
  };

  handleViewAll = async (type: string) => {
    removeStorageData("serchBarData");
    this.setState({ selectedType: type }, () => {
      handleNavigation(
        "HotelsCatalogue",
        { filterType: type },
        this.send,
        this.props
      );
    });
  };

  getAccountDetails = async () => {
    const loginHeader = {
      "Content-Type": "application/json",
      token: await getStorageData("authToken"),
    };

    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountInformationEmdPoint
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(loginHeader)
    );
    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    this.apiCallIdAccountInformation = loginRequestMessage.messageId;
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  };

  socialLinksSuccessCallBack = (responseJson: SocialLinks) => {
    const socialLinksData = responseJson.social_links.data;
    const linksDataIcon: { icons: string; navigationUrl: string }[] = [];
    socialLinksData.forEach(function (socialLink: SocialLinksData) {
      linksDataIcon.push({
        icons: socialLink.attributes.icon.url,
        navigationUrl: socialLink.attributes.social_link,
      });
    });
    this.setState({ linksData: linksDataIcon });
    setStorageData("footerNavigationUrl", JSON.stringify(linksDataIcon));
  };
  poLinksSuccessCallBack = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const poLinks = responseJson?.po_links?.data;
      const poLinksMapping = poLinks.map((link: any) => {
        return {
          title: link.attributes.title,
          url: link.attributes.url,
        };
      });
      this.setState({ poLinksData: poLinksMapping });
      localStorage.setItem("BOandPOLinks", JSON.stringify(poLinksMapping));
    }
  };

  getPOLink = async () => {
    this.poLinksCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.poLinksEndPoint,
    });
  };

  getSocialLinks = async () => {
    this.socialLinksCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.socialLinksEndPoint,
    });
  };

  apiCall = async (apiData: APIPayloadType) => {
    const { method, endPoint } = apiData;

    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  //Customizable Area End
}
